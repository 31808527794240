import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../contexts/ConfigContext';
import { useDynamicNavLink } from './useDynamicNavLink';

export interface HeaderLink {
  title: string;
  path: string;
  isPathVisible: boolean;
  dataTestId: string;
}

export const useHeaderNavLinks = () => {
  const { t } = useTranslation();
  const { featureFlags } = useContext(ConfigContext);

  const links: HeaderLink[] = [
    {
      title: t('entries.homepage.title', { defaultValue: 'Home' }),
      path: '/home',
      isPathVisible: true,
      dataTestId: 'home'
    },
    {
      title: t('entries.catalog.title', { defaultValue: 'Catalog' }),
      path: '/catalog',
      isPathVisible: true,
      dataTestId: 'catalog'
    },
    {
      title: t('entries.tabs.favourites', { defaultValue: 'Favourites' }),
      path: '/favourites',
      isPathVisible: featureFlags?.showFavourites,
      dataTestId: 'catalog'
    },
    {
      title: t('entries.header.rewards', { defaultValue: 'Rewards' }),
      path: '/rewards',
      isPathVisible: featureFlags?.enableRewards,
      dataTestId: 'rewards'
    },
    {
      title: t('entries.header.orders', { defaultValue: 'Orders' }),
      path: '/orders',
      isPathVisible: true,
      dataTestId: 'orders'
    },
    {
      title: t('entries.tabs.promotions', { defaultValue: 'Promotions' }),
      path: '/promotions',
      isPathVisible: featureFlags?.showPromotionPage,
      dataTestId: 'promotions'
    },
    {
      title: t('entries.tabs.discover', { defaultValue: 'Discover' }),
      path: '/discover',
      isPathVisible: featureFlags?.showDiscover,
      dataTestId: 'discover'
    }
  ];

  const isHeaderDesktopVersion = useDynamicNavLink(links);

  return {
    links,
    isHeaderDesktopVersion
  };
};
