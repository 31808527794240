import { Cloudinary } from '@cloudinary/url-gen';

const cloudinaryCloudName = 'www-carlsberg-com';
export const cloudinaryConfig = new Cloudinary({
  cloud: { cloudName: cloudinaryCloudName },
  url: {
    analytics: false, // this avoid suffix on url -> https://cloudinary.com/documentation/cloudinary_sdks#analytics_config
    secure: true // force https, set to false to force http
  }
});
