import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../../UI-elements/Text';
import { DistributorSteps } from './Distributor';
import styles from './distributor.module.scss';

interface Props {
  step: DistributorSteps;
  showSteps: boolean;
}

export const DistributorModalHeader: React.FC<Props> = ({ step, showSteps }) => {
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const textIsCentered = isXSmall || isSmall || isMedium;
  const { t } = useTranslation();

  const getStepsNavigation = () => {
    if (step === DistributorSteps.OUTLET_SELECT) {
      return `1 ${t('entries.selection.of', { defaultValue: 'of' })} 2`;
    }
    if (step === DistributorSteps.DISTRIBUTOR_SELECT) {
      return `2 ${t('entries.selection.of', { defaultValue: 'of' })} 2`;
    }
    return '';
  };

  const getTitle = () => {
    if (step === DistributorSteps.OUTLET_SELECT) {
      return t('entries.selection.outletTitle', { defaultValue: 'Choose the outlet you want to order' });
    }
    if (step === DistributorSteps.DISTRIBUTOR_SELECT) {
      return t('entries.selection.distributorTitle', {
        defaultValue: 'Now choose a Distributor to supply your outlet'
      });
    }
    return '';
  };

  return (
    <div>
      {showSteps && (
        <Text
          size={FONT_SIZE.SMALL}
          color={TEXT_COLOR.GREY}
          align={textIsCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT}
          weight={FONT_WEIGHT.SEMI_BOLD}
          className={styles.stepNumber}
          dataTestId="distributor-selection-step-information"
        >
          {getStepsNavigation()}
        </Text>
      )}
      <Text
        size={FONT_SIZE.LARGE}
        weight={FONT_WEIGHT.BLACK}
        align={textIsCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT}
      >
        {getTitle()}
      </Text>
    </div>
  );
};
