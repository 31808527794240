import React, { FC } from 'react';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../../UI-elements/Text';

interface Props {
  label?: string;
  formattedPrice: string;
  size: FONT_SIZE;
  align?: TEXT_ALIGN;
  weight?: FONT_WEIGHT;
  strikethrough?: boolean;
  color?: TEXT_COLOR;
  dataTestId?: string;
}

const PriceRenderer: FC<Props> = ({
  align = TEXT_ALIGN.LEFT,
  label,
  formattedPrice,
  weight = FONT_WEIGHT.REGULAR,
  strikethrough = false,
  size,
  color = TEXT_COLOR.DEFAULT,
  dataTestId = ''
}) => (
  <Text dataTestId={dataTestId} align={align} strikethrough={strikethrough} weight={weight} color={color} size={size}>
    {label && `${label} `}
    {formattedPrice}
  </Text>
);

export { PriceRenderer };
