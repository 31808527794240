import React from 'react';
import { MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY_IN_TOOLTIP } from '../../../global/constants';
import styles from './productsTooltip.module.scss';
import { ProductsTooltipItem, TooltipProductData } from './ProductsTooltipItem';

interface ProductsTooltipListProps {
  productsTooltip: TooltipProductData[];
}

const ProductsTooltipList = ({ productsTooltip }: ProductsTooltipListProps) => {
  if (!productsTooltip.length) {
    return null;
  }

  return (
    <ul className={styles.tooltipProductsList} data-testid="tooltip-list">
      {productsTooltip.slice(0, MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY_IN_TOOLTIP).map((data) => (
        <li className={styles.tooltipProductsItem} key={data.sku} data-testid="tooltip-product-item">
          <ProductsTooltipItem dataTestId="tooltip-product" productsTooltip={data} />
        </li>
      ))}
    </ul>
  );
};
export { ProductsTooltipList };
