import { configNames } from './configNames';
import { ApiConfig, CSPlusConfig, SSOConfig } from './configTypes';
import { getGlobalConfig } from './getGlobalConfig';

const configBase = `${window.location.origin}/__cx__conf__`;

const urls = {
  [configNames.csplusConfig]: getGlobalConfig().appConfigApi,
  [configNames.apiConfig]: `${configBase}/global/api.json?1`,
  [configNames.ssoConfig]: `${configBase}/global/sso.json?1`
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configPromise: Map<string, any> = new Map();

export const getConfig = async (name: configNames) => {
  if (process.env.NODE_ENV !== 'test' && configPromise.get(name)) {
    return configPromise.get(name);
  }

  const response = await fetch(urls[name], {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (name === configNames.csplusConfig) {
    // csplusconfig is served by aws lambda. Config is wrapped inside an extra data property
    const data: { version: number; data: CSPlusConfig } = await response.json();

    const promise = new Promise((resolve, _reject) => {
      resolve(data?.data);
    });

    configPromise.set(name, promise);
  } else {
    configPromise.set(name, await response.json());
  }

  return configPromise.get(name);
};

export const getCSPlusConfig = (): Promise<CSPlusConfig> => getConfig(configNames.csplusConfig);
export const getApiConfig = (): Promise<ApiConfig> => getConfig(configNames.apiConfig);
export const getSSOConfig = (): Promise<SSOConfig> => getConfig(configNames.ssoConfig);
