import cn from 'classnames';
import React, { FC } from 'react';
import { useArticlesPerSize } from '../../../../global/custom-hooks/useArticlesPerSize';
import { IArticles } from '../../../../services/discover/types';
import Article from './Article';
import styles from './articlesList.module.scss';

interface IProps {
  articlesList: IArticles[];
}
const ArticlesList: FC<IProps> = ({ articlesList }) => {
  const articlesPerRow = useArticlesPerSize();
  return (
    <div className={cn(styles.articles, styles[`visibleArticles-${articlesPerRow}`])}>
      {articlesList && articlesList.map((article: IArticles) => <Article article={article} key={article.id} />)}
    </div>
  );
};

export { ArticlesList };
