import { AdvancedImage } from '@cloudinary/react';
import React, { memo } from 'react';
import { cloudinaryConfig } from '../../global/utils/cloudinaryUtils';

interface CloudinaryImageProps {
  src: string;
  cssClassName?: string;
  dataTestId?: string;
  alt: string;
  width?: number;
  height?: number;
  type?: string;
  disableAutoFormat?: boolean;
}
export const getCloudinaryFetchImageUrl = (src: string) =>
  cloudinaryConfig.image(src).setDeliveryType('fetch').quality('auto').format('auto').toURL();

export const CloudinaryImage: React.FC<CloudinaryImageProps> = memo(
  ({ src, type = 'fetch', alt, cssClassName = '', dataTestId = '', width, height, disableAutoFormat }) => {
    // if src from umbraco is null or undefined this would return a broken image tag
    if (!src) {
      return null;
    }

    let cloudinaryImageUrl = cloudinaryConfig.image(src).setDeliveryType(type).quality('auto');

    // If the image is a svg format, cloudinary is sending as webp with auto format and image is loosing its quality
    // So we are handling auto format for svg images by passing disableAutoFormat prop
    if (!disableAutoFormat) {
      cloudinaryImageUrl = cloudinaryImageUrl.format('auto');
    }

    return (
      <AdvancedImage
        data-testid={dataTestId}
        className={cssClassName}
        alt={alt}
        cldImg={cloudinaryImageUrl}
        width={width}
        height={height}
        fetchpriority="high"
      />
    );
  }
);
