import React, { useContext } from 'react';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './footer.module.scss';

interface Props {
  brandName: string;
}

export const FooterBrand: React.FC<Props> = ({ brandName }) => {
  const { country = '' } = useContext(ConfigContext);

  return (
    <div className={styles['company-label']}>
      {country && (
        <Text
          color={TEXT_COLOR.WHITE}
          size={FONT_SIZE.SMALL}
          weight={FONT_WEIGHT.SEMI_BOLD}
          dataTestId="footer-brand-name"
        >
          {brandName}
        </Text>
      )}
    </div>
  );
};
