import { Checkbox } from '@carlsberggroup/malty.atoms.checkbox';
import { Link, LinkStyle } from '@carlsberggroup/malty.atoms.link';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterValue } from '../../../../global/interfaces/filters';
import styles from '../filters.module.scss';
import { FiltersAccordionProps } from './FiltersAccordion';

export const FILTER_INITIAL_LIMIT = 10;

const FiltersDropDownContent = ({ filter, index, handleOnChange, dataTestId }: FiltersAccordionProps) => {
  const { t } = useTranslation();
  const [seeAllFilters, setSeeAllFilters] = useState(false);

  const values = 'values' in filter ? filter.values : [];

  return (
    <div className={cn(styles['filter-values-wrapper'])}>
      {values
        .slice(0, seeAllFilters ? values.length : FILTER_INITIAL_LIMIT)
        .map((value: FilterValue, subFilterIndex: number) => (
          <label key={value.code} className={styles['filter-values']} data-testid={`${filter.code}-${value.code}`}>
            <Checkbox
              dataTestId={`${dataTestId}-${value.code}-checkbox`}
              checked={value.selected}
              labelText={
                value.label
                  ? value.label
                  : t(`entries.filterCode.${value.code.replace(/\s/g, '_')}`, { defaultValue: value.code })
              }
              value={value.code}
              onValueChange={(event) => {
                const checkEvent = event.target.checked;
                handleOnChange(index, subFilterIndex, checkEvent, filter.code, value.label, value.code);
              }}
            />
          </label>
        ))}

      {values.length > FILTER_INITIAL_LIMIT && (
        <Link linkStyle={LinkStyle.MediumSmallBold} onClick={() => setSeeAllFilters(!seeAllFilters)}>
          {seeAllFilters
            ? t('entries.filters.seeLessOptions', { defaultValue: 'See less options' })
            : `${t('entries.filters.seeAllOptions', { defaultValue: 'See all options' })} (${
                values.length - FILTER_INITIAL_LIMIT
              })`}
        </Link>
      )}
    </div>
  );
};

export { FiltersDropDownContent };
