import { RECOMMENDATIONS_CODES } from '../../../../global/constants';
import { actions } from './constants';

export interface setActiveChipAction {
  activeChip: RECOMMENDATIONS_CODES | string;
  recommendationsCatalogId: number;
}

export const setRecommendationActiveChip = ({ activeChip, recommendationsCatalogId }: setActiveChipAction) => ({
  activeChip,
  recommendationsCatalogId,
  type: actions.SET_RECOMMENDATION_CATALOG_ACTIVE_CHIP
});
