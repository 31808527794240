import { Button, ButtonSize, ButtonStyle, ButtonType } from '@carlsberggroup/malty.atoms.button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { DistributorI } from '../../../global/interfaces/distributor';
import { DistributorSteps } from './Distributor';
import styles from './distributor.module.scss';

interface Props {
  uiSelectedDistributor: DistributorI;
  isConfirming: boolean;
  setDistributorStep: (step: DistributorSteps) => void;
  setIsConfirming: (isConfirming: boolean) => void;
}

export const ConfirmButton: React.FC<Props> = ({
  uiSelectedDistributor,
  isConfirming,
  setDistributorStep,
  setIsConfirming
}) => {
  const { t } = useTranslation();
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  const confirm = () => {
    setIsConfirming(true);
    setDistributorStep(DistributorSteps.CONFIRM_TRACKED_CHANGES);
  };

  return (
    <div className={styles.confirm}>
      <Button
        text={t('entries.selection.confirm', { defaultValue: 'Confirm' })}
        type={ButtonType.Submit}
        onClick={confirm}
        style={ButtonStyle.Primary}
        disabled={!uiSelectedDistributor || isConfirming}
        dataTestId="confirm-button"
        fullWidth
        size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
      />
    </div>
  );
};
