// eslint-disable because we need to use camelcase for the GA events.
/* eslint-disable camelcase */

export enum GAEvent {
  AddFavouritesClick = 'add_to_favourites',
  AddToCart = 'add_to_cart',
  AlertMessages = 'alert_messages',
  CancelOrder = 'cancel_order',
  CleanCart = 'clean_cart',
  ClickInternal = 'click_internal',
  ClickPromotion = 'select_promotion',
  Checkout = 'begin_checkout',
  Evaluation = 'evaluation',
  FilterSelection = 'filter_selection',
  FooterClick = 'footer_click',
  Login = 'login',
  Logout = 'logout',
  MenuClick = 'menu_click',
  MenuSecClick = 'menu_sec_click',
  PageView = 'page_view',
  Pagination = 'pagination',
  Purchase = 'purchase',
  RemoveFromCart = 'remove_from_cart',
  Search = 'search',
  SelectItem = 'select_item',
  SignUp = 'sign_up',
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  ViewPromotion = 'view_promotion',
  ViewPromotionList = 'view_promotion_list'
}

export enum PageType {
  Details = 'details',
  List = 'list',
  Highlight = 'highlight',
  Private = 'private',
  Purchase = 'purchase'
}

export enum FilterType {
  Tab = 'tab',
  Sort = 'sort',
  SelectBox = 'selectBox'
}

export enum MenuType {
  Text = 'text',
  Icon = 'icon'
}

export enum LoginMethod {
  Manual = 'manual',
  Automatic = 'automatic'
}

export enum Platform {
  Web = 'web',
  Webapp = 'webapp'
}

export enum PromotionMechanic {
  Voucher = 'voucher'
}

export enum SearchFunction {
  Website = 'website',
  DiscoverPage = 'discover'
}

export enum ActivationStep {
  Step1 = 'step1Submit',
  Step2 = 'step2Continue'
}

export enum ItemListName {
  CatalogList = 'catalogList',
  SearchResultsList = 'searchResultsList',
  RecommendationsList = 'recommendationsList',
  FavouriteProductsList = 'favouriteProductsList',
  OrdersList = 'ordersList',
  CartList = 'cartList',
  RewardsList = 'rewardsList',
  PicosList = 'picosList',
  PopularProductsList = 'popularProductsList',
  PromotionsList = 'promotionsList',
  PromotionDetailList = 'promotionDetailList',
  EmptiesList = 'emptiesList',
  CheckoutList = 'checkoutList',
  BundleList = 'bundleList',
  ProductPageDetailsList = 'productPageDetailsList',
  RecommendationsUponCheckoutList = 'recommendationsUponCheckoutList',
  AlternativesList = 'alternativesList'
}

export enum EvaluationName {
  ProductEvaluation = 'productEvaluation',
  PurchaseEvaluation = 'purchaseEvaluation',
  CustomerSupport = 'customerSupport'
}

export enum EventCategory {
  Cart = 'cart',
  Footer = 'footer',
  SignUp = 'signup',
  CampaignBanner = 'campaign_banner',
  PersonalizedMessage = 'personalized_message',
  Generic = 'generic',
  RecommendationsCheckout = 'recommendations_checkout'
}

export enum EventAction {
  Click = 'click',
  ClickButton = 'click_button',
  ClickText = 'click_text',
  ClickIcon = 'click_icon',
  Message = 'message',
  MessageDisplay = 'message_display',
  MessageClickSeeMore = 'message_click_see_more',
  MessageClickDismiss = 'message_click_dismiss'
}

export enum EventLabel {
  AddVoucher = 'add_voucher',
  RemoveVoucher = 'remove_voucher',
  ApplyVoucher = 'apply_voucher',
  ShowMore = 'show_more',
  ShowLess = 'show_less',
  ClearCart = 'clear_cart',
  ContinueShopping = 'continue_shopping',
  SeePrize = 'see_prize',
  OrderNow = 'order_now',
  AddVoucherMultiple = 'add_voucher_multiple',
  NoThankYou = 'no_thank_you'
}

export enum PaginationType {
  Arrow = 'arrow',
  Scroll = 'scroll',
  Number = 'number'
}

export enum AddToCartType {
  Single = 'single',
  Multiple = 'multiple',
  Bundle = 'bundle'
}

interface Category {
  code: string;
}

interface Brand {
  code: string;
}

interface SubCategory {
  code: string;
}

interface DetailsInfo {
  brand?: Brand;
  categories?: Category[];
  subCategories?: SubCategory[];
}

export interface ProductTracking {
  id: string;
  name: string;
  price: number;
  currency: string;
  brand?: string;
  category?: string;
  quantity?: number;
  subcategory?: string;
  variant?: string;
  url?: string;
  detailsInfo?: DetailsInfo;
  list?: string;
  dimension13?: number;
  position?: number;
}

export interface BaseEvent {
  customer_id: string;
  customer_name: string;
  customer_channel: string;
  local_timestamp: string;
  event: GAEvent;
  event_name: GAEvent;
  page_type: PageType | string;
  site_market: string;
  site_language: string;
  site_environment: string;
  platform: Platform;
  user_id: string;
  client_id: string;
}

export interface ItemEvent {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency: string;
  discount?: number;
  index: number;
  item_brand?: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name: ItemListName | string;
  item_variant: string;
  price: number;
  quantity: number;
  quantity_unit: string;
  creative_name?: string;
  creative_slot?: string;
  promotion_id?: string;
  promotion_name?: string;
  promotion_amount_type?: boolean;
  promotion_amount_value?: number;
  promotion_percentage_type?: boolean;
  promotion_percentage_value?: number;
  promotion_points_type?: boolean;
  promotion_points_value?: number;
  promotion_others_type?: boolean;
  location_id?: string;
}

interface AddToCartEvent {
  add_to_cart_type: string;
  ecommerce: {
    currency: string;
    value: number;
    items: ItemEvent[];
  };
}

interface ViewCartEvent {
  ecommerce: {
    currency: string;
    value: number;
    items: ItemEvent[];
    total_discount: number;
    total_points: number;
    total_voucher_value: number;
    payment_value: number;
  };
}

interface RemoveFromCartEvent {
  ecommerce: {
    currency: string;
    value: number;
    items: ItemEvent[];
  };
}

interface SelectItemEvent {
  ecommerce: {
    items: ItemEvent[];
    item_list_name?: ItemListName;
    item_list_id?: string;
  };
}

interface BeginCheckoutEvent {
  ecommerce: {
    currency: string;
    promotion_id?: string;
    promotion_name?: string;
    promotion_mechanic?: string;
    value: number;
    coupon?: string;
    items: ItemEvent[];
    total_discount: number;
    total_points: number;
    total_voucher_value: number;
    payment_value: number;
  };
}
interface EvaluationEvent {
  evaluation_name: string;
  evaluation_grade: number;
  evaluation_comments: string;
}

interface LoginEvent {
  method?: LoginMethod;
}

interface LogoutEvent {
  method?: LoginMethod;
}

interface MenuClickEvent {
  menu_type: MenuType;
  menu_label: string;
}

interface SearchEvent {
  search_term: string;
  search_function: SearchFunction;
}

interface ClickEvent {
  link_classes?: string;
  link_domain?: string;
  link_id?: string;
  link_text?: string;
  link_url?: string;
  outbound?: string;
  event_category?: string;
  event_action?: string;
  event_label?: string;
}

interface AddToFavouritesEvent {
  ecommerce: {
    currency: string;
    value: number;
    items: ItemEvent[];
  };
}

interface CancelOrderEvent {
  ecommerce: {
    currency: string;
    value: number;
    transaction_id: string;
    affiliation?: string;
    coupon?: string;
    shipping?: string;
    tax?: number;
    items?: ItemEvent[];
  };
}

interface FilterSelectionEvent {
  filter_type: string;
  filter_name: string;
  filter_label: string;
  filter_label_id: string;
}

interface ViewPromotionEvent {
  ecommerce: {
    creative_name?: string;
    creative_slot?: string;
    creative_type?: string;
    location_id?: string;
    promotion_id?: string;
    promotion_name?: string;
    promotion_type?: string;
    items: ItemEvent[];
  };
}

interface ClickPromotionEvent {
  ecommerce: {
    creative_name?: string;
    creative_slot?: string;
    creative_type?: string;
    location_id?: string;
    promotion_id?: string;
    promotion_name?: string;
    promotion_description?: string;
    promotion_view_type?: string;
    promotion_type?: string;
    promotion_mechanic?: string;
    items: ItemEvent[];
  };
}

interface SelectPromotionEvent {
  ecommerce: {
    items: {
      promotion_id?: string;
      promotion_name?: string;
      promotion_description?: string;
      promotion_view_type?: string;
    }[];
  };
}

interface ViewPromotionListEvent {
  ecommerce: {
    items: {
      promotion_id?: string;
      promotion_name?: string;
    }[];
  };
}

interface PurchaseEvent {
  ecommerce: {
    currency: string;
    value: number;
    transaction_id: string;
    affiliation?: string;
    coupon?: string;
    shipping?: string;
    tax?: number;
    items?: ItemEvent[];
    creative_name?: string;
    creative_type?: string;
    creative_slot?: string;
    promotion_id?: string;
    promotion_name?: string;
    promotion_mechanic?: string;
    location_id?: string;
    total_discount: number;
    total_points: number;
    total_voucher_value: number;
    payment_value: number;
  };
}

interface AlertMessageEvent {
  event_category: string;
  event_action: string;
  event_label: string;
  message_type?: string;
}

interface FooterClickEvent {
  link_classes?: string;
  link_domain?: string;
  link_id?: string;
  link_text?: string;
  link_url?: string;
  outbound?: string;
}

interface ViewItemListEvent {
  ecommerce: {
    item_list_name?: ItemListName;
    item_list_id?: string;
    items: ItemEvent[];
  };
}

interface PaginationEvent {
  pagination_type: string;
  pagination_label: string;
}

interface ViewItemEvent {
  ecommerce: {
    items: ItemEvent[];
    currency: string;
    value: number;
    promotions?: {
      promotion_id?: string;
      promotion_name?: string;
      promotion_description?: string;
      promotion_type?: string;
      promotion_subtype?: string;
      promotion_value?: number;
    }[];
  };
}

// GA4 interfaces
export interface TrackingAddToCart {
  dataLayer: BaseEvent & AddToCartEvent;
}

export interface TrackingViewCart {
  dataLayer: BaseEvent & ViewCartEvent;
}

export interface TrackingRemoveFromCart {
  dataLayer: BaseEvent & RemoveFromCartEvent;
}

export interface TrackingProductClick {
  dataLayer: BaseEvent & SelectItemEvent;
}

export interface TrackingBeginCheckout {
  dataLayer: BaseEvent & BeginCheckoutEvent;
}

export interface TrackingPurchaseEvaluation {
  dataLayer: BaseEvent & EvaluationEvent;
}
export interface TrackingLogin {
  dataLayer: BaseEvent & LoginEvent;
}
export interface TrackingLogout {
  dataLayer: BaseEvent & LogoutEvent;
}

export interface TrackingSignUp {
  dataLayer: BaseEvent;
}
export interface TrackingMenu {
  dataLayer: BaseEvent & MenuClickEvent;
}

export interface TrackingSearch {
  dataLayer: BaseEvent & SearchEvent;
}

export interface TrackingClickInternal {
  dataLayer: BaseEvent & ClickEvent;
}

export interface TrackingFooterClick {
  dataLayer: BaseEvent & FooterClickEvent;
}

export interface TrackingAddToFavourites {
  dataLayer: BaseEvent & AddToFavouritesEvent;
}

export interface TrackingCancelOrder {
  dataLayer: BaseEvent & CancelOrderEvent;
}

export interface TrackingFilterCatalog {
  dataLayer: BaseEvent & FilterSelectionEvent;
}

export interface TrackingViewPromotion {
  dataLayer: BaseEvent & ViewPromotionEvent;
}

export interface TrackingClickPromotion {
  dataLayer: BaseEvent & ClickPromotionEvent;
}

export interface TrackingSelectPromotion {
  dataLayer: BaseEvent & SelectPromotionEvent;
}

export interface TrackingViewPromotionList {
  dataLayer: BaseEvent & ViewPromotionListEvent;
}
export interface TrackingPurchase {
  dataLayer: BaseEvent & PurchaseEvent;
}

export interface TrackingAlertMessage {
  dataLayer: BaseEvent & AlertMessageEvent;
}

export interface TrackingViewItemList {
  dataLayer: BaseEvent & ViewItemListEvent;
}

export interface TrackingPagination {
  dataLayer: BaseEvent & PaginationEvent;
}

export interface TrackingPageView {
  dataLayer: BaseEvent;
}

export interface TrackingViewItem {
  dataLayer: BaseEvent & ViewItemEvent;
}
