import React, { useEffect, useState } from 'react';
import { Option } from '../../global/interfaces/elements';
import { Product, ProductWithVariants } from '../../global/interfaces/products';
import { Select } from '../../UI-elements/Select';
import { FONT_SIZE, FONT_WEIGHT } from '../../UI-elements/Text';

interface Props {
  productWithVariants: ProductWithVariants;
  setSelectedVariantProduct: (product: Product) => void;
  overrideDefault?: string;
}

const Variants: React.FC<Props> = ({ productWithVariants, setSelectedVariantProduct, overrideDefault }) => {
  const [variantOptions, setVariantOptions] = useState([]);

  useEffect(() => {
    if (!productWithVariants || !productWithVariants.variants) return;

    const options = productWithVariants.variants.map((product: Product) => {
      const { sku, label } = product;
      const isDefault = overrideDefault ? overrideDefault === sku : product.isDefault;

      return { key: sku, value: label ?? sku, isDefault };
    });

    setVariantOptions(options);
  }, [productWithVariants?.variants?.length, overrideDefault]);

  const variantChanged = (variant: Option) => {
    productWithVariants.variants.filter((variantProduct: Product) => {
      if (variantProduct.sku === variant.key) setSelectedVariantProduct(variantProduct);

      return null;
    });
  };

  return (
    <Select
      options={variantOptions}
      initialValue={variantOptions.find((variantOption) => variantOption.isDefault) ?? variantOptions[0]}
      onChange={variantChanged}
      selectedValueTextSize={FONT_SIZE.TINY}
      selectedValueTextWeight={FONT_WEIGHT.BOLD}
      smallerTextBox
      dataTestId="variants"
    />
  );
};

export { Variants };
