import { Button, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { Card, CardStyle } from '@carlsberggroup/malty.atoms.card';
import { Headline, HeadlineAlign, HeadlineStyle } from '@carlsberggroup/malty.atoms.headline';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { FC, useContext, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { BFF_ERROR_CODES } from '../../../../global/constants';
import { ConfigContext } from '../../../../global/contexts/ConfigContext';
import { useGetBaseGAEventData } from '../../../../global/custom-hooks/useGetBaseGAEventData';
import { useScreenSize } from '../../../../global/custom-hooks/useScreenSize';
import { ClickInternalTracking } from '../../../../global/google-analytics';
import { EventAction, EventCategory, EventLabel } from '../../../../global/google-analytics/tracking';
import { BFFError } from '../../../../global/interfaces/checkout';
import { EnterspeedWidgetPrizeLottery } from '../../../../services/headless/types';
import { CloudinaryImage, getCloudinaryFetchImageUrl } from '../../../../UI-elements/CloudinaryImage';
import { useGetLotteryMilestone } from '../../PrizeLottery';
import { CustomizedProgressBar } from '../CustomizedProgressBar';
import { PrizeLotteryModal } from '../PrizeLotteryModal';
import { Stepper } from '../Stepper';
import { StepEnum } from '../Stepper/Stepper';
import styles from './PrizeLotteryBanner.module.scss';

const PrizeLotteryBanner: FC<EnterspeedWidgetPrizeLottery> = ({
  title,
  backgroundImage,
  overlayImage,
  progressBarEnabled,
  timelineBarEnabled,
  buttons
}) => {
  const history = useHistory();
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const isUntilTablet = isXSmall || isSmall;
  const isUntilDesktop = isXSmall || isSmall || isMedium;
  const {
    featureFlags: { showPrizeLottery }
  } = useContext(ConfigContext);
  const { data: milestonesResponse, error: milestoneError } = useGetLotteryMilestone();
  const gaEventData = useGetBaseGAEventData();
  const prizeLotteryErrorData = (milestoneError as FetchBaseQueryError)?.data as BFFError | undefined;
  const prizeLotteryError = prizeLotteryErrorData?.errorCode === BFF_ERROR_CODES.NO_PRIZE_LOTTERY_FOUND;

  const milestonesProgress = useMemo(() => {
    const completed = milestonesResponse?.milestones?.reduce((acc, milestone) => {
      if (milestone.status === StepEnum.COMPLETED) {
        return acc + 1;
      }
      return acc;
    }, 0);
    const currentMilestone = milestonesResponse?.milestones?.find((milestone) => milestone.current);
    const totalOfMilestones = milestonesResponse?.milestones?.length;
    return {
      completed,
      total: totalOfMilestones,
      currentMilestoneProgress: currentMilestone?.goals[0] ?? null
    };
  }, [milestonesResponse?.milestones]);

  const handleRedirects = (label: string, index: number) => {
    if (index === 0) {
      onClickSeePrizes(label);
    } else {
      onClickOrderNow(label);
    }
  };

  const onClickSeePrizes = (label: string) => {
    TagManager.dataLayer(
      ClickInternalTracking({
        ...gaEventData,
        eventLabel: EventLabel.SeePrize,
        eventAction: EventAction.Click,
        eventCategory: EventCategory.CampaignBanner,
        linkText: label
      })
    );
    history.push('/prize-lottery');
  };

  const onClickOrderNow = (label: string) => {
    TagManager.dataLayer(
      ClickInternalTracking({
        ...gaEventData,
        eventLabel: EventLabel.OrderNow,
        eventAction: EventAction.Click,
        eventCategory: EventCategory.CampaignBanner,
        linkText: label
      })
    );
    history.push('/catalog');
  };

  if (!showPrizeLottery || prizeLotteryError) {
    return null;
  }

  const renderCardHero = () => (
    <div className={styles.cardContent}>
      <div
        className={styles.cardContentBackground}
        style={{
          backgroundImage: `url(${getCloudinaryFetchImageUrl(backgroundImage.url)})`
        }}
      />
      <div className={styles.cardContentWrapper}>
        <div className={styles.cardContentHeadline}>
          <Headline
            align={HeadlineAlign.Left}
            headlineStyle={isUntilTablet ? HeadlineStyle.Large : HeadlineStyle.Big}
            as="h2"
          >
            {title}
          </Headline>
        </div>
        {!isUntilDesktop ? (
          <CloudinaryImage
            cssClassName={styles.cardContentImage}
            src={overlayImage?.url}
            alt={overlayImage?.title}
            dataTestId="prize-lottery-overlay-image"
            width={overlayImage?.properties?.width}
            height={overlayImage?.properties?.height}
          />
        ) : null}

        {timelineBarEnabled || progressBarEnabled ? (
          <div className={styles.cardContentFlexColumn}>
            {timelineBarEnabled ? <Stepper milestones={milestonesResponse.milestones} /> : null}
            {progressBarEnabled ? (
              <CustomizedProgressBar
                current={milestonesProgress.currentMilestoneProgress?.progress ?? 0}
                total={milestonesProgress.currentMilestoneProgress?.value ?? 1}
              />
            ) : null}
          </div>
        ) : null}

        <div className={styles.cardContentButtons}>
          {buttons.map((button, index) => (
            <Button
              key={button.name}
              style={index === 0 ? ButtonStyle.Secondary : ButtonStyle.Primary}
              text={button.name}
              fullWidth
              onClick={() => handleRedirects(button.name, index)}
            />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Card dataTestId="prize-lottery-banner" cardStyle={CardStyle.Shadowed} cardHero={renderCardHero()} />
      <PrizeLotteryModal showCongratulations={milestonesResponse.showCongratulations} />
    </>
  );
};
export { PrizeLotteryBanner };
