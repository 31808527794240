import { Text, TextAlign, TextStyle } from '@carlsberggroup/malty.atoms.text';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountDownRemainingTime } from '../../../global/custom-hooks/useCountDownRemainingTime';
import { formatDate } from '../../../global/utils/formatDate';
import { CountDownRemainingTime } from '../CountDownRemainingTime';
import styles from './cutOff.module.scss';

interface Props {
  cutOffDate: string;
  hoursLeftToShow: number;
  timeExpiredFn: () => void;
  className?: string;
}

export const CutOffInfo: React.FC<Props> = ({ cutOffDate, hoursLeftToShow, timeExpiredFn, className }) => {
  const { t } = useTranslation();
  const {
    countDownInterval,
    isRemainingTimeExpired,
    isRemainingTimeLowerThanHours,
    countDownRemainingTimeInHours,
    countDownRemainingTimeInMinutes,
    countDownRemainingTimeInSeconds
  } = useCountDownRemainingTime({
    countDowFullDate: cutOffDate
  });

  useEffect(() => {
    if (isRemainingTimeExpired) {
      timeExpiredFn();
      clearInterval(countDownInterval.current);
    }
  }, [isRemainingTimeExpired]);

  if (!isRemainingTimeLowerThanHours(hoursLeftToShow) || isRemainingTimeExpired) {
    return null;
  }

  return (
    <div className={cn(styles.cutOffContainer, className)}>
      <Text textStyle={TextStyle.MediumSmallDefault} align={TextAlign.Center}>
        {`${t('entries.cutOff.delivery', { defaultValue: 'Delivery' })} ${formatDate(cutOffDate)} - ${t(
          'entries.cutOff.orderWithin',
          {
            defaultValue: 'Order within'
          }
        )}`}
      </Text>
      &nbsp;
      <CountDownRemainingTime
        hours={countDownRemainingTimeInHours}
        minutes={countDownRemainingTimeInMinutes}
        seconds={countDownRemainingTimeInSeconds}
        isFontSizeMedium
      />
    </div>
  );
};
