import { AlertBanner, AlertBannerI, AlertBannerType } from '@carlsberggroup/malty.molecules.alert-banner';
import React, { useContext, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { useHeaderContext } from '../../global/contexts/HeaderContext';
import { useAnnouncements } from '../../global/custom-hooks/useAnnouncements';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { AlertMessageTracking } from '../../global/google-analytics';
import { EventAction, EventCategory } from '../../global/google-analytics/tracking';
import { AnnouncementContent } from '../../services/announcements/types';
import { userIsLoggedInSelector } from '../../store/slices/user';
import { TRIGGER_ANIMATION_BANNER } from '../Header/constants';
import { DismissibleStatus, SeverityStatus } from './constants';
import { getLanguageMessage, getSeverityByEid } from './helpers';

const AlertType = {
  [SeverityStatus.CRITICAL]: {
    type: AlertBannerType.Error
  },
  [SeverityStatus.WARN]: {
    type: AlertBannerType.Warning
  },
  [SeverityStatus.INFO]: {
    type: AlertBannerType.Information
  },
  [SeverityStatus.DEBT]: {
    type: AlertBannerType.Error
  }
};

const Announcement = () => {
  const { announcementContentArray, removeAnnouncement, isLoading } = useAnnouncements();

  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { locales } = useContext(ConfigContext);
  const defaultLanguage = locales?.default;
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const gaEventData = useGetBaseGAEventData();
  const history = useHistory();
  const headerContext = useHeaderContext();

  const handleDismiss = (announcementContent: AnnouncementContent) => {
    const { eid } = announcementContent;
    removeAnnouncement(announcementContent.eid);
    TagManager.dataLayer(
      AlertMessageTracking({
        ...gaEventData,
        eventCategory: EventCategory.Generic,
        eventAction: EventAction.MessageClickDismiss,
        eventLabel: getAlertMessage(announcementContent),
        messageType: getSeverityByEid(announcementContentArray, eid)
      })
    );
  };

  const trackingEvent = (announcementContent: AlertBannerI) => {
    const { message, eid } = announcementContent;
    TagManager.dataLayer(
      AlertMessageTracking({
        ...gaEventData,
        eventCategory: EventCategory.PersonalizedMessage,
        eventAction: EventAction.MessageDisplay,
        eventLabel: message,
        messageType: getSeverityByEid(announcementContentArray, eid)
      })
    );
  };

  const getAlertMessage = (announcementContent: AnnouncementContent) => {
    if (announcementContent.details.severity === SeverityStatus.DEBT) {
      return t('entries.debt.notification', { defaultValue: 'You have negative credit line' });
    }
    return (
      getLanguageMessage(announcementContent?.details?.message, language, defaultLanguage)?.body ??
      announcementContent?.details?.message[0].body
    );
  };

  const renderAction = (severity: SeverityStatus) => {
    if (severity === SeverityStatus.DEBT) {
      trackingDebtAction();
      history.push('/profile');
    }
  };

  const renderActionName = (severity: SeverityStatus) =>
    severity === SeverityStatus.DEBT && t('entries.debt.seeMore', { defaultValue: 'See more' });

  const trackingDebtAction = () => {
    TagManager.dataLayer(
      AlertMessageTracking({
        ...gaEventData,
        eventCategory: EventCategory.Generic,
        eventAction: EventAction.MessageClickSeeMore,
        eventLabel: t('entries.debt.notification', { defaultValue: 'You have negative credit line' }),
        messageType: SeverityStatus.DEBT.toLowerCase()
      })
    );
  };

  const MemoComponent = useMemo(
    () => (
      <>
        <AlertBanner
          key={language}
          alerts={announcementContentArray.map((announcementContent: AnnouncementContent) => {
            const isDismissible = announcementContent?.dismissible !== DismissibleStatus.NOT_DISMISSIBLE;
            return {
              eid: announcementContent.eid,
              type: AlertType[announcementContent?.details?.severity].type,
              message: getAlertMessage(announcementContent),
              dataQaId: `announcement-${announcementContent.eid}`,
              dismissible: isDismissible,
              onDismiss: isDismissible ? () => handleDismiss(announcementContent) : undefined,
              icon: true,
              action: () => renderAction(announcementContent?.details?.severity),
              actionName: renderActionName(announcementContent?.details?.severity)
            };
          })}
          animation={
            isMobile && {
              showAnimations: true,
              triggerYPosition: TRIGGER_ANIMATION_BANNER,
              isBannerTextCompressed: headerContext?.isAlertBannerDetailsHidden,
              toggleBannerTextCompress: headerContext?.changeTextBannerDetailState
            }
          }
          onActiveAlertChange={trackingEvent}
        />
      </>
    ),
    [headerContext?.isAlertBannerDetailsHidden, announcementContentArray, language]
  );

  return isLoggedIn && !isLoading && announcementContentArray.length ? MemoComponent : null;
};

export { Announcement };
