import { GAEvent, LoginMethod, PageType, Platform, TrackingLogin } from '../tracking';
import { gaClientId, isPWA } from '../utils';

interface LogInOutTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  pageType?: PageType | string;
  loginMethod: LoginMethod;
}

export const LoginTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  loginMethod
}: LogInOutTrackingProps): TrackingLogin => ({
  dataLayer: {
    event: GAEvent.Login,
    event_name: GAEvent.Login,
    user_id: userId,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    page_type: PageType.Private,
    method: loginMethod
  }
});

export const LogoutTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  pageType,
  userId,
  loginMethod
}: LogInOutTrackingProps): TrackingLogin => ({
  dataLayer: {
    event: GAEvent.Logout,
    event_name: GAEvent.Logout,
    user_id: userId,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    page_type: pageType,
    method: loginMethod
  }
});
