import classnames from 'classnames';
import React, { useContext } from 'react';
import { CART_SECTION_TYPES } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { ProductImageBoxIconFavourites } from './components/ProductImageBoxIconFavourites';
import { ProductImageBoxMedia } from './components/ProductImageBoxMedia';
import { ProductImageBoxPillPromotions } from './components/ProductImageBoxPillPromotions';
import styles from './productImageBox.module.scss';
import { ProductImageBoxProps, ProductImageBoxSize } from './ProductImageBox.types';

const ProductImageBox = ({
  dataTestId = 'product-image-box',
  boxLength,
  boxSize,
  images,
  cartSection,
  hasTransparentBackground = false,
  renderPillForPromotions = false,
  renderIconFavourites = false,
  productWithVariants,
  isProductInFavouriteList,
  handleNavigateToProductPage,
  setIsFavouriteModalOpen,
  fitLayout
}: ProductImageBoxProps) => {
  const isReturnable = cartSection === CART_SECTION_TYPES.returnable;

  const {
    featureFlags: { showFavourites }
  } = useContext(ConfigContext);
  return (
    <div
      data-testid={dataTestId}
      className={classnames(styles.productImage, {
        [styles.returnable]: isReturnable,
        [styles.transparentbg]: hasTransparentBackground,
        [styles.xxsmall]: boxSize === ProductImageBoxSize.XXSMALL,
        [styles.xsmall]: boxSize === ProductImageBoxSize.XSMALL,
        [styles.small]: boxSize === ProductImageBoxSize.SMALL,
        [styles.medium]: boxSize === ProductImageBoxSize.MEDIUM,
        [styles.large]: boxSize === ProductImageBoxSize.LARGE
      })}
      data-fitlayout={fitLayout === ProductImageBoxSize.FIT_LAYOUT ? true : undefined}
      onClick={handleNavigateToProductPage}
    >
      <ProductImageBoxMedia images={images} boxLength={boxLength} />
      {renderPillForPromotions ? <ProductImageBoxPillPromotions /> : null}
      {renderIconFavourites && !isReturnable && showFavourites ? (
        <ProductImageBoxIconFavourites
          isFavourite={isProductInFavouriteList}
          product={productWithVariants}
          sku={productWithVariants.sku}
          setIsFavouriteModalOpen={setIsFavouriteModalOpen}
        />
      ) : null}
    </div>
  );
};

export { ProductImageBox };
