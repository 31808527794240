import React from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../../../global/interfaces/currencies';
import { PriceI } from '../../../../../global/interfaces/products';
import { formatPrice } from '../../../../../global/utils/formatPrice';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../../../../UI-elements/Text';
import { PriceRenderer } from '../../../Price/PriceRenderer';

interface Props {
  price: PriceI;
  currency: Currency;
  dataTestId?: string;
}

const AlternativeProductPrice: React.FC<Props> = ({ price, dataTestId, currency }) => {
  const { t } = useTranslation();

  const priceToUse = price.base;
  const displayPrice = formatPrice(priceToUse, currency.code, currency.symbol);
  const displayDiscount = price.discount && formatPrice(price.discount, currency.code, currency.symbol);
  const displayStandardPrice = formatPrice(price.standard, currency.code, currency.symbol);

  return (
    <>
      {price.standard && (
        <Text
          dataTestId={`${dataTestId}-price`}
          color={TEXT_COLOR.DISABLED}
          strikethrough
          size={FONT_SIZE.TINY}
          align={TEXT_ALIGN.RIGHT}
        >
          {displayStandardPrice}
        </Text>
      )}

      <PriceRenderer
        dataTestId={`${dataTestId}-price`}
        align={TEXT_ALIGN.RIGHT}
        strikethrough={!!price.discount}
        color={price.discount ? TEXT_COLOR.DISABLED : TEXT_COLOR.DEFAULT}
        size={FONT_SIZE.TINY}
        formattedPrice={displayPrice}
      />

      {price.discount && (
        <PriceRenderer
          dataTestId={`${dataTestId}-discount-price`}
          align={TEXT_ALIGN.RIGHT}
          weight={FONT_WEIGHT.SEMI_BOLD}
          size={FONT_SIZE.TINY}
          color={TEXT_COLOR.PROMOTIONAL}
          formattedPrice={displayDiscount}
        />
      )}

      <Text
        dataTestId={`${dataTestId}-deposits`}
        size={FONT_SIZE.MICRO}
        weight={FONT_WEIGHT.SEMI_BOLD}
        align={TEXT_ALIGN.RIGHT}
      >
        {`${t('entries.cart.depositsTitle', { defaultValue: 'Deposits' })} ${formatPrice(
          price.totalDeposits,
          currency?.code,
          currency?.symbol
        )}`}
      </Text>
    </>
  );
};

export { AlternativeProductPrice };
