import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { favouritesApi } from '../favourites/favourites';
import {
  PromotionDetailsRequestParam,
  PromotionDetailsResponse,
  PromotionRequestParam,
  PromotionResponse
} from './types';

export const promotionsApi = createApi({
  reducerPath: 'promotionsApi',
  baseQuery: baseApiQuery,
  tagTypes: ['PROMOTIONS', 'PROMOTION_DETAILS'],
  endpoints: (builder) => ({
    getPromotions: builder.query<PromotionResponse, PromotionRequestParam>({
      query: (params) => ({
        url: makeApiUrl(apiEndpoints.PROMOTIONS, { salesOrg: params.salesOrg }),
        params: {
          outletId: params.outletId,
          distributorId: params.distributorId,
          type: params.type,
          page: params.page || 0,
          size: params.size
        }
      }),
      providesTags: ['PROMOTIONS']
    }),
    getPromotionDetails: builder.query<PromotionDetailsResponse, PromotionDetailsRequestParam>({
      query: (params) => ({
        url: makeApiUrl(apiEndpoints.PROMOTION_DETAILS, {
          salesOrg: params.salesOrg,
          promotionCode: params.promoCode,
          outletId: params.outletId,
          distributorId: params.distributorId
        })
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(
          favouritesApi.util.invalidateTags(['ASSOCIATED_FAVOURITE_LIST', 'FAVOURITES_LIST', 'FAVOURITE_LIST_PRODUCTS'])
        );
      },
      providesTags: ['PROMOTION_DETAILS']
    })
  })
});

export const { useGetPromotionsQuery, useGetPromotionDetailsQuery } = promotionsApi;
