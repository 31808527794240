import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { UmbracoErrorPage } from '../../services/headless/types';
import { CloudinaryImage } from '../../UI-elements/CloudinaryImage';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './error.module.scss';

interface Props {
  content: UmbracoErrorPage;
  distributorsError: unknown;
}

const ErrorPageComponent: React.FC<Props> = ({ content, distributorsError }: Props) => {
  const { t } = useTranslation();

  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  if (!content?.background) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <CloudinaryImage
          dataTestId="background-error-image"
          cssClassName={styles.image}
          src={content.background}
          alt="background-error-image"
        />
      </div>

      <div
        className={cn(styles.text, {
          [styles.mobile]: isMobile
        })}
      >
        {content.text}

        {distributorsError && (
          <Text color={TEXT_COLOR.WHITE} size={FONT_SIZE.SMALL}>
            {t('entries.error.distributorsError', {
              defaultValue: 'There was a problem with your distributors, please contact our support.'
            })}
          </Text>
        )}
      </div>

      <div
        className={cn(styles.error, {
          [styles.mobile]: isMobile
        })}
      >
        <div
          className={cn(styles['button-wrapper'], {
            [styles.mobile]: isMobile
          })}
        >
          {distributorsError ? (
            <Button
              onClick={() => {
                window.location.href = `mailto:${content.supportEmail}`;
              }}
              style={ButtonStyle.Primary}
              size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
              negative={!isMobile}
              fullWidth
              text={t('entries.error.contactSupport', { defaultValue: 'Contact support' })}
            />
          ) : (
            <>
              <Button
                onClick={() => {
                  // redirect with forced re-render
                  window.location.href = `${window.location.protocol}//${window.location.host}/home`;
                }}
                style={ButtonStyle.Primary}
                size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
                negative={!isMobile}
                fullWidth
                text={content.label}
              />
              <div className={styles.contact}>
                <Text
                  color={isMobile ? TEXT_COLOR.GREY : TEXT_COLOR.WHITE}
                  size={isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
                  dataTestId="error-page-text"
                >
                  {t('entries.error.enterInContactWith', { defaultValue: 'Enter in contact with' })}
                </Text>
                <Text
                  color={isMobile ? TEXT_COLOR.GREY : TEXT_COLOR.WHITE}
                  size={isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
                  weight={FONT_WEIGHT.BOLD}
                  underline
                  dataTestId="error-page-email-link"
                >
                  <a href={`mailto:${content.supportEmail}`}>
                    {t('entries.error.support', { defaultValue: 'support' })}
                  </a>
                </Text>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { ErrorPageComponent };
