import React from 'react';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import { getRewardCurrencyOperator } from '../../../../../global/utils/getRewardCurrencyOperator';
import { getRewardsValue } from '../../../../../global/utils/getRewardValue';
import { RewardCurrency } from '../../../RewardCurrency';
import styles from '../../productcard.module.scss';

const RewardCurrencyComponent = ({ dataTestId = '' }: { dataTestId?: string }) => {
  const { product, isReward } = React.useContext(ProductCardContext);
  const pointsPromotionValue = getRewardsValue({ isRedeemableProduct: isReward, product });
  const currencyOperator = getRewardCurrencyOperator({ isRedeemableProduct: isReward, product });

  return (
    <div className={styles.productReward}>
      <RewardCurrency value={pointsPromotionValue} operation={currencyOperator} dataTestId={dataTestId} />
    </div>
  );
};

export { RewardCurrencyComponent };
