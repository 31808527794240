import { ALIGNED, HEADLESS_RECOMMENDATIONS_TABS, HEADLESS_WIDGET_ALIAS } from '../../global/constants';
import { Banner, HeadlessLinkI, HeadlessSocialLink, ProductSliderWidget } from '../../global/interfaces/headless';
import { IArticleImage, IArticles } from '../discover/types';
import { PrizeLotteryBackgroundImageProps, PrizeLotteryButtonProps } from '../lottery/types';

interface EnterspeedSeo {
  id: string;
  view: {
    title: string;
  };
}

export interface EnterspeedWidgetRecommendation {
  id: number;
  alias: HEADLESS_WIDGET_ALIAS.recommendations;
  tabs?: {
    tab: HEADLESS_RECOMMENDATIONS_TABS;
  }[];
}

export interface EnterspeedWidgetProductSlider {
  id: number;
  alias: HEADLESS_WIDGET_ALIAS.productSlider | HEADLESS_WIDGET_ALIAS.recommendationsSlider;
  limit: number;
  widgets?: ProductSliderWidget[];
  module?: 'Recommended' | 'Related' | 'Trending';
}

export interface EnterspeedWidgetBanner {
  id: number;
  alias: HEADLESS_WIDGET_ALIAS.banners;
  bannerInterval?: number;
  bannerAutoplay?: boolean;
  widgets?: Banner[];
}

export interface EnterspeedWidgetPrizeLottery {
  id: number;
  alias: HEADLESS_WIDGET_ALIAS.prizeLottery;
  title: string;
  backgroundImage: PrizeLotteryBackgroundImageProps;
  overlayImage: PrizeLotteryBackgroundImageProps;
  buttons: PrizeLotteryButtonProps[];
  timelineBarEnabled: boolean;
  progressBarEnabled: boolean;
}

export interface EnterspeedWidgetContent {
  id: number;
  alias:
    | HEADLESS_WIDGET_ALIAS.textContent
    | HEADLESS_WIDGET_ALIAS.textAndImageContent
    | HEADLESS_WIDGET_ALIAS.imageContent
    | HEADLESS_WIDGET_ALIAS.videoContent
    | HEADLESS_WIDGET_ALIAS.textAndVideoContent;
  title?: string;
  text?: string;
  textAsJson?: TextAsJson[];
  image?: IArticleImage;
  video?: IMedia;
}
export interface IMedia {
  id: string;
  url: string;
  title: string;
  altText: string;
  properties: Properties;
  thumbnail?: IMedia;
  align?: ALIGNED;
}

export interface Properties {
  extension: string;
  width: number;
  height: number;
}

export interface EnterspeedWidgetImageList {
  id: number;
  alias: HEADLESS_WIDGET_ALIAS.imageList;
  items?: IArticleImage[];
}

export interface EnterspeedWidgetRelatedProducts {
  id: number;
  alias: HEADLESS_WIDGET_ALIAS.relatedArticles;
  related?: IArticles[];
}

export type EnterspeedWidgetBlock =
  | EnterspeedWidgetRecommendation
  | EnterspeedWidgetProductSlider
  | EnterspeedWidgetBanner
  | EnterspeedWidgetContent
  | EnterspeedWidgetImageList
  | EnterspeedWidgetRelatedProducts
  | EnterspeedWidgetPrizeLottery;

export interface PageQueryParams {
  url?: EnterspeedPages | string;
  languageCode: string;
  contentDelivery?: boolean;
  salesOrg?: string;
}

export interface QueryParams {
  languageCode: string;
  pageName?: string;
  targetGroups?: string[];
}

export interface HandleQueryParams {
  id?: string;
  languageCode: string;
  salesOrg?: string;
  targetGroups?: string[];
  contentDelivery?: boolean;
  handle?: EnterspeedHandles;
  url?: EnterspeedPages;
  sort?: string;
}

export type FAQCategory = {
  id: string;
  name: string;
};

export type FAQQuestion = {
  categoryId: string;
  id: string;
  title: string;
  content: string;
};

export type FAQs = {
  categories: FAQCategory[];
  faqs: FAQQuestion[];
};

export type FAQCategoryId = FAQCategory['id'];

export interface HeadlessConfig {
  meta: {
    status: number;
    redirect: string | null;
  };
  route: {
    generalNotice: string;
    brand: HeadlessConfigBrand;
    support: HeadlessConfigSupport;
    footer: HeadlessConfigFooter;
    loginImage: HeadlessConfigImage;
    pages: HeadlessConfigPages;
    error: HeadlessConfigError;
    checkboxes: HeadlessConfigCheckbox[];
  };
  views: unknown;
}

export interface HeadlessLoginConfig {
  meta: {
    status: number;
    redirect: string | null;
  };
  route: {
    support: HeadlessConfigSupport;
    loginImage: HeadlessConfigImage;
    links: HeadlessLinkI[];
    checkboxes: HeadlessConfigCheckbox[];
    logo: {
      altText: string;
      title: string;
      url: string;
    };
  };
  views: unknown;
}

export interface HeadlessConfigImage {
  altText: string;
  title: string;
  url: string;
}
interface HeadlessConfigBrand {
  logo: {
    altText: string;
    title: string;
    url: string;
  };
  name: string;
  project: string;
  formalEntity: string;
}

export interface HeadlessConfigCheckbox {
  confirmationText: string;
  links: HeadlessConfigLinkElement[];
}

export enum HeadlessPageType {
  TERMS_OF_USE = 'terms-of-use',
  PRIVACY_POLICY = 'privacy-policy',
  COOKIE_POLICY = 'cookie-policy'
}

export interface HeadlessConfigLinkElement {
  title: string;
  url: string;
  content: HeadlessConfigGeneral;
  pageType: HeadlessPageType;
}

interface HeadlessConfigGeneral {
  id: string;
  view: null;
  type: string;
}

interface HeadlessConfigError {
  maintenance: HeadlessConfigGeneral;
  general: HeadlessConfigGeneral;
}

interface HeadlessConfigFooter {
  faqFooterLink: HeadlessLinkI;
  links: HeadlessLinkI[];
  socialLinks: HeadlessSocialLink[];
  contacts: HeadlessConfigContacts;
}

interface HeadlessConfigContacts {
  emails: string[];
  phoneNumbers: string[];
}

interface HeadlessConfigPages {
  termsAndConditions: HeadlessConfigView;
  cookiePolicy: HeadlessConfigGeneral;
  privacyPolicy: HeadlessConfigGeneral;
  loyalty: null;
}

export interface HeadlessConfigView {
  id: string;
  type: string;
  view: {
    name: string;
    target: string;
    url: string;
  };
}

interface HeadlessConfigSupport {
  phone: string;
  email: string;
}

export interface HeadlessErrorPage {
  meta: {
    status: number;
    redirect: string;
  };
  route: {
    text: string;
    label: string;
    background: string;
  };
}

export enum EnterspeedHandles {
  HOMEPAGE = 'homepage',
  FAQ_PAGE = 'faq',
  DISCOVER_PAGE = 'discovery',
  CATEGORY = 'category',
  DISCOVER_DETAILS_PAGE = 'page',
  LOTTERY = 'lottery',
  LOYALTY_PAGE = 'loyalty',
  LOYALTY_PAGE_HOW_IT_WORKS = 'loyalty-how-it-works',
  TERMS_OF_USE = 'terms-of-use',
  PRIVACY_POLICY = 'privacy-policy',
  ERROR_PAGE = 'error',
  MAINTENANCE = 'maintenance'
}

export enum EnterspeedPages {
  CONFIG = '/',
  LOGIN = '/login-page/'
}
interface UmbracoPageMeta {
  Status: number;
  status: number;
}

export type UmbracoLogin = {
  support: HeadlessConfigSupport;
  loginImage: HeadlessConfigImage;
  links: HeadlessLinkI[];
  checkboxes: HeadlessConfigCheckbox[];
  logo: {
    altText: string;
    title: string;
    url: string;
  };
};

export type UmbracoHomePage = {
  seo: EnterspeedSeo;
  csHomepage: {
    content: {
      view: {
        blocks: EnterspeedWidgetBlock[];
      };
    };
  };
  csFAQ: {
    faqs: Array<{ id: string; content: string; categoryId: string; title: string }>;
    categories: Array<{ id: string; name: string }>;
  };
};

export type UmbracoDefaultPage = {
  loyaltyIcon?: string;
  blocks?: {
    id: string;
    view: { blocks: UmbracoPageBlocks[] };
  };
};

export type UmbracoErrorPage = {
  background?: string;
  label?: string;
  text?: string;
  supportEmail?: string;
  supportPhone?: string;
  logo?: string;
};

export interface TextAsJson {
  Type: 'element' | 'text';
  Element: string;
  Attributes: Record<string, string | number | boolean>;
  Content: string | TextAsJson[];
}

interface UmbracoPageBlocks {
  alias: string;
  headline: string;
  textAsJson?: TextAsJson[];
  image?: { url: string };
  questions?: [
    {
      question: string;
      answer: string;
      textAsJson?: TextAsJson[];
    }
  ];
}

export type HeadlessDataByPagePayload = {
  meta: UmbracoPageMeta;
  views: UmbracoDefaultPage &
    UmbracoErrorPage &
    UmbracoLotteryPage &
    UmbracoHomePage &
    UmbracoLogin & {
      [key: string]: UmbracoDefaultPage & UmbracoErrorPage & UmbracoLotteryPage & UmbracoHomePage & UmbracoLogin;
    };
};

interface UmbracoLotteryPage {
  content: {
    title: string;
    description: string;
    timeline: { description: string };
    rightPanel: {
      bottomText: string;
      bottomTextAsJson: TextAsJson[];
      circleText: string;
    };
    body: {
      bottomText: string;
      bottomTextAsJson: TextAsJson[];
      text: string;
      textAsJson: TextAsJson[];
    };
  };
}
