import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

const HeaderContext = createContext<{
  isAlertBannerDetailsHidden: boolean;
  changeTextBannerDetailState: (arg0: boolean) => void;
  changeCurrentDesktopHeaderHeight: (arg0: number) => void;
  currentDesktopHeaderHeight: number;
} | null>(null);

const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const [isAlertBannerDetailsHidden, setIsAlertBannerDetailsHidden] = useState(false);
  const [currentDesktopHeaderHeight, setCurrentDesktopHeaderHeight] = useState(0);

  const changeCurrentDesktopHeaderHeight = useCallback((newHeight: number) => {
    setCurrentDesktopHeaderHeight(newHeight);
  }, []);

  const changeTextBannerDetailState = useCallback(
    (newState: boolean) => {
      setIsAlertBannerDetailsHidden(newState);
    },
    [isAlertBannerDetailsHidden]
  );

  const value = {
    isAlertBannerDetailsHidden,
    changeTextBannerDetailState,
    currentDesktopHeaderHeight,
    changeCurrentDesktopHeaderHeight
  };

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};

export { HeaderProvider, useHeaderContext };
