import { useCallback, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertAllProductsAddedToCart, setCartToggle } from '../../components/Cart/actions';
import {
  drawerMenuIsOpen,
  searchModalIsOpen,
  setDrawerMenuClose,
  setDrawerMenuOpen,
  setSearchModalClose,
  setSearchModalOpen
} from '../../store/slices/header';
import { MenuTracking } from '../google-analytics';
import { GAEvent, MenuType } from '../google-analytics/tracking';
import { getCartToggle, getIsShowAlertAllProductsAddedToCart } from '../selectors/cart/selectors';
import { useGetBaseGAEventData } from './useGetBaseGAEventData';
import { PAGE_ROUTES, useGetCurrentPageInfo } from './useGetCurrentPageInfo';
import { useHeaderNavLinksMobile } from './useHeaderNavLinksMobile';

export const useMenuClick = () => {
  const dispatch = useDispatch();
  const {
    i18n: { language }
  } = useTranslation();

  const [isMenuMobileTabActive, setMenuMobileTabActive] = useState(null);
  const isDrawerMenuOpen = useSelector(drawerMenuIsOpen);
  const isSearchOpen = useSelector(searchModalIsOpen);
  const isCartOpen = useSelector(getCartToggle);
  const isShowAlertAllProductsAddedToCart = useSelector(getIsShowAlertAllProductsAddedToCart);

  const { navHeaderItemMobile } = useHeaderNavLinksMobile();
  const { path } = useGetCurrentPageInfo();
  const isHomePage = path === PAGE_ROUTES.home.path;
  const isCatalogPage = path === PAGE_ROUTES.catalog.path;

  const gaEventData = useGetBaseGAEventData();

  const trackMenuClick = useCallback(
    (menuType: MenuType, menuLabel: string) => {
      TagManager.dataLayer(
        MenuTracking({
          ...gaEventData,
          event: GAEvent.MenuClick,
          menuType,
          menuLabel
        })
      );
    },
    [language]
  );

  const handleActiveTab = () => {
    if (isHomePage) {
      setMenuMobileTabActive(navHeaderItemMobile.home.label);
      return;
    }

    if (isCatalogPage) {
      setMenuMobileTabActive(navHeaderItemMobile.catalog.label);
      return;
    }

    setMenuMobileTabActive(null);
  };

  const handleToggleCart = (open: boolean) => {
    if (!open) {
      handleActiveTab();
    }

    if (isShowAlertAllProductsAddedToCart) {
      dispatch(setAlertAllProductsAddedToCart(false));
    }

    dispatch(setCartToggle(open));
  };

  const handleCloseDrawerMenu = () => {
    dispatch(setDrawerMenuClose());
  };

  const handleOpenDrawerMenu = () => {
    dispatch(setDrawerMenuOpen());
  };

  const handleCloseSearchModal = () => {
    handleActiveTab();
    dispatch(setSearchModalClose());
  };

  const handleOpenSearchModal = () => {
    dispatch(setSearchModalOpen());
  };

  const handleMobileMenuClick = (type: MenuType, label: string) => {
    if (label === navHeaderItemMobile.cartDrawer.label) {
      handleToggleCart(true);
    }

    if (label === navHeaderItemMobile.searchModal.label) {
      handleOpenSearchModal();
    }

    if (label === navHeaderItemMobile.menuDrawer.label) {
      handleOpenDrawerMenu();
    }

    if (isCartOpen) {
      handleToggleCart(false);
    }

    if (isDrawerMenuOpen) {
      handleCloseDrawerMenu();
    }

    if (isSearchOpen) {
      handleCloseSearchModal();
    }

    setMenuMobileTabActive(label);
    trackMenuClick(type, label);
  };

  return {
    isCartOpen,
    isSearchOpen,
    isDrawerMenuOpen,
    isMenuMobileTabActive,
    trackMenuClick,
    handleCloseDrawerMenu,
    handleOpenDrawerMenu,
    handleCloseSearchModal,
    handleOpenSearchModal,
    handleToggleCart,
    handleMobileMenuClick,
    handleActiveTab
  };
};
