import { CartLoadingStatus } from '../../../components/Cart/reducers';
import { State } from '../../../store/reducers';

const getCart = (state: State) => state?.cart;

export const getCartToggle = (state: State): boolean => {
  const cart = getCart(state);

  return cart?.isOpen;
};

export const getShouldUpdateCart = (state: State) => {
  const cart = getCart(state);

  return cart?.shouldUpdate;
};

export const getIsShowAlertAllProductsAddedToCart = (state: State): boolean => {
  const cart = getCart(state);

  return cart?.isShowAlertAllProductsAddedToCart;
};

export const getIsCartLoading = (state: State): boolean => {
  const cart = getCart(state);
  return cart?.loadingCart === CartLoadingStatus.LOADING;
};

export const getCartError = (state: State): boolean => {
  const cart = getCart(state);
  return cart?.cartError;
};
