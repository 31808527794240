import useResizeObserver from '@react-hook/resize-observer';
import { RefObject, useLayoutEffect, useState } from 'react';

export function useSize(target: RefObject<HTMLHeadElement>) {
  const [size, setSize] = useState<DOMRect>();

  useLayoutEffect(() => {
    setSize(target?.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry: ResizeObserverEntry) => setSize(entry.contentRect));
  return size;
}
