import { IconName } from '@carlsberggroup/malty.atoms.icon';
import { Pill, PillColor, PillSize } from '@carlsberggroup/malty.atoms.pill';
import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { useMemo } from 'react';
import { ALIGNED, app } from '../../../global/constants';
import { Currency } from '../../../global/interfaces/currencies';
import { ProductWithVariants } from '../../../global/interfaces/products';
import { getDefaultProduct } from '../../../global/utils/getDefaultProduct';
import { getRewardCurrencyOperator } from '../../../global/utils/getRewardCurrencyOperator';
import { getRewardsValue } from '../../../global/utils/getRewardValue';
import { Counter } from '../../../UI-elements/Counter';
import { ProductImageBox } from '../ProductImageBox/ProductImageBox';
import { ProductImageBoxLength, ProductImageBoxSize } from '../ProductImageBox/ProductImageBox.types';
import { RewardCurrency } from '../RewardCurrency';
import styles from './alternativeProductCard.module.scss';
import { AlternativeProductPrice } from './components/AlternativeProductPrice/AlternativeProductPrice';

interface Props {
  productWithVariants: ProductWithVariants;
  dataTestId?: string;
  currency?: Currency;
  currentQuantityInCart: number;
  quantity: number;
  onQuantityChange: (quantity: number) => void;
}

const AlternativeProductCard: React.FC<Props> = ({
  productWithVariants,
  dataTestId = 'alternative-product',
  currency,
  currentQuantityInCart,
  quantity,
  onQuantityChange
}) => {
  const product = useMemo(() => getDefaultProduct(productWithVariants), [productWithVariants]);
  const pointsPromotionValue = getRewardsValue({ isRedeemableProduct: false, product });
  const currencyOperator = getRewardCurrencyOperator({ isRedeemableProduct: false, product });
  const maxQuantity = product.maxQtyOrderable;
  const limitAlternativeQuantity = maxQuantity || app.cartLimit;

  const handleChangeQuantity = (inputQuantity: number) => {
    if (inputQuantity >= 0 && inputQuantity <= limitAlternativeQuantity) {
      onQuantityChange(inputQuantity);
    }
  };

  const handleCountChange = (value: string) => {
    const counter = parseInt(value, 10);
    if (!Number.isNaN(counter) && counter >= 0 && counter <= limitAlternativeQuantity) {
      onQuantityChange(counter);
    }
  };

  return (
    <div data-testid={dataTestId} className={styles.cardAlternative}>
      <ProductImageBox
        dataTestId={`alternative-product-image-box-${product.sku}`}
        boxLength={ProductImageBoxLength.SMALL}
        images={product.images}
        boxSize={ProductImageBoxSize.SMALL}
      />

      <div className={styles.cardAlternativeHeading}>
        <Text textStyle={TextStyle.SmallDefault} align={TextAlign.Left} color={TextColor.DisableLightTheme}>
          {product.barcode ?? `SKU: ${product.sku}`}
        </Text>
        {product.name && (
          <Text textStyle={TextStyle.SmallBold} align={TextAlign.Left}>
            {product.name}
          </Text>
        )}
      </div>

      {currentQuantityInCart > 0 && (
        <div className={styles.cardAlternativePill} data-testid={`${dataTestId}-current-quantity-in-cart`}>
          <Pill
            size={PillSize.Small}
            color={PillColor.Primary}
            icon={IconName.Cart}
            text={currentQuantityInCart.toString()}
          />
        </div>
      )}

      <Counter
        dataTestId={`${dataTestId}-counter`}
        value={quantity}
        decrease={() => handleChangeQuantity(quantity - 1)}
        increase={() => handleChangeQuantity(quantity + 1)}
        onChange={handleCountChange}
        onFocus={(e) => e.target.select()}
        minValue={0}
        maxValue={limitAlternativeQuantity}
        className={styles.cardAlternativeCounter}
      />

      <RewardCurrency
        value={pointsPromotionValue}
        dataTestId={`${dataTestId}-reward-currency`}
        operation={currencyOperator}
        aligned={ALIGNED.RIGHT}
        className={styles.cardAlternativeLoyalty}
      />

      <div className={styles.cardAlternativeContent} data-testid="alternative-pricing-content">
        {product.price && currency && (
          <AlternativeProductPrice price={product.price} dataTestId={dataTestId} currency={currency} />
        )}
      </div>
    </div>
  );
};

export { AlternativeProductCard };
