import TagManager from 'react-gtm-module';
import { ViewItemListTracking } from '../google-analytics';
import { ProductWithVariants } from '../interfaces/products';
import { getDefaultProducts } from '../utils/getDefaultProduct';
import { useGetBaseGAEventData } from './useGetBaseGAEventData';

interface UseTrackingProductsSlider {
  products: ProductWithVariants[];
  slidesPerView: number;
}
const useTrackingProductsSlider = ({ products, slidesPerView }: UseTrackingProductsSlider) => {
  const gaEventData = useGetBaseGAEventData();
  const trackingProducts = (currentPage: number) => {
    const startIndex = currentPage * slidesPerView;
    const itemsShown = products.slice(startIndex, startIndex + slidesPerView);
    const productsToTrack = getDefaultProducts(itemsShown);
    TagManager.dataLayer(ViewItemListTracking({ ...gaEventData, products: productsToTrack }));
  };
  return { trackingProducts };
};

export { useTrackingProductsSlider };
