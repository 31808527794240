import cn from 'classnames';
import React, { ReactNode } from 'react';
import styles from './dropdown.module.scss';

interface Props {
  children: ReactNode;
  inActivationFlow?: boolean;
  className?: string;
}

const Dropdown: React.FC<Props> = ({ children, inActivationFlow, className }: Props) => (
  <div
    data-testid="dropdown-options"
    className={cn(styles.dropdown, className, {
      [styles['activation-flow']]: inActivationFlow
    })}
  >
    {children}
  </div>
);

export { Dropdown };
