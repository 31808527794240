import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import cn from 'classnames';
import debounce from 'debounce';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { app } from '../../global/constants';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { ListContext } from '../../global/contexts/ListContext';
import { useCart } from '../../global/custom-hooks/useCart';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { useMenuClick } from '../../global/custom-hooks/useMenuClick';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { SearchTracking, ViewItemListTracking } from '../../global/google-analytics';
import { ItemListName, SearchFunction } from '../../global/google-analytics/tracking';
import { Product, ProductWithVariants } from '../../global/interfaces/products';
import { UserConfig } from '../../global/interfaces/user';
import { getDefaultProduct } from '../../global/utils/getDefaultProduct';
import { getTodayInIsoFormat } from '../../global/utils/getToday';
import { useLazyGetCatalogProductsQuery } from '../../services/products/products';
import { CatalogPayload } from '../../services/products/types';
import { userConfigSelector } from '../../store/slices/user';
import { SearchBox } from '../../UI-elements/SearchBox';
import { FONT_SIZE, Text, TEXT_COLOR } from '../../UI-elements/Text';
import { Loading } from '../shared/Loading';
import { Modal } from '../shared/Modal/ModalComponent';
import styles from './search.module.scss';
import { SearchResult } from './SearchResult';

const FIRST_NUMBER_OF_RESULTS = 4;

const getProductVariantsList = (productsWithVariants: ProductWithVariants[]) =>
  productsWithVariants?.map((productWithVariants) => getDefaultProduct(productWithVariants));

const SearchModal = () => {
  const { currency } = useContext(ConfigContext);
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const { t } = useTranslation();
  const [hasLoadedMore, setHasLoadedMore] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<CatalogPayload | undefined>(undefined);
  const [totalAnalyticsImpressionsSent, setTotalAnalyticsImpressionsSent] = useState(0);
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const userConfig: UserConfig = useSelector(userConfigSelector);
  const [requestedQuery, setRequestedQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const modalContentRef = useRef<HTMLInputElement>();
  const isScrollBound = useRef(false);
  const gaEventData = useGetBaseGAEventData();
  const [getCatalogProducts] = useLazyGetCatalogProductsQuery();
  const { isSearchOpen, handleCloseSearchModal } = useMenuClick();
  const { selectedCart } = useCart();

  const delayedSearch = useCallback(
    debounce((query: string, fetchAllResults: boolean) => requestData(query, fetchAllResults), 300),
    [selectedOutlet, searchResults?.page, selectedDistributor]
  );

  useEffect(() => {
    if (requestedQuery.length >= 3) {
      delayedSearch(requestedQuery, hasLoadedMore);
    } else {
      setSearchResults(undefined);
    }

    return () => {
      delayedSearch.clear();
    };
  }, [requestedQuery, hasLoadedMore]);

  const requestData = (query: string, fetchAllResults: boolean) => {
    const baseParams = {
      salesOrg: selectedOutlet?.salesOrg,
      outletId: selectedOutlet?.eid,
      deliveryDate: getTodayInIsoFormat(),
      size: fetchAllResults ? searchResults?.page?.totalElements : Number(app.productsSize),
      withFilters: false,
      withSortMode: false,
      search: query,
      requestKey: `${query}_${Math.floor(Math.random() * Math.floor(100))}`,
      distributorId: selectedDistributor?.eid
    };
    setLoading(true);
    getCatalogProducts(baseParams)
      .unwrap()
      .then((searchRes) => {
        setSearchResults(searchRes);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendProductImpressionAnalytics = (newProductsDisplayed: Product[]) => {
    TagManager.dataLayer(
      ViewItemListTracking({ ...gaEventData, listName: ItemListName.SearchResultsList, products: newProductsDisplayed })
    );
    setTotalAnalyticsImpressionsSent(totalAnalyticsImpressionsSent + newProductsDisplayed.length);
  };

  useEffect(() => {
    if (searchResults?.products) {
      if (!hasLoadedMore && userConfig) {
        TagManager.dataLayer(
          SearchTracking({
            ...gaEventData,
            searchTerm: requestedQuery,
            searchFunction: SearchFunction.Website
          })
        );
      }
      sendProductImpressionAnalytics(
        getProductVariantsList(
          !hasLoadedMore ? searchResults?.products.slice(0, FIRST_NUMBER_OF_RESULTS) : searchResults?.products
        )
      );
    }
  }, [searchResults?.products]);

  const onChange = (value: string) => {
    setHasLoadedMore(false);
    setTotalAnalyticsImpressionsSent(0);
    setRequestedQuery(value);
  };

  const resetSearch = () => {
    setRequestedQuery('');
    setHasLoadedMore(false);
    setSearchResults(undefined);
    setTotalAnalyticsImpressionsSent(0);
  };

  useEffect(() => {
    resetSearch();
  }, [selectedOutlet]);

  const onScroll = () => {
    inputRef.current?.blur();
  };

  const bindOnScroll = () => {
    if (!isScrollBound.current && modalContentRef.current) {
      modalContentRef.current.addEventListener('scroll', onScroll);
      isScrollBound.current = true;
    }
  };

  const unbindOnScroll = () => {
    if (isScrollBound.current && modalContentRef.current) {
      modalContentRef.current.removeEventListener('scroll', onScroll);
      isScrollBound.current = false;
    }
  };

  const inputMountedCb = useCallback((node: HTMLInputElement) => {
    if (node !== null) {
      inputRef.current = node;
    } else {
      inputRef.current?.removeEventListener('focus', bindOnScroll);
      inputRef.current?.removeEventListener('blur', unbindOnScroll);
      inputRef.current = null;
    }
  }, []);

  const resultsMountedCb = useCallback((node: HTMLInputElement) => {
    if (node !== null) {
      modalContentRef.current = node;
      inputRef.current.addEventListener('focus', bindOnScroll);
      inputRef.current.addEventListener('blur', unbindOnScroll);
      if (document.activeElement === inputRef.current) {
        bindOnScroll();
      }
    } else {
      unbindOnScroll();
      modalContentRef.current = null;
    }
  }, []);

  const fetchAllProducts = () => {
    setHasLoadedMore(true);
    sendProductImpressionAnalytics(getProductVariantsList(searchResults?.products.slice(FIRST_NUMBER_OF_RESULTS)));
  };

  const normalizedProducts = useMemo(
    () => (hasLoadedMore ? searchResults?.products : searchResults?.products.slice(0, FIRST_NUMBER_OF_RESULTS)),
    [searchResults?.products]
  );

  if (!currency || !selectedOutlet || !userConfig || !selectedDistributor || (!isSearchOpen && !selectedCart)) {
    return null;
  }

  const count = searchResults?.page?.totalElements;

  return (
    <ListContext.Provider value={{ listName: ItemListName.SearchResultsList }}>
      <Modal
        className={styles.modalWrapper}
        active={isSearchOpen}
        closeModal={handleCloseSearchModal}
        showCloseButton
        theme={{
          container: styles.modalDrop,
          content: styles.modalContainer,
          children: cn(styles.modalChildren, styles.searchContentWidth),
          header: styles.modalHeader
        }}
        showLogo={!isMobile}
        fixedClose={!isMobile}
        headerContent={
          <SearchBox
            value={requestedQuery}
            onChange={onChange}
            parentInputRef={inputMountedCb}
            onDeleteIconClick={resetSearch}
            placeholder={t('entries.search.searchPlaceholder', { defaultValue: 'Search' })}
            className={styles.searchBox}
          />
        }
      >
        <div className={styles.textWrapper}>
          {!searchResults?.products?.length ? (
            <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARK_GREY}>
              {t('entries.search.resultsPlaceholder', { defaultValue: 'Please try a search term above.' })}
            </Text>
          ) : null}
          {normalizedProducts && !isLoading ? (
            <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARK_GREY}>
              {searchResults?.page?.totalElements > 10 ? (
                <Trans t={t} i18nKey="entries.search.moreThan10ProductsFound">
                  More than 10 products have been found
                </Trans>
              ) : (
                <Trans t={t} i18nKey="entries.search.totalResults" count={searchResults?.page?.totalElements}>
                  <strong>{{ count }} result</strong> found
                </Trans>
              )}
            </Text>
          ) : null}
        </div>
        <div className={cn(styles.content)}>
          {isLoading ? <Loading small hideButton /> : null}
          {normalizedProducts && !isLoading ? (
            <div
              className={styles.results}
              data-testid="search-scrollable-container"
              id="search-scrollable-container"
              ref={resultsMountedCb}
            >
              {normalizedProducts.map((productWithVariants) => (
                <SearchResult product={productWithVariants} key={productWithVariants.sku} />
              ))}
              {!hasLoadedMore && searchResults?.products?.length > FIRST_NUMBER_OF_RESULTS && (
                <div className={styles.showMoreButton}>
                  <Button
                    onClick={() => fetchAllProducts()}
                    text={t('entries.search.loadMore', { defaultValue: 'Load All Results' })}
                    style={ButtonStyle.Secondary}
                    size={ButtonSize.Large}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </Modal>
    </ListContext.Provider>
  );
};

export { SearchModal };
