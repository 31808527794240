import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { FAQCategory, FAQCategoryId, FAQQuestion, FAQs } from '../../services/headless/types';

interface FAQsState {
  searchTerm?: string;
  toggledFAQs: FAQCategoryId[];
  selectedCategoryId: string;
  selectedCategoryName: string;
  searchedFAQs?: FAQs;
  showSearchResults: boolean;
  maxLengthError: boolean;
}

export const FAQsAllCategory: FAQCategory = {
  id: '0',
  name: 'All Questions'
};

export const FAQsMaxLength = 50;
export const FAQsMinLength = 3;

const typeOfSearchInRegExp = 'ig';
export const FAQsAllSelectedAreasId = FAQsAllCategory.id;

const initialState: FAQsState = {
  searchTerm: undefined,
  selectedCategoryName: undefined,
  toggledFAQs: [],
  selectedCategoryId: FAQsAllSelectedAreasId,
  searchedFAQs: undefined,
  showSearchResults: true,
  maxLengthError: false
};

export const FAQsSlice = createSlice({
  name: 'FAQs',
  initialState,
  reducers: {
    setSelectedCategoryId: (
      state,
      action: PayloadAction<{ selectedCategoryId: FAQCategoryId; FAQs: FAQs; categories: FAQCategory[] }>
    ) => {
      state.selectedCategoryId = action.payload.selectedCategoryId;
      const { searchTerm } = state;

      const filteredFAQs = action.payload.FAQs?.faqs.filter((faq: FAQQuestion) => {
        const regex = new RegExp(searchTerm, typeOfSearchInRegExp);

        if (action.payload.selectedCategoryId === FAQsAllCategory.id)
          return regex.test(faq?.content) && action.payload.selectedCategoryId === FAQsAllCategory.id;

        return regex.test(faq?.content) && faq.categoryId === action.payload.selectedCategoryId;
      });

      state.searchedFAQs = {
        faqs: filteredFAQs,
        categories: state.searchedFAQs.categories
      };

      state.selectedCategoryName = action.payload.categories.find(
        (category: FAQCategory) => category.id === action?.payload.selectedCategoryId
      ).name;
    },
    setSearchTermOn: (
      state,
      action: PayloadAction<{
        searchTerm?: FAQsState['searchTerm'];
        FAQs: FAQs | undefined;
        selectedCategoryId: FAQsState['selectedCategoryId'];
      }>
    ) => {
      if (action?.payload?.FAQs === undefined) return;

      let filteredFAQs: FAQQuestion[];
      const { searchTerm } = action?.payload;
      state.searchTerm = searchTerm;

      if (
        searchTerm === undefined ||
        action?.payload?.searchTerm?.length < FAQsMinLength ||
        searchTerm.trim() === '' ||
        state.searchedFAQs === undefined
      ) {
        filteredFAQs = action?.payload?.FAQs?.faqs;
      } else {
        filteredFAQs = action?.payload?.FAQs?.faqs.filter((faq: FAQQuestion) => {
          if (searchTerm === undefined) return faq;

          const regex = new RegExp(searchTerm, typeOfSearchInRegExp);
          return regex.test(faq?.content) || regex.test(faq?.title);
        });
      }

      const filteredCategoriesIds = [...new Set(filteredFAQs.map((faq: FAQQuestion) => faq.categoryId))];

      const filteredCategories = action.payload.FAQs?.categories.filter((category: FAQCategory) =>
        filteredCategoriesIds.includes(category.id)
      );

      if (filteredCategories.length > 0) {
        filteredCategories.unshift(FAQsAllCategory);
      }

      if (searchTerm?.length === FAQsMaxLength) {
        state.showSearchResults = false;
        state.maxLengthError = true;
      } else {
        state.maxLengthError = false;
      }

      state.showSearchResults = filteredFAQs?.length !== 0;

      state.searchedFAQs = {
        faqs: filteredFAQs,
        categories: filteredCategories
      };

      state.selectedCategoryId = FAQsAllCategory.id;
      const foundCategoryName = filteredCategories.find(
        (category: FAQCategory) => category.id === action?.payload.selectedCategoryId
      )?.name;

      state.selectedCategoryName = foundCategoryName === undefined ? FAQsAllCategory.name : foundCategoryName;
    },
    setToggledFAQs: (state, action: PayloadAction<{ toggled: boolean; FAQId: FAQCategoryId }>) => {
      if (action.payload.toggled) state.toggledFAQs = [...new Set([...state.toggledFAQs]).add(action.payload.FAQId)];
      else state.toggledFAQs = state.toggledFAQs.filter((category: FAQCategoryId) => category !== action.payload.FAQId);
    }
  }
});

export const { setSearchTermOn, setToggledFAQs, setSelectedCategoryId } = FAQsSlice.actions;
export const searchTermSelector = (state: RootState): FAQsState['searchTerm'] => state?.FAQs?.searchTerm;
export const showSearchResultsSelector = (state: RootState): FAQsState['showSearchResults'] =>
  state?.FAQs?.showSearchResults;
export const searchedFAQsSelector = (state: RootState): FAQs => state?.FAQs?.searchedFAQs;
export const maxLengthErrorSelector = (state: RootState): boolean => state?.FAQs?.maxLengthError;
export const toggledFAQsArraySelector = (state: RootState): FAQsState['toggledFAQs'] => state?.FAQs?.toggledFAQs;
export const selectedFAQsCategoryIdSelector = (state: RootState): FAQsState['selectedCategoryId'] =>
  state?.FAQs?.selectedCategoryId;
export const selectedFAQsCategoryNameSelector = (state: RootState): FAQsState['selectedCategoryName'] =>
  state?.FAQs?.selectedCategoryName;
export default FAQsSlice.reducer;
