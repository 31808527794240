import cn from 'classnames';
import React from 'react';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import { useScreenSize } from '../../../../../global/custom-hooks/useScreenSize';
import { FONT_SIZE } from '../../../../../UI-elements/Text';
import { Price } from '../../../Price';
import styles from '../../productcard.module.scss';

const Container = ({ children, dataTestId = '' }: { children?: React.ReactNode; dataTestId?: string }) => {
  const { product, cartSection, isReward, searchBarList } = React.useContext(ProductCardContext);
  const { isXSmall, isSmall, isMedium, isLarge, isXLarge, isXXLarge, isXXXLarge } = useScreenSize();
  const isTabletLandscape = isXSmall || isSmall || isMedium || isLarge;
  const mobileFontSize = isTabletLandscape ? FONT_SIZE.TINY : FONT_SIZE.MEDIUM;
  const isLargeScreen = isLarge || isXLarge || isXXLarge || isXXXLarge;

  const wrapperElement = (childrenNode: React.ReactNode) => {
    if (searchBarList && isLargeScreen) return childrenNode;
    return <div className={styles.productPriceContainer}>{childrenNode}</div>;
  };

  return (
    <>
      {wrapperElement(
        <>
          {!isReward ? (
            <div
              className={cn(styles['product-price'], {
                [styles.searchWrapper]: searchBarList
              })}
            >
              <Price
                dataTestId={`${dataTestId}-product`}
                price={product.price}
                size={mobileFontSize}
                cartSection={cartSection}
              />
            </div>
          ) : null}
          {children}
        </>
      )}
    </>
  );
};

export { Container };
