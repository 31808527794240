import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import { Text, TextAlign, TextStyle } from '@carlsberggroup/malty.atoms.text';
import * as React from 'react';
import styles from './emptyProductList.module.scss';

interface EmptyProductListProps {
  dataTestId: string;
  text: string;
}

const EmptyProductList = ({ dataTestId, text }: EmptyProductListProps) => {
  const { Bottle } = IconsPaths;

  return (
    <div data-testid={dataTestId} className={styles.alternativeEmpty}>
      <Bottle color={IconColor.Primary} size={IconSize.Large} />
      <Text textStyle={TextStyle.MediumBold} align={TextAlign.Center}>
        {text}
      </Text>
    </div>
  );
};

export { EmptyProductList };
