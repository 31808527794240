import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { OrderDetail } from '../../global/interfaces/orders';
import { baseApiQuery } from '../baseApiQuery';
import { CancelOrderResponse, OrderQueryParam, OrdersQueryParam, OrdersQueryResponse } from './types';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: baseApiQuery,
  tagTypes: ['ORDERS', 'ORDER'],
  endpoints: (builder) => ({
    getOrders: builder.query<OrdersQueryResponse, OrdersQueryParam>({
      query: ({ eid, ...params }: OrdersQueryParam) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ORDERS, { eid }),
        params
      }),
      providesTags: ['ORDERS']
    }),
    getOrder: builder.query<OrderDetail, OrderQueryParam>({
      query: ({ salesOrg, eid, id }: OrderQueryParam) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ORDERS_ID, { id, eid }),
        params: {
          salesOrg
        }
      }),
      providesTags: ['ORDER']
    }),
    cancelOrder: builder.mutation<CancelOrderResponse, OrderQueryParam>({
      query: ({ salesOrg, eid, id }: OrderQueryParam) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_CANCEL_ORDER, { id, eid }),
        params: {
          salesOrg
        },
        method: 'DELETE'
      }),
      invalidatesTags: ['ORDERS', 'ORDER']
    })
  })
});

export const { useGetOrdersQuery, useGetOrderQuery, useCancelOrderMutation } = ordersApi;
