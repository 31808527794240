import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { getToken } from '../auth/authHandler';
import { getApiConfig, getCSPlusConfig } from '../config/getConfig';
import { app } from '../global/constants';
import { ApiError } from '../global/interfaces/error';

export type FetchBaseQueryErrorExtended = {
  data: ApiError;
} & FetchBaseQueryError;

export const baseApiQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | FetchBaseQueryErrorExtended,
  { noAuth?: boolean; recaptchaToken?: string }
> = async (args, api, extraOptions) => {
  const apiconfig = await getApiConfig();
  const csplus = await getCSPlusConfig();
  const token = getToken();
  const languageCode = localStorage.getItem('i18nextLng') || app.defaultLanguage;
  const recaptchaToken = window.sessionStorage.getItem('recaptchaToken') || '';
  const apiArgs = typeof args === 'string' ? { url: args } : args;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${apiconfig.url}${csplus.url}`,
    prepareHeaders: (headers) => {
      headers.set('Accept-Language', languageCode);
      if (!extraOptions?.noAuth) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (
        (recaptchaToken.length && apiArgs?.url?.includes('pub/user-signup')) ||
        apiArgs?.url?.includes('pub/password-reset')
      ) {
        headers.set('G-Recaptcha-Response', `${recaptchaToken}`);
      }
      headers.set('Content-Type', 'application/hal+json');
      return headers;
    }
  });
  return rawBaseQuery(args, api, extraOptions);
};
