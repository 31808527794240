import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../assets/info.svg';
import { FONT_SIZE, Text, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './offlinemessage.module.scss';

const OfflineMessage: React.FC<EmptyObject> = () => {
  const { t } = useTranslation();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));

    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  }, []);

  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    if (!isOnline) {
      setShouldReload(true);
    }

    if (shouldReload) {
      window.location.reload(true);
      setShouldReload(false);
    }
  }, [isOnline]);

  if (isOnline) {
    return null;
  }

  return (
    <div className={styles.offline}>
      <div className={styles['offline-content']}>
        <Info />
        <Text color={TEXT_COLOR.WHITE} size={FONT_SIZE.SMALL}>
          {t('entries.general.offline', {
            defaultValue: "It looks like you're offline, please reconnect to continue!"
          })}
        </Text>
      </div>
    </div>
  );
};

export { OfflineMessage };
