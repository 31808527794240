import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY_IN_TOOLTIP } from '../../../global/constants';
import styles from './productsTooltip.module.scss';
import { TooltipProductData } from './ProductsTooltipItem';

interface ProductsTooltipLimitedProps {
  productsTooltip: TooltipProductData[];
}

const ProductsTooltipLimited = ({ productsTooltip }: ProductsTooltipLimitedProps) => {
  const { t } = useTranslation();

  if (!(productsTooltip.length > MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY_IN_TOOLTIP)) {
    return null;
  }

  return (
    <div className={styles.tooltipFooter} data-testid="tooltip-footer">
      <Text textStyle={TextStyle.SmallBold} align={TextAlign.Left} color={TextColor.White}>
        {t('entries.cart.tooltipExtra', {
          defaultValue: `{{itemsCount}} more items added`,
          itemsCount: productsTooltip.length - MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY_IN_TOOLTIP
        })}
      </Text>
    </div>
  );
};
export { ProductsTooltipLimited };
