import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PWAPrompt from 'react-ios-pwa-prompt';

const AddToHomeScreen: FC<EmptyObject> = () => {
  const { t } = useTranslation();

  return (
    <PWAPrompt
      timesToShow={5}
      delay={30000}
      permanentlyHideOnDismiss={false}
      copyTitle={t('entries.addToHomeScreen.title', { defaultValue: 'Add to Home Screen' })}
      copyBody={t('entries.addToHomeScreen.body', {
        defaultValue:
          'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.'
      })}
      copyShareButtonLabel={t('entries.addToHomeScreen.shareButtonLabel', {
        defaultValue: `1) Press the 'Share' button`
      })}
      copyAddHomeButtonLabel={t('entries.addToHomeScreen.addButtonLabel', {
        defaultValue: `2) Press 'Add to Home Screen'`
      })}
      copyClosePrompt={t('entries.addToHomeScreen.close', { defaultValue: 'Cancel' })}
    />
  );
};

export { AddToHomeScreen };
