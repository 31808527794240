import React, { FC } from 'react';
import { HEADLESS_WIDGET_ALIAS } from '../../global/constants';
import { HeadlessContentWidget } from '../../pages/DiscoverDetails/components/HeadlessContentWidget/HeadlessContentWidget';
import { HeadlessImageListWidget } from '../../pages/DiscoverDetails/components/HeadlessImageListWidget/HeadlessImageListWidget';
import { RelatedArticleWidget } from '../../pages/DiscoverDetails/components/RelatedArticleWidget/RelatedArticleWidget';
import { HeadlessBannerWidget } from '../../pages/Home/components/HeadlessBannerWidget';
import { HeadlessProductSliderWidget } from '../../pages/Home/components/HeadlessProductSliderWidget';
import { RecommendationsCatalog } from '../../pages/Home/components/RecommendationsCatalog';
import { PrizeLotteryBanner } from '../../pages/PrizeLottery/components/PrizeLotteryBanner';
import { EnterspeedWidgetBlock } from '../../services/headless/types';

type OptionalParams = {
  id?: number;
  alias?: string;
};
// use loadable for the least used widgets
const WIDGET_COMPONENTS = {
  [HEADLESS_WIDGET_ALIAS.banners]: HeadlessBannerWidget,
  [HEADLESS_WIDGET_ALIAS.prizeLottery]: PrizeLotteryBanner,
  [HEADLESS_WIDGET_ALIAS.recommendations]: RecommendationsCatalog,
  [HEADLESS_WIDGET_ALIAS.productSlider]: HeadlessProductSliderWidget,
  [HEADLESS_WIDGET_ALIAS.recommendationsSlider]: HeadlessProductSliderWidget,
  [HEADLESS_WIDGET_ALIAS.textContent]: HeadlessContentWidget,
  [HEADLESS_WIDGET_ALIAS.textAndImageContent]: HeadlessContentWidget,
  [HEADLESS_WIDGET_ALIAS.imageContent]: HeadlessContentWidget,
  [HEADLESS_WIDGET_ALIAS.videoContent]: HeadlessContentWidget,
  [HEADLESS_WIDGET_ALIAS.textAndVideoContent]: HeadlessContentWidget,
  [HEADLESS_WIDGET_ALIAS.relatedArticles]: RelatedArticleWidget,
  [HEADLESS_WIDGET_ALIAS.imageList]: HeadlessImageListWidget
};

export const HeadlessWidget = ({ widgetBlock, id }: { widgetBlock: EnterspeedWidgetBlock; id: number }) => {
  const WidgetComponent = WIDGET_COMPONENTS[widgetBlock.alias] as FC<OptionalParams>;

  if (!WidgetComponent) {
    return null;
  }

  return <WidgetComponent {...widgetBlock} id={id} alias={widgetBlock?.alias} />;
};
