import React from 'react';
import { CATALOG_TYPES, HEADLESS_SLIDER_CODES } from '../../../global/constants';
import { ListContext } from '../../../global/contexts/ListContext';
import { ItemListName } from '../../../global/google-analytics/tracking';
import { EnterspeedWidgetProductSlider } from '../../../services/headless/types';
import { FavouritesProductSlider } from './FavouritesProductSlider';
import { PromotionsProductSlider } from './PromotionProductsSlider';
import { RecommendationsProductSlider } from './RecommendationsProductSlider';

export const HeadlessProductSliderWidget: React.FC<EnterspeedWidgetProductSlider> = ({
  widgets,
  limit = 12,
  module
}) => {
  if (module) {
    return (
      <ListContext.Provider value={{ listName: ItemListName.PopularProductsList }}>
        <RecommendationsProductSlider title="" limit={limit} headlessSliderCode={HEADLESS_SLIDER_CODES.popular} />;
      </ListContext.Provider>
    );
  }

  if (!widgets?.length) {
    return null;
  }

  const { type, title, backgroundImage } = widgets[0];

  if (type === HEADLESS_SLIDER_CODES.favourites) {
    return (
      <ListContext.Provider value={{ listName: ItemListName.FavouriteProductsList }}>
        <FavouritesProductSlider
          limit={limit}
          title={title}
          backgroundImage={backgroundImage}
          type={CATALOG_TYPES.favourites}
        />
      </ListContext.Provider>
    );
  }

  if (type === HEADLESS_SLIDER_CODES.picos) {
    return (
      <ListContext.Provider value={{ listName: ItemListName.PicosList }}>
        <FavouritesProductSlider
          limit={limit}
          title={title}
          backgroundImage={backgroundImage}
          type={CATALOG_TYPES.picos}
        />
      </ListContext.Provider>
    );
  }

  if (type === HEADLESS_SLIDER_CODES.promotions) {
    return (
      <ListContext.Provider value={{ listName: ItemListName.PromotionsList }}>
        <PromotionsProductSlider
          limit={limit}
          title={title}
          backgroundImage={backgroundImage}
          type={CATALOG_TYPES.promotions}
        />
      </ListContext.Provider>
    );
  }

  return (
    <ListContext.Provider value={{ listName: ItemListName.RecommendationsList }}>
      <RecommendationsProductSlider
        title={title}
        limit={limit}
        headlessSliderCode={type}
        backgroundImage={backgroundImage}
      />
    </ListContext.Provider>
  );
};
