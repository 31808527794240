import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartSlice {
  baseSku?: string;
  listOfSkusLoading: string[];
}

const initialState: CartSlice = {
  baseSku: undefined,
  listOfSkusLoading: []
};

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    setClickedProductBaseSku: (state, action: PayloadAction<string>) => {
      Object.assign(state, {
        baseSku: action.payload,
        listOfSkusLoading: state.listOfSkusLoading.includes(action.payload)
          ? state.listOfSkusLoading
          : [...state.listOfSkusLoading, action.payload]
      });
    },
    resetProductBaseSku: (state) => {
      state.baseSku = undefined;
      state.listOfSkusLoading = [];
    }
  }
});

export const { setClickedProductBaseSku, resetProductBaseSku } = cartSlice.actions;

export default cartSlice.reducer;
