import { CART_SECTION_TYPES, CATALOG_TYPES, CHIP_TYPES } from '../constants';

export const getCartSection = (activeType: CHIP_TYPES) => {
  if (activeType === CATALOG_TYPES.empties) {
    return CART_SECTION_TYPES.returnable;
  }
  if (activeType === CATALOG_TYPES.reward) {
    return CART_SECTION_TYPES.reward;
  }

  return CART_SECTION_TYPES.product;
};
