export const getGlobalConfig = () => {
  // eslint-disable-next-line no-underscore-dangle
  const config = window.__CONFIG__ ?? {
    //  appConfigApi defaults to csplus mock in local environment
    appConfigApi: `${window.location.origin}/configuration/handler`,
    enterspeedApi: 'https://delivery.enterspeed.com/v1',
    contentDeliveryApi: 'https://api.dev.carlsbergwebservices.com/cx-cslight-headless-contentdelivery/handler'
  };

  return config;
};
