import { useEffect, useRef } from 'react';

const useAutoHideTimer = (callback: () => void, delay: number) => {
  const timerRef = useRef<number | NodeJS.Timeout | null>(null);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [delay]);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      callback();
    }, delay);
  };

  return resetTimer;
};

export { useAutoHideTimer };
