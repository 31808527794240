import { useGetCartsQuery } from '../../services/cart/cart';
import { useGetTrackedChangesQuery } from '../../services/outlets/outlets';
import { DistributorI } from '../interfaces/distributor';
import { Outlet } from '../interfaces/outlet';
import { ITrackedChanges } from '../interfaces/trackedChanges';

interface TrackedChangesHook {
  trackedChangesData: ITrackedChanges;
}

export const useTrackedChanges = (
  uiSelectedOutlet: Outlet,
  uiSelectedDistributor: DistributorI
): TrackedChangesHook => {
  const { salesOrg, eid: outletId } = uiSelectedOutlet || {};
  const { eid: distributorId } = uiSelectedDistributor || {};

  const cartParams = {
    salesOrg,
    outletId,
    distributorId
  };
  const cartSkipParams = { skip: !salesOrg || !outletId || !distributorId };
  const { data, error: getCartsError } = useGetCartsQuery(cartParams, cartSkipParams);
  if (getCartsError) {
    console.error(getCartsError);
  }

  const trackedChangesParams = {
    salesOrg,
    outletId,
    distributorId,
    cartEid: data?.carts && data?.carts[0]?.eid
  };
  const trackedChangesSkipParams = { skip: !salesOrg || !outletId || !distributorId || !data?.carts };
  const { data: trackedChangesData, error: getTrackedChangesError } = useGetTrackedChangesQuery(
    trackedChangesParams,
    trackedChangesSkipParams
  );
  if (getTrackedChangesError) {
    console.error(getTrackedChangesError);
  }

  return { trackedChangesData };
};
