import React from 'react';
import { useSelector } from 'react-redux';
import { useCart } from '../../global/custom-hooks/useCart';
import { State } from '../../store/reducers';
import PromotionalModal from './PromotionalModalComponent';

const PromotionalModalContainer: React.FC<EmptyObject> = () => {
  const { selectedCart } = useCart();
  const appliedPromotion = useSelector((state: State) => state.promotionModal)?.promotionObject;

  if (!appliedPromotion?.appliedPromotionData) {
    return null;
  }

  return <PromotionalModal cart={selectedCart} />;
};

export default PromotionalModalContainer;
