import { AlertInline, AlertInlineColor } from '@carlsberggroup/malty.molecules.alert-inline';
import cn from 'classnames';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { app, CART_SECTION_TYPES, CHIP_TYPES, PRODUCT_CARD_STYLE, PROMOTION_TYPES } from '../../../global/constants';
import { ProductCardContext } from '../../../global/contexts/ProductCardContext';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { ProductClickTracking } from '../../../global/google-analytics';
import { Product, ProductWithVariants } from '../../../global/interfaces/products';
import { SKU } from '../../../global/types';
import { getCartSection } from '../../../global/utils/getCartSection';
import { getDefaultProduct } from '../../../global/utils/getDefaultProduct';
import { ProductImageBox } from '../ProductImageBox';
import { ProductImageBoxLength, ProductImageBoxSize } from '../ProductImageBox/ProductImageBox.types';
import { Container } from './components/Content';
import { Name } from './components/Name';
import { ProductCardFooterType } from './components/ProductCardFooterType';
import { RewardCurrencyComponent } from './components/RewardCurrency';
import { SKUDetails } from './components/SKU';
import { StockLabelComponent } from './components/Stock';
import { VariantComponent } from './components/Variants';
import { FavouriteModal } from './FavouriteModal';
import styles from './productcard.module.scss';

interface RootComponentProps {
  renderIconFavourites: boolean;
  children: React.ReactNode;
  productWithVariants: ProductWithVariants;
  activeType: CHIP_TYPES;
  onProductCardClick?: (sku: SKU) => void;
  productCardStyle?: PRODUCT_CARD_STYLE;
  promotionType?: PROMOTION_TYPES;
  disabled?: boolean;
  searchBarList?: boolean;
  dataTestId?: string;
  hasTransparentBackground?: boolean;
}

export const RootComponentContext = React.createContext<{
  product: Product;
  productWithVariants: ProductWithVariants;
  setProduct: React.Dispatch<React.SetStateAction<Product>>;
  isProductWithNoPrice: boolean;
  activeType: CHIP_TYPES | undefined;
  isReward: boolean;
  navigateToProductPage: () => void;
}>({
  product: undefined,
  setProduct: () => undefined,
  productWithVariants: undefined,
  isProductWithNoPrice: false,
  activeType: undefined,
  isReward: false,
  navigateToProductPage: () => undefined
});

const RootComponent = ({
  productWithVariants,
  dataTestId = '',
  activeType,
  productCardStyle,
  children,
  onProductCardClick,
  disabled,
  renderIconFavourites,
  promotionType,
  searchBarList,
  hasTransparentBackground = false
}: RootComponentProps) => {
  const { t } = useTranslation();
  const productCardRef: RefObject<HTMLDivElement> = useRef();
  const [product, setProduct] = useState<Product>(getDefaultProduct(productWithVariants));
  const isProductWithNoPrice = !product?.price;
  const cartSection = getCartSection(activeType);
  const isReward = cartSection === CART_SECTION_TYPES.reward;
  const history = useHistory();
  const gaEventData = useGetBaseGAEventData();
  const isBundle = productCardStyle === PRODUCT_CARD_STYLE.shadowed;
  const [isProductInFavouriteList, setIsProductInFavouriteList] = useState(productWithVariants.isFavourite);
  const [isFavouriteModalOpen, setIsFavouriteModalOpen] = useState(false);
  const { isXSmall, isSmall } = useScreenSize();
  const maxQuantity = product.maxQtyOrderable;
  const [showMaxQuantityAlertOnSearchBar, setShowMaxQuantityAlertOnSearchBar] = useState(false);
  const isMobile = isXSmall || isSmall;

  const closeFavouriteModal = () => {
    setIsFavouriteModalOpen(false);
  };

  const navigateToProductPage = useCallback(() => {
    if (typeof onProductCardClick === 'function') {
      onProductCardClick(product.sku);
    }

    if (isReward) {
      return;
    }

    TagManager.dataLayer(ProductClickTracking({ ...gaEventData, products: [product] }));
    const url =
      product.baseSku === product.sku
        ? `/products/${cartSection}/${product.baseSku}/${product.baseSku}`
        : `/products/${cartSection}/${product.baseSku}/${product.sku}`;

    if (history.location.pathname !== url) {
      history.push(url);
    }
  }, [product, onProductCardClick, isReward, gaEventData, history, cartSection]);

  const handleChangeProduct = useCallback((newProduct: Product) => {
    setProduct(newProduct);
  }, []);

  useEffect(() => {
    setIsProductInFavouriteList(productWithVariants.isFavourite);

    return () => {
      setIsProductInFavouriteList(false);
    };
  }, [productWithVariants.isFavourite]);

  const cardContextValue = useMemo(
    () => ({
      product,
      productWithVariants,
      cartSection,
      handleChangeProduct,
      isReward,
      navigateToProductPage,
      activeType,
      searchBarList,
      showMaxQuantityAlertOnSearchBar,
      setShowMaxQuantityAlertOnSearchBar
    }),
    [
      product,
      activeType,
      productWithVariants,
      cartSection,
      handleChangeProduct,
      cartSection,
      isReward,
      navigateToProductPage,
      searchBarList,
      showMaxQuantityAlertOnSearchBar,
      setShowMaxQuantityAlertOnSearchBar
    ]
  );

  const imageBoxProps = () => {
    if (searchBarList) {
      return {
        boxSize: isMobile ? ProductImageBoxSize.SMALL : ProductImageBoxSize.MEDIUM,
        boxLength: ProductImageBoxLength.SMALL,
        fitLayout: ProductImageBoxSize.FIT_LAYOUT
      };
    }
    return {
      boxSize: ProductImageBoxSize.MEDIUM,
      boxLength: ProductImageBoxLength.MEDIUM,
      fitLayout: undefined
    };
  };

  return (
    <ProductCardContext.Provider value={cardContextValue}>
      <div
        data-testid={`${dataTestId}-product-card`}
        className={cn(styles['product-card'], {
          [styles.isReward]: isReward,
          [styles.disableProduct]: isProductWithNoPrice || disabled,
          [styles.isBundle]: isBundle,
          [styles.searchBarLayout]: searchBarList
        })}
        ref={productCardRef}
      >
        {isProductWithNoPrice || disabled ? (
          <div data-testid="disable-layer" className={styles.disableProduct} />
        ) : null}

        <ProductImageBox
          boxSize={imageBoxProps().boxSize}
          boxLength={imageBoxProps().boxLength}
          images={product.images}
          cartSection={cartSection}
          renderPillForPromotions
          renderIconFavourites={renderIconFavourites}
          renderPillCounterFromCart
          productWithVariants={productWithVariants}
          isProductInFavouriteList={isProductInFavouriteList}
          setIsFavouriteModalOpen={setIsFavouriteModalOpen}
          handleNavigateToProductPage={navigateToProductPage}
          fitLayout={imageBoxProps().fitLayout}
          dataTestId={`${dataTestId}-image-box`}
          hasTransparentBackground={hasTransparentBackground}
        />

        <div
          className={cn(styles.productCardContent, {
            [styles.searchBarContentLayout]: searchBarList,
            [styles.isBundleContent]: isBundle
          })}
        >
          {children}
          <ProductCardFooterType promotionType={promotionType} isQuantityReadOnly={isBundle} dataTestId={dataTestId} />
        </div>
        {isFavouriteModalOpen ? (
          <FavouriteModal salesOrg={product.salesOrg} product={product} closeFavouriteModal={closeFavouriteModal} />
        ) : null}
      </div>
      {searchBarList && showMaxQuantityAlertOnSearchBar ? (
        <AlertInline
          color={AlertInlineColor.NotificationLight}
          message={t('entries.catalog.maxOrderLimit', {
            maxQuantity: maxQuantity ?? app.cartLimit,
            defaultValue: 'Max order limit: {{maxQuantity}}'
          })}
          dataQaId={`${dataTestId}-cart-max-quantity`}
        />
      ) : null}
    </ProductCardContext.Provider>
  );
};

const ProductCard = {
  Root: RootComponent,
  Name,
  SKULabel: SKUDetails,
  Container,
  StockLabel: StockLabelComponent,
  RewardCurrency: RewardCurrencyComponent,
  Variants: VariantComponent
};

export { ProductCard };
