import React from 'react';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import { useStockVisibility } from '../../../../../global/custom-hooks/useStockVisibility';
import { StockStatus } from '../../../../../global/interfaces/products';
import { StockLabel } from '../../../../StockAvailability/StockLabel';

interface StockLabelProps {
  dataTestId?: string;
}

const StockLabelComponent = ({ dataTestId = '' }: StockLabelProps) => {
  const { product, cartSection } = React.useContext(ProductCardContext);
  const isProductWithNoPrice = !product.price;
  const status = isProductWithNoPrice ? StockStatus.NOT_AVAILABLE : product?.stock?.stockStatus;
  const isStockVisible = useStockVisibility({ cartSection, product });
  if (!isStockVisible) return null;
  return <StockLabel dataTestId={`${dataTestId}-product-stock`} status={status} />;
};

export { StockLabelComponent };
