import { IconName } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAddToCartNotification } from '../../global/custom-hooks/useAddToCartNotification';
import { PAGE_ROUTES, useGetCurrentPageInfo } from '../../global/custom-hooks/useGetCurrentPageInfo';
import { useHeaderNavLinks } from '../../global/custom-hooks/useHeaderNavLinks';
import { useHeaderNavLinksMobile } from '../../global/custom-hooks/useHeaderNavLinksMobile';
import { useMenuClick } from '../../global/custom-hooks/useMenuClick';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { MenuType } from '../../global/google-analytics/tracking';
import { HeadlessImageI } from '../../global/interfaces/headless';
import globalStyles from '../../styles/global.scss';
import { CloudinaryImage } from '../../UI-elements/CloudinaryImage';
import { Announcement } from '../Announcement';
import { FloatTooltip } from '../shared/FloatTooltip';
import { ProductsTooltip } from '../shared/ProductsTooltip';
import { HeaderOffset } from './components/HeaderOffset';
import { NavHeader } from './components/NavHeader';
import { NavHeaderMobile } from './components/NavHeaderMobile';
import styles from './header.module.scss';

interface HeaderComponentProps {
  logo: HeadlessImageI;
  onLogout: () => void;
  showNavBarMenu?: boolean;
}

const HeaderComponent = ({ logo, onLogout, showNavBarMenu = true }: HeaderComponentProps) => {
  const { path } = useGetCurrentPageInfo();
  const headerRef: RefObject<HTMLHeadElement> = useRef();
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const showNavMenu = useMemo(() => showNavBarMenu && path !== PAGE_ROUTES.checkout.path, [showNavBarMenu, path]);
  const {
    trackMenuClick,
    handleMobileMenuClick,
    handleActiveTab,
    isMenuMobileTabActive,
    isDrawerMenuOpen,
    isSearchOpen,
    isCartOpen
  } = useMenuClick();

  const { isHeaderDesktopVersion } = useHeaderNavLinks();
  const { navHeaderItemMobile } = useHeaderNavLinksMobile();
  const {
    isToggleTooltip,
    setIsToggleTooltip,
    addingProductsNotification,
    tooltipProductsData
  } = useAddToCartNotification();

  useEffect(() => {
    if (!isMobile || !showNavMenu) {
      return;
    }

    if (!isDrawerMenuOpen && !isSearchOpen && !isCartOpen) {
      handleActiveTab();
    }
  }, [path, isDrawerMenuOpen, isSearchOpen, isCartOpen, isMobile]);

  return (
    <>
      <header className={styles.header} ref={headerRef}>
        <Announcement />
        {!isMobile ? (
          <div className={styles.headerHeight}>
            <div
              className={cn(globalStyles.container, styles.headerContainer, {
                [styles.desktopMenu]: isHeaderDesktopVersion
              })}
            >
              {logo && (
                <Link
                  data-testid="link-logo"
                  to="/home"
                  className={styles['logo-link']}
                  onClick={() => trackMenuClick(MenuType.Icon, 'Home')}
                >
                  <CloudinaryImage
                    dataTestId="logo-image"
                    cssClassName={styles.shopLogo}
                    src={logo?.url}
                    alt="header-logo"
                    width={180}
                    height={32}
                    disableAutoFormat
                  />
                </Link>
              )}
              {showNavMenu ? <NavHeader onLogout={onLogout} /> : null}
            </div>
          </div>
        ) : null}

        {isMobile && showNavMenu ? (
          <NavHeaderMobile.Root>
            <NavHeaderMobile.Tab isActive={isMenuMobileTabActive === navHeaderItemMobile.home.label}>
              <NavHeaderMobile.ItemRoute
                icon={IconName.Home}
                isActive={isMenuMobileTabActive === navHeaderItemMobile.home.label}
                label={navHeaderItemMobile.home.label}
                onMenuClick={handleMobileMenuClick}
                routePath={navHeaderItemMobile.home.path}
              />
            </NavHeaderMobile.Tab>
            <NavHeaderMobile.Tab isActive={isMenuMobileTabActive === navHeaderItemMobile.catalog.label}>
              <NavHeaderMobile.ItemRoute
                icon={IconName.Bottle}
                isActive={isMenuMobileTabActive === navHeaderItemMobile.catalog.label}
                label={navHeaderItemMobile.catalog.label}
                onMenuClick={handleMobileMenuClick}
                routePath={navHeaderItemMobile.catalog.path}
              />
            </NavHeaderMobile.Tab>
            <NavHeaderMobile.Tab isActive={isMenuMobileTabActive === navHeaderItemMobile.cartDrawer.label}>
              <FloatTooltip.Root
                open={isToggleTooltip}
                onOpenChange={() => setIsToggleTooltip(!isToggleTooltip)}
                placement="top"
              >
                <FloatTooltip.Content
                  className={cn(styles.floatTooltipContentMobile, {
                    [styles.searchBarZIndex]: isSearchOpen
                  })}
                >
                  <ProductsTooltip.Root>
                    <ProductsTooltip.List productsTooltip={tooltipProductsData} />
                    <ProductsTooltip.Limited productsTooltip={tooltipProductsData} />
                    <ProductsTooltip.Fetching addingProductsNotification={addingProductsNotification} />
                  </ProductsTooltip.Root>
                </FloatTooltip.Content>
                <FloatTooltip.Trigger>
                  <NavHeaderMobile.ItemButton
                    icon={IconName.Cart}
                    isActive={isMenuMobileTabActive === navHeaderItemMobile.cartDrawer.label}
                    label={navHeaderItemMobile.cartDrawer.label}
                    onMenuClick={handleMobileMenuClick}
                  />
                </FloatTooltip.Trigger>
              </FloatTooltip.Root>
            </NavHeaderMobile.Tab>
            <NavHeaderMobile.Tab isActive={isMenuMobileTabActive === navHeaderItemMobile.searchModal.label}>
              <NavHeaderMobile.ItemButton
                icon={IconName.Search}
                isActive={isMenuMobileTabActive === navHeaderItemMobile.searchModal.label}
                label={navHeaderItemMobile.searchModal.label}
                onMenuClick={handleMobileMenuClick}
              />
            </NavHeaderMobile.Tab>
            <NavHeaderMobile.Tab isActive={isMenuMobileTabActive === navHeaderItemMobile.menuDrawer.label}>
              <NavHeaderMobile.ItemButton
                icon={IconName.Menu}
                isActive={isMenuMobileTabActive === navHeaderItemMobile.menuDrawer.label}
                label={navHeaderItemMobile.menuDrawer.label}
                onMenuClick={handleMobileMenuClick}
              />
            </NavHeaderMobile.Tab>
          </NavHeaderMobile.Root>
        ) : null}
      </header>
      <HeaderOffset headerRef={headerRef} />
    </>
  );
};

export { HeaderComponent };
