import cssVars from '../styles/_variables.scss';

export const app = {
  productsSize: 12,
  largeProductsSize: 15,
  defaultLanguage: 'en-GB',
  defaultCurrencyCode: 'LAK',
  defaultCurrencySymbol: '₭',
  largeDeviceWidth: 770,
  cartLimit: 9999,
  maxProductQuantityInCartShownOnPill: 99,
  minAddToCart: 1
};

export enum breakpoints {
  xsmall = parseInt(cssVars.xsmall, 10), // 375px
  small = parseInt(cssVars.small, 10), // 768px
  medium = parseInt(cssVars.medium, 10), // 1024px
  large = parseInt(cssVars.large, 10), // 1280px
  xlarge = parseInt(cssVars.xlarge, 10), // 1440px
  xxlarge = parseInt(cssVars.xxlarge, 10) // 1920px
}

export enum PRODUCTS_PER_SIZE {
  xsmall = 1,
  small = 2,
  medium = 3,
  large = 4,
  xlarge = 5
}

export enum GUTTER_PER_SIZE {
  large = parseInt(cssVars['gutter-width-large'], 10), // 32px
  medium = parseInt(cssVars['gutter-width-medium'], 10), // 24px
  small = parseInt(cssVars['gutter-width-small'], 10), // 16px
  xsmall = parseInt(cssVars['gutter-width-xsmall'], 10) // 8px
}

export enum MARKETS {
  russia = 'ru',
  malaysia = 'my',
  laos = 'la',
  lithuania = 'lt',
  latvia = 'lv',
  estonia = 'ee'
}

export enum ENVIRONMENTS {
  dev = 'dev',
  tst = 'tst'
}

export enum CATALOG_TYPES {
  default = 'PRD',
  promotions = 'PRO',
  empties = 'EMP',
  favourites = 'FAV',
  reward = 'RWD',
  picos = 'PIC'
}

export enum CART_SECTION_TYPES {
  product = 'PRD',
  returnable = 'EMP',
  reward = 'RWD',
  free = 'FREE',
  notDeliverableProduct = 'NOT_DELIVERABLE_PRODUCT',
  notDeliverableReward = 'NOT_DELIVERABLE_REWARD',
  notDeliverableFree = 'NOT_DELIVERABLE_FREE',
  notDeliverableReturnable = 'NOT_DELIVERABLE_RETURNABLE'
}

export type CHIP_TYPES = CATALOG_TYPES | RECOMMENDATIONS_CODES;

export const PROMOTIONS_FILTER_CODE = 'promotions';

export enum PRODUCT_CARD_STYLE {
  plain = 'plain',
  shadowed = 'shadowed'
}

export enum STEPS {
  landing = 'landing',
  activate = 'activate'
}

export enum LocizeConfig {
  projectId = '96f02527-3e9d-4a49-90dd-47f5c417caa5'
}

export enum RECOMMENDATIONS_CODES {
  popular = 'POPULAR',
  trending = 'TRENDING',
  recentlyBought = 'RECENTLY_BOUGHT',
  new = 'NEW',
  substituting = 'SUBSTITUTING',
  complementary = 'COMPLEMENTARY',
  promotions = 'PROMOTIONS'
}

export enum HEADLESS_SLIDER_CODES {
  popular = 'Popular',
  trending = 'Trending',
  new = 'New',
  favourites = 'Favourites',
  recentlyBought = 'Recently Bought',
  picos = 'PicOS',
  promotions = 'Promotions'
}

export enum HEADLESS_RECOMMENDATIONS_TABS {
  popular = 'Popular',
  trending = 'Trending',
  new = 'New',
  recentlyBought = 'Recently Bought'
}

export enum HEADLESS_WIDGET_ALIAS {
  banners = 'csBannerWidgetElementType',
  productSlider = 'csProductSliderElementType',
  recommendations = 'csRecommendationsElementType',
  textContent = 'csTitleAndTextElementType',
  textAndImageContent = 'csImagePlusTextElementType',
  imageContent = 'csImageElementType',
  videoContent = 'csVideoElementType',
  cta = 'csCallToActionElementType',
  imageList = 'csImageListElementType',
  relatedArticles = 'csRelatedArticlesElementType',
  recommendationsSlider = 'csSystemModulesElementType',
  textAndVideoContent = 'csVideoPlusTextElementType',
  prizeLottery = 'csLaosPrizeLotteryElementType'
}

export enum PROMOTION_TYPES {
  PERCENTAGE_OLD = 'Percentage',
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT_OLD = 'Amount',
  AMOUNT = 'AMOUNT',
  FREE_ITEM = 'FREE_ITEM',
  FREE_ITEM_ADD = 'FREE_ITEM_ADD',
  FREE_ITEM_ADD_REPEAT = 'FREE_ITEM_ADD_REPEAT',
  BUNDLE = 'BUNDLE',
  POINTS_OLD = 'Points',
  POINTS = 'POINTS',
  OTHERS = 'OTHERS'
}

export enum LANGUAGE_CODE {
  russian = 'ru-RU',
  laos = 'lo-LA',
  english = 'en-GB'
}

export enum BFF_ERROR_CODES {
  CUT_OFF_ERROR_DETAILS = 'ORD_001 - Invalid cut-off time',
  OUT_OF_STOCK_ERROR_CODE = 'BFF-017',
  FAVOURITE_LIST_PRODUCTS_ERROR = 'BFF-017',
  NO_STOCK_INFORMATION_ERROR_CODE = 'BFF-018',
  INACTIVE_OUTLET_ERROR_CODE = 'BFF-020',
  CREATE_FAVOURITE_NAME_ALREADY_EXIST = 'BFF-021',
  EDIT_FAVOURITE_NAME_ALREADY_EXIST = 'BFF-022',
  FAVOURITE_LIST_NOT_EXIST = 'BFF-023',
  NO_WAREHOUSE_ERROR_CODE = 'BFF-024',
  NO_PRIZE_LOTTERY_FOUND = 'BFF-029'
}

export enum ALIGNED {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

export enum COMPLIANCE_POLICIES {
  COOKIE = 'Cookie policy',
  PRIVACY = 'Privacy policy'
}

export const CAROUSEL_SLIDES_PER_VIEW = 4;
export const FAVOURITE_PRODUCTS_PER_PAGE = 15;

export const PAGES_DISTRIBUTOR_IS_NOT_VISIBLE: string[] = ['/checkout'];
export const DISTRIBUTOR_LOCAL_STORAGE_KEY = 'userHasSelectedDistributor';

export const ADD_TO_CART_NOTIFICATION = 'ADD_TO_CART_NOTIFICATION';
export const ADDING_PRODUCTS_NOTIFICATION = 'ADDING_PRODUCTS_NOTIFICATION';

export const MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY_IN_TOOLTIP = 3;

export const FAVOURITE_TITLE_MAX_LENGTH = 255;
export const COUNTER_MAX_LENGTH = 5;
export const COUNTER_MIN_LENGTH = 1;
export const FILTER_CHIPS_HEIGHT_MOBILE = 124;

export const PROMOTION_FALLBACK_IMAGE =
  'https://res.cloudinary.com/www-carlsberg-com/image/upload/f_auto/q_auto/v1695374775/csplus/fallback-promotions-image_ya4byz.png';

export const G_RECAPTCHA_ID = 'g-recaptcha-el';
export const DEFAULT_RESPONSIBLY_MESSAGE_URL = 'http://www.responsibledrinking.org';
export const GOOGLE_PRIVACY_POLICY_URL = 'https://policies.google.com/privacy';
export const GOOGLE_PRIVACY_TERMS_URL = 'https://policies.google.com/terms';

export const FLAG_ICON_WIDTH = 20;
export const FLAG_ICON_HEIGHT = 14;
