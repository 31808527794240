import { FilterType, GAEvent, PageType, Platform, TrackingFilterCatalog } from '../tracking';
import { gaClientId, isPWA } from '../utils';

interface FilterTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteEnvironment: string;
  siteLanguage: string;
  userId: string;
  siteMarket: string;
  filterType: FilterType;
  filterName: string;
  filterLabel: string;
  filterLabelId: string;
}

export const FilterTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteEnvironment,
  siteLanguage,
  userId,
  siteMarket,
  filterType,
  filterName,
  filterLabel,
  filterLabelId
}: FilterTrackingProps): TrackingFilterCatalog => ({
  dataLayer: {
    event: GAEvent.FilterSelection,
    event_name: GAEvent.FilterSelection,
    page_type: PageType.List,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    user_id: userId,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    filter_type: filterType,
    filter_name: filterName,
    filter_label: filterLabel,
    filter_label_id: filterLabelId
  }
});
