import { Headline, HeadlineAlign, HeadlineStyle } from '@carlsberggroup/malty.atoms.headline';
import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import React, { FC } from 'react';
import { HeadlessImageI } from '../../../../global/interfaces/headless';
import { CloudinaryImage } from '../../../../UI-elements/CloudinaryImage';
import styles from './drawerHeader.module.scss';

export type DrawerHeaderProps = {
  title?: string;
  onClose: () => void;
  dataTestId?: string;
  useBreadcrumb?: boolean;
  logo?: HeadlessImageI;
};

const DrawerHeader: FC<DrawerHeaderProps> = ({
  dataTestId = 'drawer-header',
  onClose,
  title,
  useBreadcrumb = false,
  logo
}) => {
  const { Close, ArrowSmallLeft } = IconsPaths;
  return (
    <div className={styles.header} style={logo && { boxShadow: 'none' }}>
      {!useBreadcrumb ? (
        <div data-testid={`${dataTestId}-close-button`} className={styles.buttonWrapper} onClick={onClose}>
          <Close color={IconColor.Primary} size={IconSize.Medium} />
        </div>
      ) : null}
      {!useBreadcrumb && logo ? (
        <CloudinaryImage
          dataTestId="logo-image-drawer"
          src={logo?.url}
          alt="header-logo"
          width={56}
          height={32}
          disableAutoFormat
        />
      ) : null}
      {title ? (
        <div className={styles.title}>
          {useBreadcrumb && (
            <div data-testid={`${dataTestId}-back-button`} className={styles.buttonWrapper} onClick={onClose}>
              <ArrowSmallLeft color={IconColor.Primary} size={IconSize.Medium} />
            </div>
          )}
          <Headline align={HeadlineAlign.Left} headlineStyle={HeadlineStyle.Large}>
            {title}
          </Headline>
        </div>
      ) : null}
    </div>
  );
};

export default DrawerHeader;
