import { CART_SECTION_TYPES } from '../../global/constants';
import { SKU } from '../../global/types';

const filterProductQuantityBySku = (sku: SKU, list: Record<SKU, number> | null) => {
  if (!list) {
    return null;
  }
  return Object.entries(list).reduce((acc: Record<SKU, number> | null, [prdSku, prdQuantity]) => {
    if (prdSku === sku) {
      return acc;
    }
    return {
      ...acc,
      [prdSku]: prdQuantity
    };
  }, null);
};

const mapQuantitiesToAddAllToCartInput = ({
  cartQuantities,
  recommendationsQuantities
}: {
  cartQuantities: Record<SKU, number>;
  recommendationsQuantities: Record<SKU, number>;
}) =>
  Object.entries(recommendationsQuantities).reduce((result, [sku, quantity]) => {
    if (!quantity) {
      return result;
    }
    const cartQuantity = cartQuantities[sku] ?? 0;
    return [
      ...result,
      {
        section: CART_SECTION_TYPES.product,
        quantity: quantity + cartQuantity,
        sku
      }
    ];
  }, []);

export { filterProductQuantityBySku, mapQuantitiesToAddAllToCartInput };
