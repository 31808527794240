import { AlertInline, AlertInlineColor } from '@carlsberggroup/malty.molecules.alert-inline';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { app } from '../../../global/constants';
import { ProductCardContext } from '../../../global/contexts/ProductCardContext';
import { useCart } from '../../../global/custom-hooks/useCart';
import { getCartProduct } from '../../../global/utils/getCartProduct';
import { setCartToggle } from '../../Cart/actions';
import { AddToCart } from '../../shared/AddToCart';
import { AddToCartProvider, withProductMaxQuantity } from '../../shared/AddToCart/AddToCartContext';
import { ProductMaxQuantityAlert } from './ProductMaxQuantityAlert';

interface Props {
  dataTestId?: string;
  hideAddToCartButton?: boolean;
  primary?: boolean;
}

const EnhancedAddToCartProvider = withProductMaxQuantity(AddToCartProvider);

const ProductCartQuantity: React.FC<Props> = ({
  dataTestId = 'product-cart-quantity',
  hideAddToCartButton,
  primary
}) => {
  const { t } = useTranslation();
  const { product, cartSection, searchBarList, setShowMaxQuantityAlertOnSearchBar } = useContext(ProductCardContext);
  const dispatch = useDispatch();
  const { selectedCart } = useCart();
  const [cartQuantity, setCartQuantity] = useState(0);
  const maxQuantity = product.maxQtyOrderable;

  const limitCartQuantity = maxQuantity ? maxQuantity - cartQuantity : app.cartLimit - cartQuantity;
  const isMaxQuantityTextVisible = maxQuantity && limitCartQuantity > 0;

  const handleCartToggle = useCallback(() => {
    dispatch(setCartToggle(true));
  }, []);

  useEffect(() => {
    if (selectedCart) {
      const cartProduct = getCartProduct(selectedCart, product?.sku, false);

      if (cartProduct) {
        const productQuantity = cartProduct.quantity ?? 0;
        setCartQuantity(productQuantity);
      } else {
        setCartQuantity(0);
      }
    }
  }, [selectedCart, product]);

  useEffect(() => {
    if (!hideAddToCartButton && isMaxQuantityTextVisible && searchBarList) {
      setShowMaxQuantityAlertOnSearchBar(true);
    }
  }, [hideAddToCartButton, isMaxQuantityTextVisible, searchBarList]);

  if (!hideAddToCartButton && limitCartQuantity <= 0 && !searchBarList) {
    return (
      <>
        <AlertInline
          firstActionText={t('entries.cart.editInCart', { defaultValue: 'Edit in Cart' })}
          firstAction={handleCartToggle}
          color={AlertInlineColor.NotificationLight}
          message={t('entries.cart.maxQuantityInfo', {
            defaultValue: 'You added the maximum order limit of this product'
          })}
          dataQaId="cart-limit-alert"
        />
        <ProductMaxQuantityAlert dataTestId={dataTestId} productMaxQtyOrderable={maxQuantity} />
      </>
    );
  }

  if (!selectedCart) {
    return null;
  }

  return (
    <>
      <EnhancedAddToCartProvider cartSection={cartSection} isCart={hideAddToCartButton} product={product}>
        <AddToCart dataTestId={dataTestId} primary={primary} />
      </EnhancedAddToCartProvider>
      {!hideAddToCartButton && isMaxQuantityTextVisible && !searchBarList ? (
        <ProductMaxQuantityAlert dataTestId={dataTestId} productMaxQtyOrderable={maxQuantity} />
      ) : null}
    </>
  );
};

export { ProductCartQuantity };
