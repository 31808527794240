import { actions } from './constants';

export const setCartToggle = (isCartOpen: boolean) => ({
  isCartOpen,
  type: actions.SET_CART_TOGGLE
});

export const setAlertAllProductsAddedToCart = (isShowAlertAllProductsAddedToCart: boolean) => ({
  isShowAlertAllProductsAddedToCart,
  type: actions.SET_ALERT_ALL_PRODUCTS_ADDED_TO_CART
});

export const setCartLoaderTrue = () => ({
  type: actions.SET_CART_LOADER_TRUE
});

export const setCartLoaderFalse = () => ({
  type: actions.SET_CART_LOADER_FALSE
});

export const setCartError = () => ({
  type: actions.SET_CART_ERROR_TRUE
});
