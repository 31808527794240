import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactNode;
  isLoggedIn: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, isLoggedIn, ...props }: PrivateRouteProps) => (
  <Route
    exact
    {...props}
    render={({ location }) =>
      !isLoggedIn ? (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      ) : (
        children
      )
    }
  />
);

export { PrivateRoute };
