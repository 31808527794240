import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import classnames from 'classnames';
import React from 'react';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import styles from '../../productcard.module.scss';

const Name = ({ dataTestId }: { dataTestId?: string }) => {
  const { product, isReward, navigateToProductPage } = React.useContext(ProductCardContext);
  return (
    <div
      className={classnames(styles['product-name'], { [styles.isReward]: isReward })}
      onClick={navigateToProductPage}
    >
      <Text dataQaId={`${dataTestId}-product-name`} textStyle={TextStyle.MediumSmallBold}>
        {product.name}
      </Text>
    </div>
  );
};

export { Name };
