import { isAmplifyEnv, isTestEnv } from '../global/utils/environment';
import { CSPlusHostConfig } from './configTypes';

export const getHostSettings = (configs: CSPlusHostConfig[]) => {
  try {
    if (isTestEnv) {
      return configs[0];
    }

    const config = configs?.find((x: CSPlusHostConfig) => x.host === window?.location?.hostname);

    // this is hardcoded to the AWS amplify preview domain

    if (isAmplifyEnv) {
      const configForMarket = configs?.find((x) => x.country.toLowerCase() === process.env.MARKET);
      return configForMarket;
    }

    return config;
  } catch (e) {
    console.error('Error getting host settings', e);
    return null;
  }
};
