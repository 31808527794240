import { datadogRum } from '@datadog/browser-rum';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconLoaderBig } from '../../../assets/icon-loader-big.svg';
import { Button } from '../../../UI-elements/Button';
import { FONT_SIZE, Text } from '../../../UI-elements/Text';
import styles from './loading.module.scss';

export interface Props {
  fullScreen?: boolean;
  hideButton?: boolean;
  hideText?: boolean;
  small?: boolean;
  dataTestId?: string;
  fixed?: boolean;
}

const Loading: React.FC<Props> = ({
  fullScreen,
  hideButton,
  hideText,
  small,
  fixed,
  dataTestId = 'loading-component'
}: Props) => {
  const { t } = useTranslation();

  const [text, setText] = useState(
    !hideText && !small ? t('entries.general.loading', { defaultValue: 'Loading' }) : ''
  );

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (!hideButton) {
        setShowButton(true);
        setText(t('entries.general.slowConnection', { defaultValue: 'Slow connection?' }));
        datadogRum.addError(new Error('Bad connection showed after loading for 10000ms'));
      }
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={cn(styles.loading, { [styles.small]: small }, { [styles.full]: fullScreen, [styles.fixed]: fixed })}
      data-testid={dataTestId}
    >
      <IconLoaderBig />
      <div className={styles['loading-text']}>
        {text && <Text size={small ? FONT_SIZE.TINY : FONT_SIZE.SMALL}>{text}</Text>}
        {showButton && (
          <Button
            text={t('entries.general.tryAgain', { defaultValue: 'Try again' })}
            action={() => window.location.reload()}
            size={small ? 'tiny' : 'small'}
          />
        )}
      </div>
    </div>
  );
};

export { Loading };
