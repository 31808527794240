import { Button, ButtonSize, ButtonStyle, ButtonType } from '@carlsberggroup/malty.atoms.button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RECOMMENDATIONS_CODES } from '../../../../global/constants';
import { ConfigContext } from '../../../../global/contexts/ConfigContext';
import { useAddBulkProducts } from '../../../../global/custom-hooks/useAddBulkProducts';
import { useCart } from '../../../../global/custom-hooks/useCart';
import { useDistributors } from '../../../../global/custom-hooks/useDistributors';
import { useRecommended } from '../../../../global/custom-hooks/useRecommended';
import { ItemListName } from '../../../../global/google-analytics/tracking';
import { useRemoveProductFromCartMutation } from '../../../../services/cart/cart';
import { EmptyProductList } from '../../../shared/EmptyProductList/EmptyProductList';
import { ProductListModal } from '../../../shared/ProductListDrawer';
import { alternativeProductsSelector } from '../../slice/alternativeProducts';

type AlternativesContentProps = {
  dataTestId?: string;
  onClose: () => void;
};

const AlternativesContent = ({ dataTestId, onClose }: AlternativesContentProps) => {
  const { t } = useTranslation();
  const { featureFlags } = React.useContext(ConfigContext);
  const { selectedCart } = useCart();
  const { productSku } = useSelector(alternativeProductsSelector);
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const [removeProductFromCart, { isLoading: isLoadingRemoveFromCart }] = useRemoveProductFromCartMutation();
  const { data: recommendationProducts, isLoading, isFetching, isUninitialized } = useRecommended({
    limit: 8,
    recommendationsCode: RECOMMENDATIONS_CODES.substituting,
    sku: productSku ?? '',
    skip: !selectedCart || !productSku || !featureFlags?.showAlternativeProducts
  });
  const {
    recommendationsQuantities,
    cartQuantities,
    onQuantityChange,
    isLoadingAddToCart,
    onAddBulkClick
  } = useAddBulkProducts({
    productSku
  });

  const handleUpdateCartWithAlternatives = async () => {
    await onAddBulkClick()
      .then(async () => {
        await removeProductFromCart({
          cartId: selectedCart.eid,
          distributorId: selectedDistributor.eid,
          outletId: selectedOutlet.eid,
          sku: productSku,
          salesOrg: selectedOutlet.salesOrg
        });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ProductListModal
      dataTestId={dataTestId}
      listName={ItemListName.AlternativesList}
      innerHeader={t('entries.alternatives.subtitle', {
        defaultValue: 'Choose an alternative to replace the unavailable product in your cart'
      })}
      isLoading={isLoading || isFetching || isUninitialized}
      isActionTriggered={isLoadingAddToCart || isLoadingRemoveFromCart}
      emptyPlaceholder={
        <EmptyProductList
          dataTestId="alternative-product-empty"
          text={t('entries.cart.emptyRecommendations', {
            defaultValue: 'Sorry, but we were not able to find any alternatives for this product'
          })}
        />
      }
      products={recommendationProducts?.products}
      productQuantities={recommendationsQuantities}
      cartQuantities={cartQuantities}
      onQuantityChange={onQuantityChange}
      actionButtons={
        <>
          <Button
            onClick={onClose}
            size={ButtonSize.Large}
            style={ButtonStyle.Secondary}
            type={ButtonType.Default}
            fullWidth
          >
            {t('entries.actions.cancel', { defaultValue: 'Cancel' })}
          </Button>
          <Button
            disabled={!recommendationsQuantities}
            onClick={handleUpdateCartWithAlternatives}
            size={ButtonSize.Large}
            style={ButtonStyle.Primary}
            type={ButtonType.Submit}
            fullWidth
          >
            {t('entries.products.addToCartButton', { defaultValue: 'Add to cart' })}
          </Button>
        </>
      }
    />
  );
};

export { AlternativesContent };
