import classnames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CART_SECTION_TYPES } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { PriceI } from '../../../global/interfaces/products';
import { formatPrice } from '../../../global/utils/formatPrice';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../../UI-elements/Text';
import styles from './price.module.scss';
import { PriceRenderer } from './PriceRenderer';

interface Props {
  align?: TEXT_ALIGN;
  black?: boolean;
  price: PriceI;
  removeLabels?: boolean;
  size: FONT_SIZE;
  total?: boolean;
  cartSection: CART_SECTION_TYPES;
  dataTestId?: string;
}

const Price: React.FC<Props> = ({
  align,
  black,
  removeLabels,
  total,
  price,
  size,
  cartSection,
  dataTestId = ''
}: Props) => {
  const { t } = useTranslation();
  const { currency } = useContext(ConfigContext);
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const isReturnable = cartSection === CART_SECTION_TYPES.returnable;
  const isReward = cartSection === CART_SECTION_TYPES.reward;
  const isNotDeliverableReward = cartSection === CART_SECTION_TYPES.notDeliverableReward;

  const priceSize = useMemo(() => {
    if (price?.discount) {
      return isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL;
    }
    return size;
  }, [isMobile, size, price]);

  const priceText = useMemo(() => {
    if (isReward || isNotDeliverableReward) {
      return t('entries.price.reward', { defaultValue: 'Reward' });
    }

    return t('entries.price.free', { defaultValue: 'Free' });
  }, [t, isReward, isNotDeliverableReward]);

  if (!price) {
    return null;
  }

  const priceToUse = total ? price && price.total : price && price.base;
  const displayPrice = formatPrice(priceToUse, currency.code, currency.symbol);
  const displayDiscount = price.discount && formatPrice(price.discount, currency.code, currency.symbol);
  const displayStandardPrice = formatPrice(price.standard, currency.code, currency.symbol);

  if ((!price.total && !price.base && !isReturnable) || isReward || isNotDeliverableReward) {
    return (
      <div
        className={classnames(styles.free, {
          [styles['flex-right']]: align && align !== TEXT_ALIGN.LEFT,
          [styles['flex-left']]: !align || align === TEXT_ALIGN.LEFT
        })}
        data-testid={isReward || isNotDeliverableReward ? 'price-reward' : 'price-free'}
      >
        <Text
          align={align || TEXT_ALIGN.LEFT}
          weight={FONT_WEIGHT.SEMI_BOLD}
          color={black ? TEXT_COLOR.DEFAULT : TEXT_COLOR.PROMOTIONAL}
          size={size}
        >
          {priceText}
        </Text>
      </div>
    );
  }

  if (isReturnable) {
    return (
      <PriceRenderer align={align} size={size} color={TEXT_COLOR.EMPTIES} label="≈" formattedPrice={displayPrice} />
    );
  }

  if (removeLabels) {
    return (
      <Text dataTestId={dataTestId} align={align || TEXT_ALIGN.LEFT} size={size || FONT_SIZE.SMALL}>
        {displayPrice}
      </Text>
    );
  }

  return (
    <>
      {price.standard && (
        <Text
          dataTestId={`${dataTestId}-price`}
          color={TEXT_COLOR.DISABLED}
          strikethrough
          size={isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
        >
          {`${t('entries.price.standardPrice', { defaultValue: 'Standard Price' })} ${displayStandardPrice}`}
        </Text>
      )}

      <PriceRenderer
        dataTestId={`${dataTestId}-price`}
        align={align}
        strikethrough={!!price.discount}
        color={price.discount ? TEXT_COLOR.DISABLED : TEXT_COLOR.DEFAULT}
        size={priceSize}
        formattedPrice={displayPrice}
      />

      {price.discount && (
        <PriceRenderer
          dataTestId={`${dataTestId}-price`}
          align={align}
          weight={FONT_WEIGHT.SEMI_BOLD}
          size={size}
          color={TEXT_COLOR.PROMOTIONAL}
          label={t('entries.price.discountPrice', { defaultValue: 'Discount Price' })}
          formattedPrice={displayDiscount}
        />
      )}

      {price.unit > 0 && (
        <div className={styles.unit}>
          <PriceRenderer
            dataTestId={`${dataTestId}-price-per-unit`}
            size={FONT_SIZE.TINY}
            weight={FONT_WEIGHT.BOLD}
            formattedPrice={formatPrice(price.unit, currency.code, currency.symbol)}
          />
          <Text size={FONT_SIZE.TINY} weight={FONT_WEIGHT.BOLD}>
            {t('entries.price.unit', { defaultValue: 'per unit' })}
          </Text>
        </div>
      )}
    </>
  );
};

export { Price };
