import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import { i18n } from '../../i18n';
import { cartApi } from '../../services/cart/cart';
import { favouritesApi } from '../../services/favourites/favourites';
import { headlessApi } from '../../services/headless/headlessApi';
import { ordersApi } from '../../services/orders/orders';
import { productsApi } from '../../services/products/products';
import { promotionsApi } from '../../services/promotions';
import { PageViewTracking } from '../google-analytics';
import { MenuType } from '../google-analytics/tracking';
import { Language } from '../interfaces/language';
import { useCurrentLanguage } from './useCurrentLanguage';
import { useGetBaseGAEventData } from './useGetBaseGAEventData';

type UseLanguageSelectorProps = {
  handleToggleSelect: () => void | undefined;
  onMenuClick?: ((type: MenuType, label: string) => void) | (() => void) | undefined;
};

export const useLanguageSelector = ({ handleToggleSelect, onMenuClick }: UseLanguageSelectorProps) => {
  const dispatch = useDispatch();
  const currentLanguage = useCurrentLanguage();
  const gaEventData = useGetBaseGAEventData();

  const handleSelectLanguage = (language: Language) => {
    if (language?.isoCode === currentLanguage?.isoCode) {
      handleToggleSelect?.();
      onMenuClick?.(MenuType.Icon, language.name);
      return;
    }

    i18n.changeLanguage(language.isoCode.replace('_', '-'));
    document.documentElement.lang = language?.isoCode;

    dispatch(productsApi.util.invalidateTags(['PRODUCT', 'PRODUCTS']));
    dispatch(favouritesApi.util.invalidateTags(['FAVOURITE_LIST_PRODUCTS']));
    dispatch(cartApi.util.invalidateTags(['CART']));
    dispatch(ordersApi.util.invalidateTags(['ORDERS']));
    dispatch(headlessApi.util.invalidateTags(['HEADLESS_PAGE']));
    dispatch(promotionsApi.util.invalidateTags(['PROMOTIONS', 'PROMOTION_DETAILS']));
    handleToggleSelect?.();
    TagManager.dataLayer(PageViewTracking({ ...gaEventData, siteLanguage: language.isoCode }));
  };

  return { handleSelectLanguage, currentLanguage };
};
