import cn from 'classnames';
import { nanoid } from 'nanoid';
import React from 'react';
import { FLAG_ICON_HEIGHT, FLAG_ICON_WIDTH } from '../../../global/constants';
import { useCurrentLanguage } from '../../../global/custom-hooks/useCurrentLanguage';
import { useLanguageSelector } from '../../../global/custom-hooks/useLanguageSelector';
import { useLocales } from '../../../global/custom-hooks/useLocales';
import { MenuType } from '../../../global/google-analytics/tracking';
import { Language } from '../../../global/interfaces/language';
import { Dropdown } from '../../../UI-elements/DropdownCard';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../../UI-elements/Text';
import { FlagIcon } from './FlagIcon';
import styles from './languages.module.scss';

interface Props {
  hasFlag: boolean;
  inActivationFlow?: boolean;
  onMenuClick?: (type: MenuType, label: string) => void;
  isSelectLanguageOpen?: boolean;
  toggleLanguage?: (value: boolean) => void;
}

const LanguagesDropdown: React.FC<Props> = ({
  hasFlag = true,
  inActivationFlow,
  onMenuClick,
  isSelectLanguageOpen,
  toggleLanguage
}: Props) => {
  const { locales } = useLocales();
  const currentLanguage = useCurrentLanguage();
  const handleToggleSelect = () => {
    toggleLanguage(!isSelectLanguageOpen);
  };

  const { handleSelectLanguage } = useLanguageSelector({ handleToggleSelect, onMenuClick });

  const renderLanguages = () =>
    locales?.map((language: Language) => (
      <div
        key={nanoid()}
        className={cn(styles.item, { [styles.selected]: language.isoCode === currentLanguage?.isoCode })}
        onClick={() => handleSelectLanguage(language)}
        data-testid="language-selector"
      >
        {hasFlag && <FlagIcon width={FLAG_ICON_WIDTH} height={FLAG_ICON_HEIGHT} name={language.flag} />}
        <div>
          <Text
            dataTestId={`language-language-name-${language.name}`}
            size={FONT_SIZE.TINY}
            weight={FONT_WEIGHT.REGULAR}
          >
            {language.name}
          </Text>
        </div>
      </div>
    ));

  if (!isSelectLanguageOpen) {
    return null;
  }

  return (
    <div className={styles.languages}>
      <Dropdown className={styles.dropdownLanguagesContainer} inActivationFlow={inActivationFlow}>
        {renderLanguages()}
      </Dropdown>
    </div>
  );
};

export { LanguagesDropdown };
