import { CSPlusFeatureFlags } from '../../../config/configTypes';
import { StockStatus } from '../../../global/interfaces/products';

export const isProductStockAvailable = (stockStatus: StockStatus, featureFlags: CSPlusFeatureFlags) => {
  if (!featureFlags) {
    return false;
  }

  if (stockStatus === StockStatus.NO_STOCK_INFO && !featureFlags.orderingNoStockInformation) {
    return true;
  }

  if (stockStatus === StockStatus.OUT_OF_STOCK && !featureFlags.orderingOutOfStock) {
    return true;
  }

  return false;
};

export const isQuantityOutOfBounds = (
  isReward: boolean,
  quantity: number,
  maxQuantity: number,
  cartProductCount: number
) => {
  if (isReward) {
    return quantity > maxQuantity;
  }
  return quantity + cartProductCount > maxQuantity;
};
