import { Input, InputSize, InputType } from '@carlsberggroup/malty.atoms.input';
import cn from 'classnames';
import React from 'react';
import { app, COUNTER_MAX_LENGTH, COUNTER_MIN_LENGTH } from '../../global/constants';
import styles from './counter.module.scss';

export interface Props {
  decrease: () => void;
  increase: () => void;
  isInputDisabled?: boolean;
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: string | number;
  dataTestId?: string;
  minValue?: number;
  maxValue?: number;
  className?: string;
  disableQuantityInput?: boolean;
}

const Counter: React.FC<Props> = ({
  decrease,
  increase,
  isInputDisabled = false,
  onChange,
  onKeyDown,
  onFocus = () => null,
  onBlur = () => null,
  value,
  dataTestId = '',
  minValue = 1,
  maxValue = app.cartLimit,
  className = '',
  disableQuantityInput = false
}: Props) => {
  const disableMinus = value <= minValue;
  const disablePlus = value >= maxValue;
  return (
    <div className={cn(styles.counter, className)}>
      <Input
        aria-label="quantity"
        dataTestId={`${dataTestId}-quantity`}
        maxLength={COUNTER_MAX_LENGTH}
        minLength={COUNTER_MIN_LENGTH}
        pattern="\d*"
        value={value?.toString() || '0'}
        type={InputType.Quantity}
        size={InputSize.Medium}
        onValueChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isInputDisabled}
        disableLeftButton={disableMinus}
        disableRightButton={disablePlus}
        onClickLeftInputButton={decrease}
        onClickRightInputButton={increase}
        disableQuantityInput={disableQuantityInput}
      />
    </div>
  );
};

export { Counter };
