import { AnnouncementContent } from '../../services/announcements/types';

export const getLanguageMessage = (
  messages: {
    title: string;
    body: string;
    locale: string;
  }[],
  currentLanguage: string,
  defaultLanguage: string
) => {
  const languageMessage = messages.find((message) => message.locale.replace('_', '-') === currentLanguage);
  if (!languageMessage) {
    // fallback to default language
    return messages.find((message) => message.locale.replace('_', '-') === defaultLanguage);
  }
  return languageMessage;
};

export const getSeverityByEid = (announcementContentArray: AnnouncementContent[], eid: string) => {
  const announcementContent = announcementContentArray.find((announcement) => announcement.eid === eid);
  return announcementContent?.details?.severity;
};
