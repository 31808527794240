import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { useContext, useEffect, useState } from 'react';
import { app, CART_SECTION_TYPES } from '../../../../../global/constants';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import { useCart } from '../../../../../global/custom-hooks/useCart';
import { getCartProduct } from '../../../../../global/utils/getCartProduct';
import styles from './productCounterPill.module.scss';
import { ProductImageBoxPillCounterProps } from './ProductCounterPill.types';

const ProductCounterPill = ({ dataTestId = 'product-counter-pill', setTranslate }: ProductImageBoxPillCounterProps) => {
  const { product, cartSection } = useContext(ProductCardContext);
  const { selectedCart } = useCart();
  const [totalProducts, setTotalProducts] = useState(0);
  const isReward = cartSection === CART_SECTION_TYPES.reward;

  useEffect(() => {
    if (!selectedCart) {
      return;
    }

    const cartProduct = getCartProduct(selectedCart, product.sku, isReward);

    if (!cartProduct) {
      setTotalProducts(0);

      return;
    }

    const productQuantity: number = cartProduct.quantity;

    setTotalProducts(productQuantity);
  }, [selectedCart, product.sku]);

  const productQuantityInCart =
    totalProducts > app.maxProductQuantityInCartShownOnPill
      ? `${app.maxProductQuantityInCartShownOnPill}+`
      : totalProducts.toString();

  useEffect(() => {
    if (totalProducts) {
      setTranslate(true);
    } else {
      setTranslate(false);
    }
  }, [totalProducts]);

  if (totalProducts === 0 || !selectedCart) {
    return null;
  }

  return (
    <div className={styles.wrapper} data-testid={dataTestId}>
      <Text
        className={styles.tinyFont}
        align={TextAlign.Center}
        color={TextColor.DigitalBlack}
        textStyle={TextStyle.MicroBold}
      >
        {productQuantityInCart}
      </Text>
    </div>
  );
};

export { ProductCounterPill };
