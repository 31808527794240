import React, { useContext, useEffect, useRef, useState } from 'react';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useClearkIoData } from '../../../global/custom-hooks/useClerkIoData';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { DistributorI } from '../../../global/interfaces/distributor';
import { Outlet } from '../../../global/interfaces/outlet';
import {
  useLazyGetDistributorsQuery,
  useSelectDistributorMutation,
  useSelectOutletMutation
} from '../../../services/outlets/outlets';
import { useLogEmailMutation } from '../../../services/tracking/tracking';
import { TrackChangesModal } from '../../TrackedChanges';
import { Modal } from '../Modal/ModalComponent';
import { ConfirmButton } from './ConfirmButton';
import { DistributorSteps } from './Distributor';
import styles from './distributor.module.scss';
import { DistributorList } from './DistributorList';
import { DistributorModalFooter } from './DistributorModalFooter';
import { DistributorModalHeader } from './DistributorModalHeader';
import { checkUserHasSetDistributor, getDefaultStep, saveDistributorModalVisibilityToLocalStorage } from './helpers';
import { OutletList } from './OutletList';
import { SelectionInfo } from './SelectionInfo';
import { SelectionList } from './SelectionList';

interface Props {
  initialOutlet: Outlet;
  initialDistributor: DistributorI;
  initialStep: DistributorSteps;
  destroyDistributorModal: () => void;
}

export const DistributorModal: React.FC<Props> = ({
  initialOutlet,
  initialDistributor,
  initialStep,
  destroyDistributorModal
}) => {
  // init local state for temp selected Outlet and Distributor
  const [uiSelectedOutlet, setUIOutlet] = useState<Outlet>(initialOutlet);
  const [uiSelectedDistributor, setUIDistributor] = useState<DistributorI>(initialDistributor);

  const {
    outlets,
    distributors,
    selectedOutlet,
    selectedDistributor,
    userHasSingleOutlet,
    userHasManyOutlets,
    outletHasSingleDistributor,
    outletHasManyDistributors
  } = useDistributors(uiSelectedOutlet);

  const [getDistributors] = useLazyGetDistributorsQuery();

  useEffect(() => {
    const getUIDistributor = () => {
      if (uiSelectedOutlet?.eid === initialOutlet?.eid) {
        return initialDistributor;
      }
      if (outletHasSingleDistributor) {
        return distributors?.[0];
      }
      return undefined;
    };
    setUIDistributor(getUIDistributor());
  }, [distributors]);

  const [distributorStep, setDistributorStep] = useState<DistributorSteps>(initialStep);
  const isOutletStep = distributorStep === DistributorSteps.OUTLET_SELECT;
  const isDistributorStep = distributorStep === DistributorSteps.DISTRIBUTOR_SELECT;

  const isTrackedChangesStep = distributorStep === DistributorSteps.CONFIRM_TRACKED_CHANGES;

  const { isXSmall, isSmall } = useScreenSize();
  const isModalFullWidth = isXSmall || isSmall;
  const [selectOutlet] = useSelectOutletMutation();
  const [selectDistributor] = useSelectDistributorMutation();
  const { clerkIoUrl, clerkIoApiKey } = useClearkIoData();
  const [logEmail] = useLogEmailMutation();
  const [isDistributorModalVisible, setDistributorModalVisibility] = useState(true);
  const [isConfirming, setIsConfirming] = useState(false);
  const showCloseButton = checkUserHasSetDistributor();
  const { featureFlags } = useContext(ConfigContext);
  const hideDistributor = featureFlags?.isDirectMarket;

  const selectionListWrapperRef = useRef<HTMLDivElement>(null);
  const scrollSelectionListToTop = () => {
    if (selectionListWrapperRef.current) {
      selectionListWrapperRef.current.scrollTop = 0;
    }
  };

  const confirmOutletDistributor = ({ outlet, distributor }: { outlet: Outlet; distributor: DistributorI }) => {
    if (!outlet || !distributor) {
      return;
    }
    if (outlet.eid !== selectedOutlet?.eid) {
      selectOutlet({
        salesOrg: outlet.salesOrg,
        outletId: outlet.eid
      });
    }
    if (distributor.eid !== selectedDistributor?.eid) {
      selectDistributor({
        salesOrg: outlet.salesOrg,
        outletId: outlet.eid,
        distributorId: distributor.eid
      });

      logEmail({ clerkIoApiKey, clerkIoUrl, outletId: outlet.eid });
    }
    saveDistributorModalVisibilityToLocalStorage(true);
    destroyDistributorModal();
  };

  const onOutletSelect = (outlet: Outlet) => {
    setUIOutlet(outlet);
    getDistributors(
      {
        salesOrg: outlet.salesOrg,
        outletId: outlet.eid
      },
      true
    )
      .unwrap()
      .then((distributorsArray) => {
        if (distributorsArray.length > 1) {
          setDistributorStep(DistributorSteps.DISTRIBUTOR_SELECT);
        }
      });
  };

  const onDistributorSelect = (distributor: DistributorI) => {
    setUIDistributor(distributor);
  };

  const onTrackChangesCancel = () => {
    setDistributorStep(getDefaultStep(userHasSingleOutlet));
    setDistributorModalVisibility(true);
  };

  if (!outlets) {
    return null;
  }

  return (
    <>
      <Modal
        dataTestId="distributors-modal"
        active={isDistributorModalVisible}
        closeModal={destroyDistributorModal}
        hideOverlay={isModalFullWidth}
        showCloseButton={showCloseButton}
        showLogo={isModalFullWidth}
        fixedClose={isModalFullWidth}
        theme={{
          content: styles.modalContainer,
          children: styles.childrenFixedFullHeight
        }}
      >
        <DistributorModalHeader step={distributorStep} showSteps={userHasManyOutlets && outletHasManyDistributors} />
        <div className={styles.selectionListWrapper} ref={selectionListWrapperRef}>
          <SelectionList uiSelectedOutlet={uiSelectedOutlet} isConfirming={isConfirming}>
            {isOutletStep && (
              <OutletList
                selectedOutletId={uiSelectedOutlet?.eid}
                onOutletSelect={onOutletSelect}
                scrollSelectionListToTop={scrollSelectionListToTop}
              />
            )}
            {isDistributorStep && (
              <DistributorList
                selectedDistributorId={uiSelectedDistributor?.eid}
                distributors={distributors}
                onDistributorSelect={onDistributorSelect}
              />
            )}
          </SelectionList>
          <SelectionInfo
            outlet={uiSelectedOutlet}
            distributor={uiSelectedDistributor}
            hideDistributorInfo={hideDistributor}
          />
          <DistributorModalFooter distributorStep={distributorStep} setDistributorStep={setDistributorStep}>
            <ConfirmButton
              uiSelectedDistributor={uiSelectedDistributor}
              setDistributorStep={setDistributorStep}
              isConfirming={isConfirming}
              setIsConfirming={setIsConfirming}
            />
          </DistributorModalFooter>
        </div>
      </Modal>
      {isTrackedChangesStep && (
        <TrackChangesModal
          uiSelectedOutlet={uiSelectedOutlet}
          uiSelectedDistributor={uiSelectedDistributor}
          confirmOutletDistributor={confirmOutletDistributor}
          destroyDistributorModal={destroyDistributorModal}
          setDistributorModalVisibility={setDistributorModalVisibility}
          setIsConfirming={setIsConfirming}
          onTrackChangesCancel={onTrackChangesCancel}
        />
      )}
    </>
  );
};
