import cn from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useProductsPerSize } from '../../global/custom-hooks/useProductsPerSize';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { useTrackingProductsSlider } from '../../global/custom-hooks/useTrackingProductsSlider';
import { HeadlessImageI } from '../../global/interfaces/headless';
import { ProductWithVariants } from '../../global/interfaces/products';
import { Carousel } from '../../UI-elements/Carousel';
import { CloudinaryImage } from '../../UI-elements/CloudinaryImage';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../UI-elements/Text';
import styles from './productSlider.module.scss';

interface Props {
  title: string;
  children: ReactElement[];
  products: ProductWithVariants[];
  linkBtn?: { label: string; url: string; onClick?: () => void };
  backgroundImage?: HeadlessImageI;
  dataTestId?: string;
}

const ProductSlider: FC<Props> = ({
  children,
  title,
  products,
  linkBtn,
  backgroundImage,
  dataTestId = 'product-slider'
}) => {
  const { isXSmall, isSmall } = useScreenSize();
  const titleFontSize = isXSmall || isSmall ? FONT_SIZE.MEDIUM_LARGE : FONT_SIZE.LARGE;
  const slidesPerView = useProductsPerSize();
  const { trackingProducts } = useTrackingProductsSlider({
    products,
    slidesPerView
  });
  return (
    <div
      className={cn(styles.productSliderWrapper, { [styles.hasNoBackgroundImage]: backgroundImage === null })}
      data-testid={dataTestId}
    >
      <div className={styles.wideImageWrapper}>
        <CloudinaryImage alt="headless-image-product-slider" src={backgroundImage?.url} />
      </div>
      <div className={styles.titleWrapper}>
        <Text
          weight={FONT_WEIGHT.BOLD}
          size={titleFontSize}
          className={styles.title}
          dataTestId={`${dataTestId}-title`}
        >
          {title}
        </Text>
        {linkBtn && (
          <Link
            className={styles.linkBtn}
            onClick={() => linkBtn?.onClick()}
            to={linkBtn.url}
            data-testid={`${dataTestId}-link`}
          >
            {linkBtn.label}
          </Link>
        )}
      </div>
      <Carousel
        slidesPerView={slidesPerView}
        theme={{
          arrow: styles.arrow
        }}
        dataTestId={`${dataTestId}-carousel`}
        trackingItems={trackingProducts}
      >
        {children}
      </Carousel>
    </div>
  );
};

export { ProductSlider };
