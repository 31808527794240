import { Loading, LoadingSize } from '@carlsberggroup/malty.molecules.loading';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsCartLoading } from '../../global/selectors/cart/selectors';
import styles from './insertToCartLoading.module.scss';

const InsertCartLoading = () => {
  const cartLoading = useSelector(getIsCartLoading);

  return (
    <>
      {cartLoading && (
        <div className={styles.content} data-testid="insert-cart--content">
          <div className={styles.blackout} data-testid="insert-cart--blackout" />
          <Loading size={LoadingSize.Medium} dataQaId="insert-cart--loading" />
        </div>
      )}
    </>
  );
};

export { InsertCartLoading };
