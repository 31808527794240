import { useState } from 'react';

export type UseCounterProps = {
  initialQuantity: number;
};

export const useCounter = ({ initialQuantity = 0 }: UseCounterProps) => {
  const [quantity, setQuantity] = useState<number>(initialQuantity);
  return { quantity, setQuantity };
};
