import { addListener, PayloadAction, removeListener } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  INotification,
  notificationsSelector,
  removeNotification,
  setNotification
} from '../../components/Notifications/slice/notifications';
import { TooltipProductData } from '../../components/shared/ProductsTooltip/ProductsTooltipItem';
import { ADDING_PRODUCTS_NOTIFICATION, ADD_TO_CART_NOTIFICATION } from '../constants';
import { IAddToCartNotification } from '../types';
import { mergeNotificationsAndCartData } from '../utils/mergeNotificationsAndCartData';
import { useAutoHideTimer } from './useAutoHideTimer';
import { useCart } from './useCart';

export const useAddToCartNotification = () => {
  const dispatch = useDispatch();
  const { selectedCart } = useCart();
  const [isToggleTooltip, setIsToggleTooltip] = useState(false);
  const notifications = useSelector(notificationsSelector);

  const addingProductsNotification = notifications.find(
    (notification) => notification.id === ADDING_PRODUCTS_NOTIFICATION
  ) as INotification;

  const cartNotifications = notifications.filter((notification) =>
    notification.id.startsWith(ADD_TO_CART_NOTIFICATION)
  ) as IAddToCartNotification[];

  const tooltipProductsData = useMemo(() => mergeNotificationsAndCartData(selectedCart, cartNotifications), [
    cartNotifications,
    selectedCart
  ]) as TooltipProductData[];

  const startAutoHideTimer = useAutoHideTimer(() => {
    setIsToggleTooltip(false);
  }, 3000);

  const handleProductAddedToCart = () => {
    setIsToggleTooltip(true);
    startAutoHideTimer();
  };

  const handleAddingProductsToCart = () => {
    setIsToggleTooltip(true);
    startAutoHideTimer();
  };

  useEffect(() => {
    const onAddToCartNotification = (action: PayloadAction<INotification>) => {
      if (action.payload.id.startsWith(ADD_TO_CART_NOTIFICATION)) {
        handleProductAddedToCart();
      }
    };

    dispatch(
      addListener({
        actionCreator: setNotification,
        effect: onAddToCartNotification
      })
    );

    return () => {
      dispatch(removeListener({ actionCreator: setNotification, effect: onAddToCartNotification }));
    };
  }, []);

  useEffect(() => {
    const onAddingProductsNotification = (action: PayloadAction<INotification>) => {
      if (action.payload.id === ADDING_PRODUCTS_NOTIFICATION) {
        handleAddingProductsToCart();
      }
    };
    dispatch(
      addListener({
        actionCreator: setNotification,
        effect: onAddingProductsNotification
      })
    );

    return () => {
      dispatch(removeListener({ actionCreator: setNotification, effect: onAddingProductsNotification }));
    };
  }, []);

  useEffect(() => {
    if (!isToggleTooltip) {
      cartNotifications.forEach((notification) => {
        dispatch(removeNotification(notification.id));
      });
    }
  }, [isToggleTooltip]);

  return {
    isToggleTooltip,
    setIsToggleTooltip,
    addingProductsNotification,
    tooltipProductsData
  };
};
