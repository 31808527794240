import React from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { App } from './app';
import { isAmplifyEnv, isDevelopment } from './global/utils/environment';
import { startI18n } from './i18n';
import * as serviceWorker from './serviceWorker';
import './styles/global.scss';

if (isDevelopment) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { worker } = require('./mockServer/browser'); //  we only want to import this locally
  worker.start();
}

if (!isDevelopment && !isAmplifyEnv) {
  serviceWorker.register({
    onUpdate() {
      console.info('SW Cache Updated.');
      setTimeout(() => document.location.reload(), 1000);
    }
  });
}

const rootElement = document.getElementById('react-app');

const startApp = () =>
  startI18n().then(() => {
    render(
      <>
        <Helmet>
          <meta
            name="description"
            content="Now you can order all available products from Lao Brewery online by using your smartphone or computer within minutes."
          />
        </Helmet>
        <App />
      </>,
      rootElement
    );
  });

startApp();
