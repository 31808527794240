import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { VouchersListPayload } from '../../global/interfaces/vouchers';
import { baseApiQuery } from '../baseApiQuery';
import { VouchersListParams } from './types';

export const vouchersApi = createApi({
  reducerPath: 'vouchersApi',
  baseQuery: baseApiQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getVouchersList: builder.query<VouchersListPayload, VouchersListParams>({
      query: ({ distributorId, outletId, salesOrg }: VouchersListParams) => ({
        url: makeApiUrl(apiEndpoints.VOUCHERS_LIST, { salesOrg, outletId }),
        params: {
          distributorId
        }
      })
    })
  })
});

export const { useGetVouchersListQuery } = vouchersApi;
