export const getTodayInIsoFormat = (): string => {
  const today: Date = new Date();

  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  const day: string = dd < 10 ? `0${dd}` : `${dd}`;
  const month: string = mm < 10 ? `0${mm}` : `${mm}`;

  return `${yyyy}-${month}-${day}`;
};
