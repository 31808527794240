import React from 'react';
import { UmbracoErrorPage } from '../../services/headless/types';
import { ErrorPageComponent } from './ErrorPageComponent';
import { NoDistributorError } from './NoDistributorErrorComponent';
import { NoOutletsError } from './NoOutletsErrorComponent';

const ErrorHandlingComponent = ({
  errorContent,
  isSingleInactiveDistributor,
  distributorsError,
  isNoOutlets
}: {
  errorContent: UmbracoErrorPage;
  isSingleInactiveDistributor: boolean;
  distributorsError: unknown;
  isNoOutlets: boolean;
}) => {
  if (isNoOutlets) {
    return <NoOutletsError content={errorContent} />;
  }
  if (isSingleInactiveDistributor) {
    return <NoDistributorError content={errorContent} />;
  }
  return <ErrorPageComponent content={errorContent} distributorsError={distributorsError} />;
};

export { ErrorHandlingComponent };
