import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Link } from '@carlsberggroup/malty.atoms.link';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { DistributorSteps } from './Distributor';
import styles from './distributor.module.scss';

interface Props {
  children: React.ReactNode;
  distributorStep: DistributorSteps;
  setDistributorStep: (step: DistributorSteps) => void;
}
export const DistributorModalFooter: React.FC<Props> = ({ distributorStep, setDistributorStep, children }) => {
  const { userHasSingleOutlet } = useDistributors();
  const { t } = useTranslation();
  const showBackButton = !userHasSingleOutlet && distributorStep === DistributorSteps.DISTRIBUTOR_SELECT;
  const goBack = () => setDistributorStep(DistributorSteps.OUTLET_SELECT);

  return (
    <div
      className={cn(styles.footer, {
        [styles.singleOutlet]: userHasSingleOutlet
      })}
    >
      {showBackButton && (
        <div className={styles.backWrapper}>
          <div className={styles.backButton}>
            <Link onClick={goBack} dataTestId="distributors-go-back">
              <div className={styles.backTextWrapper}>
                <Icon name={IconName.ChevronLeft} color={IconColor.DigitalBlack} size={IconSize.Medium} />
                <Text textStyle={TextStyle.MediumSmallBold}>
                  {t('entries.products.back', { defaultValue: 'Back' })}
                </Text>
              </div>
            </Link>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
