import { Text, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import styles from '../../productcard.module.scss';

const SKUDetails = ({ dataTestId = '' }: { dataTestId?: string }) => {
  const { product } = React.useContext(ProductCardContext);
  return (
    <div className={styles['product-sku']}>
      <Text dataQaId={`${dataTestId}-product-sku-label`} color={TextColor.Support60} textStyle={TextStyle.SmallDefault}>
        {product.barcode ?? `SKU: ${product.sku}`}
      </Text>
    </div>
  );
};

export { SKUDetails };
