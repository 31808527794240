import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnterspeedWidgetRelatedProducts } from '../../../../services/headless/types';
import { ArticlesList } from '../../../Discover/components/ArticlesList/ArticlesList';
import styles from './relatedArticleWidget.module.scss';

const RelatedArticleWidget: React.FC<EnterspeedWidgetRelatedProducts> = ({ related }) => {
  const { t } = useTranslation();

  if (!related.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Text textStyle={TextStyle.SmallBold} as="h1">
        {t('entries.discoverDetails.similiarArticles', {
          defaultValue: 'Similiar articles'
        })}
      </Text>

      <ArticlesList articlesList={related} />
    </div>
  );
};

export { RelatedArticleWidget };
