import { Filters, FilterValue } from '../interfaces/filters';

const filterSelectedValues = (values: FilterValue[]) => {
  const filtered = values.filter((x) => x.selected);

  if (!filtered.length) {
    return null;
  }

  return filtered.map((value) => value.code);
};

export const getCurrentFilters = (filters: Filters[] = [], blacklist: string[] = []) => {
  let count = -1;
  return filters?.reduce((acc: Record<string, string | string[] | null>, item: Filters) => {
    const hasSelectedValue = item.values?.find((x) => x.selected);
    if (!hasSelectedValue || blacklist.includes(item.code)) {
      return acc;
    }
    count += 1;

    return {
      ...acc,
      [`filters[${count}].code`]: item.code,
      [`filters[${count}].values`]: filterSelectedValues(item.values)
    };
  }, {});
};
