import { DISTRIBUTOR_LOCAL_STORAGE_KEY } from '../../../global/constants';
import { DistributorSteps } from './Distributor';

export const getDefaultStep = (userHasSingleOutlet: boolean): DistributorSteps => {
  if (userHasSingleOutlet) {
    return DistributorSteps.DISTRIBUTOR_SELECT;
  }
  return DistributorSteps.OUTLET_SELECT;
};

export const saveDistributorModalVisibilityToLocalStorage = (visibility: boolean) =>
  localStorage.setItem(DISTRIBUTOR_LOCAL_STORAGE_KEY, visibility ? 'true' : 'false');

export const checkUserHasSetDistributor = () => localStorage.getItem(DISTRIBUTOR_LOCAL_STORAGE_KEY) === 'true';
