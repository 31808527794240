import cn from 'classnames';
import React from 'react';
import styles from './navHeaderMobile.module.scss';

interface NavHeaderMobileTabProps {
  children: React.ReactNode;
  isActive: boolean;
}

const NavHeaderMobileTab = ({ children, isActive }: NavHeaderMobileTabProps) => (
  <li className={cn(styles.navHeaderMobileTab, { [styles.activeTab]: isActive })} role="tab-item">
    {children}
  </li>
);

export { NavHeaderMobileTab };
