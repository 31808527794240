import { PaddedContainer } from '@carlsberggroup/malty.atoms.padded-container';
import { PaddedContainerSize } from '@carlsberggroup/malty.atoms.padded-container/dist/PaddedContainer.types';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { ViewItemListTracking } from '../../../global/google-analytics';
import { ItemListName } from '../../../global/google-analytics/tracking';
import { ProductWithVariants } from '../../../global/interfaces/products';
import { getDefaultProducts } from '../../../global/utils/getDefaultProduct';
import { AlternativeProductCard } from '../AlternativeProductCard';
import { LoadingContainer } from '../LoadingContainer.tsx';
import styles from './productListModal.module.scss';

interface ProductListModalProps {
  dataTestId: string;
  listName: ItemListName;
  innerHeader?: string;
  isLoading: boolean;
  isActionTriggered?: boolean;
  emptyPlaceholder?: React.ReactNode;
  products: ProductWithVariants[];
  productQuantities: Record<string, number>;
  cartQuantities: Record<string, number>;
  actionButtons: React.ReactNode;
  onQuantityChange: (sku: string) => (quantity: number) => void;
}

const ProductListModal = ({
  dataTestId,
  listName,
  innerHeader,
  isLoading,
  isActionTriggered,
  emptyPlaceholder,
  products,
  productQuantities,
  cartQuantities,
  actionButtons,
  onQuantityChange
}: ProductListModalProps) => {
  const { currency } = React.useContext(ConfigContext);
  const gaEventData = useGetBaseGAEventData();

  useEffect(() => {
    if (products?.length > 0) {
      TagManager.dataLayer(ViewItemListTracking({ ...gaEventData, products: getDefaultProducts(products), listName }));
    }
  }, [products]);

  return (
    <>
      <article className={styles.scrollWrapper} data-testid={dataTestId}>
        {innerHeader ? (
          <PaddedContainer padding={PaddedContainerSize.XSmall}>
            <div className={styles.headerWrapper}>
              <Text textStyle={TextStyle.MediumSmallDefault}>{innerHeader}</Text>
            </div>
          </PaddedContainer>
        ) : null}
        <PaddedContainer padding={PaddedContainerSize.XSmall}>
          <div className={styles.productsSection}>
            {isLoading || isActionTriggered ? (
              <LoadingContainer
                dataTestId={`${dataTestId}-products-fetching`}
                hideButton
                hideText
                absolute={isActionTriggered}
              />
            ) : null}
            {!isLoading && products?.length > 0 ? (
              <ul className={styles.productsList}>
                {products?.map((product) => (
                  <li className={styles.productWrapper} key={product.sku}>
                    <AlternativeProductCard
                      dataTestId={`${dataTestId}-product`}
                      onQuantityChange={onQuantityChange(product.sku)}
                      quantity={productQuantities?.[product.sku] ?? 0}
                      currentQuantityInCart={cartQuantities[product.sku]}
                      currency={currency}
                      productWithVariants={product}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
            {!isLoading && products?.length === 0 ? (
              <div className={styles.emptyWrapper}>{emptyPlaceholder}</div>
            ) : null}
          </div>
        </PaddedContainer>
      </article>
      <footer className={styles.footer}>
        <PaddedContainer padding={PaddedContainerSize.XSmall}>
          <div className={styles.footerButtons}>{actionButtons}</div>
        </PaddedContainer>
      </footer>
    </>
  );
};
export { ProductListModal };
