import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { QueryParams } from './types';

export const customerFeedbackApi = createApi({
  reducerPath: 'customerFeedbackApi',
  baseQuery: baseApiQuery,
  endpoints: (builder) => ({
    createFeedback: builder.mutation<void, QueryParams>({
      query: (params: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_ORDER_ORDER_ID_FEEDBACK_SALES_ORG, {
          orderId: params.orderId,
          salesOrg: params.salesOrg
        }),
        method: 'POST',
        body: JSON.stringify(params.body)
      })
    })
  })
});

export const { useCreateFeedbackMutation } = customerFeedbackApi;
