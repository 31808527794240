import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useCurrentLanguage } from '../../../global/custom-hooks/useCurrentLanguage';
import { useHandleOutsideClick } from '../../../global/custom-hooks/useHandleOutsideClick';
import { MenuType } from '../../../global/google-analytics/tracking';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../../UI-elements/Text';
import styles from './languages.module.scss';
import { LanguagesDropdown } from './LanguagesDropdown';

interface Props {
  hasFlag?: boolean;
  isAbsolute?: boolean;
  isLogin?: boolean;
  shouldClose?: boolean;
  inActivationFlow?: boolean;
  hideLabel?: boolean;
  disable?: boolean;
  onMenuClick?: (type: MenuType, label: string) => void;
}

const Languages: React.FC<Props> = ({
  hasFlag = true,
  isAbsolute = true,
  isLogin,
  shouldClose,
  inActivationFlow,
  hideLabel = false,
  disable = false,
  onMenuClick
}: Props) => {
  const currentLanguage = useCurrentLanguage();
  const [isSelectLanguageOpen, toggleLanguage] = useState(false);
  const languageDropdownRef: RefObject<HTMLDivElement> = useRef();

  useEffect(() => {
    if (shouldClose) {
      toggleLanguage(false);
    }
  }, [shouldClose]);

  const handleToggleSelect = () => {
    toggleLanguage(!isSelectLanguageOpen);
  };

  useHandleOutsideClick(languageDropdownRef, () => {
    toggleLanguage(false);
  });

  const shortenIsoCode = () => {
    if (!currentLanguage || !Object.keys(currentLanguage).length) {
      return null;
    }

    return currentLanguage.isoCode === 'en-GB'
      ? currentLanguage.isoCode.substring(0, 2).toUpperCase()
      : currentLanguage.isoCode.substring(3, 5);
  };

  const selectDropdownItem = () => {
    if (!disable) {
      handleToggleSelect();
    }
  };

  return (
    <div className={styles['languages-wrapper']}>
      <div
        className={cn(styles.wrapper, {
          [styles.absolute]: isAbsolute,
          [styles.relative]: !isAbsolute,
          [styles.login]: isLogin
        })}
      >
        <div
          data-testid="languages-dropdown"
          className={styles['language-wrapper']}
          ref={languageDropdownRef}
          onClick={selectDropdownItem}
        >
          <div className={styles.text}>
            <Icon name={IconName.Globe} color={IconColor.Primary} size={IconSize.Medium} />
            {!hideLabel && (
              <Text size={FONT_SIZE.TINY} weight={FONT_WEIGHT.BOLD} color={TEXT_COLOR.DARKER_GREY}>
                {shortenIsoCode()}
              </Text>
            )}
            <div className={styles.chevron} />
          </div>
          {isSelectLanguageOpen && (
            <LanguagesDropdown
              onMenuClick={onMenuClick}
              inActivationFlow={inActivationFlow}
              isSelectLanguageOpen={isSelectLanguageOpen}
              toggleLanguage={toggleLanguage}
              hasFlag={hasFlag}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { Languages };
