import { Input, InputSize, InputType } from '@carlsberggroup/malty.atoms.input';
import cn from 'classnames';
import React from 'react';
import styles from './searchbox.module.scss';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  parentInputRef?: (node: HTMLInputElement) => void;
  onDeleteIconClick?: () => void;
  className?: string;
  dataTestId?: string;
}

const SearchBox: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  parentInputRef,
  onDeleteIconClick,
  className,
  dataTestId
}: Props) => (
  <div className={cn(styles.searchBox, className)} data-testid={dataTestId}>
    <Input
      dataTestId="input-search-box"
      value={value}
      onValueChange={onChange}
      placeholder={placeholder}
      ref={parentInputRef}
      type={InputType.Search}
      size={InputSize.Large}
      onClearButtonClick={onDeleteIconClick}
    />
  </div>
);

export { SearchBox };
