import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../global/utils/formatDate';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../Text';
import styles from './deliveryDate.module.scss';

interface Props {
  nearestDeliveryDate: string;
}
export const DeliveryDate: React.FC<Props> = ({ nearestDeliveryDate }) => {
  const { t } = useTranslation();

  const nearestDeliveryDateTranslation = t('entries.distributor.nearestDeliveryDate', {
    defaultValue: 'Nearest delivery date'
  });
  const formatedDate = formatDate(nearestDeliveryDate);
  const deliveryDateText = `${nearestDeliveryDateTranslation} - ${formatedDate}`;

  return (
    <Text
      className={styles.nearestDeliveryDate}
      size={FONT_SIZE.TINY}
      color={TEXT_COLOR.DARKER_GREY}
      weight={FONT_WEIGHT.BOLD}
      dataTestId="nearest-delivery-date"
    >
      {deliveryDateText}
    </Text>
  );
};
