import { createApi } from '@reduxjs/toolkit/query/react';
import { clerkIoEndpoints } from '../../api/constants/endpoints';
import { getVisitorId } from '../../global/google-analytics/utils';
import { baseApiQuery } from '../baseApiQuery';
import { ILogClick, ILogEmail, ILogProduct, ILogSale } from './types';

export const trackingApi = createApi({
  reducerPath: 'trackingApi',
  baseQuery: baseApiQuery,
  tagTypes: ['TRACKING'],
  endpoints: (builder) => ({
    logClick: builder.mutation<unknown, ILogClick>({
      query: ({ clerkIoApiKey, clerkIoUrl, sku }) => ({
        url: `${clerkIoUrl}${clerkIoEndpoints.LOG_CLICK}`,
        body: JSON.stringify({
          key: clerkIoApiKey,
          product: sku,
          visitor: getVisitorId()
        }),
        method: 'POST'
      }),
      extraOptions: {
        noAuth: true
      },
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch();
      }
    }),
    logEmail: builder.mutation<unknown, ILogEmail>({
      query: ({ clerkIoApiKey, clerkIoUrl, outletId }) => ({
        url: `${clerkIoUrl}${clerkIoEndpoints.LOG_EMAIL}`,
        body: JSON.stringify({
          key: clerkIoApiKey,
          email: `${outletId}@dummy.clerk`,
          visitor: getVisitorId()
        }),
        method: 'POST'
      }),
      extraOptions: {
        noAuth: true
      },
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch();
      }
    }),
    logSale: builder.mutation<unknown, ILogSale>({
      query: ({ clerkIoApiKey, clerkIoUrl, orderId, products, outletId }) => ({
        url: `${clerkIoUrl}${clerkIoEndpoints.LOG_SALE}`,
        body: JSON.stringify({
          key: clerkIoApiKey,
          sale: orderId,
          products,
          customer: outletId,
          email: `${outletId}@dummy.clerk`,
          visitor: getVisitorId()
        }),
        method: 'POST'
      }),
      extraOptions: {
        noAuth: true
      },
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch();
      }
    }),
    logProduct: builder.mutation<unknown, ILogProduct>({
      query: ({ clerkIoApiKey, clerkIoUrl, sku }) => ({
        url: `${clerkIoUrl}${clerkIoEndpoints.LOG_PRODUCT}`,
        body: JSON.stringify({
          key: clerkIoApiKey,
          product: sku,
          visitor: getVisitorId()
        }),
        method: 'POST'
      }),
      extraOptions: {
        noAuth: true
      },
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch();
      }
    })
  })
});

export const { useLogClickMutation, useLogEmailMutation, useLogProductMutation, useLogSaleMutation } = trackingApi;
