import { AdvancedImage } from '@cloudinary/react';
import React, { memo, useContext } from 'react';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { cloudinaryConfig } from '../../../global/utils/cloudinaryUtils';

interface Props {
  name: string;
  width: number;
  height: number;
}

const FlagIcon: React.FC<Props> = memo(({ name, width, height }) => {
  const { hostEnv } = useContext(ConfigContext);
  const cloudinaryFolder = `csplus/${hostEnv}`;
  const cloudinaryFlagsFolder = '/images/flags/';

  const flagURL = cloudinaryConfig
    .image(`${cloudinaryFolder}${cloudinaryFlagsFolder}${name}.svg`)
    .format('auto')
    .quality('auto');

  return <AdvancedImage alt={`${name}-flag`} width={width} height={height} cldImg={flagURL} />;
});

export { FlagIcon };
