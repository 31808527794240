import { Cart } from '../interfaces/cart';
import { Product } from '../interfaces/products';

const getCartProduct = (cart: Cart, sku: string, isReward: boolean): Product => {
  if (!cart || !sku) return null;

  return isReward
    ? cart.redeemableItems.find((x) => x.sku === sku)
    : cart.items.find((x) => x.sku === sku) ?? cart.returnItems.find((x) => x.sku === sku);
};

export { getCartProduct };
