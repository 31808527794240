import cn from 'classnames';
import React, { ReactNode } from 'react';
import styles from './text.module.scss';

export enum TEXT_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum TEXT_COLOR {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERCIARY = 'terciary',
  WHITE = 'white',
  LIGHTER_GREY = 'lighter-grey',
  GREY = 'grey',
  DARK_GREY = 'dark-grey',
  DARKER_GREY = 'darker-grey',
  BLACK = 'black',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  DISABLED = 'disabled',
  PROMOTIONAL = 'promotional',
  EMPTIES = 'empties',
  COINS = 'coins',
  FAQ_TITLE = 'faq-title',
  RESULTS_NOT_FOUND = 'resultsNotFound'
}

export enum FONT_SIZE {
  MICRO = 'micro',
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  MEDIUM_LARGE = 'medium-large',
  LARGE = 'large',
  BIG = 'big',
  HUGE = 'huge'
}

export enum FONT_WEIGHT {
  LIGHT = 'light',
  REGULAR = 'regular',
  SEMI_BOLD = 'semi-bold',
  BOLD = 'bold',
  BLACK = 'black'
}

export interface TextProps {
  align?: TEXT_ALIGN;
  children: string | number | ReactNode;
  className?: string;
  color?: TEXT_COLOR;
  dataTestId?: string;
  size?: FONT_SIZE;
  strikethrough?: boolean;
  emphasized?: boolean;
  underline?: boolean;
  weight?: FONT_WEIGHT;
  uppercase?: boolean;
}

const Text: React.FC<TextProps> = ({
  children,
  className = '',
  dataTestId = '',
  underline = false,
  strikethrough = false,
  emphasized = false,
  size = FONT_SIZE.MEDIUM,
  align = TEXT_ALIGN.LEFT,
  weight = FONT_WEIGHT.REGULAR,
  color = TEXT_COLOR.DEFAULT,
  uppercase = false
}: TextProps) => (
  <div
    className={cn(className, styles.text, styles[size], styles[align], styles[color], styles[weight], {
      [styles.strikethrough]: strikethrough,
      [styles.emphasized]: emphasized,
      [styles.underline]: underline,
      [styles.uppercase]: uppercase
    })}
    data-testid={dataTestId}
  >
    {children}
  </div>
);

export { Text };
