import cn from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { app } from '../../global/constants';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { Outlet } from '../../global/interfaces/outlet';
import { useGetHeadlessDataByPageHandleQuery } from '../../services/headless/headlessApi';
import { EnterspeedHandles, UmbracoErrorPage } from '../../services/headless/types';
import { userConfigOutletsSelector, userIsLoggedInSelector } from '../../store/slices/user';
import globalStyles from '../../styles/global.scss';
import { Header } from '../Header';
import { Loading } from '../shared/Loading';
import { ErrorHandlingComponent } from './ErrorHandlingComponent';
import { RedirectToErrorPage } from './RedirectToErrorPage';

const ErrorPage: React.FC<EmptyObject> = () => {
  const history = useHistory();
  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const { salesOrg } = useContext(ConfigContext);
  const languageCode = localStorage.getItem('i18nextLng') || app.defaultLanguage;
  const { isSingleInactiveDistributor, distributorsError } = useDistributors();
  const outlets: Outlet[] = useSelector(userConfigOutletsSelector);
  const isNoOutlets = outlets && !outlets.length;

  const { data, isFetching, error } = useGetHeadlessDataByPageHandleQuery(
    {
      handle: EnterspeedHandles.ERROR_PAGE,
      languageCode,
      contentDelivery: true,
      salesOrg
    },
    { skip: !languageCode }
  );
  const errorContent = data?.views['cs-error-page'] as UmbracoErrorPage;

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/login');
    }
  }, []);

  if (error) {
    return <RedirectToErrorPage />;
  }
  if (isFetching) {
    return <Loading fullScreen />;
  }
  if (!errorContent?.background) {
    return null;
  }

  return (
    <>
      <Header showNavBarMenu={false} />
      <main className={cn(globalStyles.mainContent, globalStyles.container)}>
        <ErrorHandlingComponent
          isNoOutlets={isNoOutlets}
          errorContent={errorContent}
          isSingleInactiveDistributor={isSingleInactiveDistributor}
          distributorsError={distributorsError}
        />
      </main>
    </>
  );
};

export default ErrorPage;
