import { app } from '../constants';

export const formatDate = (date: string, options?: Intl.DateTimeFormatOptions) => {
  let languageCode = localStorage.getItem('i18nextLng') || app.defaultLanguage;
  const parsedDate = new Date(date);

  // Javascript returns m/DD/yyyy format for lo-LA, but customer want dd/MM/YYYY format, like en-GB
  if (languageCode === 'lo-LA' || languageCode === 'en') {
    languageCode = 'en-GB';
  }

  return parsedDate.toLocaleDateString(languageCode, options);
};

export const formatApiDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(12);
  return newDate.toISOString().split('T')[0];
};

export const getFormattedHour = (date: Date) => {
  let languageCode = localStorage.getItem('i18nextLng') || app.defaultLanguage;
  if (languageCode === 'en') {
    languageCode = 'en-GB';
  }
  return date.toLocaleString(languageCode, { hour: 'numeric', minute: 'numeric' });
};

export const setEndOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(23);
  newDate.setMinutes(59);
  newDate.setSeconds(59);
  newDate.setMilliseconds(999);
  return newDate;
};
