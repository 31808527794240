import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { Outlet } from '../../../global/interfaces/outlet';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../../UI-elements/Text';
import styles from './distributor.module.scss';

interface Props {
  outlet: Outlet;
}

export const OutletCard: React.FC<Props> = ({ outlet }) => {
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const hasTinyFontSize = isXSmall || isSmall || isMedium;
  const { t } = useTranslation();

  if (!outlet) {
    return null;
  }

  return (
    <div className={styles.card}>
      <Text
        size={hasTinyFontSize ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
        color={TEXT_COLOR.DEFAULT}
        weight={FONT_WEIGHT.BOLD}
        className={styles.cardName}
        dataTestId="selection-info-outlet-name"
      >
        {outlet.name}
      </Text>

      <div className={styles.outletDetails}>
        <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARKER_GREY}>
          {t('entries.selection.costumerId', { defaultValue: 'Customer ID: ' })}
          {outlet.eid}
        </Text>
        <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARKER_GREY} dataTestId="outlet-option-address">
          {outlet.address}
          {outlet.city && outlet.address && `, `}
          {outlet.city}
        </Text>
      </div>
    </div>
  );
};
