import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import cn from 'classnames';
import React from 'react';
import { ALIGNED } from '../../../../global/constants';
import { umbracoParser } from '../../../../global/utils/umbracoParser';
import { EnterspeedWidgetContent } from '../../../../services/headless/types';
import { CloudinaryImage } from '../../../../UI-elements/CloudinaryImage';
import { Video } from '../Video/Video';
import styles from './headlessContentWidget.module.scss';

const HeadlessContentWidget: React.FC<EnterspeedWidgetContent> = ({ title, textAsJson, image, video }) => {
  const isFullWidth =
    image?.align !== ALIGNED.LEFT &&
    image?.align !== ALIGNED.RIGHT &&
    video?.align !== ALIGNED.LEFT &&
    video?.align !== ALIGNED.RIGHT;
  return (
    <div className={styles.contentWrapper} data-testid="content-widget">
      {title && (
        <Text textStyle={TextStyle.SmallBold} as="h1">
          {title}
        </Text>
      )}

      <div
        className={cn(styles.mediaArea, {
          [styles.fullWidth]: isFullWidth
        })}
      >
        <div>{textAsJson && umbracoParser(textAsJson)}</div>

        {image && (
          <div
            className={cn(styles.media, {
              [styles.left]: image?.align === ALIGNED.LEFT
            })}
          >
            <CloudinaryImage
              src={image?.url}
              alt={image.title}
              dataTestId={`${image?.id}-content-image`}
              width={image?.properties?.width}
              height={image?.properties?.height}
            />
          </div>
        )}
        {video && (
          <div
            className={cn(styles.media, {
              [styles.left]: image?.align === ALIGNED.LEFT
            })}
          >
            <Video
              url={video.url}
              extension={video.properties?.extension}
              thumbnail={video.thumbnail?.url}
              dataQaId={video.id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { HeadlessContentWidget };
