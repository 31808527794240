import { breakpoints } from '../constants';
import { useHandleWindowResize } from './useHandleWindowResize';

export const useScreenSize = () => ({
  isXSmall: useHandleWindowResize(`(max-width: ${breakpoints.xsmall}px)`), // 0 ... 374px,
  isSmall: useHandleWindowResize(`(min-width: ${breakpoints.xsmall}px) and (max-width: ${breakpoints.small - 1}px)`), // 375px ... 767px
  isMedium: useHandleWindowResize(`(min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.medium - 1}px)`), // 768px ... 1023px
  isLarge: useHandleWindowResize(`(min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large - 1}px)`), // 1024px ... 1279px
  isXLarge: useHandleWindowResize(`(min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.xlarge - 1}px)`), // 1280px ... 1439px
  isXXLarge: useHandleWindowResize(
    `(min-width: ${breakpoints.xlarge}px) and (max-width: ${breakpoints.xxlarge - 1}px)`
  ), // 1440px ... 1919px
  isXXXLarge: useHandleWindowResize(`(min-width: ${breakpoints.xxlarge}px)`) // 1920px
});
