import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ProductWithVariants } from '../../global/interfaces/products';

interface FavSKU {
  sku: string;
  favListId: string;
  favListName: string;
  product: ProductWithVariants;
}
interface FavouritesPageState {
  lastRemovedFavouriteProduct?: FavSKU;
}

const initialState: FavouritesPageState = {
  lastRemovedFavouriteProduct: undefined
};

export const FavouritesPageSlice = createSlice({
  name: 'FavouritesPage',
  initialState,
  reducers: {
    setLastRemovedFavouriteProduct: (state, action: PayloadAction<FavSKU>) => {
      state.lastRemovedFavouriteProduct = action.payload;
    }
  }
});

export const { setLastRemovedFavouriteProduct } = FavouritesPageSlice.actions;
export const lastRemovedFavouriteProduct = (state: RootState) => state?.favourites?.lastRemovedFavouriteProduct;

export default FavouritesPageSlice.reducer;
