import { Button, ButtonStyle, ButtonType } from '@carlsberggroup/malty.atoms.button';
import { IconName } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLockPageScroll } from '../../../global/custom-hooks/useLockPageScroll';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { mergeTheme } from '../../../global/utils/mergeTheme';
import { useGetHeadlessConfigQuery } from '../../../services/headless/headlessApi';
import { EnterspeedPages } from '../../../services/headless/types';
import { CloudinaryImage } from '../../../UI-elements/CloudinaryImage';
import { Overlay } from '../../../UI-elements/Overlay';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../../UI-elements/Text';
import headerStyles from '../../Header/header.module.scss';
import styles from './modal.module.scss';

const defaultTheme = {
  container: styles.modal,
  content: styles.content,
  header: styles.header,
  description: styles.description,
  children: styles.children
};

export type ModalTheme = Partial<typeof defaultTheme>;

interface ModalProps {
  active: boolean;
  closeModal: () => void;
  children: ReactNode;
  dataTestId?: string;
  hideOverlay?: boolean;
  title?: string;
  large?: boolean;
  description?: string;
  showCloseButton?: boolean;
  showLogo?: boolean;
  theme?: ModalTheme;
  headerContent?: ReactNode;
  fixedClose?: boolean;
  className?: string;
}

const Modal = ({
  active,
  closeModal,
  dataTestId = '',
  description,
  children,
  hideOverlay,
  large,
  showCloseButton,
  showLogo,
  title,
  theme,
  headerContent,
  fixedClose = false,
  className
}: ModalProps) => {
  const {
    i18n: { language }
  } = useTranslation();
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { data: siteHeadlessData } = useGetHeadlessConfigQuery(
    { url: EnterspeedPages.CONFIG, languageCode: language },
    {
      skip: !language
    }
  );
  const clickModal = (event: SyntheticEvent) => {
    event.persist();
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    if (active && isFirstRender) {
      setIsFirstRender(false);
    }
  }, [active, isFirstRender]);

  const mergedTheme = useMemo(() => mergeTheme<typeof defaultTheme>(defaultTheme, theme), [defaultTheme, theme]);
  useLockPageScroll(active);

  return createPortal(
    <>
      <div
        className={cn(mergedTheme.container, className, {
          [styles.isOpen]: active,
          [styles.isClose]: !isFirstRender && !active
        })}
        onClick={closeModal ? clickModal : null}
        data-testid={`${dataTestId}-wrapper`}
      >
        <div
          data-testid={dataTestId}
          className={cn(mergedTheme.content, {
            [styles.large]: large
          })}
        >
          {showLogo || headerContent ? (
            <div className={cn(mergedTheme.header, headerStyles.headerHeight)}>
              {showLogo ? (
                <CloudinaryImage
                  dataTestId="logo-image"
                  cssClassName={cn(styles.logo, headerStyles.shopLogo)}
                  src={siteHeadlessData?.route?.brand?.logo?.url}
                  alt="header-logo"
                />
              ) : null}
              {headerContent}
            </div>
          ) : null}
          {closeModal && showCloseButton ? (
            <div
              className={cn(styles.close, {
                [styles['fixed-close']]: fixedClose
              })}
            >
              <Button
                type={ButtonType.Submit}
                onClick={closeModal}
                style={ButtonStyle.Transparent}
                dataTestId={`${dataTestId}-button-close`}
                icon={IconName.Close}
              />
            </div>
          ) : null}
          {title ? (
            <Text
              dataTestId={`${dataTestId}-title`}
              align={TEXT_ALIGN.CENTER}
              size={isMobile ? FONT_SIZE.MEDIUM_LARGE : FONT_SIZE.LARGE}
              weight={FONT_WEIGHT.BOLD}
            >
              {title}
            </Text>
          ) : null}

          {description ? (
            <div className={mergedTheme.description}>
              <Text
                dataTestId={`${dataTestId}-description`}
                color={TEXT_COLOR.DARK_GREY}
                align={TEXT_ALIGN.CENTER}
                size={FONT_SIZE.TINY}
              >
                {description}
              </Text>
            </div>
          ) : null}
          <div className={mergedTheme.children}>{children}</div>
        </div>
      </div>
      {!hideOverlay ? <Overlay active={active} action={closeModal} /> : null}
    </>,
    document.body
  );
};

export { Modal };
