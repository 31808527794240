import { useEffect, useState } from 'react';
import { useDismissAnnouncementMutation, useGetAnnouncementsQuery } from '../../services/announcements/announcements';
import { AnnouncementContent } from '../../services/announcements/types';
import { useDistributors } from './useDistributors';

export const useAnnouncements = () => {
  const [announcementContentArray, setAnnouncementContentArray] = useState<AnnouncementContent[]>([]);
  const [dismissAnnouncement, _result] = useDismissAnnouncementMutation();
  const { selectedOutlet } = useDistributors();
  const { ...props } = useGetAnnouncementsQuery(
    {
      salesOrg: selectedOutlet?.salesOrg,
      outletId: selectedOutlet?.eid,
      page: 0,
      size: 20
    },
    { skip: !selectedOutlet }
  );

  const removeAnnouncement = (eid: string) => {
    dismissAnnouncement({
      outletId: selectedOutlet.eid,
      salesOrg: selectedOutlet.salesOrg,
      announcementId: eid
    });

    setAnnouncementContentArray((prevArray) => prevArray.filter((announcement) => announcement.eid !== eid));
  };

  useEffect(() => {
    if (props.data) {
      setAnnouncementContentArray(props.data.content);
    }
  }, [props.data]);

  return { ...props, announcementContentArray, removeAnnouncement };
};
