import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { ListContext } from '../../../../../global/contexts/ListContext';
import { PageContext } from '../../../../../global/contexts/PageContext';
import { useGetBaseGAEventData } from '../../../../../global/custom-hooks/useGetBaseGAEventData';
import { AddToFavouritesTracking } from '../../../../../global/google-analytics';
import { Product } from '../../../../../global/interfaces/products';
import {
  useAddToFavouriteListMutation,
  useDeleteFavouriteItemMutation
} from '../../../../../services/favourites/favourites';
import { AssociatedFavListPayload } from '../../../../../services/favourites/types';
import { RedirectToErrorPage } from '../../../../ApiErrorHandler/RedirectToErrorPage';
import { Loading } from '../../../Loading';
import { EmptyList } from '../EmptyList/EmptyList';
import { SwitchToggle } from '../SwitchToggle/SwitchToggle';
import styles from './modalContent.module.scss';

interface ModalContentProps {
  favouriteList: AssociatedFavListPayload;
  salesOrg: string;
  product: Product;
  outletId: string;
}

interface SwitchChangeParams {
  favListId: string;
  favListName: string;
  isChecked: boolean;
}
export const ModalContent = ({ favouriteList, salesOrg, outletId, product }: ModalContentProps) => {
  const { pageType } = useContext(PageContext);
  const { listName } = useContext(ListContext);
  const gaEventData = useGetBaseGAEventData();
  const [isLoading, setIsLoading] = useState(false);
  const sku = product.baseSku;
  const [addToFavouriteList, { error: addToFavouriteListError }] = useAddToFavouriteListMutation();
  const [deleteFavouriteList, { error: deleteFavouriteListError }] = useDeleteFavouriteItemMutation();
  if (addToFavouriteListError || deleteFavouriteListError) {
    return <RedirectToErrorPage />;
  }

  const handleSwitchChange = ({ favListId, favListName, isChecked }: SwitchChangeParams) => {
    setIsLoading(true);
    if (!isChecked) {
      addToFavouriteList({
        sku,
        favListId,
        favListName,
        salesOrg,
        outletId
      }).then(() => {
        setIsLoading(false);

        TagManager.dataLayer(
          AddToFavouritesTracking({
            ...gaEventData,
            product,
            pageType,
            listName
          })
        );
      });
    } else {
      deleteFavouriteList({
        sku,
        favListId,
        salesOrg,
        outletId
      }).then(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <div className={styles.modalContentWrapper}>
      {favouriteList?.content.length > 0 ? (
        favouriteList.content.map(({ id, name, associate }) => (
          <label key={id} htmlFor={id} className={styles.listItem} title={name}>
            <SwitchToggle
              isChecked={associate}
              onChange={() =>
                handleSwitchChange({
                  favListId: id,
                  isChecked: associate,
                  favListName: name
                })
              }
              itemId={id}
            />
            <Text textStyle={TextStyle.MediumSmallDefault}>{name}</Text>
          </label>
        ))
      ) : (
        <EmptyList />
      )}

      {isLoading && (
        <div className={styles.loaderOverlay}>
          <Loading fullScreen hideButton hideText small />
        </div>
      )}
    </div>
  );
};
