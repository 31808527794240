import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetHeadlessConfigQuery } from '../../../services/headless/headlessApi';
import { EnterspeedPages } from '../../../services/headless/types';
import styles from './distributor.module.scss';

export const NoActiveDistributorsInfoMessage = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { data: siteHeadlessData } = useGetHeadlessConfigQuery(
    { url: EnterspeedPages.CONFIG, languageCode: language },
    {
      skip: !language
    }
  );

  if (siteHeadlessData?.meta?.status !== 200) {
    return null;
  }

  return (
    <>
      <div className={styles.inactiveOutlet}>
        <p className={styles.title}>
          {t('entries.distributor.inActiveDistributorsTitle', { defaultValue: 'Outlet without Distributors' })}
        </p>
      </div>
      <div className={styles.highlight}>
        <Trans i18nKey="entries.distributor.inActiveDistributorsMessage">
          <a href={`mailto:${siteHeadlessData?.route?.support?.email}`} className={styles.contact}>
            support
          </a>
        </Trans>
      </div>
    </>
  );
};
