import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '../../api/constants/endpoints';
import { LoyaltyBalance } from '../../global/interfaces/loyaltyBalance';
import { baseApiQuery } from '../baseApiQuery';
import { LoyaltyBalanceQueryParams } from './types';

export const loyaltyBalanceApi = createApi({
  reducerPath: 'loyaltyBalanceApi',
  baseQuery: baseApiQuery,
  tagTypes: ['LOYALTY_BALANCE'],
  endpoints: (builder) => ({
    getLoyaltyBalance: builder.query<LoyaltyBalance, LoyaltyBalanceQueryParams>({
      query: (params: LoyaltyBalanceQueryParams) => ({
        url: apiEndpoints.LOYALTY_BALANCE,
        params
      }),
      providesTags: ['LOYALTY_BALANCE']
    })
  })
});

export const { useGetLoyaltyBalanceQuery } = loyaltyBalanceApi;
