import React from 'react';
import styles from './navHeaderMobile.module.scss';

interface NavHeaderMobileProps {
  children: React.ReactNode;
}

const NavHeaderMobileRoot = ({ children }: NavHeaderMobileProps) => (
  <nav className={styles.navHeaderMobile} role="tab-nav">
    <ul className={styles.navHeaderMobileList} role="tab-list">
      {children}
    </ul>
  </nav>
);

export { NavHeaderMobileRoot };
