import { useTranslation } from 'react-i18next';
import { PAGE_ROUTES } from './useGetCurrentPageInfo';

export interface NavHeaderItem {
  path?: string;
  label: string;
}

export type NavHeaderItems = Record<string, NavHeaderItem>;

enum NAV_HEADER_ACTION_BUTTONS {
  cartDrawer = 'Cart',
  searchModal = 'Search',
  menuDrawer = 'Menu',
  languageModal = 'Language',
  profileModal = 'Profile'
}

export const useHeaderNavLinksMobile = () => {
  const { t } = useTranslation();

  const navHeaderItemMobile: NavHeaderItems = {
    home: {
      path: `/${PAGE_ROUTES.home.path}`,
      label: t('entries.navMenuMobileItem.home', { defaultValue: PAGE_ROUTES.home.name })
    },
    catalog: {
      path: `/${PAGE_ROUTES.catalog.path}`,
      label: t('entries.navMenuMobileItem.catalog', { defaultValue: PAGE_ROUTES.catalog.name })
    },
    cartDrawer: {
      label: t('entries.navMenuMobileItem.cart', { defaultValue: NAV_HEADER_ACTION_BUTTONS.cartDrawer })
    },
    searchModal: {
      label: t('entries.navMenuMobileItem.search', { defaultValue: NAV_HEADER_ACTION_BUTTONS.searchModal })
    },
    menuDrawer: {
      label: t('entries.navMenuMobileItem.menu', { defaultValue: NAV_HEADER_ACTION_BUTTONS.menuDrawer })
    },
    languageModal: {
      label: t('entries.navMenuMobileItem.language', { defaultValue: NAV_HEADER_ACTION_BUTTONS.languageModal })
    },
    profileModal: {
      label: t('entries.navMenuMobileItem.profile', { defaultValue: NAV_HEADER_ACTION_BUTTONS.profileModal })
    }
  };

  return {
    navHeaderItemMobile
  };
};
