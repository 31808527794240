import { Product, Promotion, PromotionTypes } from '../../interfaces/products';
import {
  GAEvent,
  ItemListName,
  PageType,
  Platform,
  TrackingAddToFavourites,
  TrackingProductClick,
  TrackingViewItem,
  TrackingViewItemList
} from '../tracking';
import { gaClientId, isPWA, mapProductsItems, mapPromotions } from '../utils';

interface ProductClickTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  pageType: PageType;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  products: Product[];
  currency: string;
  listName: string;
}

interface AddToFavouritesTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  product: Product;
  pageType: PageType;
  listName: string;
}

interface ViewItemListTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products: Product[];
  pageType: PageType;
  listName: ItemListName;
}

interface ViewItemTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  product: Product;
  pageType: PageType;
  listName: ItemListName;
  promotionTypes?: PromotionTypes[];
  promotions?: Promotion[];
}

export const ProductClickTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  pageType,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  products,
  currency,
  listName
}: ProductClickTrackingProps): TrackingProductClick => ({
  dataLayer: {
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    page_type: pageType,
    site_market: siteMarket,
    site_language: siteLanguage,
    site_environment: siteEnvironment,
    user_id: userId,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    event: GAEvent.SelectItem,
    event_name: GAEvent.SelectItem,
    ecommerce: {
      items: mapProductsItems({
        products,
        currency,
        quantity: 1,
        listName
      })
    }
  }
});

export const AddToFavouritesTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  product,
  pageType,
  listName
}: AddToFavouritesTrackingProps): TrackingAddToFavourites => ({
  dataLayer: {
    event: GAEvent.AddFavouritesClick,
    event_name: GAEvent.AddFavouritesClick,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      currency,
      value: product?.price?.discount || product?.price?.base,
      items: mapProductsItems({ products: [product], currency, quantity: 1, listName })
    }
  }
});

export const ViewItemListTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  pageType,
  listName
}: ViewItemListTrackingProps): TrackingViewItemList => ({
  dataLayer: {
    event: GAEvent.ViewItemList,
    event_name: GAEvent.ViewItemList,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      item_list_name: listName,
      items: mapProductsItems({ products, currency, listName, quantity: 1 })
    }
  }
});
export const ViewItemTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  listName,
  pageType,
  currency,
  product,
  promotionTypes,
  promotions
}: ViewItemTrackingProps): TrackingViewItem => ({
  dataLayer: {
    event: GAEvent.ViewItem,
    event_name: GAEvent.ViewItem,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      items: mapProductsItems({ products: [product], currency, listName, quantity: 1 }),
      value: product?.price?.discount || product?.price?.base,
      currency,
      promotions: mapPromotions(promotions, promotionTypes)
    }
  }
});
