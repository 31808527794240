import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { getCSPlusConfig } from '../config/getConfig';
import { getGlobalConfig } from '../config/getGlobalConfig';
import { getHostSettings } from '../config/getHostSettings';

// enterspeed
type ArgsBaseHeadlessApiQuery = FetchArgs & { contentDelivery?: boolean; targetGroups?: string[] };
export const baseHeadlessApiQuery: BaseQueryFn<ArgsBaseHeadlessApiQuery, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const globalConfig = getGlobalConfig();
  const csplus = await getCSPlusConfig();
  const hostSettings = getHostSettings(csplus.configs);

  if (!globalConfig.enterspeedApi || !globalConfig.contentDeliveryApi) {
    console.error('enterspeedApi or contentDeliveryApi not defined');
  }

  if (!hostSettings || !hostSettings.enterspeedApiKey) {
    console.error('enterspeedApiKey url is not defined');
  }

  const baseUrl = args.contentDelivery ? globalConfig.contentDeliveryApi : globalConfig.enterspeedApi;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      headers.set('X-Api-Key', hostSettings.enterspeedApiKey);

      if (args.targetGroups) {
        headers.set('x-segment', encodeURIComponent(args.targetGroups.join(',')));
      }

      return headers;
    }
  });
  return rawBaseQuery(args, api, extraOptions);
};
