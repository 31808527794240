import { Cart } from '../interfaces/cart';
import { Product } from '../interfaces/products';
import { mergeCartProducts } from './mergeCartProducts';

export enum CartType {
  BUNDLE = 'bundle',
  PRODUCT = 'product'
}

type MergeCartProps = (selectedCart: Cart, cartItemsType?: CartType) => Product[];

const mergeCart: MergeCartProps = (selectedCart: Cart, cartItemsType = CartType.PRODUCT) => {
  if (cartItemsType === CartType.BUNDLE) {
    return [...(selectedCart?.items ?? [])];
  }
  return mergeCartProducts(selectedCart);
};

export { mergeCart };
