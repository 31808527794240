import { GAEvent, PageType, Platform, TrackingFooterClick } from '../tracking';
import { gaClientId, isPWA } from '../utils';

interface FooterClickTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  linkText?: string;
  linkUrl?: string;
}

export const FooterClickTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  linkText,
  linkUrl
}: FooterClickTrackingProps): TrackingFooterClick => ({
  dataLayer: {
    event: GAEvent.FooterClick,
    event_name: GAEvent.FooterClick,
    user_id: userId,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    link_text: linkText,
    link_url: linkUrl,
    page_type: PageType.Private,
    customer_id: customerId,
    customer_name: customerName,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId
  }
});
