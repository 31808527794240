import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { logout } from '../../auth/authHandler';
import { ANNOUNCEMENTS_LOCAL_STORAGE_KEY } from '../../components/Announcement/constants';
import { userConfigSelector } from '../../store/slices/user';
import { LogoutTracking } from '../google-analytics';
import { LoginMethod } from '../google-analytics/tracking';
import { UserConfig } from '../interfaces/user';
import { useGetBaseGAEventData } from './useGetBaseGAEventData';

const useLogout = () => {
  const userConfig: UserConfig = useSelector(userConfigSelector);
  const gaEventData = useGetBaseGAEventData();

  const handleLogout = (options?: { redirectUri: string }) => {
    if (!userConfig) return;

    if (localStorage.getItem(ANNOUNCEMENTS_LOCAL_STORAGE_KEY)) {
      localStorage.removeItem(ANNOUNCEMENTS_LOCAL_STORAGE_KEY);
    }

    TagManager.dataLayer(
      LogoutTracking({
        ...gaEventData,
        loginMethod: LoginMethod.Manual
      })
    );
    logout(options);
  };

  return { handleLogout };
};

export { useLogout };
