import { AlertInline, AlertInlineColor } from '@carlsberggroup/malty.molecules.alert-inline';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { app } from '../../../global/constants';
import { Product } from '../../../global/interfaces/products';
import styles from '../cartquantity.module.scss';

interface Props {
  dataTestId?: string;
  productMaxQtyOrderable: Product['maxQtyOrderable'];
}

const ProductMaxQuantityAlert: React.FC<Props> = ({ dataTestId = '', productMaxQtyOrderable }) => {
  const { t } = useTranslation();

  const isProductDetailPage = window.location.pathname.includes('/products/');

  return (
    <div
      className={cn(styles.productLimit, {
        [styles.productLimitOnPDP]: isProductDetailPage
      })}
    >
      <AlertInline
        color={AlertInlineColor.NotificationLight}
        message={t('entries.catalog.maxOrderLimit', {
          maxQuantity: productMaxQtyOrderable ?? app.cartLimit,
          defaultValue: 'Max order limit: {{maxQuantity}}'
        })}
        dataQaId={`${dataTestId}-cart-max-quantity`}
      />
    </div>
  );
};

export { ProductMaxQuantityAlert };
