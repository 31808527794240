import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetCatalogProductsQuery } from '../../services/products/products';
import { CatalogQueryParams } from '../../services/products/types';
import { State } from '../../store/reducers';
import { CATALOG_TYPES } from '../constants';
import { getCurrentPage, getCurrentSortMode } from '../selectors/catalog/selectors';
import { getActiveChip, getFilters } from '../selectors/filters/selectors';
import { getCurrentFilters } from '../utils/getCurrentFilters';
import { getTodayInIsoFormat } from '../utils/getToday';
import { useNumberOfProducts } from '../utils/numberOfProducts';
import { useDistributors } from './useDistributors';

export const useFetchCatalog = () => {
  const filters = useSelector(getFilters);
  const activeChip = useSelector(getActiveChip);
  const currentFilters = getCurrentFilters(filters);
  const currentPage = useSelector(getCurrentPage);
  const numberOfProducts = useNumberOfProducts();
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const shouldSkip = !selectedOutlet || !selectedDistributor;
  const currentSortMode = useSelector(getCurrentSortMode);
  const rewardPage = useSelector((state: State) => state.rewardsPageSlice.currentPage);

  const catalogParams: CatalogQueryParams = useMemo(
    () => ({
      salesOrg: selectedOutlet?.salesOrg,
      outletId: selectedOutlet?.eid,
      deliveryDate: getTodayInIsoFormat(),
      size: numberOfProducts,
      page: activeChip === CATALOG_TYPES.reward ? rewardPage : currentPage,
      type: activeChip,
      withSortMode: true,
      withFilters: false,
      distributorId: selectedDistributor?.eid,
      sortMode: currentSortMode?.code || (currentFilters.sortMode as string),
      ...currentFilters
    }),
    [
      selectedOutlet,
      getTodayInIsoFormat,
      numberOfProducts,
      activeChip,
      rewardPage,
      currentPage,
      selectedDistributor,
      currentFilters,
      currentSortMode
    ]
  );

  const { ...props } = useGetCatalogProductsQuery(catalogParams, {
    skip: shouldSkip
  });

  return { ...props, catalogData: props.data };
};
