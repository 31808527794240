import { nanoid } from 'nanoid';
import React, { FC, useContext, useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RedirectToErrorPage } from '../../components/ApiErrorHandler/RedirectToErrorPage';
import { HeadlessWidget } from '../../components/HeadlessWidget';
import { Loading } from '../../components/shared/Loading';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { PageContext } from '../../global/contexts/PageContext';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { useOutletDetails } from '../../global/custom-hooks/useOutletDetails';
import { PageViewTracking } from '../../global/google-analytics';
import { PageType } from '../../global/google-analytics/tracking';
import { useGetHeadlessDataByPageHandleQuery } from '../../services/headless/headlessApi';
import { EnterspeedHandles } from '../../services/headless/types';
import { userConfigSelector } from '../../store/slices/user';
import styles from './home.module.scss';

const Home: FC = () => {
  const isTrackingCalledRef = useRef(false);
  const gaEventData = useGetBaseGAEventData();
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { selectedOutlet, selectedDistributor, isLoadingOutletsDistributors } = useDistributors();
  const { outletDetails, isFetchingOutletDetails } = useOutletDetails({
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    distributorId: selectedDistributor?.eid
  });
  const { targetGroups } = outletDetails || {};
  const userConfig = useSelector(userConfigSelector);
  const { environment } = useContext(ConfigContext);

  const headlessParams = {
    handle: EnterspeedHandles.HOMEPAGE,
    languageCode: language,
    targetGroups,
    salesOrg: selectedOutlet?.salesOrg,
    contentDelivery: true
  };
  const headlessRequestSkipParams = { skip: !targetGroups || !selectedOutlet?.salesOrg };
  const {
    data: homePageData,
    error: homePageError,
    isFetching: isFetchingHomeContent
  } = useGetHeadlessDataByPageHandleQuery(headlessParams, headlessRequestSkipParams);

  useEffect(() => {
    if (
      !selectedOutlet ||
      !language ||
      !userConfig ||
      !environment ||
      isTrackingCalledRef.current ||
      isFetchingHomeContent
    ) {
      return;
    }
    TagManager.dataLayer(PageViewTracking({ ...gaEventData }));
    isTrackingCalledRef.current = true;
  }, [language, userConfig, environment, isTrackingCalledRef.current, selectedOutlet, isFetchingHomeContent]);

  if (isFetchingHomeContent || isLoadingOutletsDistributors || isFetchingOutletDetails) {
    return <Loading fullScreen />;
  }

  if (homePageError) {
    return <RedirectToErrorPage />;
  }

  const homePageBlocks = homePageData?.views.csHomepage?.content.view.blocks || [];
  return (
    <PageContext.Provider value={{ pageType: PageType.Highlight }}>
      <div className={styles.homeContent}>
        <Helmet>
          <title>{t('entries.homepage.title', { defaultValue: 'Home' })}</title>
        </Helmet>
        {homePageBlocks.map((widgetBlock, idx: number) => (
          <div key={nanoid()} data-testid="home-headless-widget" className={styles.headlessWidgetWrapper}>
            <HeadlessWidget widgetBlock={widgetBlock} id={idx} />
          </div>
        ))}
      </div>
    </PageContext.Provider>
  );
};
export { Home };
