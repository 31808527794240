import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HEADLESS_RECOMMENDATIONS_TABS, RECOMMENDATIONS_CODES } from '../../../../global/constants';
import { ListContext } from '../../../../global/contexts/ListContext';
import { useRecommended } from '../../../../global/custom-hooks/useRecommended';
import { useScreenSize } from '../../../../global/custom-hooks/useScreenSize';
import { ItemListName } from '../../../../global/google-analytics/tracking';
import { getActiveChip } from '../../../../global/selectors/recommendationsCatalog/selectors';
import { EnterspeedWidgetRecommendation } from '../../../../services/headless/types';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../../../UI-elements/Text';
import { Chips } from '../../../Catalog/Chips/ChipsComponent';
import { setRecommendationActiveChip } from './actions';
import { RecommendationProducts } from './components/RecommendationProducts';
import { RECOMMENDATIONS_REQUEST_LIMIT } from './constants';
import styles from './recommendationsCatalog.module.scss';

const tabsMapper: Record<HEADLESS_RECOMMENDATIONS_TABS, RECOMMENDATIONS_CODES> = {
  [HEADLESS_RECOMMENDATIONS_TABS.popular]: RECOMMENDATIONS_CODES.popular,
  [HEADLESS_RECOMMENDATIONS_TABS.new]: RECOMMENDATIONS_CODES.new,
  [HEADLESS_RECOMMENDATIONS_TABS.trending]: RECOMMENDATIONS_CODES.trending,
  [HEADLESS_RECOMMENDATIONS_TABS.recentlyBought]: RECOMMENDATIONS_CODES.recentlyBought
};

type Props = EnterspeedWidgetRecommendation & { id: number };

const RecommendationsCatalog: FC<Props> = ({ id, tabs }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const chips = tabs.map(({ tab }) => tabsMapper[tab]);
  const defaultChip = chips[0];
  const activeChip = useSelector(getActiveChip(id));

  const setDefaultChip = () =>
    dispatch(setRecommendationActiveChip({ activeChip: defaultChip, recommendationsCatalogId: id }));
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  useEffect(() => {
    if (tabs && !activeChip) {
      setDefaultChip();
    }
  }, [tabs]);

  const { data, isFetching, error, isRecommendationEngineEnabled } = useRecommended({
    limit: RECOMMENDATIONS_REQUEST_LIMIT,
    recommendationsCode: activeChip,
    skip: !activeChip
  });

  if (!activeChip || !isRecommendationEngineEnabled) {
    return null;
  }

  const onChipChange = (tab: RECOMMENDATIONS_CODES) => {
    dispatch(setRecommendationActiveChip({ activeChip: tab, recommendationsCatalogId: id }));
  };

  return (
    <ListContext.Provider value={{ listName: ItemListName.RecommendationsList }}>
      <section className={styles.container} data-testid={`recommendations-catalog-${id}`}>
        <Text
          weight={FONT_WEIGHT.BOLD}
          size={isMobile ? FONT_SIZE.MEDIUM_LARGE : FONT_SIZE.LARGE}
          className={styles.title}
        >
          {t('entries.recommendationsWidget.title', { defaultValue: 'Our Suggestions' })}
        </Text>
        <div className={styles['chip-wrapper']}>
          <Chips chips={chips} onTabChange={onChipChange} isCatalogLoading={isFetching} />
        </div>
        <RecommendationProducts
          products={data?.products}
          isProductsLoading={isFetching}
          activeChip={activeChip}
          error={error}
        />
      </section>
    </ListContext.Provider>
  );
};

export { RecommendationsCatalog };
