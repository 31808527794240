import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { getApiConfig, getCSPlusConfig } from '../config/getConfig';
import { ApiError } from '../global/interfaces/error';

export type FetchBaseQueryErrorExtended = {
  data: ApiError;
} & FetchBaseQueryError;

export const baseMaintenanceApiQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | FetchBaseQueryErrorExtended
> = async (args, api, extraOptions) => {
  const apiconfig = await getApiConfig();
  const csplus = await getCSPlusConfig();

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${apiconfig.url}${csplus.url}`
  });
  return rawBaseQuery(args, api, extraOptions);
};
