import { CSPlusHostConfig } from '../../src/config/configTypes';
import { ENVIRONMENTS, MARKETS } from '../../src/global/constants';

type MarketConfig = {
  [market in MARKETS]: CSPlusHostConfig;
};

export const MARKET_CONFIGS: MarketConfig = {
  la: {
    host: 'localhost',
    hostEnv: 'dev',
    salesOrg: '1010',
    country: 'LA',
    phonePrefix: '+856',
    recaptchaKey: '6Ld0Y98oAAAAAKT1--kkiWjCwOnTja07CZ7Gbcrl',
    marketContacts: {
      email: '',
      phone: ''
    },
    locales: {
      default: 'lo-LA',
      available: [
        {
          isoCode: 'lo-LA',
          name: 'ລາວ',
          flag: 'la'
        },
        {
          isoCode: 'en-GB',
          name: 'English',
          flag: 'gb'
        }
      ]
    },
    currency: {
      code: 'LAK',
      symbol: '₭'
    },
    VATInformation: {
      showVATInformation: true,
      transactionTax: 0.1,
      isVATIncludedInPrice: true
    },
    featureFlags: {
      enableRewards: true,
      enableFAQs: true,
      isDirectMarket: false,
      orderingOutOfStock: false,
      orderingNoStockInformation: false,
      showAlternativeProducts: true,
      showDiscover: true,
      canCheckoutWithEmptiesOnly: false,
      hideDepositLabels: false,
      showDepositFeeDescription: false,
      hideCutoffTime: false,
      hideAddNewAddress: false,
      showFavourites: true,
      showPayments: true,
      showEmpties: false,
      showDocumentsPresenceOnCheckout: false,
      showNoStockInfo: true,
      showLoyalty: true,
      showDisclaimerOnCheckout: false,
      showVouchers: {
        showModalList: true,
        showInputOnly: false
      },
      showPrizeLottery: false,
      showPromotionPage: true,
      showRecommendationsUponCheckout: true,
      showBundlesOnPDP: true,
      enableNPS: {
        showLocalFeedbackNPS: true,
        showHotJarNPS: false
      }
    },
    translationVersion: 'latest',
    compliancePackProjectId: '0bd16879-02b0-4567-822b-64c840ab7986',
    enterspeedApiKey: 'environment-bf4a187b-c435-4115-9851-991fc637ad1f',
    seoPageMetaData: {
      loginPage: {
        title: 'Login – Order Beerlao Online',
        description:
          'Now you can order all your favourite products on your smartphone or computer within minutes. Brands available are Beerlao, Carlsberg, Kronenbourg Blanc, Mineral Drinking Water, Mirinda, Pepsi, Revive Isotonic, Seven Up, Soda Lao Tigerghead, Somersby, Sting, Tigerhead Drinking Water and Tuborg'
      }
    }
  },
  ru: {
    host: 'localhost',
    hostEnv: 'dev',
    salesOrg: '2040',
    country: 'RU',
    phonePrefix: '+7',
    recaptchaKey: '6Ld0Y98oAAAAAKT1--kkiWjCwOnTja07CZ7Gbcrl',
    marketContacts: {},
    locales: {
      default: 'ru-RU',
      available: [
        {
          isoCode: 'ru-RU',
          name: 'Русский',
          flag: 'ru'
        },
        {
          isoCode: 'en-GB',
          name: 'English',
          flag: 'gb'
        }
      ]
    },
    currency: {
      code: 'RUB',
      symbol: '₽'
    },
    VATInformation: {
      showVATInformation: true,
      transactionTax: 0.21,
      isVATIncludedInPrice: true
    },
    featureFlags: {
      enableRewards: false,
      enableFAQs: true,
      isDirectMarket: false,
      orderingOutOfStock: true,
      orderingNoStockInformation: false,
      showAlternativeProducts: true,
      showDiscover: true,
      canCheckoutWithEmptiesOnly: true,
      hideDepositLabels: true,
      showDepositFeeDescription: false,
      hideCutoffTime: false,
      hideAddNewAddress: true,
      showFavourites: true,
      showPayments: true,
      showEmpties: true,
      showDocumentsPresenceOnCheckout: true,
      showNoStockInfo: true,
      showLoyalty: false,
      showDisclaimerOnCheckout: false,
      showVouchers: {
        showModalList: false,
        showInputOnly: true
      },
      showPrizeLottery: false,
      showPromotionPage: true,
      showRecommendationsUponCheckout: false,
      showBundlesOnPDP: true,
      enableNPS: {
        showLocalFeedbackNPS: true,
        showHotJarNPS: false
      }
    },
    translationVersion: 'latest',
    compliancePackProjectId: '7167fc92-04d7-477e-9958-57fd34b662b4',
    enterspeedApiKey: 'environment-caa33fed-fe36-40ab-9356-10d3a6e26e03'
  },
  my: {
    host: 'localhost',
    hostEnv: 'dev',
    salesOrg: '1020',
    country: 'MY',
    phonePrefix: '+60',
    recaptchaKey: '6Ld0Y98oAAAAAKT1--kkiWjCwOnTja07CZ7Gbcrl',
    marketContacts: {
      email: 'test@email.com',
      phone: '+60 111 111 111'
    },
    locales: {
      default: 'zh-CN',
      available: [
        {
          isoCode: 'zh-CN',
          name: 'Chinese',
          flag: 'my'
        },
        {
          isoCode: 'en-GB',
          name: 'English',
          flag: 'gb'
        }
      ]
    },
    currency: {
      code: 'MYR',
      symbol: 'RM'
    },
    VATInformation: {
      showVATInformation: false,
      transactionTax: 0,
      isVATIncludedInPrice: true
    },
    featureFlags: {
      enableRewards: false,
      enableFAQs: true,
      isDirectMarket: false,
      orderingOutOfStock: false,
      orderingNoStockInformation: false,
      showAlternativeProducts: true,
      showDiscover: true,
      canCheckoutWithEmptiesOnly: true,
      hideDepositLabels: true,
      showDepositFeeDescription: false,
      hideCutoffTime: true,
      hideAddNewAddress: true,
      showFavourites: true,
      showPayments: false,
      showEmpties: true,
      showDocumentsPresenceOnCheckout: false,
      showNoStockInfo: true,
      showLoyalty: false,
      showDisclaimerOnCheckout: true,
      showVouchers: {
        showModalList: false,
        showInputOnly: true
      },
      showPrizeLottery: false,
      showPromotionPage: false,
      showRecommendationsUponCheckout: false,
      showBundlesOnPDP: true,
      enableNPS: {
        showLocalFeedbackNPS: true,
        showHotJarNPS: false
      }
    },
    translationVersion: 'latest',
    compliancePackProjectId: '2bf3fa07-4efc-44ba-9c3c-c5f0c4a82091',
    enterspeedApiKey: 'environment-3db90449-e37d-4f3c-aa33-256c5fdc2b2f',
    seoPageMetaData: {
      loginPage: {
        title: 'Login – Order Carlsberg Malaysia Online',
        description:
          'Now you can order all available products from Malaysia online by using your smartphone or computer within minutes. Brands available are Carlsberg, Asahi, Brooklyn, Connors, Cylinder,Green Label, Kronenbourg Blanc,Nutrimalt, Royal Stout,Skol, Smooth Draught, Somersby, Special Brew.'
      }
    }
  },
  lt: {
    host: 'localhost',
    hostEnv: 'dev',
    salesOrg: 'LT01',
    country: 'LT',
    phonePrefix: '+370',
    recaptchaKey: '6Ld0Y98oAAAAAKT1--kkiWjCwOnTja07CZ7Gbcrl',
    marketContacts: {
      email: 'test@email.com',
      phone: '+370 111 111 111'
    },
    locales: {
      default: 'en-GB',
      available: [
        {
          isoCode: 'en-GB',
          name: 'English',
          flag: 'gb'
        },
        {
          isoCode: 'lt-LT',
          name: 'Lithuanian',
          flag: 'lt'
        }
      ]
    },
    currency: {
      code: 'EUR',
      symbol: '€'
    },
    VATInformation: {
      showVATInformation: true,
      transactionTax: 0.21,
      isVATIncludedInPrice: false
    },
    featureFlags: {
      enableRewards: false,
      enableFAQs: true,
      isDirectMarket: true,
      orderingOutOfStock: false,
      orderingNoStockInformation: false,
      showAlternativeProducts: true,
      showDiscover: true,
      canCheckoutWithEmptiesOnly: true,
      hideDepositLabels: true,
      showDepositFeeDescription: true,
      hideCutoffTime: true,
      hideAddNewAddress: true,
      showFavourites: true,
      showPayments: true,
      showEmpties: true,
      showDocumentsPresenceOnCheckout: true,
      showNoStockInfo: true,
      showLoyalty: false,
      showDisclaimerOnCheckout: false,
      showVouchers: {
        showModalList: false,
        showInputOnly: true
      },
      showPrizeLottery: false,
      showPromotionPage: false,
      showRecommendationsUponCheckout: false,
      showBundlesOnPDP: true,
      enableNPS: {
        showLocalFeedbackNPS: true,
        showHotJarNPS: false
      }
    },
    translationVersion: 'latest',
    compliancePackProjectId: 'cebc37da-7feb-4845-a7ce-463dcfe737d6',
    enterspeedApiKey: 'environment-8e7a01c5-65a8-41f7-b6de-9d9feebd0f24',
    seoPageMetaData: {
      loginPage: {
        title: 'Login - Order Švyturys – Utenos Alus',
        description:
          'Švyturys Utenos Alus beer online ordering platform, order online quickly and conveniently! Anywhere and anytime! Now you can order all products of Švyturys by smartphone or computer, it will take just a few minutes Brands available are Battery, Brooklyn, Carlsberg, Dlight, Grimbergen, Kro 1664, Kvass, POS Carlsberg, POS Grimbergen, POS Kro 1664, POS NAB GO, POS Other, POS R.Plytos, POS Somersby, POS Svyturys, POS Vichy, Raudonu Plytu, Somersby, Super Manki, Svyturys, Svyturys non alcohol, Tuborg, Utenos, Vichy, Vichy Fresh'
      }
    }
  },
  lv: {
    host: 'localhost',
    hostEnv: 'dev',
    salesOrg: 'LV01',
    country: 'LV',
    phonePrefix: '+371',
    recaptchaKey: '6Ld0Y98oAAAAAKT1--kkiWjCwOnTja07CZ7Gbcrl',
    marketContacts: {
      email: 'test@email.com',
      phone: '+371 111 111 111'
    },
    locales: {
      default: 'lv-LV',
      available: [
        {
          isoCode: 'lv-LV',
          name: 'Latviski',
          flag: 'lv'
        },
        {
          isoCode: 'en-GB',
          name: 'English',
          flag: 'gb'
        }
      ]
    },
    currency: {
      code: 'EUR',
      symbol: '€'
    },
    VATInformation: {
      showVATInformation: true,
      transactionTax: 0.21,
      isVATIncludedInPrice: true
    },
    featureFlags: {
      enableRewards: false,
      isDirectMarket: false,
      enableFAQs: true,
      orderingOutOfStock: true,
      orderingNoStockInformation: true,
      showAlternativeProducts: true,
      showDiscover: true,
      canCheckoutWithEmptiesOnly: true,
      hideDepositLabels: true,
      showDepositFeeDescription: false,
      hideCutoffTime: false,
      hideAddNewAddress: true,
      showFavourites: true,
      showPayments: true,
      showEmpties: true,
      showDocumentsPresenceOnCheckout: false,
      showNoStockInfo: true,
      showLoyalty: false,
      showDisclaimerOnCheckout: true,
      showVouchers: {
        showModalList: false,
        showInputOnly: true
      },
      showPrizeLottery: false,
      showPromotionPage: false,
      showRecommendationsUponCheckout: false,
      showBundlesOnPDP: true,
      enableNPS: {
        showLocalFeedbackNPS: true,
        showHotJarNPS: false
      }
    },
    translationVersion: 'latest',
    compliancePackProjectId: '29c0ed55-cd9e-40ee-958d-14da548020f3',
    enterspeedApiKey: 'environment-418394b1-accc-4044-a2a4-fe56b6f83c53',
    seoPageMetaData: {
      loginPage: {
        title: 'Login - Order A/S Aldaris beers Online',
        description: 'Order all A/S Aldaris products online using your smartphone or computer in a few minutes!'
      }
    }
  },
  ee: {
    host: 'localhost',
    hostEnv: 'dev',
    salesOrg: 'EE01',
    country: 'EE',
    phonePrefix: '+372',
    recaptchaKey: '6Ld0Y98oAAAAAKT1--kkiWjCwOnTja07CZ7Gbcrl',
    marketContacts: {
      email: 'test@email.com',
      phone: '+372 111 111 111'
    },
    locales: {
      default: 'et-EE',
      available: [
        {
          isoCode: 'et-EE',
          name: 'Eesti Keel',
          flag: 'ee'
        },
        {
          isoCode: 'en-GB',
          name: 'English',
          flag: 'gb'
        }
      ]
    },
    currency: {
      code: 'EUR',
      symbol: '€'
    },
    VATInformation: {
      showVATInformation: true,
      transactionTax: 0.22,
      isVATIncludedInPrice: false
    },
    featureFlags: {
      enableRewards: false,
      isDirectMarket: true,
      enableFAQs: true,
      orderingOutOfStock: true,
      orderingNoStockInformation: true,
      showAlternativeProducts: true,
      showDiscover: true,
      canCheckoutWithEmptiesOnly: true,
      hideDepositLabels: true,
      showDepositFeeDescription: false,
      hideCutoffTime: false,
      hideAddNewAddress: true,
      showFavourites: true,
      showPayments: true,
      showEmpties: true,
      showDocumentsPresenceOnCheckout: false,
      showNoStockInfo: true,
      showLoyalty: true,
      showDisclaimerOnCheckout: true,
      showVouchers: {
        showModalList: false,
        showInputOnly: true
      },
      showPrizeLottery: false,
      showPromotionPage: false,
      showRecommendationsUponCheckout: false,
      showBundlesOnPDP: true,
      enableNPS: {
        showLocalFeedbackNPS: true,
        showHotJarNPS: false
      }
    },
    translationVersion: 'latest',
    compliancePackProjectId: '73d79894-5677-4172-a378-0bb9e536515c',
    enterspeedApiKey: 'environment-6e9b27ad-0c00-4e57-9156-64aca1657f35',
    seoPageMetaData: {
      loginPage: {
        title: 'Login - Order Saku Õlletehase AS beers online',
        description:
          'Now you can order Saku Õlletehase AS products online in just a few minutes using your smartphone or computer.'
      }
    }
  }
};

type EnvMapping = {
  [env in ENVIRONMENTS]: string;
};

const ENV_MAPPING: EnvMapping = {
  dev: 'development',
  tst: 'test'
};

export default (market: MARKETS, env = ENVIRONMENTS.dev) => ({
  version: 1,
  data: {
    environment: ENV_MAPPING[env] || ENV_MAPPING[ENVIRONMENTS.dev],
    url: '/csplus',
    'e.identity': '/e-identity',
    configs: [
      {
        ...(MARKET_CONFIGS[market] || MARKET_CONFIGS.la),
        // while running tests this might be undefined
        ...(window && window.location && window.location.hostname ? { host: window.location.hostname } : {})
      }
    ]
  }
});
