import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { RefObject, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAddToCartNotification } from '../../../../global/custom-hooks/useAddToCartNotification';
import { useHandleOutsideClick } from '../../../../global/custom-hooks/useHandleOutsideClick';
import { useHeaderNavLinks } from '../../../../global/custom-hooks/useHeaderNavLinks';
import { useMenuClick } from '../../../../global/custom-hooks/useMenuClick';
import { MenuType } from '../../../../global/google-analytics/tracking';
import { FloatTooltip } from '../../../shared/FloatTooltip';
import { Languages } from '../../../shared/Languages';
import { ProductsTooltip } from '../../../shared/ProductsTooltip';
import styles from '../../header.module.scss';
import { CartCountBadge } from '../CartCountBadge';
import { DropdownHeader } from '../DropdownHeader';

interface NavHeaderProps {
  onLogout: () => void;
}

const NavHeader = ({ onLogout }: NavHeaderProps) => {
  const dropdownRef: RefObject<HTMLDivElement> = useRef();
  const [isCustomerDropdownOpen, toggleCustomerDropdown] = useState(false);
  const { pathname } = useLocation();
  const { handleToggleCart, isCartOpen, handleOpenSearchModal, trackMenuClick, handleOpenDrawerMenu } = useMenuClick();
  const { links, isHeaderDesktopVersion } = useHeaderNavLinks();
  const {
    isToggleTooltip,
    setIsToggleTooltip,
    addingProductsNotification,
    tooltipProductsData
  } = useAddToCartNotification();

  const handleToggleCustomerDropdown = () => {
    toggleCustomerDropdown(!isCustomerDropdownOpen);
  };

  useHandleOutsideClick(dropdownRef, () => {
    toggleCustomerDropdown(false);
  });

  return (
    <>
      {isHeaderDesktopVersion ? (
        <nav className={styles.headerLinks}>
          <ul className={styles.links}>
            {links.map(({ title, path, isPathVisible, dataTestId }) => {
              const isActive = pathname?.includes(path) || (path === '/catalog' && pathname.includes('/products'));
              return (
                isPathVisible && (
                  <li key={path} className={styles.linkItem}>
                    <NavLink
                      data-testid={`header-link-${dataTestId}`}
                      className={cn({ [styles.activeLink]: isActive })}
                      to={{ pathname: path, state: { from: pathname } }}
                      onClick={() => trackMenuClick(MenuType.Text, title)}
                    >
                      {title}
                    </NavLink>
                  </li>
                )
              );
            })}
          </ul>
        </nav>
      ) : null}

      <div className={styles.menu} style={{ gridColumn: isHeaderDesktopVersion ? 3 : 2 }}>
        <div className={styles.subMenu}>
          <div data-testid="search-icon" className={styles.element} onClick={handleOpenSearchModal}>
            <Icon name={IconName.Search} color={IconColor.Primary} size={IconSize.Medium} />
          </div>
          <div className={styles.element}>
            <Languages
              hasFlag
              isAbsolute={false}
              shouldClose={isCustomerDropdownOpen}
              hideLabel
              onMenuClick={trackMenuClick}
            />
          </div>
          {isHeaderDesktopVersion ? (
            <div
              data-testid="link-customer"
              ref={dropdownRef}
              className={styles.element}
              onClick={handleToggleCustomerDropdown}
            >
              <Icon name={IconName.Customer} color={IconColor.Primary} size={IconSize.Medium} />
              <div className={styles.account}>
                <DropdownHeader
                  isCustomerDropdownOpen={isCustomerDropdownOpen}
                  onLogout={onLogout}
                  onMenuClick={trackMenuClick}
                />
              </div>
            </div>
          ) : null}
          <FloatTooltip.Root
            open={isToggleTooltip}
            onOpenChange={() => setIsToggleTooltip(!isToggleTooltip)}
            placement="bottom-end"
          >
            <FloatTooltip.Content className={styles.floatTooltipContentDesktop}>
              <ProductsTooltip.Root>
                <ProductsTooltip.List productsTooltip={tooltipProductsData} />
                <ProductsTooltip.Limited productsTooltip={tooltipProductsData} />
                <ProductsTooltip.Fetching addingProductsNotification={addingProductsNotification} />
              </ProductsTooltip.Root>
            </FloatTooltip.Content>
            <FloatTooltip.Trigger>
              <div data-testid="link-cart" onClick={() => handleToggleCart(!isCartOpen)} className={styles.element}>
                <Icon name={IconName.Cart} color={IconColor.Primary} size={IconSize.Medium} />
                <CartCountBadge />
              </div>
            </FloatTooltip.Trigger>
          </FloatTooltip.Root>
        </div>

        {!isHeaderDesktopVersion ? (
          <div onClick={handleOpenDrawerMenu} className={cn(styles.element, styles.hamburgerMenu)}>
            <Icon name={IconName.Menu} color={IconColor.Primary} size={IconSize.Medium} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export { NavHeader };
