import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { QueryParams, UserConfigPayload } from './types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseApiQuery,
  tagTypes: ['USERCONFIG'],
  endpoints: (builder) => ({
    getUserConfig: builder.query<UserConfigPayload, QueryParams>({
      query: ({ salesOrg }: QueryParams) => ({
        url: apiEndpoints.USER_CONFIG,
        params: { salesOrg }
      }),
      providesTags: ['USERCONFIG']
    })
  })
});

export const { useGetUserConfigQuery } = userApi;
