import { Product } from '../../interfaces/products';
import { GAEvent, PageType, Platform, TrackingCancelOrder } from '../tracking';
import { gaClientId, isPWA, mapProductsItems } from '../utils';

interface CancelOrderTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products: Product[];
  pageType: PageType;
  listName: string;
  transactionId: string;
  value: number;
  tax: number;
}

export const CancelOrderTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  pageType,
  listName,
  transactionId,
  value,
  tax
}: CancelOrderTrackingProps): TrackingCancelOrder => ({
  dataLayer: {
    event: GAEvent.CancelOrder,
    event_name: GAEvent.CancelOrder,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      items: mapProductsItems({ products, currency, listName, quantity: 1 }),
      transaction_id: transactionId,
      currency,
      value,
      tax
    }
  }
});
