import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { AddressesPayload, QueryParams } from './types';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseApiQuery,
  tagTypes: ['ADDRESSES'],
  endpoints: (builder) => ({
    getAddresses: builder.query<AddressesPayload, QueryParams>({
      query: ({ eid, salesOrg }: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ADDRESSES, { eid }),
        params: { salesOrg }
      }),
      providesTags: ['ADDRESSES']
    }),
    createAddress: builder.mutation<void, QueryParams>({
      query: ({ eid, salesOrg, body }: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ADDRESSES, {
          eid
        }),
        params: { salesOrg },
        method: 'POST',
        body: JSON.stringify(body)
      }),
      invalidatesTags: ['ADDRESSES']
    }),
    updateAddress: builder.mutation<void, QueryParams>({
      query: ({ eid, deliveryEid, salesOrg, body }: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ADDRESSES_DELIVERY_EID, {
          eid,
          deliveryEid
        }),
        params: { salesOrg },
        method: 'POST',
        body: JSON.stringify(body)
      }),
      invalidatesTags: ['ADDRESSES']
    }),
    deleteAddress: builder.mutation<void, QueryParams>({
      query: ({ eid, deliveryEid, salesOrg }) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ADDRESSES_DELIVERY_EID, {
          eid,
          deliveryEid
        }),
        method: 'DELETE',
        params: {
          salesOrg
        }
      }),
      invalidatesTags: ['ADDRESSES']
    })
  })
});

export const {
  useGetAddressesQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation
} = profileApi;
