import cn from 'classnames';
import React from 'react';
import styles from './overlay.module.scss';

interface Props {
  active: boolean;
  action: () => void;
  transparent?: boolean;
  zIndex?: number;
}

const Overlay: React.FC<Props> = ({ action, active, transparent, zIndex }: Props) => (
  <div
    style={{ zIndex }}
    data-testid="overlay"
    className={cn(styles.overlay, {
      [styles.open]: active,
      [styles.transparent]: transparent
    })}
    onClick={action}
  />
);

export { Overlay };
