import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../config/getBaseUrl';
import { baseHeadlessApiQuery } from '../baseHeadlessApiQuery';
import { IArticleResponse, ICategoriesArticleResponse, SingleArticleResponse } from '../discover/types';
import {
  EnterspeedPages,
  HandleQueryParams,
  HeadlessConfig,
  HeadlessDataByPagePayload,
  HeadlessLoginConfig,
  PageQueryParams
} from './types';

const handleBuilder = ({ handle, languageCode, salesOrg }: HandleQueryParams) => {
  if (!salesOrg) {
    return `${handle}$culture=${languageCode.toLowerCase()}`;
  }
  return `${handle}$culture=${languageCode.toLowerCase()}$salesOrg=${salesOrg}`;
};

const buildUrlParam = ({ url, languageCode }: PageQueryParams) => {
  // Currently umbraco does not have a tst api environment.
  // This will make the api to point dev when we are on carlshop tst
  const baseUrl = getBaseUrl().replace(/.tst./i, '.dev.');
  return `${baseUrl}/${languageCode.toLocaleLowerCase()}${decodeURI(url)}`;
};

export const headlessApi = createApi({
  reducerPath: 'headlessApi',
  baseQuery: baseHeadlessApiQuery,
  tagTypes: ['HEADLESS_PAGE'],
  endpoints: (builder) => ({
    getHeadlessDataByPageHandle: builder.query<HeadlessDataByPagePayload, HandleQueryParams>({
      query: (queryParams: HandleQueryParams) => ({
        url: '',
        contentDelivery: queryParams.contentDelivery,
        targetGroups: queryParams.targetGroups,
        params: {
          handle: handleBuilder({ ...queryParams })
        }
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.meta?.status && data?.meta?.status !== 200) {
            console.error('getHeadlessDataByPageHandle', data);
          }
        } catch (_err) {
          // do nothing
        }
      },
      providesTags: ['HEADLESS_PAGE']
    }),
    getHeadlessConfig: builder.query<HeadlessConfig, PageQueryParams>({
      query: (params: PageQueryParams) => ({
        url: '',
        params: { url: buildUrlParam(params) }
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.meta?.status && data?.meta?.status !== 200) {
            console.error('getHeadlessConfig', data);
          }
        } catch (_err) {
          // do nothing
        }
      }
    }),
    getHeadlessLoginConfig: builder.query<HeadlessLoginConfig, PageQueryParams>({
      query: (params: PageQueryParams) => ({
        url: '',
        params: { url: buildUrlParam({ url: EnterspeedPages.LOGIN, ...params }) }
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.meta?.status && data?.meta?.status !== 200) {
            console.error('getHeadlessConfig', data);
          }
        } catch (_err) {
          // do nothing
        }
      }
    }),
    getAllDiscoveryCategories: builder.mutation<IArticleResponse, HandleQueryParams>({
      query: ({ sort, ...queryParams }: HandleQueryParams) => ({
        url: '',
        contentDelivery: true,
        method: 'POST',
        params: {
          handle: handleBuilder({ ...queryParams })
        },
        body: { sort }
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.meta?.status && data?.meta?.status !== 200) {
            console.error('getHeadlessErrorPage', data);
          }
        } catch (_err) {
          // do nothing
        }
      }
    }),
    getArticlesByCategoryId: builder.mutation<ICategoriesArticleResponse, HandleQueryParams>({
      query: ({ handle, id, sort }: HandleQueryParams) => ({
        url: '',
        contentDelivery: true,
        method: 'POST',
        params: {
          handle: `${handle}$id=${id}`
        },
        body: { sort }
      }),
      async onQueryStarted(_arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.meta?.status && data?.meta?.status !== 200) {
            console.error('getHeadlessErrorPage', data);
          }
        } catch (_err) {
          // do nothing
        }
      }
    }),
    getArticle: builder.query<SingleArticleResponse, HandleQueryParams>({
      query: ({ handle, id, contentDelivery }: HandleQueryParams) => ({
        url: '',
        contentDelivery,
        params: {
          handle: `${handle}$id=${id}`
        }
      })
    })
  })
});

export const {
  useGetHeadlessDataByPageHandleQuery,
  useGetHeadlessConfigQuery,
  useGetHeadlessLoginConfigQuery,
  useGetAllDiscoveryCategoriesMutation,
  useGetArticlesByCategoryIdMutation,
  useGetArticleQuery
} = headlessApi;
