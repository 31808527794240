import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Link } from '@carlsberggroup/malty.atoms.link';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import styles from './distributor.module.scss';

interface Props {
  clickHandler: () => void;
  content: string | ReactNode;
  dataTestIdButton?: string;
  dataTestIdButtonText?: string;
  icon: IconName;
}

export const DistributorNavBarButton: React.FC<Props> = ({
  clickHandler,
  content,
  dataTestIdButton,
  dataTestIdButtonText,
  icon
}) => (
  <div className={cn(styles.distributorNavBarButton)}>
    <Link onClick={clickHandler} dataTestId={dataTestIdButton}>
      <div className={cn(styles.distributorNavBarTextWrapper)}>
        <div className={styles.distributorNavBarIconWrapper}>
          <Icon name={icon} color={IconColor.DigitalBlack} size={IconSize.Medium} />
        </div>
        <Text textStyle={TextStyle.MediumSmallDefault} dataQaId={dataTestIdButtonText} ellipsis>
          {content as string}
        </Text>
      </div>
    </Link>
  </div>
);
