enum apiEndpoints {
  // APP INFO
  APP_MAINTENANCE_INFO = '/app-info',

  // ANNOUNCEMENTS
  ANNOUNCEMENTS = '/api/announcements/display',
  ANNOUNCEMENTS_DISMISS = 'api/announcements/dismiss',

  // CATALOG
  CATALOG = '/api/catalog',

  // CART
  CARTS = '/api/carts',
  CARTS_EID_ITEMS = '/api/carts/%eid%/items',
  CARTS_EID_VOUCHER = '/api/carts/%eid%/vouchers/%voucherId%',
  CARTS_EID = '/api/carts/%eid%',
  CARTS_EID_ITEMS_SKU = '/api/carts/%eid%/items/%sku%',
  CARTS_REORDER = '/api/carts/%salesOrg%/reorder',
  CARTS_BULK_INSERT = '/api/carts/v1/%salesOrg%/carts/%eid%/bulk/upsert',

  // CALCULATION
  CALCULATE_PRICE = '/api/calculation/bundle?salesOrg=%salesOrg%&outletId=%outletId%&distributorId=%distributorId%',

  // FILTERS
  FILTERS = '/api/filters',

  // PRODUCTS
  PRODUCTS_BASE_SKU = '/api/products/%baseSku%',

  // RECOMMENDATIONS
  RECOMMENDATIONS = '/api/recommendations',

  // LOYALTY
  LOYALTY_BALANCE = '/api/loyalty/balance',

  // OUTLETS
  OUTLETS = '/api/outlets',
  OUTLETS_EID_ADDRESSES = '/api/outlets/%eid%/addresses',
  OUTLETS_EID_ADDRESSES_DELIVERY_EID = '/api/outlets/%eid%/addresses/%deliveryEid%',
  OUTLETS_EID_ORDERS = '/api/outlets/%eid%/orders',
  OUTLETS_EID_DELIVERY_DATES = '/api/outlets/%eid%/deliveryDates',
  OUTLETS_EID_ORDERS_ID = '/api/outlets/%eid%/orders/%id%',
  OUTLETS_ORDER_ORDER_ID_FEEDBACK_SALES_ORG = '/api/outlets/order/%orderId%/feedback?salesOrg=%salesOrg%',
  OUTLETS_EID_CANCEL_ORDER = '/api/outlets/%eid%/orders/%id%',

  // PROMOTIONS
  PROMOTIONS = '/api/v1/%salesOrg%/promotions',
  PROMOTION_DETAILS = '/api/v1/%salesOrg%/promotions/%promotionCode%?outletId=%outletId%&distributorId=%distributorId%',

  // VOUCHERS
  VOUCHERS_LIST = '/api/v1/%salesOrg%/outlet/%outletId%/vouchers',

  // USER
  RESET_PASSWORD = '/api/pub/password-reset',
  VALIDATION = '/api/pub/user-signup',
  USER_CONFIG = '/api/user/config',
  USER = '/api/user',

  // FAVOURITES PAGE
  CREATE_FAVOURITES_LIST = '/api/products/v1/%salesOrg%/outlet/%outletId%/favourites/create',
  FAVOURITES_LIST = '/api/products/v1/%salesOrg%/outlet/%outletId%/favourites',
  FAVOURITES_LIST_PRODUCTS = '/api/products/v1/%salesOrg%/outlet/%outletId%/favourites/%favListId%',

  // FAVOURITE LIST
  ASSOCIATED_FAVOURITE_LIST = '/api/products/v1/%salesOrg%/outlet/%outletId%/favourites/items/%sku%',

  // MILESTONES LIST
  MILESTONES_LIST = '/api/v1/%salesOrg%/outlet/%outletId%/milestones'
}

enum clerkIoEndpoints {
  LOG_EMAIL = '/log/email',
  LOG_PRODUCT = '/log/product',
  LOG_SALE = '/log/sale',
  LOG_CLICK = '/log/click'
}

const makeApiUrl = (endpoint: string, replaceParams: { [key: string]: string | number }): string => {
  let replacedEndpoint = endpoint;
  const paramsKeysArray = Object.keys(replaceParams);

  paramsKeysArray.forEach((param) => {
    const paramValue = replaceParams[param];
    replacedEndpoint = replacedEndpoint.replace(`%${param}%`, paramValue?.toString());
  });
  return replacedEndpoint;
};

export { apiEndpoints, clerkIoEndpoints, makeApiUrl };
