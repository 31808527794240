import { intervalToDuration } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

interface UseCountDownRemainingTimeProps {
  countDowFullDate: string;
}

const convertCountDownToHours = (countDown: number) =>
  Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
const convertCountDownToMinutes = (countDown: number) => Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
const convertCountDownToSeconds = (countDown: number) => Math.floor((countDown % (1000 * 60)) / 1000);

const getIntervalValue = (countDown: number) => {
  if (convertCountDownToHours(countDown) > 1) {
    return 60 * 60 * 1000;
  }
  if (convertCountDownToMinutes(countDown) > 1) {
    return 60 * 1000;
  }
  return 1000;
};

const useCountDownRemainingTime = ({ countDowFullDate }: UseCountDownRemainingTimeProps) => {
  const countDownDate = new Date(`${countDowFullDate}Z`).getTime();
  const countDownInterval = useRef(null);
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());
  const isRemainingTimeExpired = countDown < 0;

  useEffect(() => {
    if (countDown > 0) {
      countDownInterval.current = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, getIntervalValue(countDown));
    }

    return () => clearInterval(countDownInterval.current);
  }, [countDownDate, countDown]);

  const remainingTimeOfDates = intervalToDuration({
    start: new Date(),
    end: new Date(countDowFullDate)
  });

  const isRemainingTimeLowerThanHours = (hours: number) =>
    remainingTimeOfDates.years === 0 &&
    remainingTimeOfDates.months === 0 &&
    remainingTimeOfDates.days === 0 &&
    remainingTimeOfDates.hours < hours;

  const countDownRemainingTimeInHours = convertCountDownToHours(countDown);
  const countDownRemainingTimeInMinutes = convertCountDownToMinutes(countDown);
  const countDownRemainingTimeInSeconds = convertCountDownToSeconds(countDown);

  return {
    isRemainingTimeExpired,
    isRemainingTimeLowerThanHours,
    countDownRemainingTimeInHours,
    countDownRemainingTimeInMinutes,
    countDownRemainingTimeInSeconds,
    countDownInterval
  };
};

export { useCountDownRemainingTime };
