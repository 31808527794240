import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import React from 'react';
import styles from './switchToggle.module.scss';

interface Props {
  itemId: string;
  isChecked: boolean;
  onChange: () => void;
}

export const SwitchToggle: React.FC<Props> = ({ itemId, isChecked = false, onChange }) => {
  const { ToggleOn, ToggleOff } = IconsPaths;

  return (
    <div className={styles.switch}>
      {isChecked ? (
        <ToggleOn color={IconColor.Primary} size={IconSize.Medium} />
      ) : (
        <ToggleOff color={IconColor.Primary} size={IconSize.Medium} />
      )}
      <input
        aria-label="switch-item"
        id={itemId}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        data-testid={`switch-item-${itemId}`}
      />
    </div>
  );
};
