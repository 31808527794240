import { CART_SECTION_TYPES } from '../../../global/constants';
import { Image, ProductWithVariants } from '../../../global/interfaces/products';

export enum ProductImageBoxSize {
  XXSMALL = 'xxsmall', // Tooltip
  XSMALL = 'xsmall', // Search bar
  SMALL = 'small', // Cart / Checkout / Order Details / Alternatives
  MEDIUM = 'medium', // Home / Catalog
  LARGE = 'large', // Product Detail,
  FIT_LAYOUT = 'fitLayout'
}

export enum ProductImageBoxLength {
  XXSMALL = 42,
  XSMALL = 68,
  SMALL = 70,
  MEDIUM = 200,
  LARGE = 300
}

export interface ProductImageBoxProps {
  dataTestId?: string;
  boxSize?: ProductImageBoxSize;
  images?: Image[];
  boxLength: ProductImageBoxLength;
  cartSection?: CART_SECTION_TYPES;
  hasTransparentBackground?: boolean;
  renderPillCounterFromCart?: boolean;
  renderPillForPromotions?: boolean;
  renderIconFavourites?: boolean;
  productWithVariants?: ProductWithVariants;
  isProductInFavouriteList?: boolean;
  handleNavigateToProductPage?: () => void;
  setIsFavouriteModalOpen?: (isFavouriteModalOpen?: boolean) => void;
  fitLayout?: ProductImageBoxSize;
}
