export enum DismissibleStatus {
  DISMISSIBLE = 'DISMISSIBLE',
  NOT_DISMISSIBLE = 'NOT_DISMISSIBLE',
  SESSION = 'SESSION'
}

export enum SeverityStatus {
  INFO = 'INFO',
  WARN = 'WARN',
  CRITICAL = 'CRITICAL',
  DEBT = 'DEBT'
}

export const ANNOUNCEMENTS_LOCAL_STORAGE_KEY = 'hideAnnouncementsBanner';
