import cn from 'classnames';
import React from 'react';
import { Loading, Props as LoadingProps } from '../Loading';
import styles from './loadingContainer.module.scss';

export type Props = Exclude<LoadingProps, 'fullScreen'> & {
  className?: string;
  absolute?: boolean;
};

const LoadingContainer: React.FC<Props> = ({ className, absolute, ...rest }: Props) => (
  <div className={cn(styles.container, className, { [styles.absolute]: absolute })}>
    <Loading {...rest} />
  </div>
);

export { LoadingContainer };
