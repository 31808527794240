import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { ProductWithVariants } from '../../global/interfaces/products';
import { baseApiQuery } from '../baseApiQuery';
import { favouritesApi } from '../favourites/favourites';
import {
  CatalogPayload,
  CatalogQueryParams,
  ProductQueryParams,
  RecommendedPayload,
  RecommendedQueryParams
} from './types';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: baseApiQuery,
  tagTypes: ['PRODUCT', 'PRODUCTS'],
  endpoints: (builder) => ({
    getProduct: builder.query<ProductWithVariants, ProductQueryParams>({
      query: ({ baseSku, ...params }: ProductQueryParams) => ({
        url: makeApiUrl(apiEndpoints.PRODUCTS_BASE_SKU, { baseSku }),
        params
      }),
      providesTags: ['PRODUCT']
    }),
    getCatalogProducts: builder.query<CatalogPayload, CatalogQueryParams | string>({
      query: (params: CatalogQueryParams) => ({
        url: apiEndpoints.CATALOG,
        params
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(
          favouritesApi.util.invalidateTags(['ASSOCIATED_FAVOURITE_LIST', 'FAVOURITES_LIST', 'FAVOURITE_LIST_PRODUCTS'])
        );
      },
      providesTags: ['PRODUCTS']
    }),
    getRecommendedProducts: builder.query<RecommendedPayload, RecommendedQueryParams>({
      query: (params: RecommendedQueryParams) => ({
        url: apiEndpoints.RECOMMENDATIONS,
        params
      }),
      providesTags: ['PRODUCTS']
    })
  })
});

export const {
  useGetProductQuery,
  useGetCatalogProductsQuery,
  useLazyGetCatalogProductsQuery,
  useGetRecommendedProductsQuery
} = productsApi;
