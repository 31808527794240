import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { defaultImage } from '@cloudinary/url-gen/actions/delivery';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../global/contexts/ConfigContext';
import { Image } from '../../../../../global/interfaces/products';
import { cloudinaryConfig } from '../../../../../global/utils/cloudinaryUtils';
import { isTestEnv } from '../../../../../global/utils/environment';
import { ProductImageBoxMediaProps, ProductImageBoxMediaSize } from './ProductImageBoxMedia.types';

const ProductImageBoxMediaMemoized: React.FC<ProductImageBoxMediaProps> = ({ images, boxLength }) => {
  const { hostEnv } = useContext(ConfigContext);
  let productImage: Image = null;
  let productImageUrl: CloudinaryImage = null;

  const cloudinaryFolder = `csplus/${hostEnv}`;
  const productsFallbackCloudinaryFolder = `csplus:${hostEnv}:images:products`;

  const plugins = !isTestEnv && [lazyload({ rootMargin: '10px 20px 10px 20px', threshold: 0.15 }), responsive()];

  if (images?.length > 0) {
    productImage = images.find((x) => x.size === ProductImageBoxMediaSize.MEDIUM);
    productImageUrl = cloudinaryConfig
      .image(`${cloudinaryFolder}${productImage.url}`)
      .format('auto')
      .quality('auto')
      .delivery(defaultImage(`${productsFallbackCloudinaryFolder}:fallback.png`));
  }

  const fallbackImage = cloudinaryConfig
    .image(`${cloudinaryFolder}/images/products/fallback.png`)
    .format('auto')
    .quality('auto');

  const [imageUrl, setImageUrl] = useState(productImageUrl || fallbackImage);

  useEffect(() => {
    setImageUrl(productImageUrl || fallbackImage);
  }, [images]);

  return (
    <AdvancedImage
      alt="product-image-box"
      cldImg={imageUrl}
      plugins={plugins}
      width={boxLength}
      height={boxLength}
      onError={() => setImageUrl(fallbackImage)}
      fetchpriority="high"
    />
  );
};

const ProductImageBoxMedia = memo(ProductImageBoxMediaMemoized);
export { ProductImageBoxMedia };
