import { useGetRecommendedProductsQuery } from '../../services/products/products';
import { RECOMMENDATIONS_CODES } from '../constants';
import { getVisitorId } from '../google-analytics/utils';
import { useDistributors } from './useDistributors';
import { useOutletDetails } from './useOutletDetails';

interface Props {
  limit: number;
  sku?: string;
  skip?: boolean;
  recommendationsCode: RECOMMENDATIONS_CODES;
}

export const useRecommended = ({ limit, sku, recommendationsCode, skip }: Props) => {
  const { selectedOutlet, selectedDistributor } = useDistributors();

  const { outletDetails } = useOutletDetails({
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    distributorId: selectedDistributor?.eid
  });

  const isRecommendationEngineEnabled: boolean = outletDetails?.recommendationEngine !== undefined;

  const recommendedParams = {
    limit,
    skus: [sku],
    recommendationType: recommendationsCode,
    visitorId: getVisitorId(),
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    distributorId: selectedDistributor?.eid
  };

  const querySubscriptionOptions = {
    skip: !selectedOutlet || !selectedDistributor || !isRecommendationEngineEnabled || skip
  };

  const { ...props } = useGetRecommendedProductsQuery(recommendedParams, querySubscriptionOptions);

  return {
    isRecommendationEngineEnabled,
    ...props
  };
};
