import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { ConfigContext } from '../../../../global/contexts/ConfigContext';
import { PAGE_ROUTES } from '../../../../global/custom-hooks/useGetCurrentPageInfo';
import { useHeaderNavLinks } from '../../../../global/custom-hooks/useHeaderNavLinks';
import { useLockPageScroll } from '../../../../global/custom-hooks/useLockPageScroll';
import { useMenuClick } from '../../../../global/custom-hooks/useMenuClick';
import { MenuType } from '../../../../global/google-analytics/tracking';
import { HeadlessImageI } from '../../../../global/interfaces/headless';
import { Drawer } from '../../../shared/Drawer';
import styles from './drawer-header.module.scss';

interface SideDrawerHeaderProps {
  logout: () => void;
  logo: HeadlessImageI;
}

const SideDrawerHeader = ({ logout, logo }: SideDrawerHeaderProps) => {
  const { Customer, Help } = IconsPaths;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { featureFlags } = useContext(ConfigContext);
  const { links, isHeaderDesktopVersion } = useHeaderNavLinks();
  const { isDrawerMenuOpen, handleCloseDrawerMenu, trackMenuClick } = useMenuClick();
  useLockPageScroll(isDrawerMenuOpen);

  if (isHeaderDesktopVersion) {
    return null;
  }

  return (
    <Drawer
      dataTestId="menu-drawer"
      isOpen={isDrawerMenuOpen}
      onClose={handleCloseDrawerMenu}
      logo={logo}
      zIndex={7}
      showLanguageSelector
    >
      <div className={styles.content}>
        <nav className={styles.linksWrapper}>
          <ul className={styles.links}>
            {links.map(({ title, path, isPathVisible }) => {
              const isActive = pathname?.includes(path) || (path === '/catalog' && pathname.includes('/products'));
              return (
                isPathVisible && (
                  <li key={path} className={styles.linkItem}>
                    <NavLink
                      className={cn({ [styles.activeLink]: isActive })}
                      to={{ pathname: path, state: { from: pathname } }}
                      onClick={() => {
                        trackMenuClick(MenuType.Text, title);
                        handleCloseDrawerMenu();
                      }}
                    >
                      {title}
                    </NavLink>
                  </li>
                )
              );
            })}
          </ul>
        </nav>

        <div className={styles.myAccount}>
          <NavLink
            activeClassName={styles.activeLink}
            to={{ pathname: `/${PAGE_ROUTES.profile.path}`, state: { from: pathname } }}
            onClick={handleCloseDrawerMenu}
          >
            <Customer color={IconColor.DigitalBlack} size={IconSize.Medium} />
            {t('entries.header.account', { defaultValue: 'My Account' })}
          </NavLink>
          {featureFlags?.enableFAQs ? (
            <NavLink
              activeClassName={styles.activeLink}
              to={{ pathname: `/${PAGE_ROUTES.faqs.path}`, state: { from: pathname } }}
              onClick={handleCloseDrawerMenu}
            >
              <Help color={IconColor.DigitalBlack} size={IconSize.Medium} />
              {t('entries.header.faqHelp', { defaultValue: 'FAQ - HELP' })}
            </NavLink>
          ) : null}
        </div>
        <Button
          className={styles.logoutButton}
          style={ButtonStyle.Secondary}
          fullWidth
          onClick={logout}
          size={ButtonSize.Large}
          text={t('entries.header.logout', { defaultValue: 'Log Out' })}
        />
      </div>
    </Drawer>
  );
};

export { SideDrawerHeader };
