import { PROMOTION_TYPES } from '../constants';
import { Deposit, Product, Promotion, PromotionTypes } from '../interfaces/products';
import { getPromotionsDetails } from '../utils/promotionsUtils';
import { ItemEvent, ItemListName } from './tracking';

const matchGa = document.cookie.match(/_ga=(.+?);/);
const selectedGa = matchGa?.length ? matchGa[0] : '';
export const gaClientId = selectedGa?.split('.')?.slice(-2)?.join('.');

export const setVisitorId = (value: string) => localStorage.setItem('vid', value);

export const getVisitorId = () => localStorage.getItem('vid') || '';

export const getProductsValue = (products: Product[], quantity?: number) =>
  products?.reduce(
    (accum, item) =>
      accum + ((item?.price?.base || 0) + (item.deposits?.totalDeposits || 0)) * (quantity || item.quantity),
    0
  );

const getBrand = (product: Product) => product?.detailsInfo?.brand?.code ?? `BRAND NOT AVAILABLE`;

const getCategory = (product: Product) => (product?.detailsInfo?.categories ?? [])[0]?.code ?? `CATEGORY NOT AVAILABLE`;

const getPackage = (product: Product) =>
  product?.package?.size ? `${product?.package.size}ml` : `PACKAGE NOT AVAILABLE`;

const createDeposit = (
  item: Deposit,
  listName: ItemListName | string,
  currency: string,
  index: number,
  quantityUnit?: string
) => ({
  item_id: item.sku,
  index,
  currency,
  price: item?.basePrice || 0,
  item_list_name: listName,
  item_name: item.product?.name,
  item_category: 'DEPOSITS',
  item_variant: getPackage(item.product),
  quantity: item?.quantity,
  quantity_unit: quantityUnit
});

const createItem = (
  item: Product,
  listName: ItemListName | string,
  currency: string,
  index: number,
  quantity: number
) => {
  const promotionDetails = getPromotionsDetails(item.availablePromotionTypes);
  return {
    item_id: item.sku,
    index,
    currency,
    price: item.price?.base,
    item_list_name: listName,
    item_name: item.name,
    item_brand: getBrand(item),
    item_category: getCategory(item),
    item_variant: getPackage(item),
    quantity: (quantity || item.quantity) ?? 1,
    quantity_unit: item.package?.unit,
    discount: item.price?.discount ? item.price?.base - item.price?.discount : 0,
    promotion_amount_type: promotionDetails?.isAmountDiscount || false,
    promotion_amount_value: promotionDetails?.amountDiscountValue || 0,
    promotion_percentage_type: promotionDetails?.isPercentageDiscount || false,
    promotion_percentage_value: promotionDetails?.percentageDiscountValue || 0,
    promotion_points_type: promotionDetails?.isPointsRewards || false,
    promotion_points_value: promotionDetails?.pointsRewardsValue || 0,
    promotion_others_type: promotionDetails?.isOtherPromotion || false
  };
};

export const mapProductsItems = ({
  products,
  currency,
  listName,
  quantity
}: {
  products: Product[];
  currency: string;
  listName: ItemListName | string;
  quantity?: number;
}) => {
  const itemsArr: ItemEvent[] = [];
  let positionIndex = 0;

  products?.forEach((product) => {
    positionIndex += 1;

    itemsArr.push(createItem(product, listName, currency, positionIndex, quantity));

    if (product.deposits?.depositsCollection) {
      product.deposits.depositsCollection.forEach((deposit) => {
        positionIndex += 1;
        itemsArr.push(createDeposit(deposit, listName, currency, positionIndex, product.package?.unit));
      });
    }
  });

  return itemsArr;
};

export const mapPromotions = (promotions: Promotion[], promotionTypes: PromotionTypes[]) =>
  promotionTypes?.map((promotionType, index) => {
    const promoDetails = promotions[index];

    const promotionSubtype =
      promotionType.type !== PROMOTION_TYPES.AMOUNT && promotionType.type !== PROMOTION_TYPES.PERCENTAGE
        ? promoDetails?.type
        : null;

    const promotionValue =
      promotionType.type === PROMOTION_TYPES.AMOUNT || promotionType.type === PROMOTION_TYPES.PERCENTAGE
        ? promotionType.value
        : null;

    return {
      promotion_id: promoDetails?.code,
      promotion_name: promoDetails?.title,
      promotion_description: promoDetails?.description,
      promotion_type: promotionType.type,
      promotion_subtype: promotionSubtype,
      promotion_value: promotionValue
    };
  });

export const isPWA = () => window?.matchMedia('(display-mode: standalone)')?.matches;
