import { Button, ButtonSize, ButtonStyle, ButtonType } from '@carlsberggroup/malty.atoms.button';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Destination } from '../../../assets/destination.svg';
import { ReactComponent as Transport } from '../../../assets/transport.svg';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { DistributorI } from '../../../global/interfaces/distributor';
import { Outlet } from '../../../global/interfaces/outlet';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../../UI-elements/Text';
import { Modal } from '../Modal/ModalComponent';
import { DistributorSteps } from './Distributor';
import styles from './distributor.module.scss';
import { DistributorCard } from './DistributorCard';
import { OutletCard } from './OutletCard';

interface Props {
  step: DistributorSteps;
  destroyDistributorModal: () => void;
  distributor: DistributorI;
  outlet: Outlet;
}

export const DistributorModalInfo: React.FC<Props> = ({ step, distributor, outlet, destroyDistributorModal }) => {
  const { t } = useTranslation();

  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  const getTitle = () => {
    if (step === DistributorSteps.OUTLET_INFO) {
      return t('entries.selection.singleOutletTitle', {
        defaultValue: 'Outlet Information Details'
      });
    }
    if (step === DistributorSteps.DISTRIBUTOR_INFO) {
      return t('entries.selection.singleDistributorTitle', {
        defaultValue: 'Distributor Information Details'
      });
    }
    return '';
  };

  return (
    <Modal
      dataTestId="info-modal"
      active
      closeModal={destroyDistributorModal}
      hideOverlay={isMobile}
      showCloseButton={false}
      showLogo={isMobile}
      fixedClose={isMobile}
      theme={{
        content: classnames(styles.outletDistributorInfoStep, styles.modalContainer)
      }}
    >
      <div>
        <Text size={FONT_SIZE.LARGE} weight={FONT_WEIGHT.BOLD}>
          {getTitle()}
        </Text>
      </div>
      <div className={classnames(styles.selectionListWrapper, styles.outletDistributorInfoStep)}>
        <div className={styles.list} data-testid="selection-list">
          <div className={classnames(styles.cardWrapper, styles.disabled)} data-testid="info-option">
            <div className={styles.optionWrapper}>
              <div className={styles.leftAreaWrapper}>
                <div className={styles.icon}>
                  {step === DistributorSteps.DISTRIBUTOR_INFO && <Transport />}
                  {step === DistributorSteps.OUTLET_INFO && <Destination />}
                </div>
                {step === DistributorSteps.DISTRIBUTOR_INFO && <DistributorCard distributor={distributor} />}
                {step === DistributorSteps.OUTLET_INFO && <OutletCard outlet={outlet} />}
              </div>
            </div>
          </div>
        </div>
        <div className={classnames(styles.footer)}>
          <div className={styles.confirm}>
            <Button
              text={t('entries.selection.continueShopping', { defaultValue: 'Continue Shopping' })}
              type={ButtonType.Submit}
              onClick={destroyDistributorModal}
              style={ButtonStyle.Primary}
              dataTestId="confirm-button"
              size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
              fullWidth
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
