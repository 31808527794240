import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorBoundary } from '../../components/ErrorBoundary/useErrorBoundary';
import { BFF_ERROR_CODES, CATALOG_TYPES } from '../../global/constants';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { PageContext } from '../../global/contexts/PageContext';
import { useCurrentLanguage } from '../../global/custom-hooks/useCurrentLanguage';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { useFetchCatalog } from '../../global/custom-hooks/useFetchCatalog';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { PageViewTracking } from '../../global/google-analytics';
import { PageType } from '../../global/google-analytics/tracking';
import { BFFError } from '../../global/interfaces/checkout';
import { filtersApi } from '../../services/filters/filters';
import { setCatalogActiveChip, setCurrentPage, setSortMode } from '../../store/slices/catalog';
import { userConfigSelector } from '../../store/slices/user';
import { CatalogComponent } from './CatalogComponent';

const CatalogContent = () => {
  const {
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();
  const { triggerBoundary } = useErrorBoundary();
  const userConfig = useSelector(userConfigSelector);
  const { environment } = useContext(ConfigContext);
  const currentLanguage = useCurrentLanguage();
  const gaEventData = useGetBaseGAEventData();
  const [isTrackingCalled, setIsTrackingCalled] = useState(false);
  const { catalogData, error: catalogError } = useFetchCatalog();
  const catalogErrorData = (catalogError as FetchBaseQueryError)?.data as BFFError | undefined;
  const hasWarehouseError = catalogErrorData?.errorCode === BFF_ERROR_CODES.NO_WAREHOUSE_ERROR_CODE;

  useEffect(() => {
    dispatch(setCatalogActiveChip(CATALOG_TYPES.default));
    if (!window.location.pathname.includes('/product')) {
      dispatch(setSortMode(null));
      dispatch(setCurrentPage(0));
    }
  }, []);

  useEffect(() => {
    if (!catalogData?.sortModes) {
      return;
    }
    dispatch(filtersApi.util.invalidateTags(['FILTERS']));
  }, [language]);

  useEffect(() => {
    if (hasWarehouseError) {
      triggerBoundary();
    }
  }, [hasWarehouseError]);

  useEffect(() => {
    if (!currentLanguage || !userConfig || !environment || isTrackingCalled) return;
    TagManager.dataLayer(PageViewTracking({ ...gaEventData }));
    setIsTrackingCalled(true);
  }, [currentLanguage, environment, isTrackingCalled]);

  return <CatalogComponent />;
};

const CatalogContainer = () => {
  const { selectedOutlet, selectedDistributor } = useDistributors();

  if (!selectedOutlet || !selectedDistributor) {
    return null;
  }

  return (
    <PageContext.Provider value={{ pageType: PageType.List }}>
      <CatalogContent />
    </PageContext.Provider>
  );
};
export default CatalogContainer;
