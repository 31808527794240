import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { app } from '../constants';
import { useLocales } from './useLocales';

export const useCurrentLanguage = () => {
  const {
    i18n: { language }
  } = useTranslation();
  const { locales } = useLocales();
  return useMemo(
    () => locales.find((x) => x.isoCode === language) ?? locales.find((x) => x.isoCode === app.defaultLanguage),
    [locales, language]
  );
};
