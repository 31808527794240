import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import { logout } from './auth/authHandler';
import { getCSPlusConfig } from './config/getConfig';
import { getHostSettings } from './config/getHostSettings';
import { app, LocizeConfig } from './global/constants';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'languageDetector',
  lookup: null,
  cacheUserLanguage(lng) {
    localStorage.setItem('i18nextLng', lng);
  }
});

const isDevelopment = process.env.NODE_ENV === 'development';

const i18nInstance = i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next);

const i18nConfig = async () => {
  const csplus = await getCSPlusConfig();
  const hostConfig = getHostSettings(csplus.configs);

  i18nInstance.on('languageChanged', (language) => {
    localStorage.setItem('i18nextLng', language);
    document.documentElement.lang = language;
  });

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  await i18nInstance.init(
    {
      fallbackLng: isDevelopment ? app.defaultLanguage : hostConfig.locales.default,
      defaultNS: 'translations',
      ns: ['translations'],
      interpolation: {
        escapeValue: false
      },
      initImmediate: false,
      react: {
        useSuspense: true
      },
      lng: localStorage.getItem('i18nextLng'),
      supportedLngs: hostConfig.locales.available.map((language) => language.isoCode),
      saveMissing: true,
      load: 'currentOnly',
      contextSeparator: '.',
      backend: {
        projectId: LocizeConfig.projectId,
        referenceLng: String(app.defaultLanguage),
        version: hostConfig.translationVersion ?? 'latest',
        ...(isDevelopment && {
          allowedAddOrUpdateHosts: ['localhost', '0.0.0.0'],
          apiKey: process.env.LOCIZE_API_KEY
        })
      }
    },
    (err) => {
      if (err) {
        console.error('Failed to load translations', err);

        if (window.location.pathname !== '/maintenance') {
          if (logout) logout();
          window.location.replace(`${window.location.origin}/maintenance`);
        }
      }
    }
  );
};
export const startI18n = async () => {
  await i18nConfig();
  return i18nInstance;
};

export { i18nInstance as i18n };
