import { useEffect, useState } from 'react';
import {
  ApiConfig as BaseAPIConfig,
  CSPlusConfig as CSPlusConfigType,
  CSPlusHostConfig
} from '../../config/configTypes';
import { getApiConfig } from '../../config/getConfig';

export type CSPlusConfig = Partial<CSPlusHostConfig> & Partial<Omit<CSPlusConfigType, 'configs'>>;

export type APIConfig = Partial<BaseAPIConfig>;

export const useAPIConfig = (): APIConfig => {
  const [config, setConfig] = useState<APIConfig>({});

  useEffect(() => {
    let isComponentMounted = true;
    const configSetter = async () => {
      const apiConfig = await getApiConfig();

      if (isComponentMounted) {
        setConfig(apiConfig);
      }
    };

    configSetter();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return config;
};
