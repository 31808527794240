import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isAuthenticated } from '../../auth/authHandler';
import { LoginContainer } from '../../pages/Login';
import { Loading } from '../shared/Loading';
import { PrivateRoute } from './PrivateRouteComponent';

interface Props {
  path: string;
}

const PrivateRouteContainer: React.FC<Props> = ({ children, path, ...props }) => {
  const { pathname } = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    if (pathname !== '/silent-check-sso.html') {
      localStorage.setItem('currentLocation', pathname);
    }

    isAuthenticated().then((result) => {
      setValid(result);
      setLoading(false);
    });
  }, []);

  if (isLoading) return <Loading fullScreen />;

  return (
    <PrivateRoute isLoggedIn={isValid} path={path} {...props}>
      <LoginContainer>{children}</LoginContainer>
    </PrivateRoute>
  );
};

export { PrivateRouteContainer };
