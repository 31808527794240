import { PROMOTION_TYPES } from '../constants';
import { Product } from '../interfaces/products';

interface Props {
  product: Product;
  isRedeemableProduct: boolean;
  isNotDeliverableProduct?: boolean;
  isNotDeliverableReward?: boolean;
  isNotDeliverableFree?: boolean;
}

const getRewardsValue = ({
  product,
  isRedeemableProduct,
  isNotDeliverableProduct,
  isNotDeliverableReward,
  isNotDeliverableFree
}: Props): number => {
  const rewardPointsAvailable = product?.availablePromotionTypes?.find(
    (promotion) => promotion.type === PROMOTION_TYPES.POINTS || promotion.type === PROMOTION_TYPES.POINTS_OLD
  )?.value;

  if (isNotDeliverableProduct || isNotDeliverableReward || isNotDeliverableFree) {
    return 0;
  }

  if (isRedeemableProduct && product.price?.base && !product.price?.total) {
    return product.price.base;
  }

  if (isRedeemableProduct && product.price?.total) {
    return product.price.total;
  }

  if (rewardPointsAvailable) {
    return rewardPointsAvailable;
  }

  if (product.collectablePoints) {
    return product.collectablePoints;
  }

  return 0;
};

export { getRewardsValue };
