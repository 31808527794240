import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FetchBaseQueryErrorExtended } from '../../services/baseApiQuery';
import { useGetCartsQuery, useGetSelectedCartQuery } from '../../services/cart/cart';
import { Cart } from '../interfaces/cart';
import { useDistributors } from './useDistributors';

interface UseCartHook {
  selectedCart: Cart;
  refetchSelectedCart: () => void;
  refetchCarts: () => void;
  isSelectedCartLoading: boolean;
  isSelectedCartFetching: boolean;
  isCartsLoading: boolean;
  totalProducts: number;
  error?: FetchBaseQueryError | FetchBaseQueryErrorExtended | SerializedError;
}

export const useCart = (): UseCartHook => {
  const { selectedOutlet, selectedDistributor } = useDistributors();

  const getCartsParams = {
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    distributorId: selectedDistributor?.eid
  };
  const getCartsSkipParams = {
    skip: !selectedOutlet || !selectedDistributor
  };
  const { data, refetch: refetchCarts, isLoading: isCartsLoading, error: cartsError } = useGetCartsQuery(
    getCartsParams,
    getCartsSkipParams
  );

  const getSelectedCartParams = {
    cartId: data?.carts[0]?.eid,
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    distributorId: selectedDistributor?.eid
  };
  const getSelectedCartSkipParams = {
    skip: !selectedOutlet || !selectedDistributor || !data
  };
  const {
    data: selectedCart,
    refetch: refetchSelectedCart,
    isFetching: isSelectedCartFetching,
    isLoading: isSelectedCartLoading,
    error: selectedCartError
  } = useGetSelectedCartQuery(getSelectedCartParams, getSelectedCartSkipParams);

  const { totalProducts: totalItems, totalReturnItems, totalRedeemableItems, totalFreeItems } =
    selectedCart?.total ?? {};
  const totalProducts = totalItems + totalFreeItems + totalReturnItems + totalRedeemableItems || 0;

  return {
    selectedCart,
    isSelectedCartLoading,
    isSelectedCartFetching,
    isCartsLoading,
    refetchCarts,
    refetchSelectedCart,
    totalProducts,
    error: cartsError || selectedCartError
  };
};
