import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { DeliveryDate } from '../../global/interfaces/checkout';
import { DistributorI } from '../../global/interfaces/distributor';
import { Order } from '../../global/interfaces/orders';
import { Outlet, OutletDetails } from '../../global/interfaces/outlet';
import { ITrackedChanges } from '../../global/interfaces/trackedChanges';
import { UserConfig } from '../../global/interfaces/user';
import { baseApiQuery } from '../baseApiQuery';
import {
  AddressesPayload,
  AddressesQueryParams,
  BasicParams,
  BasicParamsWithDistributor,
  DeliveryDatesQueryParams,
  OrderQueryParams,
  TrackedChangesParams
} from './types';

export const getSelectedOutlet = (outlets: Outlet[]) =>
  outlets?.find((outlet: Outlet) => outlet.selected) ||
  outlets?.find(({ activeDistributorCount }) => activeDistributorCount);

export const getSelectedDistributor = (distributors: DistributorI[]) =>
  distributors?.find((distributor: DistributorI) => distributor.isSelected) || distributors?.[0];

export const outletsApi = createApi({
  reducerPath: 'outletsApi',
  baseQuery: baseApiQuery,
  tagTypes: ['OutletDetails', 'TrackedChanges'],
  endpoints: (builder) => ({
    getOutlets: builder.query<UserConfig, { salesOrg: string }>({
      query: ({ salesOrg }) => ({
        url: `${apiEndpoints.USER}/config`,
        params: {
          salesOrg
        }
      })
    }),
    getOutletDetails: builder.query<OutletDetails, BasicParamsWithDistributor>({
      query: ({ salesOrg, outletId, distributorId }) => ({
        url: `${apiEndpoints.OUTLETS}/${outletId}`,
        params: {
          salesOrg,
          distributorId
        }
      }),
      providesTags: ['OutletDetails']
    }),
    getDistributors: builder.query<DistributorI[], BasicParams>({
      query: ({ salesOrg, outletId }) => ({
        url: `${apiEndpoints.OUTLETS}/${outletId}/distributors`,
        params: {
          salesOrg
        }
      })
    }),
    getTrackedChanges: builder.query<ITrackedChanges, TrackedChangesParams>({
      query: ({ salesOrg, outletId, distributorId, cartEid }) => ({
        url: `${apiEndpoints.CARTS}/${cartEid}/trackChanges`,
        params: {
          salesOrg,
          outletId,
          targetDistributorId: distributorId
        }
      }),
      providesTags: ['TrackedChanges']
    }),
    selectOutlet: builder.mutation<void, BasicParams>({
      query: ({ salesOrg, outletId }) => ({
        url: `${apiEndpoints.USER}/outlet`,
        method: 'POST',
        params: {
          salesOrg,
          outletId
        }
      }),
      async onQueryStarted({ salesOrg, outletId }, { dispatch, queryFulfilled }) {
        const updateThunk = outletsApi.util.updateQueryData('getOutlets', { salesOrg }, (getOutletsCache) => ({
          ...getOutletsCache,
          outlets: getOutletsCache.outlets.map((outlet: Outlet) => ({
            ...outlet,
            selected: outlet.eid === outletId
          }))
        }));
        const patchResult = dispatch(updateThunk);
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    selectDistributor: builder.mutation<void, BasicParamsWithDistributor>({
      query: ({ salesOrg, outletId, distributorId }) => ({
        url: `${apiEndpoints.OUTLETS}/${outletId}/distributors`,
        method: 'POST',
        params: {
          salesOrg
        },
        body: JSON.stringify({
          distributorId
        })
      }),
      invalidatesTags: ['TrackedChanges'],
      async onQueryStarted({ distributorId, ...queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          outletsApi.util.updateQueryData('getDistributors', queryParams, (getDistributorsCache) =>
            getDistributorsCache.map((distributor) => ({
              ...distributor,
              isSelected: distributor.eid === distributorId
            }))
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    getDeliveryDates: builder.query<DeliveryDate[], DeliveryDatesQueryParams>({
      query: ({ outletId, ...rest }) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_DELIVERY_DATES, { eid: outletId }),
        method: 'GET',
        params: rest
      })
    }),
    getAddresses: builder.query<AddressesPayload, AddressesQueryParams>({
      query: ({ outletId, ...rest }) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ADDRESSES, { eid: outletId }),
        method: 'GET',
        params: rest
      })
    }),
    createOrder: builder.mutation<Order, OrderQueryParams>({
      query: ({ outletId, salesOrg, ...rest }) => ({
        url: makeApiUrl(apiEndpoints.OUTLETS_EID_ORDERS, { eid: outletId }),
        method: 'POST',
        body: JSON.stringify(rest),
        params: {
          salesOrg
        }
      })
    })
  })
});

export const {
  useGetOutletDetailsQuery,
  useGetTrackedChangesQuery,
  useGetOutletsQuery,
  useGetDistributorsQuery,
  useSelectOutletMutation,
  useSelectDistributorMutation,
  useGetDeliveryDatesQuery,
  useGetAddressesQuery,
  useCreateOrderMutation,
  useLazyGetDistributorsQuery
} = outletsApi;
