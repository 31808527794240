import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { NavHeaderItem } from '../../../../global/custom-hooks/useHeaderNavLinksMobile';
import { MenuType } from '../../../../global/google-analytics/tracking';
import { CartCountBadge } from '../CartCountBadge';
import styles from './navHeaderMobile.module.scss';

interface NavHeaderMobileItemButtonProps {
  icon: IconName;
  label: NavHeaderItem['label'];
  onMenuClick?: (type: MenuType, label: string) => void;
  isActive: boolean;
}

const NavHeaderMobileItemButton = ({ icon, isActive, onMenuClick, label }: NavHeaderMobileItemButtonProps) => (
  <button
    aria-label="mobile-menu-button"
    type="button"
    className={styles.navHeaderMobileItem}
    onClick={() => onMenuClick(MenuType.Icon, label)}
    role="tab-item__button"
    disabled={isActive}
  >
    <div className={styles.navHeaderMobileItemIcon}>
      <Icon name={icon} size={IconSize.Medium} color={IconColor.DigitalBlack} />
      {icon === IconName.Cart ? <CartCountBadge /> : null}
    </div>
    <div className={styles.navHeaderMobileItemText}>
      <Text dataQaId="mobile-menu-text" textStyle={isActive ? TextStyle.TinyBold : TextStyle.TinyDefault} ellipsis>
        {label}
      </Text>
    </div>
  </button>
);

export { NavHeaderMobileItemButton };
