import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
  to: string;
  dataTestId?: string;
}

const ConditionalLink: FC<Props> = ({ children, to, dataTestId }) => {
  if (!to) return <>{children}</>;
  if (to.startsWith('/')) return <Link to={to}>{children}</Link>;

  const toWithHttp = to.startsWith('http') ? to : `https://${to}`;
  const url = new URL(toWithHttp);
  return url.hostname === window.location.hostname ? (
    <Link to={url.pathname} data-testid={dataTestId}>
      {children}
    </Link>
  ) : (
    <a href={url.href} data-testid={dataTestId}>
      {children}
    </a>
  );
};

export { ConditionalLink };
