import { Chip } from '@carlsberggroup/malty.atoms.chip';
import cn from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CHIP_TYPES } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { getActiveChip } from '../../../global/selectors/filters/selectors';
import styles from './chips.module.scss';
import { ChipsType, getChips } from './getChips';

interface ChopsProps {
  chips: CHIP_TYPES[];
  onTabChange: (type: string, label: string) => void;
  isCatalogLoading: boolean;
}

const Chips = ({ chips, onTabChange, isCatalogLoading }: ChopsProps) => {
  const activeChip = useSelector(getActiveChip);
  const { t } = useTranslation();
  const config = useContext(ConfigContext);
  const chipContainer = useRef<HTMLDivElement>();
  const { isSmall, isXSmall } = useScreenSize();
  const isMobile = isSmall || isXSmall;
  const visibleChips = [...getChips(config).values()].filter(({ isVisible }) => isVisible);
  const availableChips = useMemo(
    () => chips.map((chipType) => visibleChips.find(({ code }) => code === chipType)).filter(Boolean),
    [chips, visibleChips]
  );
  const availableUniqueChips: ChipsType[] = [...new Set(availableChips)];
  const scrollToActiveChip = useCallback(() => {
    if (chipContainer && chipContainer.current && availableUniqueChips && !isCatalogLoading) {
      const { scrollWidth, clientWidth, children } = chipContainer.current;
      if (scrollWidth > clientWidth) {
        let left = 0;
        const activeChipIndex = availableUniqueChips.findIndex((availableChip) => availableChip.code === activeChip);

        if (activeChipIndex && children) {
          left = Array.from(children)
            .filter((_, index) => index <= activeChipIndex)
            .map((element) => element.scrollWidth)
            .reduce((total, num) => total + num, 0);
        }
        chipContainer.current.scroll({ left, behavior: 'smooth' });
      }
    }
  }, [chipContainer, availableUniqueChips]);
  useEffect(() => {
    if (isMobile) {
      scrollToActiveChip();
    }
  }, [activeChip, isCatalogLoading, isMobile]);

  return (
    <div ref={chipContainer} className={cn(styles['chips-wrapper'])}>
      {availableUniqueChips.map(({ i18n: { key, defaultValue }, code }) => (
        <Chip
          label={t(key, { defaultValue })}
          selected={activeChip === code}
          key={code}
          onChange={() => !isCatalogLoading && onTabChange(code, defaultValue)}
          disabled={isCatalogLoading}
          dataTestId={`chip-${code}`}
        />
      ))}
    </div>
  );
};

export { Chips };
