import React, { RefObject, useEffect, useState } from 'react';
import { useHeaderContext } from '../../../../global/contexts/HeaderContext';
import { useSize } from '../../../../global/custom-hooks/useSize';
import { HEADER_MENU_HEIGHT, TRIGGER_ANIMATION_BANNER } from '../../constants';

interface HeaderOffsetProps {
  headerRef: RefObject<HTMLHeadElement>;
}

const HeaderOffset = ({ headerRef }: HeaderOffsetProps) => {
  const wrapperSize = useSize(headerRef);
  const [headerHeightWrapper, setHightElementWrapper] = useState(HEADER_MENU_HEIGHT);
  const headerContext = useHeaderContext();
  const element = document.body.getBoundingClientRect();
  const scrollY = Math.abs(element.top);

  useEffect(() => {
    if (scrollY < TRIGGER_ANIMATION_BANNER && wrapperSize?.height >= 0) {
      setHightElementWrapper(wrapperSize?.height);
    }
    if (headerContext?.currentDesktopHeaderHeight !== wrapperSize?.height) {
      headerContext?.changeCurrentDesktopHeaderHeight(wrapperSize?.height);
    }
  }, [wrapperSize?.height, headerContext?.currentDesktopHeaderHeight]);

  return (
    <div
      style={{
        height: headerHeightWrapper
      }}
    />
  );
};

export { HeaderOffset };
