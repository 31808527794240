import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Text, TextAlign, TextStyle } from '@carlsberggroup/malty.atoms.text';
import { Modal, ModalSize } from '@carlsberggroup/malty.molecules.modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './confirmModal.module.scss';

interface ConfirmModalProps {
  showModal: boolean;
  closeModal?: () => void;
  onConfirm: () => void;
  dataTestId?: string;
  contentMessage?: string | JSX.Element;
  confirmButtonText?: string;
  cancelButtonText?: string;
  titleMessage?: string;
  singleButton?: boolean;
  iconName?: IconName;
  isDismissible?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  showModal,
  closeModal,
  onConfirm,
  dataTestId = '',
  contentMessage,
  titleMessage,
  confirmButtonText,
  cancelButtonText,
  singleButton = false,
  iconName = IconName.Alert,
  isDismissible = false
}) => {
  const confirmModalTestId = `confirm-modal-${dataTestId}`;
  const { t } = useTranslation();

  return (
    <div className={styles.modalWrapper} data-testid={confirmModalTestId}>
      <Modal
        open={showModal}
        onClose={closeModal}
        dismissible={isDismissible}
        size={ModalSize.Medium}
        actions={
          <div className={styles.buttonGroup}>
            {!singleButton && (
              <Button
                fullWidth
                text={cancelButtonText || t('entries.orders.cancel', { defaultValue: 'Cancel' })}
                size={ButtonSize.Large}
                style={ButtonStyle.Secondary}
                onClick={closeModal}
              />
            )}
            <Button
              fullWidth
              text={confirmButtonText || t('entries.orders.confirm', { defaultValue: 'Confirm' })}
              size={ButtonSize.Large}
              style={ButtonStyle.Primary}
              onClick={onConfirm}
              dataTestId={dataTestId}
            />
          </div>
        }
        content={
          <div className={styles.modalContent} data-testid="confirm-modal-content">
            <Icon name={iconName} size={IconSize.ExtraLarge} color={IconColor.DigitalBlack} />
            <Text
              dataQaId={dataTestId}
              align={TextAlign.Center}
              className={styles.modalTitle}
              textStyle={TextStyle.MediumBold}
            >
              {titleMessage}
            </Text>

            <Text align={TextAlign.Center} textStyle={TextStyle.MediumDefault}>
              {contentMessage}
            </Text>
          </div>
        }
      />
    </div>
  );
};
