import classNames from 'classnames';
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useLockPageScroll } from '../../../global/custom-hooks/useLockPageScroll';
import { HeadlessImageI } from '../../../global/interfaces/headless';
import { Overlay } from '../../../UI-elements/Overlay';
import DrawerHeader from './components/DrawerHeader';
import { LanguageGroup } from './components/LanguageSelector';
import styles from './drawer.module.scss';

export enum DrawerType {
  DEFAULT = 'default',
  NESTED = 'nested'
}

interface DrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  dataTestId?: string;
  zIndex?: number;
  className?: string;
  drawerType?: DrawerType;
  logo?: HeadlessImageI;
  showLanguageSelector?: boolean;
}

export const Drawer: FC<DrawerProps> = ({
  children,
  isOpen,
  onClose,
  title,
  zIndex = 6,
  dataTestId = 'drawer',
  className,
  drawerType = DrawerType.DEFAULT,
  logo,
  showLanguageSelector = false
}) => {
  useLockPageScroll(isOpen || drawerType === DrawerType.NESTED);

  return createPortal(
    <>
      <div
        style={{ zIndex }}
        className={classNames(styles.container, className, {
          [styles.isOpen]: isOpen,
          [styles.nested]: drawerType === DrawerType.NESTED
        })}
        data-testid={dataTestId}
      >
        <section className={styles.wrapper}>
          <DrawerHeader
            dataTestId={dataTestId}
            title={title}
            onClose={onClose}
            useBreadcrumb={drawerType === DrawerType.NESTED}
            logo={logo}
          />
          {showLanguageSelector ? <LanguageGroup onCloseMenu={onClose} /> : null}
          {children}
        </section>
      </div>
      <Overlay active={isOpen} action={onClose} zIndex={zIndex - 1} />
    </>,
    document.body
  );
};
