import { Text, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { Fragment } from 'react';

interface CountDownRemainingTimeProps {
  hours: number;
  minutes: number;
  seconds: number;
  isFontSizeMedium?: boolean;
}

const CountDownRemainingTime: React.FC<CountDownRemainingTimeProps> = ({
  hours,
  minutes,
  seconds,
  isFontSizeMedium = false
}) => {
  const countDown = [
    {
      key: 0,
      isTimeStampVisible: hours >= 1,
      timeStamp: `${hours + 1} hours`,
      textStyle: isFontSizeMedium ? TextStyle.MediumSmallDefault : TextStyle.SmallDefault,
      textColor: TextColor.DigitalBlack
    },
    {
      key: 1,
      isTimeStampVisible: !!(hours === 0 && minutes >= 1),
      timeStamp: `${minutes + 1} min`,
      textStyle: isFontSizeMedium ? TextStyle.MediumSmallBold : TextStyle.SmallBold,
      textColor: TextColor.Fail
    },
    {
      key: 2,
      isTimeStampVisible: !!(hours === 0 && minutes === 0),
      timeStamp: `${seconds} sec`,
      textStyle: isFontSizeMedium ? TextStyle.MediumSmallBold : TextStyle.SmallBold,
      textColor: TextColor.Fail
    }
  ];

  return (
    <>
      {countDown.map(({ isTimeStampVisible, timeStamp, textStyle, textColor, key }) => (
        <Fragment key={key}>
          {isTimeStampVisible && (
            <Text textStyle={textStyle} color={textColor} dataQaId="countdown-remaining-time">
              {timeStamp}
            </Text>
          )}
        </Fragment>
      ))}
    </>
  );
};
export { CountDownRemainingTime };
