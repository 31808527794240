import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Link, LinkColor } from '@carlsberggroup/malty.atoms.link';
import { Text, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { RouterState } from '../../../global/interfaces/routerState';
import styles from './backbutton.module.scss';

export interface Props {
  dataTestId?: string;
  customRouteBack?: () => void;
}

const BackButton = ({ dataTestId = '', customRouteBack }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname, state } = useLocation<RouterState>();
  const shouldGoBackToCatalog =
    (pathname.includes('/products/') && state?.from?.includes('/catalog')) || pathname.includes('/faqs');

  const goBack = () => {
    if (shouldGoBackToCatalog) {
      history.push('/catalog', state);
    } else {
      history.goBack();
    }
  };

  const buttonText = shouldGoBackToCatalog
    ? t('entries.products.backToShop', { defaultValue: 'Back to shopping' })
    : t('entries.products.back', { defaultValue: 'Back' });

  const handleBack = () => {
    if (customRouteBack) {
      return customRouteBack();
    }
    return goBack();
  };

  return (
    <div className={styles.wrapper}>
      {history.length > 1 && (
        <Link color={LinkColor.Support60} onClick={handleBack} dataTestId={dataTestId}>
          <div className={styles.textWrapper}>
            <Icon name={IconName.ChevronLeft} color={IconColor.Support60} size={IconSize.Medium} />
            <Text textStyle={TextStyle.MediumSmallBold} color={TextColor.Support60}>
              {buttonText}
            </Text>
          </div>
        </Link>
      )}
    </div>
  );
};

export { BackButton };
