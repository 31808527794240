import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userConfigUserSelector } from '../../store/slices/user';
import { ConfigContext } from '../contexts/ConfigContext';
import { ListContext } from '../contexts/ListContext';
import { ItemListName, PageType, Platform } from '../google-analytics/tracking';
import { isPWA } from '../google-analytics/utils';
import { User } from '../interfaces/user';
import { useDistributors } from './useDistributors';
import { useGetCurrentPageInfo } from './useGetCurrentPageInfo';

interface GAEventDataProps {
  userId: string;
  localTimestamp: string;
  customerId: string;
  customerName: string;
  customerChannel: string;
  siteEnvironment: string;
  siteLanguage: string;
  siteMarket: string;
  platform: Platform;
  currency: string;
  pageType: PageType;
  listName: ItemListName;
}

export const useGetBaseGAEventData = (): GAEventDataProps => {
  const { selectedOutlet } = useDistributors();
  const user: User = useSelector(userConfigUserSelector);
  const { listName } = useContext(ListContext);
  const { environment, country, currency } = useContext(ConfigContext);
  const { pageType } = useGetCurrentPageInfo();

  const {
    i18n: { language }
  } = useTranslation();

  return {
    userId: user?.eid,
    localTimestamp: new Date().toISOString(),
    customerId: selectedOutlet?.eid,
    customerName: selectedOutlet?.name,
    customerChannel: selectedOutlet?.tradeChannel1Desc,
    siteEnvironment: environment,
    siteLanguage: language,
    siteMarket: country,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    currency: currency.code,
    pageType,
    listName
  };
};
