import React from 'react';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { Product } from '../../../global/interfaces/products';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../../UI-elements/Text';
import { ProductImageBox } from '../ProductImageBox/ProductImageBox';
import { ProductImageBoxLength, ProductImageBoxSize } from '../ProductImageBox/ProductImageBox.types';
import styles from './productItem.module.scss';

interface ProductItemProps {
  product: Product;
  dataTestId: string;
}

const ProductItem = ({ product, dataTestId }: ProductItemProps) => {
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  return (
    <>
      <div key={product.sku} data-testid={dataTestId} className={styles.result}>
        <ProductImageBox
          dataTestId={`search-product-image-box-${product.sku}`}
          boxLength={ProductImageBoxLength.XSMALL}
          images={product.images}
          boxSize={ProductImageBoxSize.XSMALL}
        />
        <div className={styles.productNameWrapper}>
          <div>
            <Text
              color={TEXT_COLOR.DARK_GREY}
              size={isMobile ? FONT_SIZE.MICRO : FONT_SIZE.TINY}
              className={styles.sku}
            >
              {product.barcode ?? `SKU: ${product.sku}`}
            </Text>
            <Text weight={FONT_WEIGHT.BOLD} size={FONT_SIZE.TINY}>
              {product.name}
            </Text>
          </div>
        </div>
      </div>
      {isMobile && <hr className={styles.separator} />}
    </>
  );
};

export { ProductItem };
