import { OPERATION } from '../../components/shared/RewardCurrency/RewardCurrency';
import { PROMOTION_TYPES } from '../constants';
import { Product } from '../interfaces/products';

interface Props {
  product: Product;
  isRedeemableProduct: boolean;
}
const getRewardCurrencyOperator = ({ product, isRedeemableProduct }: Props): OPERATION => {
  const isRewardPointsAvailable = product?.availablePromotionTypes?.find(
    (promotion) => promotion.type === PROMOTION_TYPES.POINTS || promotion.type === PROMOTION_TYPES.POINTS_OLD
  )?.value;

  if (isRedeemableProduct && product.price) {
    return OPERATION.SUBTRACTION;
  }

  if (isRewardPointsAvailable || product.collectablePoints) {
    return OPERATION.SUM;
  }

  return OPERATION.NONE;
};
export { getRewardCurrencyOperator };
