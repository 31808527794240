import { Button, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { IconName } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CART_SECTION_TYPES } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { ProductCardContext } from '../../../global/contexts/ProductCardContext';
import { useCart } from '../../../global/custom-hooks/useCart';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { getIsCartLoading } from '../../../global/selectors/cart/selectors';
import { getCartProduct } from '../../../global/utils/getCartProduct';
import { Counter } from '../../../UI-elements/Counter';
import { ProductCounterPill } from '../ProductImageBox/components/ProductCounterPill';
import styles from './addtocart.module.scss';
import { AddToCartContext } from './AddToCartContext';
import { isProductStockAvailable, isQuantityOutOfBounds } from './helpers';

interface Props {
  primary?: boolean;
  dataTestId?: string;
}

const AddToCart: React.FC<Props> = ({ primary, dataTestId = '' }: Props) => {
  const { t } = useTranslation();
  const { featureFlags } = useContext(ConfigContext);
  const { product, cartSection, searchBarList } = useContext(ProductCardContext);
  const {
    quantity,
    addToCart,
    changeCount,
    handleCountChange,
    maxCounterValue,
    maxQuantity,
    minQuantity,
    isCart
  } = useContext(AddToCartContext);

  const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
  const isMobileAndTablet: boolean = isXSmall || isSmall || isMedium || isLarge;
  const { selectedCart } = useCart();
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const isReward = cartSection === CART_SECTION_TYPES.reward;
  const isCardLoading = useSelector(getIsCartLoading);
  const cartProduct = getCartProduct(selectedCart, product?.sku, isReward);
  const [translate, setTranslate] = React.useState(false);

  const cartProductCount = cartProduct?.quantity ?? 0;

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isQuantityOutOfBounds(isReward, quantity, maxQuantity, cartProductCount)) {
        switch (event.key) {
          case 'Enter':
            addToCart();
            break;
          case 'ArrowDown':
            changeCount(-1, false);
            break;
          case 'ArrowUp':
            changeCount(1, true);
            break;
          default:
            break;
        }
      }
    },
    [isReward, quantity, maxQuantity, cartProductCount]
  );

  if (!selectedCart || !selectedOutlet || !selectedDistributor) {
    return null;
  }

  const isAddToCartButtonDisabled: boolean =
    isQuantityOutOfBounds(isReward, quantity, maxQuantity, cartProductCount) ||
    isProductStockAvailable(product?.stock?.stockStatus, featureFlags) ||
    isCardLoading;

  return (
    <div
      className={cn(styles.addtocart, {
        [styles.searchBarStyle]: searchBarList,
        [styles.primary]: primary,
        [styles.applyTranslate]: translate
      })}
    >
      <div className={styles.counter}>
        <Counter
          dataTestId={`${dataTestId}-counter`}
          value={quantity}
          decrease={() => changeCount(-1, false)}
          increase={() => changeCount(1, true)}
          onChange={(e) => handleCountChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          onFocus={(e) => e.target.select()}
          minValue={minQuantity}
          maxValue={maxCounterValue}
          isInputDisabled={isCardLoading}
          disableQuantityInput={isCart}
        />
      </div>
      {!isCart && (
        <div className={styles.buttonWrapper}>
          <Button
            dataTestId={`${dataTestId}-button`}
            onClick={addToCart}
            icon={isMobileAndTablet && !primary && IconName.Cart}
            text={
              (!isMobileAndTablet || primary) && t('entries.products.addToCartButton', { defaultValue: 'Add to Cart' })
            }
            style={quantity > 0 ? ButtonStyle.Primary : ButtonStyle.Secondary}
            disabled={isAddToCartButtonDisabled}
            fullWidth
          />
          <ProductCounterPill setTranslate={setTranslate} />
        </div>
      )}
    </div>
  );
};

export { AddToCart };
