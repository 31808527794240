import { Pagination } from '@carlsberggroup/malty.molecules.pagination';
import { PaginationTrigger } from '@carlsberggroup/malty.molecules.pagination/dist/Pagination.types';
import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CATALOG_TYPES } from '../../global/constants';
import { ListContext } from '../../global/contexts/ListContext';
import { useFetchCatalog } from '../../global/custom-hooks/useFetchCatalog';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { PaginationTracking } from '../../global/google-analytics';
import { ItemListName, PaginationType } from '../../global/google-analytics/tracking';
import { getActiveChip } from '../../global/selectors/filters/selectors';
import { scrollToTop } from '../../global/utils/scrollToTop';
import { setCurrentPage } from '../../store/slices/catalog';
import styles from './catalog.module.scss';
import { Filters } from './Filters';
import { ListSort } from './ListSort/ListSort';
import { Products } from './Products';

const itemListNameMapper: Record<CATALOG_TYPES, ItemListName> = {
  [CATALOG_TYPES.default]: ItemListName.CatalogList,
  [CATALOG_TYPES.promotions]: ItemListName.PromotionsList,
  [CATALOG_TYPES.picos]: ItemListName.PicosList,
  [CATALOG_TYPES.empties]: ItemListName.EmptiesList,
  [CATALOG_TYPES.reward]: ItemListName.RewardsList,
  [CATALOG_TYPES.favourites]: ItemListName.FavouriteProductsList
};

const CatalogComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeChip = useSelector(getActiveChip);
  const gaEventData = useGetBaseGAEventData();
  const { catalogData } = useFetchCatalog();

  useEffect(() => {
    if (!catalogData?.products) {
      history.replace('/catalog');
    }
  }, [catalogData?.products]);

  const fetchMoreProducts = useCallback((pageNr: number, trigger: PaginationTrigger) => {
    const paginationType = trigger === PaginationTrigger.PageNr ? PaginationType.Number : PaginationType.Arrow;
    const paginationLabel = trigger === PaginationTrigger.PageNr ? pageNr.toString() : trigger;
    TagManager.dataLayer(PaginationTracking({ ...gaEventData, paginationType, paginationLabel }));
    scrollToTop();
    dispatch(setCurrentPage(pageNr - 1));
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('entries.catalog.title', { defaultValue: 'Catalog' })}</title>
      </Helmet>
      <div className={cn(styles.sidebar)}>
        <Filters dataTestId="catalog-filters" />
      </div>
      <div className={styles.catalog}>
        <ListSort />
        <div className={styles.wrapper}>
          <div className={styles['products-container']}>
            <ListContext.Provider value={{ listName: itemListNameMapper[activeChip] }}>
              <Products dataTestId="catalog" renderIconForFavourites />
            </ListContext.Provider>
            {catalogData?.page?.totalPages ? (
              <Pagination
                onChange={fetchMoreProducts}
                currentPage={catalogData?.page?.number + 1}
                count={catalogData?.page?.totalPages}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
const MemoCatalog = React.memo(CatalogComponent);
export { MemoCatalog as CatalogComponent };
