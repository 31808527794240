import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OutletsErrorIllustration } from '../../assets/outlet-error.svg';
import { logout } from '../../auth/authHandler';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { UmbracoErrorPage } from '../../services/headless/types';
import { CloudinaryImage } from '../../UI-elements/CloudinaryImage';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './error.module.scss';

interface Props {
  content: UmbracoErrorPage;
}

const NoOutletsError: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation();

  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  if (!content || !content.background) {
    return null;
  }

  return (
    <div
      className={cn(styles.outlets, {
        [styles.mobile]: isMobile
      })}
    >
      <div>
        <CloudinaryImage dataTestId="logo-image" cssClassName={styles.logo} src={content.logo} alt="header-logo" />
      </div>
      <Text size={FONT_SIZE.LARGE} weight={FONT_WEIGHT.BOLD} align={TEXT_ALIGN.CENTER}>
        {t('entries.error.noOutletsTitle', { defaultValue: 'Outlet Not Found!' })}
      </Text>
      <Text
        align={TEXT_ALIGN.CENTER}
        color={TEXT_COLOR.DARKER_GREY}
        size={isMobile ? FONT_SIZE.SMALL : FONT_SIZE.MEDIUM}
      >
        {t('entries.error.noOutletsParagraph', {
          defaultValue: 'You cannot enter the shop because your account does not have an outlet attached.'
        })}
      </Text>
      <div
        className={cn(styles['outlets-error-image'], {
          [styles.mobile]: isMobile
        })}
      >
        <OutletsErrorIllustration />
      </div>
      <div
        className={cn(styles['bottom-wrapper'], {
          [styles.mobile]: isMobile
        })}
      >
        <Text
          align={TEXT_ALIGN.CENTER}
          color={TEXT_COLOR.DARKER_GREY}
          size={isMobile ? FONT_SIZE.TINY : FONT_SIZE.MEDIUM}
        >
          {t('entries.error.outeltsContactSupport', {
            defaultValue: 'Please contact our online support if you need assistance with this issue.'
          })}
        </Text>
        <div className={styles['button-wrapper']}>
          <Button
            style={ButtonStyle.Secondary}
            size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
            onClick={() =>
              logout({
                redirectUri: `${window.location.protocol}//${window.location.host}/`
              })
            }
            text={t('entries.error.backToLogin', { defaultValue: 'Back to Login' })}
          />
          <Button
            style={ButtonStyle.Primary}
            size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
            onClick={() => {
              window.location.href = `mailto:${content.supportEmail}`;
            }}
            text={t('entries.error.contactSupport', { defaultValue: 'Contact support' })}
          />
        </div>
      </div>
    </div>
  );
};

export { NoOutletsError };
