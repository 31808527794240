import cn from 'classnames';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { useHandleOutsideClick } from '../../global/custom-hooks/useHandleOutsideClick';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { Option } from '../../global/interfaces/elements';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../Text';
import styles from './select.module.scss';

export interface Props {
  dataTestId?: string;
  onChange?: (e?: Option) => void;
  label?: string;
  initialValue?: Option | null;
  options: Option[];
  placeholder?: string;
  hasError?: boolean;
  selectedValueTextSize?: FONT_SIZE;
  selectedValueTextWeight?: FONT_WEIGHT;
  smallerTextBox?: boolean;
}

const Select: React.FC<Props> = ({
  dataTestId = '',
  label,
  initialValue,
  onChange,
  options,
  placeholder,
  hasError,
  selectedValueTextSize = FONT_SIZE.SMALL,
  selectedValueTextWeight = FONT_WEIGHT.REGULAR,
  smallerTextBox
}) => {
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const selectRef: RefObject<HTMLDivElement> = useRef();
  const [showOptionList, setShowOptionList] = useState(false);
  const [selectedValueState, setSelectedValueState] = useState(null);

  useHandleOutsideClick(selectRef, () => {
    if (showOptionList) setShowOptionList(false);
  });

  const toggleOptionList = () => {
    setShowOptionList(!showOptionList);
  };

  const handleOptionSelected = (option: Option) => {
    setShowOptionList(false);
    setSelectedValueState(option);
    onChange(option);
  };

  useEffect(() => {
    setSelectedValueState(initialValue);
  }, [initialValue?.key]);

  const selectedValue = options?.find(({ key }) => key === selectedValueState?.key)?.value;

  return (
    <div data-testid={dataTestId} className={cn(styles.wrapper, { [styles['show-options']]: showOptionList })}>
      {label && (
        <div className={styles.label}>
          <Text
            dataTestId={`${dataTestId}-label`}
            size={isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
            weight={FONT_WEIGHT.BOLD}
          >
            {label}
          </Text>
        </div>
      )}
      <div ref={selectRef} data-testid={`${dataTestId}-select`}>
        <button
          type="button"
          data-testid={`${dataTestId}-select-container`}
          className={cn(styles['select-container'], {
            [styles.error]: hasError,
            [styles.smaller]: smallerTextBox
          })}
          onClick={() => toggleOptionList()}
        >
          <Text
            color={placeholder && !selectedValueState ? TEXT_COLOR.DARK_GREY : TEXT_COLOR.DEFAULT}
            weight={selectedValueTextWeight}
            size={selectedValueTextSize}
            dataTestId={`${dataTestId}-select-text`}
          >
            {selectedValue || placeholder}
          </Text>
          <span data-testid={`${dataTestId}-select-icon`} className={styles.icon}>
            <ChevronDown />
          </span>
        </button>
      </div>
      <ul
        className={cn(styles['option-list'], { [styles['hide-option-list']]: !showOptionList })}
        data-testid={`${dataTestId}-dropdown-option`}
      >
        {options?.map((option: Option) => (
          <li
            data-testid={`${dataTestId}-${option.value.toLowerCase().replace(/\s/g, '-')}`}
            key={option.key}
            onClick={() => handleOptionSelected(option)}
          >
            <Text
              size={FONT_SIZE.SMALL}
              weight={selectedValueState?.key === option.key ? FONT_WEIGHT.SEMI_BOLD : FONT_WEIGHT.REGULAR}
            >
              {option.value}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { Select };
