import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProductSlider } from '../../../../components/ProductSlider';
import { ProductCard } from '../../../../components/shared/ProductCard';
import { CATALOG_TYPES } from '../../../../global/constants';
import { useDistributors } from '../../../../global/custom-hooks/useDistributors';
import { HeadlessImageI } from '../../../../global/interfaces/headless';
import { getTodayInIsoFormat } from '../../../../global/utils/getToday';
import { useGetCatalogProductsQuery } from '../../../../services/products/products';
import { setCatalogActiveChip } from '../../../../store/slices/catalog';

export interface PromotionsProductsProps {
  limit?: number;
  title: string;
  backgroundImage?: HeadlessImageI;
  type: CATALOG_TYPES.promotions;
}

const PromotionsProductSlider = ({ limit = 12, title, backgroundImage, type }: PromotionsProductsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const url = '/catalog';
  const cta = {
    URL: url,
    text: t('entries.favourites.seeAll', { defaultValue: 'See all' }),
    action: () => {
      dispatch(setCatalogActiveChip(CATALOG_TYPES.promotions));
    }
  };

  const params = {
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    deliveryDate: getTodayInIsoFormat(),
    size: limit,
    withFilters: false,
    withSortMode: false,
    type,
    distributorId: selectedDistributor?.eid
  };

  const { data, error, isFetching } = useGetCatalogProductsQuery(params, {
    skip: !selectedOutlet || !selectedDistributor
  });

  if (isFetching || error || !data?.products.length || !selectedOutlet || !selectedDistributor) {
    return null;
  }

  return (
    <div data-testid={`promotions-slider-${type}`}>
      <ProductSlider
        backgroundImage={backgroundImage}
        products={data?.products}
        title={title}
        linkBtn={{ label: cta.text, url: cta.URL, onClick: () => cta?.action() }}
      >
        {data?.products.map((productWithVariants) => {
          const dataTestId = `slider-${type}`;
          return (
            <ProductCard.Root
              dataTestId={dataTestId}
              productWithVariants={productWithVariants}
              activeType={type}
              key={productWithVariants.sku}
              renderIconFavourites
            >
              <ProductCard.SKULabel dataTestId={dataTestId} />
              <ProductCard.Name dataTestId={dataTestId} />
              <ProductCard.Container dataTestId={dataTestId}>
                <ProductCard.RewardCurrency dataTestId={dataTestId} />
              </ProductCard.Container>
              <ProductCard.StockLabel dataTestId={dataTestId} />
              <ProductCard.Variants />
            </ProductCard.Root>
          );
        })}
      </ProductSlider>
    </div>
  );
};
export { PromotionsProductSlider };
