import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { Loading } from '../../components/shared/Loading';
import { CSPlusHostConfig } from '../../config/configTypes';
import { getCSPlusConfig } from '../../config/getConfig';
import { getHostSettings } from '../../config/getHostSettings';
import { CSPlusConfig } from '../custom-hooks/useConfig';

export interface AppConfigResponse {
  environment: string;
  url: string;
  'e.identity': string;
  configs: CSPlusHostConfig[];
}

export type ConfigContextProviderTypes = {
  config: CSPlusConfig;
  children: ReactNode;
};

export type withConfigContextTypes = Omit<ConfigContextProviderTypes, 'config'>;

export const ConfigContext = createContext<CSPlusConfig>(null);

export const withConfigContext = (Component: typeof Provider) => ({ children }: withConfigContextTypes) => {
  const [config, setConfig] = useState<CSPlusConfig>(undefined);

  useEffect(() => {
    const fetchConfig = async () => {
      const appConfig: AppConfigResponse = await getCSPlusConfig();
      const { configs, ...rest } = appConfig;
      const configParsedByHostname: CSPlusHostConfig = getHostSettings(configs);
      const { enableRewards } = configParsedByHostname.featureFlags;

      setConfig({
        ...rest,
        ...configParsedByHostname,
        featureFlags: { ...configParsedByHostname.featureFlags, enableRewards }
      });
    };
    fetchConfig();
  }, []);

  if (!config) {
    return <Loading fullScreen />;
  }

  return <Component config={config}>{children}</Component>;
};

const Provider: FC<ConfigContextProviderTypes> = ({ config, children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

export const ConfigContextProvider = withConfigContext(Provider);
