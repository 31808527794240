import React, { useMemo } from 'react';
import { app, CART_SECTION_TYPES } from '../../../global/constants';
import { useCart } from '../../../global/custom-hooks/useCart';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { Product } from '../../../global/interfaces/products';
import { getCartProduct } from '../../../global/utils/getCartProduct';
import { useGetLoyaltyBalanceQuery } from '../../../services/loyaltyBalance/loyaltyBalance';
import { useAddToCart } from './useAddToCart';

export type AddToCartContextTypes = ReturnType<typeof useAddToCart> & {
  minQuantity: number;
  maxQuantity: number;
  maxCounterValue: number;
  isCart: boolean;
};

const initialDebouncedAddToCart = (_amount: number, _isIncreaseCount?: boolean) =>
  new Promise((resolve) => resolve(null)) as Promise<null>;
initialDebouncedAddToCart.clear = (): void => null;
initialDebouncedAddToCart.flush = (): void => null;

export const AddToCartContext = React.createContext<AddToCartContextTypes>({
  minQuantity: app.minAddToCart,
  maxQuantity: app.cartLimit,
  maxCounterValue: app.cartLimit,
  addToCart: () => null,
  changeCount: () => null,
  debouncedAddToCart: initialDebouncedAddToCart,
  handleCountChange: () => null,
  quantity: 0,
  isCart: false
});

const getCounterMaxValue = ({
  maxOrderableQuantity,
  cartQuantity,
  isCart
}: {
  maxOrderableQuantity?: number;
  cartQuantity: number;
  isCart: boolean;
}) => {
  if (isCart) {
    return maxOrderableQuantity;
  }

  return maxOrderableQuantity - cartQuantity;
};

export type MaxQuantityHOCTypes = {
  children: (data: { maxQuantity: number; maxCounterValue: number }) => React.ReactNode;
  product: Product;
  isCart: boolean;
};

export type withMaxQuantityTypes = Omit<AddToCartProviderTypes, 'maxCounterValue' | 'maxQuantity'>;

export const withRewardMaxQuantity = (Component: typeof AddToCartProvider) => ({
  product,
  isCart,
  cartSection,
  children
}: withMaxQuantityTypes) => {
  const { selectedCart } = useCart();
  const { selectedOutlet } = useDistributors();
  const cartRewardProduct = useMemo(() => getCartProduct(selectedCart, product?.sku, true), [selectedCart, product]);
  const { data: balance } = useGetLoyaltyBalanceQuery(
    {
      salesOrg: selectedOutlet?.salesOrg,
      outletId: selectedOutlet?.eid
    },
    {
      skip: !selectedOutlet
    }
  );

  const currentBalanceAfterCartItemsCost =
    (balance?.availablePoints ?? 0) - (selectedCart?.total?.totalRedeemablePoints ?? 0);

  const maxQuantity = Math.floor(currentBalanceAfterCartItemsCost / (product?.price?.base || 1));
  const maxCounterValue = getCounterMaxValue({
    maxOrderableQuantity: maxQuantity,
    cartQuantity: cartRewardProduct?.quantity ?? 0,
    isCart
  });

  return (
    <Component
      cartSection={cartSection}
      product={product}
      isCart={isCart}
      maxQuantity={maxQuantity}
      maxCounterValue={maxCounterValue}
    >
      {children}
    </Component>
  );
};

export const withProductMaxQuantity = (Component: typeof AddToCartProvider) => ({
  product,
  isCart,
  cartSection,
  children
}: withMaxQuantityTypes) => {
  const { selectedCart } = useCart();
  const cartProduct = useMemo(() => getCartProduct(selectedCart, product?.sku, false), [selectedCart, product]);

  const maxCounterValue = getCounterMaxValue({
    maxOrderableQuantity: product.maxQtyOrderable,
    cartQuantity: cartProduct?.quantity ?? 0,
    isCart
  });

  return (
    <Component
      cartSection={cartSection}
      product={product}
      isCart={isCart}
      maxQuantity={product.maxQtyOrderable || app.cartLimit}
      maxCounterValue={maxCounterValue}
    >
      {children}
    </Component>
  );
};

export type AddToCartProviderTypes = {
  product: Product;
  cartSection: CART_SECTION_TYPES;
  isCart: boolean;
  maxCounterValue: number;
  maxQuantity: number;
  children: React.ReactNode;
};

export const AddToCartProvider: React.FC<AddToCartProviderTypes> = ({
  product,
  cartSection,
  isCart,
  maxCounterValue,
  maxQuantity,
  children
}) => {
  const { quantity, addToCart, changeCount, handleCountChange, debouncedAddToCart } = useAddToCart({
    product,
    cartSection,
    isCart
  });

  return (
    <AddToCartContext.Provider
      value={{
        quantity,
        addToCart,
        changeCount,
        handleCountChange,
        maxCounterValue,
        debouncedAddToCart,
        maxQuantity,
        minQuantity: app.minAddToCart,
        isCart
      }}
    >
      {children}
    </AddToCartContext.Provider>
  );
};
