import { PROMOTION_TYPES } from '../constants';
import { SKU } from '../types';
import { Links } from './links';

export interface PriceI {
  base: number;
  discount?: number;
  total?: number;
  totalDeposits?: number;
  standard?: number;
  unit?: number;
}

export interface Image {
  url: string;
  size: string;
}

export interface Package {
  unit: string;
  type?: string;
  size: string;
}

export interface Promotion {
  type: string;
  code: string;
  name: string;
  title: string;
  description: string;
}

export interface Category {
  code: string;
}

export interface Brand {
  code: string;
}

export interface SubCategory {
  code: string;
}

export interface DetailsInfo {
  brand?: Brand;
  categories?: Category[];
  subCategories?: SubCategory[];
}

export enum StockStatus {
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  LOW_QUANTITY = 'LOW_QUANTITY',
  IN_STOCK = 'IN_STOCK',
  NO_STOCK_INFO = 'NO_STOCK_INFO',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

export interface StockData {
  stockStatus: StockStatus;
}

export interface Product {
  salesOrg: string;
  sku: SKU;
  baseSku: SKU;
  barcode?: string;
  name?: string;
  label?: string;
  isDefault?: boolean;
  currency?: string;
  type?: string;
  price?: PriceI;
  stock?: StockData;
  availablePromotionTypes?: PromotionTypes[];
  images: Image[];
  availablePromotions?: Promotion[];
  package: Package;
  quantity?: number;
  description?: string;
  detailsInfo?: DetailsInfo;
  deposits?: {
    totalDeposits: number;
    depositsCollection: Deposit[];
  };
  _links?: Links;
  isNew?: boolean;
  isReturnable?: boolean;
  alcohol?: number;
  liquidType?: string;
  filtration?: string;
  countryOfOrigin?: string;
  maxQtyOrderable?: number;
  fittingType?: string;
  ingredients?: string;
  ibu?: number;
  originalGravity?: number;
  nutritionFacts?: number;
  isPasteurized?: boolean;
  isFiltered?: boolean;
  collectablePoints?: number;
  qualifyingQuantity?: number;
}

export interface Deposit {
  sku: string;
  quantity: number;
  basePrice: number;
  totalPrice: number;
  product: DepositProduct;
}

export interface DepositProduct {
  salesOrg: string;
  baseSku: string;
  sku: string;
  name: string;
  label: string;
  type: string;
  images: Image[];
  detailsInfo: DetailsInfo;
  isNew: boolean;
  isDefault: boolean;
  package: Package;
}

export interface ProductWithVariants {
  salesOrg: string;
  sku: SKU;
  isFavourite?: boolean;
  variants: Product[];
}

export interface PromotionTypes {
  value: number;
  type: PROMOTION_TYPES;
}
