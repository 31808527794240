import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { FLAG_ICON_HEIGHT, FLAG_ICON_WIDTH } from '../../../../../global/constants';
import { useLanguageSelector } from '../../../../../global/custom-hooks/useLanguageSelector';
import { useLocales } from '../../../../../global/custom-hooks/useLocales';
import { Language } from '../../../../../global/interfaces/language';
import { FlagIcon } from '../../../Languages/FlagIcon';
import styles from './language-selector.module.scss';

type LanguageGroupProps = {
  onCloseMenu: () => void;
};

export const LanguageGroup = ({ onCloseMenu }: LanguageGroupProps) => {
  const { locales } = useLocales();
  const { handleSelectLanguage, currentLanguage } = useLanguageSelector({ handleToggleSelect: onCloseMenu });

  const handleOnClick = (locale: Language) => {
    handleSelectLanguage(locale);
    onCloseMenu();
  };

  const memorizedLanguages = React.useMemo(
    () => (
      <>
        {locales.length > 0 && (
          <div className={styles.group}>
            {locales.map((locale) => (
              <button
                aria-pressed={currentLanguage === locale ? 'true' : 'false'}
                type="button"
                onClick={() => handleOnClick(locale)}
                key={locale.isoCode}
              >
                <FlagIcon width={FLAG_ICON_WIDTH} height={FLAG_ICON_HEIGHT} name={locale.flag} />
                <Text textStyle={currentLanguage === locale ? TextStyle.MediumSmallBold : TextStyle.MediumSmallDefault}>
                  {locale.name}
                </Text>
              </button>
            ))}
          </div>
        )}
      </>
    ),
    [locales, currentLanguage]
  );

  return memorizedLanguages;
};
