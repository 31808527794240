import { Text, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { IArticles } from '../../../../services/discover/types';
import { getCloudinaryFetchImageUrl } from '../../../../UI-elements/CloudinaryImage';
import styles from './articlesList.module.scss';

interface Props {
  article: IArticles;
}
const Article: React.FC<Props> = ({ article }) => {
  // format date DD Mon YYYY
  const date = new Date(article.date);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  return (
    <Link to={`discover/${article.id}`} className={styles.article} data-testid="article-item">
      <div
        style={{
          backgroundImage: `url(${getCloudinaryFetchImageUrl(article.image?.url)})`
        }}
        className={styles.articleImage}
      />

      <div className={classnames(styles.articleDetails)}>
        <div className={classnames(styles.articleTitle)}>
          <Text dataQaId={`article-name-${article.id}`} textStyle={TextStyle.MediumSmallBold}>
            {article.headline}
          </Text>
        </div>
        <div className={classnames(styles.articleDescription)}>
          <Text dataQaId={`article-description-${article.id}`} textStyle={TextStyle.MediumSmallDefault}>
            {article.text}
          </Text>
        </div>
        <Text textStyle={TextStyle.SmallBold} color={TextColor.Support60}>
          {formattedDate}
        </Text>
      </div>
    </Link>
  );
};
export default Article;
