import { autoUpdate, offset, useDismiss, useFloating, useFocus, useInteractions, useRole } from '@floating-ui/react';
import React from 'react';
import { TooltipOptions } from '../../components/shared/FloatTooltip/FloatTooltipRoot';
import { useScreenSize } from './useScreenSize';

export const useFloatTooltip = ({
  initialOpen = false,
  placement,
  open: controlledOpen,
  onOpenChange: setControlledOpen
}: TooltipOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: isMobile ? 20 : 6,
        alignmentAxis: isMobile ? null : 10
      })
    ],
    strategy: 'fixed'
  });

  const { context } = data;

  const focus = useFocus(context, {
    enabled: controlledOpen == null
  });

  const dismiss = useDismiss(context, {
    enabled: false
  });

  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data
    }),
    [open, setOpen, interactions, data]
  );
};
