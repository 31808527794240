import { State } from '../../../store/reducers';
import { CATALOG_TYPES } from '../../constants';

const getCatalog = (state: State) => state.catalog;

export const getActiveChip = (state: State): CATALOG_TYPES => {
  const catalog = getCatalog(state);

  if (!catalog || !catalog?.activeChip) {
    return CATALOG_TYPES.default;
  }

  return catalog?.activeChip;
};

export const getFiltersToggle = (state: State) => {
  const catalog = getCatalog(state);

  return catalog?.isFiltersOpen;
};

export const getFilters = (state: State) => state.catalog.filters?._embedded?.filters;
