import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../UI-elements/Button';
import styles from './createButton.module.scss';

interface Props {
  isValid: boolean;
  onClick: () => void;
  isLoading: boolean;
}

export const CreateButton: React.FC<Props> = ({ onClick, isValid = false, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Button
      text={t('entries.favourites.create', { defaultValue: 'Create' })}
      action={onClick}
      disabled={!isValid}
      dataTestId="create-favourite-list-button"
      className={styles.createButton}
      loading={isLoading}
    />
  );
};
