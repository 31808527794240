import { Placement } from '@floating-ui/react';
import React from 'react';
import { FloatTooltipContext } from '../../../global/contexts/FloatTooltipContext';
import { useFloatTooltip } from '../../../global/custom-hooks/useFloatTooltip';

export interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const FloatTooltipRoot = ({ children, ...options }: { children: React.ReactNode } & TooltipOptions) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useFloatTooltip(options);
  return <FloatTooltipContext.Provider value={tooltip}>{children}</FloatTooltipContext.Provider>;
};

export { FloatTooltipRoot };
