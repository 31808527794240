import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DistributorErrorIllustration } from '../../assets/outlet-error.svg';
import { logout } from '../../auth/authHandler';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { UmbracoErrorPage } from '../../services/headless/types';
import { CloudinaryImage } from '../../UI-elements/CloudinaryImage';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './error.module.scss';

interface Props {
  content: UmbracoErrorPage;
}

const NoDistributorError: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation();

  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  if (!content || !content.background) {
    return null;
  }

  return (
    <div
      className={cn(styles.outlets, {
        [styles.mobile]: isMobile
      })}
    >
      <div>
        <CloudinaryImage dataTestId="logo-image" cssClassName={styles.logo} src={content.logo} alt="header-logo" />
      </div>
      <Text size={FONT_SIZE.LARGE} weight={FONT_WEIGHT.BOLD} align={TEXT_ALIGN.CENTER} uppercase>
        {t('entries.error.noDistributorTitle', { defaultValue: 'Distributor Not Available' })}
      </Text>
      <Text align={TEXT_ALIGN.CENTER} color={TEXT_COLOR.DARKER_GREY} size={FONT_SIZE.SMALL}>
        {t('entries.error.noDistributorParagraph', {
          defaultValue: 'Sorry, there is no associated distributor to the outlet.'
        })}
      </Text>
      <div
        className={cn(styles['outlets-error-image'], {
          [styles.mobile]: isMobile
        })}
      >
        <DistributorErrorIllustration />
      </div>
      <div
        className={cn(styles['bottom-wrapper'], {
          [styles.mobile]: isMobile
        })}
      >
        <div className={styles['button-wrapper']}>
          <Button
            style={ButtonStyle.Secondary}
            size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
            onClick={() => {
              window.location.href = `mailto:${content.supportEmail}`;
            }}
            text={t('entries.error.contactSupport', { defaultValue: 'Contact support' })}
          />
          <Button
            style={ButtonStyle.Primary}
            size={isMobile ? ButtonSize.Large : ButtonSize.Medium}
            onClick={() =>
              logout({
                redirectUri: `${window.location.protocol}//${window.location.host}/`
              })
            }
            text={t('entries.error.backToLogin', { defaultValue: 'Back to Login' })}
          />
        </div>
      </div>
    </div>
  );
};

export { NoDistributorError };
