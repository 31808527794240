import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { useTrackedChanges } from '../../global/custom-hooks/useTrackedChanges';
import { DistributorI } from '../../global/interfaces/distributor';
import { Outlet } from '../../global/interfaces/outlet';
import { Button } from '../../UI-elements/Button';
import { Info } from '../../UI-elements/Info';
import { INFO_SIZES, INFO_TYPES } from '../../UI-elements/Info/Info';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN } from '../../UI-elements/Text';
import distributorStyles from '../shared/Distributor/distributor.module.scss';
import { Modal } from '../shared/Modal/ModalComponent';
import { ProductItem } from '../shared/ProductItem';
import styles from './trackedChanges.module.scss';

interface Props {
  uiSelectedOutlet: Outlet;
  uiSelectedDistributor: DistributorI;
  destroyDistributorModal: () => void;
  confirmOutletDistributor: (props: { outlet: Outlet; distributor: DistributorI }) => void;
  setDistributorModalVisibility: (isShown: boolean) => void;
  setIsConfirming: (isConfirming: boolean) => void;
  onTrackChangesCancel: () => void;
}

const TrackChangesModal: React.FC<Props> = ({
  uiSelectedOutlet,
  uiSelectedDistributor,
  destroyDistributorModal,
  confirmOutletDistributor,
  setDistributorModalVisibility,
  setIsConfirming,
  onTrackChangesCancel
}) => {
  const { t } = useTranslation();
  const { isXSmall, isSmall } = useScreenSize();
  const isModalFullWidth = isXSmall || isSmall;
  const { trackedChangesData } = useTrackedChanges(uiSelectedOutlet, uiSelectedDistributor);
  const { freeItems, items, returnItems } = trackedChangesData || {};
  const cartHasTrackedChanges =
    freeItems?.removed.length > 0 || items?.removed.length > 0 || returnItems?.removed.length > 0;

  const [isTrackedChangesOpen, setTrackedChangesModalOpen] = useState(false);

  const confirm = () => {
    confirmOutletDistributor({ outlet: uiSelectedOutlet, distributor: uiSelectedDistributor });
  };

  useEffect(() => {
    if (trackedChangesData) {
      if (cartHasTrackedChanges) {
        setIsConfirming(false);
        setTrackedChangesModalOpen(true);
        setDistributorModalVisibility(false);
      } else {
        confirmOutletDistributor({ outlet: uiSelectedOutlet, distributor: uiSelectedDistributor });
      }
    }
  }, [trackedChangesData]);

  return (
    <Modal
      dataTestId="tracked-changes-modal"
      active={isTrackedChangesOpen}
      closeModal={destroyDistributorModal}
      hideOverlay={isModalFullWidth}
      showCloseButton
      showLogo={isModalFullWidth}
      fixedClose={isModalFullWidth}
      theme={{
        content: distributorStyles.modalContainer,
        children: styles.flex
      }}
    >
      <Text
        className={styles.title}
        size={FONT_SIZE.MEDIUM_LARGE}
        weight={FONT_WEIGHT.SEMI_BOLD}
        align={TEXT_ALIGN.CENTER}
      >
        {t('entries.trackedChanges.title', {
          defaultValue:
            'Some items in your Cart are not available with the distributor you´re selecting. Do you still wish to proceed?'
        })}
      </Text>
      <Info type={INFO_TYPES.WARNING} size={INFO_SIZES.LARGE}>
        {t('entries.trackedChanges.alert', {
          defaultValue:
            'By changing distributor you are going to lose the following items in your cart, because they are not available in the assortment.'
        })}
      </Info>
      <Text className={styles.subtitle} weight={FONT_WEIGHT.SEMI_BOLD} size={FONT_SIZE.TINY}>
        {t('entries.trackedChanges.subtitle', {
          defaultValue: 'Items that you´ll lose in the cart'
        })}
      </Text>
      {cartHasTrackedChanges && (
        <div className={styles.itemsWrapper}>
          {trackedChangesData.freeItems.removed.map((item) => (
            <ProductItem key={item.sku} product={item} dataTestId="tracked-changes-free-items" />
          ))}
          {trackedChangesData.items.removed.map((item) => (
            <ProductItem key={item.sku} product={item} dataTestId="tracked-changes-items" />
          ))}
          {trackedChangesData.returnItems.removed.map((item) => (
            <ProductItem key={item.sku} product={item} dataTestId="tracked-changes-return-items" />
          ))}
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        <Button
          type="black-outline"
          text={t('entries.actions.cancel', {
            defaultValue: 'Cancel'
          })}
          action={onTrackChangesCancel}
        />
        <Button
          text={t('entries.actions.confirm', {
            defaultValue: 'Confirm'
          })}
          action={confirm}
        />
      </div>
    </Modal>
  );
};

export { TrackChangesModal };
