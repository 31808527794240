import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { Drawer } from '../shared/Drawer';
import { AlternativesContent } from './components/ModalContent/AlternativesContent';
import { alternativeProductsSelector, closeAlternativesModal } from './slice/alternativeProducts';

type Props = {
  dataTestId?: string;
};

const AlternativesModal = ({ dataTestId = 'alternatives-modal' }: Props) => {
  const dispatch = useDispatch();
  const { featureFlags } = React.useContext(ConfigContext);
  const { isModalVisible } = useSelector(alternativeProductsSelector);
  const { t } = useTranslation();

  if (!featureFlags?.showAlternativeProducts) {
    return null;
  }

  const onCloseClick = () => {
    dispatch(closeAlternativesModal());
  };

  return (
    <Drawer
      dataTestId={`${dataTestId}-drawer`}
      isOpen={isModalVisible}
      onClose={onCloseClick}
      zIndex={7}
      title={t('entries.alternatives.title', { defaultValue: 'Select an alternative' })}
    >
      {isModalVisible ? <AlternativesContent dataTestId={dataTestId} onClose={onCloseClick} /> : null}
    </Drawer>
  );
};

export { AlternativesModal };
