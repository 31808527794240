import { Headline, HeadlineStyle } from '@carlsberggroup/malty.atoms.headline';
import { Text, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import cn from 'classnames';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BackButton } from '../../components/shared/BackButton';
import { Loading } from '../../components/shared/Loading';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { PageContext } from '../../global/contexts/PageContext';
import { useCurrentLanguage } from '../../global/custom-hooks/useCurrentLanguage';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { PageViewTracking } from '../../global/google-analytics';
import { PageType } from '../../global/google-analytics/tracking';
import { Language } from '../../global/interfaces/language';
import { umbracoParser } from '../../global/utils/umbracoParser';
import { useGetHeadlessDataByPageHandleQuery } from '../../services/headless/headlessApi';
import { EnterspeedHandles } from '../../services/headless/types';
import { useFetchMilestonesQuery } from '../../services/lottery';
import { userConfigSelector } from '../../store/slices/user';
import { CircularProgressWrapper } from './components/CircularProgressWrapper';
import { CustomizedProgressBar } from './components/CustomizedProgressBar';
import { CongratulateModalEnum, PrizeLotteryModal } from './components/PrizeLotteryModal/PrizeLotteryModal';
import { Stepper } from './components/Stepper';
import { StepEnum } from './components/Stepper/Stepper';
import styles from './PrizeLottery.module.scss';

export type LotteryMilestonesProps = {
  eid: string;
  status: StepEnum;
  showCongratulations: CongratulateModalEnum;
  milestones: {
    milestoneId: string;
    status: StepEnum;
    from: string;
    to: string;
    current?: boolean;
    goals: {
      type: string;
      value: number;
      progress: number;
    }[];
  }[];
};

const useGetLotteryMilestone = () => {
  const { selectedOutlet } = useDistributors();
  const { data: responseData, ...props } = useFetchMilestonesQuery({
    outletId: selectedOutlet?.eid,
    salesOrg: selectedOutlet?.salesOrg
  });

  const parserCurrentWeek = () => ({
    eid: responseData?.eid,
    status: responseData?.status,
    showCongratulations: responseData?.showCongratulations,
    milestones: responseData?.milestones?.map((milestone) => {
      const today = new Date();
      const from = new Date(milestone.from);
      const to = new Date(milestone.to);
      if (!milestone?.current) {
        const current = today >= from && today <= to;
        return {
          ...milestone,
          current
        };
      }
      return milestone;
    })
  });

  return {
    data: parserCurrentWeek(),
    ...props
  };
};

const PrizeLottery: FC<EmptyObject> = () => {
  const { t } = useTranslation();
  const { isXSmall, isSmall } = useScreenSize();
  const { environment } = useContext(ConfigContext);
  const {
    i18n: { language }
  } = useTranslation();
  const userConfig = useSelector(userConfigSelector);
  const { selectedOutlet } = useDistributors();
  const isMobile = isXSmall || isSmall;
  const gaEventData = useGetBaseGAEventData();
  const [isTrackingCalled, setIsTrackingCalled] = useState(false);
  const { data } = useGetLotteryMilestone();
  const currentLanguage: Language | null = useCurrentLanguage();

  const headlessParams = {
    handle: EnterspeedHandles.LOTTERY,
    languageCode: language,
    contentDelivery: true,
    salesOrg: selectedOutlet?.salesOrg
  };
  const headlessRequestSkipParams = {
    skip: !currentLanguage?.isoCode
  };
  const { data: umbracoLotteryData, isLoading } = useGetHeadlessDataByPageHandleQuery(
    headlessParams,
    headlessRequestSkipParams
  );
  const lotteryData = umbracoLotteryData?.views?.lotteryPrizePage?.content;
  const hideCircleProgress = !lotteryData?.rightPanel?.circleText;

  useEffect(() => {
    if (!selectedOutlet || !language || !userConfig || !environment || isTrackingCalled) return;
    TagManager.dataLayer(PageViewTracking({ ...gaEventData }));
    setIsTrackingCalled(true);
  }, [language, userConfig, environment, isTrackingCalled, selectedOutlet]);

  const milestonesProgress = useMemo(() => {
    const completed = data?.milestones?.reduce((acc, milestone) => {
      if (milestone.status === StepEnum.COMPLETED) {
        return acc + 1;
      }
      return acc;
    }, 0);
    const currentMilestone = data?.milestones?.find((milestone) => milestone.current);
    const totalOfMilestones = data?.milestones?.length;
    return {
      completed,
      total: totalOfMilestones,
      currentMilestoneProgress: currentMilestone?.goals[0] ?? null
    };
  }, [data?.milestones]);

  return (
    <PageContext.Provider value={{ pageType: PageType.Details }}>
      <Helmet>
        <title>{t('entries.tabs.prizeLottery', { defaultValue: 'Prize Lottery' })}</title>
      </Helmet>
      <BackButton dataTestId="prize-lottery-back-button" />
      {isLoading ? (
        <Loading fullScreen />
      ) : (
        <div
          className={cn(styles.container, {
            [styles.noCircleProgress]: hideCircleProgress
          })}
        >
          <Headline dataTestId="lottery-title" as="h1" headlineStyle={HeadlineStyle.Big}>
            {lotteryData?.title}
          </Headline>

          {lotteryData?.description ? (
            <Text
              className={styles['title-area--subtitle']}
              textStyle={isMobile ? TextStyle.MediumDefault : TextStyle.SmallDefault}
              color={TextColor.Support80}
            >
              {lotteryData?.description}
            </Text>
          ) : null}

          <div
            className={cn(styles['widgets-area'], {
              [styles.noCircleProgressFlexCol]: hideCircleProgress
            })}
          >
            <div className={styles['progress-wrapper']} data-testid="lottery-progress-wrapper">
              {data?.milestones?.length ? (
                <div className={styles['stepper-wrapper']}>
                  <Stepper hasGiftIcon milestones={data?.milestones} />
                </div>
              ) : null}

              <CustomizedProgressBar
                label={lotteryData?.timeline?.description}
                current={milestonesProgress?.currentMilestoneProgress?.progress ?? 0}
                total={milestonesProgress?.currentMilestoneProgress?.value ?? 1}
              />
            </div>

            <div className={styles['info-block']} data-testid="lottery-info-block">
              {umbracoParser(lotteryData?.body?.textAsJson)}
            </div>
            {!hideCircleProgress ? (
              <div className={styles['circular-progress-bar-wrapper']} data-testid="lottery-circle-progress-bar">
                <div className={styles['absolute-item']}>
                  {data.milestones?.length ? (
                    <CircularProgressWrapper
                      progressLabel={lotteryData?.rightPanel?.circleText}
                      total={milestonesProgress.total}
                      completed={milestonesProgress.completed}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            <div
              className={cn(styles['prizes-block'], {
                [styles.noCircleProgressFlexCol]: hideCircleProgress
              })}
              data-testid="lottery-prizes-block"
            >
              <div className={styles['weekly-block']}>{umbracoParser(lotteryData?.body?.bottomTextAsJson)}</div>
              <div className={styles['monthly-block']}>{umbracoParser(lotteryData?.rightPanel?.bottomTextAsJson)}</div>
            </div>
          </div>
        </div>
      )}
      <PrizeLotteryModal showCongratulations={data?.showCongratulations} />
    </PageContext.Provider>
  );
};
export { PrizeLottery, useGetLotteryMilestone };
