import { CATALOG_TYPES, PROMOTIONS_FILTER_CODE, RECOMMENDATIONS_CODES } from '../../../global/constants';
import { CSPlusConfig } from '../../../global/custom-hooks/useConfig';

export type ChipsType = {
  i18n: {
    key: string;
    defaultValue: string;
  };
  code: CATALOG_TYPES | RECOMMENDATIONS_CODES;
  blacklistedFilters?: string[];
  isVisible: boolean;
};

export const getChips = ({ featureFlags: { showEmpties } }: CSPlusConfig) =>
  new Map<CATALOG_TYPES | RECOMMENDATIONS_CODES, ChipsType>([
    [
      CATALOG_TYPES.default,
      {
        i18n: {
          key: 'entries.tabs.allProducts',
          defaultValue: 'All Products'
        },
        code: CATALOG_TYPES.default,
        isVisible: true,
        blacklistedFilters: [PROMOTIONS_FILTER_CODE]
      }
    ],
    [
      CATALOG_TYPES.promotions,
      {
        i18n: {
          key: 'entries.tabs.promotions',
          defaultValue: 'Promotions'
        },
        code: CATALOG_TYPES.promotions,
        isVisible: true
      }
    ],
    [
      CATALOG_TYPES.empties,
      {
        i18n: {
          key: 'entries.tabs.empties',
          defaultValue: 'Empties'
        },
        code: CATALOG_TYPES.empties,
        isVisible: showEmpties
      }
    ],
    [
      CATALOG_TYPES.picos,
      {
        i18n: {
          key: 'entries.tabs.picos',
          defaultValue: 'Our Choices'
        },
        code: CATALOG_TYPES.picos,
        isVisible: true
      }
    ],
    [
      RECOMMENDATIONS_CODES.popular,
      {
        i18n: {
          key: 'entries.recommendations.popular',
          defaultValue: 'Popular'
        },
        code: RECOMMENDATIONS_CODES.popular,
        isVisible: true
      }
    ],
    [
      RECOMMENDATIONS_CODES.trending,
      {
        i18n: {
          key: 'entries.recommendations.trending',
          defaultValue: 'Trending'
        },
        code: RECOMMENDATIONS_CODES.trending,
        isVisible: true
      }
    ],
    [
      RECOMMENDATIONS_CODES.recentlyBought,
      {
        i18n: {
          key: 'entries.recommendations.recentlyBought',
          defaultValue: 'Recently Bought'
        },
        code: RECOMMENDATIONS_CODES.recentlyBought,
        isVisible: true
      }
    ],
    [
      RECOMMENDATIONS_CODES.new,
      {
        i18n: {
          key: 'entries.recommendations.new',
          defaultValue: 'New'
        },
        code: RECOMMENDATIONS_CODES.new,
        isVisible: true
      }
    ]
  ]);
