import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FullBrightGift } from '../../../../assets/gift-100.svg';
import { ReactComponent as HalfBrightGift } from '../../../../assets/gift-half.svg';
import { ReactComponent as NonColorGift } from '../../../../assets/gift-non-color.svg';
import { ReactComponent as OpenGift } from '../../../../assets/gift-open.svg';
import { LotteryMilestonesProps } from '../../PrizeLottery';
import styles from './stepper.module.scss';

export enum StepEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED'
}

interface StepperProps {
  hasGiftIcon?: boolean;
  milestones?: LotteryMilestonesProps['milestones'];
}

export const Stepper = ({ hasGiftIcon = false, milestones }: StepperProps) => {
  const { t } = useTranslation();

  const getGiftIcon = (stepItem: StepEnum, isCurrentWeek: boolean) => {
    switch (stepItem) {
      case StepEnum.COMPLETED:
        return isCurrentWeek ? (
          <OpenGift data-testid="open-gift-icon" className={styles['step-icon']} />
        ) : (
          <OpenGift data-testid="half-bright-open-gift-icon" className={styles['step-icon']} style={{ opacity: 0.5 }} />
        );
      case StepEnum.FAILED:
        return <NonColorGift data-testid="non-bright-gift-icon" className={styles['step-icon']} />;
      case StepEnum.IN_PROGRESS:
        return isCurrentWeek ? (
          <FullBrightGift data-testid="gift-icon" className={styles['step-icon']} />
        ) : (
          <HalfBrightGift data-testid="half-bright-gift-icon" className={styles['step-icon']} />
        );
      default:
        return <HalfBrightGift data-testid="half-bright-gift-icon" className={styles['step-icon']} />;
    }
  };

  const getStepperIcon = (stepItem: StepEnum, isCurrentWeek: boolean) => {
    if (stepItem === StepEnum.COMPLETED) {
      return (
        <Icon
          name={IconName.Check}
          size={isCurrentWeek ? IconSize.Small : IconSize.ExtraSmall}
          color={IconColor.White}
        />
      );
    }
    if (stepItem === StepEnum.FAILED) {
      return (
        <Icon
          name={IconName.Close}
          size={isCurrentWeek ? IconSize.Small : IconSize.ExtraSmall}
          color={IconColor.White}
        />
      );
    }
    return null;
  };

  const layoutProps = {
    'data-hasgifticon': hasGiftIcon
  };

  if (!milestones) return null;

  return (
    <div {...layoutProps} className={styles.container}>
      <div {...layoutProps} className={styles.stepper}>
        {milestones?.map(({ milestoneId, status, current }, index) => (
          <React.Fragment key={milestoneId}>
            <div
              data-testid="week-block"
              style={
                {
                  '--font-weight': !current ? 'normal' : '700',
                  '--circle-size': current ? '15px' : '11px'
                } as CSSProperties
              }
              data-state={status}
              data-message={`${t('entries.prizeLottery.week', { defaultValue: 'Week' })} ${index + 1}`}
              className={styles.circle}
            >
              {hasGiftIcon && getGiftIcon(status as StepEnum, current)}
              {getStepperIcon(status as StepEnum, current)}
            </div>
            {index !== Object.keys(milestones).length - 1 && <div className={styles.line} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
