import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ListContext } from '../../../../global/contexts/ListContext';
import { PageContext } from '../../../../global/contexts/PageContext';
import { useDistributors } from '../../../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../../../global/custom-hooks/useGetBaseGAEventData';
import { AddToFavouritesTracking } from '../../../../global/google-analytics';
import { Product } from '../../../../global/interfaces/products';
import {
  favouritesApi,
  useAddToFavouriteListMutation,
  useDeleteFavouriteItemMutation,
  useGetAssociatedFavouriteListQuery
} from '../../../../services/favourites/favourites';
import { RedirectToErrorPage } from '../../../ApiErrorHandler/RedirectToErrorPage';
import { Loading } from '../../Loading';
import { Modal } from '../../Modal/ModalComponent';
import styles from './favouriteModal.module.scss';
import { ModalContent } from './ModalContent/ModalContent';
import { ModalFooter } from './ModalFooter/ModalFooter';
import { ModalHeader } from './ModalHeader/ModalHeader';

interface TagDescription<T extends string> {
  type: T;
  id?: string | number;
}

interface Props {
  salesOrg: string;
  product: Product;
  closeFavouriteModal: () => void;
}

export const FavouriteModal: React.FC<Props> = ({ salesOrg, product, closeFavouriteModal }) => {
  const { pageType } = useContext(PageContext);
  const { listName } = useContext(ListContext);
  const { selectedOutlet } = useDistributors();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const sku = product.baseSku;
  const params = {
    salesOrg,
    outletId: selectedOutlet?.eid,
    sku
  };

  const { data: favouriteList, error: getFavouriteListError, isLoading } = useGetAssociatedFavouriteListQuery(params, {
    skip: !selectedOutlet
  });

  const [addToFavouriteList, { error: addToFavouriteListError }] = useAddToFavouriteListMutation();
  const [deleteFavouriteList, { error: deleteFavouriteListError }] = useDeleteFavouriteItemMutation();
  const [isToggleLoading, setIsToggleLoading] = useState(false);
  const [isCreateClicked, setIsCreateClicked] = useState(false);
  const gaEventData = useGetBaseGAEventData();

  useEffect(() => {
    const handleToggleFavouriteForSingleList = () => {
      const { id: favListId, name: favListName, associate } = favouriteList.content[0];

      if (!associate) {
        setIsToggleLoading(true);
        addToFavouriteList({
          sku,
          favListId,
          favListName,
          salesOrg,
          outletId: selectedOutlet?.eid
        }).then(() => {
          setIsToggleLoading(false);
          closeFavouriteModal();

          TagManager.dataLayer(
            AddToFavouritesTracking({
              ...gaEventData,
              product,
              pageType,
              listName
            })
          );
        });
        invalidateTagsButFavoritesRoute(['FAVOURITES_LIST', 'FAVOURITE_LIST_PRODUCTS']);
      } else {
        setIsToggleLoading(true);
        deleteFavouriteList({
          sku,
          favListId,
          salesOrg,
          outletId: selectedOutlet?.eid
        }).then(() => {
          setIsToggleLoading(false);
          closeFavouriteModal();
        });
        invalidateTagsButFavoritesRoute(['ASSOCIATED_FAVOURITE_LIST']);
      }
    };

    const invalidateTagsButFavoritesRoute = (tags: string[]) => {
      if (pathname !== '/favourites') {
        const tagDescriptions = tags.map((tag) => ({ type: tag })) as TagDescription<
          'FAVOURITES_LIST' | 'FAVOURITE_LIST_PRODUCTS' | 'ASSOCIATED_FAVOURITE_LIST'
        >[];
        dispatch(favouritesApi.util.invalidateTags(tagDescriptions));
      }
    };

    if (favouriteList?.content?.length === 1 && !isToggleLoading && !isCreateClicked) {
      handleToggleFavouriteForSingleList();
    }
  }, [favouriteList?.content, isCreateClicked]);

  if (favouriteList?.content?.length === 1 || !favouriteList) {
    return null;
  }

  if (getFavouriteListError || addToFavouriteListError || deleteFavouriteListError) {
    return <RedirectToErrorPage />;
  }
  const portalContainer = document.getElementById('react-portal');

  return ReactDOM.createPortal(
    <Modal
      dataTestId="favourite-modal"
      active
      closeModal={closeFavouriteModal}
      showCloseButton
      fixedClose={false}
      theme={{
        content: styles.modalContainer,
        children: styles.modalChildren
      }}
    >
      <ModalHeader />
      {(isLoading && <Loading small />) || (
        <ModalContent
          favouriteList={favouriteList}
          salesOrg={salesOrg}
          outletId={selectedOutlet?.eid}
          product={product}
        />
      )}
      <ModalFooter
        favouriteList={favouriteList}
        salesOrg={salesOrg}
        product={product}
        outletId={selectedOutlet?.eid}
        setIsCreateClicked={setIsCreateClicked}
      />
    </Modal>,
    portalContainer
  );
};
