import cn from 'classnames';
import React from 'react';
import { ReactComponent as Check } from '../../../assets/check.svg';
import { ReactComponent as Transport } from '../../../assets/transport.svg';
import { DistributorI } from '../../../global/interfaces/distributor';
import styles from './distributor.module.scss';
import { DistributorCard } from './DistributorCard';

interface Props {
  distributors: DistributorI[];
  selectedDistributorId: string;
  onDistributorSelect: (distributor: DistributorI | undefined) => void;
}

export const DistributorList: React.FC<Props> = ({ distributors, selectedDistributorId, onDistributorSelect }) => (
  <div className={styles.listWrapper}>
    {distributors?.map((distributor: DistributorI) => {
      const isSelected = distributor.eid === selectedDistributorId;
      return (
        <div
          key={distributor.eid}
          className={styles.cardWrapper}
          onClick={() => onDistributorSelect(distributor)}
          data-testid="distributor-option"
        >
          <div
            className={cn(styles.optionWrapper, {
              [styles.selected]: isSelected
            })}
          >
            <div className={styles.leftAreaWrapper}>
              <div className={styles.icon}>
                <Transport />
              </div>
              <DistributorCard
                dataTestId={`distributor-card-${isSelected ? 'selected' : null}`}
                distributor={distributor}
              />
            </div>
            <div className={styles.rightAreaWrapper}>
              {isSelected && (
                <div className={styles.check}>
                  <Check data-testid="option-selected-icon" />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);
