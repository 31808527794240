import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { setCartError, setCartLoaderFalse } from '../../components/Cart/actions';
import { INotification, setNotification } from '../../components/Notifications/slice/notifications';
import { ADD_TO_CART_NOTIFICATION } from '../../global/constants';
import { Cart } from '../../global/interfaces/cart';
import { Product } from '../../global/interfaces/products';
import { setPromotionData } from '../../store/slices/promotionModalSlice';
import { baseApiQuery } from '../baseApiQuery';
import {
  AddAllToCartParams,
  AddMultipleProductsToCartInput,
  AddProductParams,
  CartsParams,
  CartsPayload,
  RemoveProductParams,
  SelectedCartParams,
  VoucherCartParams
} from './types';

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: baseApiQuery,
  tagTypes: ['CART', 'CARTS'],
  endpoints: (builder) => ({
    getCarts: builder.query<CartsPayload, CartsParams>({
      query: (params) => {
        const { salesOrg, outletId, distributorId } = params;
        return {
          url: apiEndpoints.CARTS,
          params: {
            salesOrg,
            outletId,
            distributorId
          }
        };
      }
    }),
    getSelectedCart: builder.query<Cart, SelectedCartParams>({
      query: ({ salesOrg, outletId, distributorId, cartId }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_EID, { eid: cartId }),
        params: {
          salesOrg,
          outletId,
          distributorId
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const handleErrors = async () => {
          try {
            await queryFulfilled;
            dispatch(setCartLoaderFalse());
          } catch (error) {
            dispatch(setCartError());
          }
        };
        await handleErrors();
      },
      providesTags: ['CART']
    }),
    addProductToCart: builder.mutation<Cart, AddProductParams>({
      query: ({ salesOrg, outletId, distributorId, cartId, sku, section, quantity }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_EID_ITEMS_SKU, {
          eid: cartId,
          sku
        }),
        method: 'POST',
        params: {
          salesOrg,
          outletId,
          distributorId,
          section
        },
        body: JSON.stringify({
          quantity
        })
      }),
      async onQueryStarted(addProductParams, { dispatch, queryFulfilled }) {
        let callAsyncAction = false;
        const { salesOrg, outletId, distributorId, cartId, sku, quantity } = addProductParams;

        const request = dispatch(
          cartApi.util.updateQueryData(
            'getSelectedCart',
            { salesOrg, outletId, distributorId, cartId },
            (cartDraft: Cart) => {
              const currentItemQuantity = cartDraft.items.find((item) => item.sku === sku)?.quantity;
              const setNewTotal = () => ({
                ...cartDraft.total,
                totalProducts: cartDraft.total.totalProducts - currentItemQuantity + quantity
              });

              const setNewItemsWithQuantity = () =>
                cartDraft.items.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      quantity
                    };
                  }
                  return item;
                });

              if (!currentItemQuantity) {
                callAsyncAction = true;
              } else {
                Object.assign(cartDraft, {
                  items: setNewItemsWithQuantity(),
                  total: setNewTotal()
                });
              }
            }
          )
        );

        try {
          if (callAsyncAction) {
            const { data: asyncCartUpdate } = await queryFulfilled;
            dispatch(
              setNotification({
                id: `${ADD_TO_CART_NOTIFICATION}_${sku}`,
                sku
              } as INotification)
            );
            dispatch(
              cartApi.util.updateQueryData(
                'getSelectedCart',
                { salesOrg, outletId, distributorId, cartId },
                () => asyncCartUpdate
              )
            );
            dispatch(setCartLoaderFalse());
            dispatch(
              setPromotionData({
                appliedPromotionData: asyncCartUpdate?.appliedPromotionData,
                appliedPromotions: asyncCartUpdate?.appliedPromotions
              })
            );
          } else {
            await queryFulfilled.then((response) => {
              const appliedPromotionDataResult = response?.data?.appliedPromotionData;
              const appliedPromotionsResult = response?.data?.appliedPromotions;
              if (appliedPromotionDataResult && appliedPromotionsResult) {
                dispatch(
                  setPromotionData({
                    appliedPromotionData: appliedPromotionDataResult,
                    appliedPromotions: appliedPromotionsResult
                  })
                );
              }
            });
            dispatch(
              setNotification({
                id: `${ADD_TO_CART_NOTIFICATION}_${sku}`,
                sku
              } as INotification)
            );
            dispatch(cartApi.util.invalidateTags(['CART']));
          }
        } catch {
          request.undo();
          dispatch(cartApi.util.invalidateTags(['CART']));
          dispatch(setCartError());
        }
      }
    }),
    addAllProductsToCart: builder.mutation<Cart, AddAllToCartParams>({
      query: ({ orderId, cartId, salesOrg, outletId, distributorId, locale }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_REORDER, {
          salesOrg
        }),
        method: 'POST',
        body: JSON.stringify({
          orderId,
          cartId,
          outletId,
          locale,
          distributorId
        })
      }),
      async onQueryStarted(addAllToCartParams, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCart } = await queryFulfilled;
          const { salesOrg, outletId, distributorId, cartId } = addAllToCartParams;
          dispatch(
            cartApi.util.updateQueryData(
              'getSelectedCart',
              { salesOrg, cartId, outletId, distributorId },
              () => updatedCart
            )
          );
        } catch {
          dispatch(cartApi.util.invalidateTags(['CART']));
        }
      }
    }),
    addMultipleProductsToCart: builder.mutation<Cart, AddMultipleProductsToCartInput>({
      query: ({ cartId, salesOrg, outletId, distributorId, locale, items }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_BULK_INSERT, {
          salesOrg,
          eid: cartId
        }),
        params: {
          outletId
        },
        method: 'POST',
        body: JSON.stringify({
          items,
          distributorId,
          cartId,
          locale
        })
      }),
      async onQueryStarted(addAllToCartParams, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCart } = await queryFulfilled;
          const { salesOrg, outletId, distributorId, cartId, items } = addAllToCartParams;
          dispatch(
            cartApi.util.updateQueryData(
              'getSelectedCart',
              { salesOrg, cartId, outletId, distributorId },
              () => updatedCart
            )
          );
          items.forEach((alternative) => {
            dispatch(
              setNotification({
                id: `${ADD_TO_CART_NOTIFICATION}_${alternative.sku}`,
                sku: alternative.sku
              } as INotification)
            );
          });
          dispatch(
            setPromotionData({
              appliedPromotionData: updatedCart?.appliedPromotionData,
              appliedPromotions: updatedCart?.appliedPromotions
            })
          );
        } catch {
          dispatch(cartApi.util.invalidateTags(['CART']));
        }
      }
    }),
    cleanCartProducts: builder.mutation<Cart, SelectedCartParams>({
      query: ({ salesOrg, outletId, distributorId, cartId }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_EID_ITEMS, { eid: cartId }),
        method: 'DELETE',
        params: {
          salesOrg,
          outletId,
          distributorId
        }
      }),
      async onQueryStarted(cleanCartParams, { dispatch, queryFulfilled }) {
        const { salesOrg, outletId, distributorId, cartId } = cleanCartParams;
        const request = dispatch(
          cartApi.util.updateQueryData(
            'getSelectedCart',
            { salesOrg, outletId, distributorId, cartId },
            (draft: Cart) => {
              Object.assign(draft, {
                total: {
                  value: 0,
                  differentItems: 0,
                  differentFreeItems: 0,
                  totalProducts: 0,
                  totalFreeItems: 0,
                  totalDeposits: 0,
                  totalWithDeposits: 0,
                  totalReturnItems: 0,
                  totalReturnValue: 0,
                  totalRedeemableItems: 0,
                  totalRedeemablePoints: 0,
                  totalCollectablePoints: 0,
                  totalVouchersValue: 0
                },
                items: [],
                freeItems: [],
                returnItems: [],
                redeemableItems: [],
                appliedPromotions: [],
                vouchers: [
                  {
                    applied: [],
                    notApplied: []
                  }
                ],
                appliedPromotionData: null,
                notDeliveredItems: null,
                notDeliveredFreeItems: null,
                notDeliveredRedeemableItems: null
              });
            }
          )
        );

        try {
          await queryFulfilled;
          dispatch(cartApi.util.invalidateTags(['CART']));
        } catch {
          request.undo();
          dispatch(setCartError());
        }
      }
    }),
    removeProductFromCart: builder.mutation<Cart, RemoveProductParams>({
      query: ({ salesOrg, outletId, distributorId, cartId, sku, section }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_EID_ITEMS_SKU, {
          eid: cartId,
          sku
        }),
        method: 'DELETE',
        params: {
          salesOrg,
          outletId,
          distributorId,
          section
        }
      }),
      async onQueryStarted(removeProductParams, { dispatch, queryFulfilled }) {
        const { salesOrg, outletId, distributorId, cartId, sku } = removeProductParams;
        const request = dispatch(
          cartApi.util.updateQueryData(
            'getSelectedCart',
            { salesOrg, outletId, distributorId, cartId },
            (cartDraft: Cart) => {
              const itemToRemove = cartDraft.items.find((item) => item.sku === sku);
              const redeemableItemToRemove = cartDraft.redeemableItems.find((item) => item.sku === sku);

              const updateItems = (items: Product[]) => items.filter((item) => item.sku !== sku);
              const setNewTotal = () => ({
                ...cartDraft.total,
                totalProducts: cartDraft.total.totalProducts - itemToRemove?.quantity
              });

              const setNewItems = () => (itemToRemove ? updateItems(cartDraft.items) : cartDraft.items);
              const setNewRedeemableItems = () =>
                redeemableItemToRemove ? updateItems(cartDraft.redeemableItems) : cartDraft.redeemableItems;

              return {
                ...cartDraft,
                items: setNewItems(),
                redeemableItems: setNewRedeemableItems(),
                total: setNewTotal()
              };
            }
          )
        );
        try {
          await queryFulfilled;
          dispatch(cartApi.util.invalidateTags(['CART']));
        } catch {
          request.undo();
          dispatch(setCartLoaderFalse());
          dispatch(setCartError());
        }
      }
    }),
    addVoucherToCart: builder.mutation<Cart, VoucherCartParams>({
      query: ({ salesOrg, outletId, distributorId, cartId, voucherId }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_EID_VOUCHER, { eid: cartId, voucherId }),
        method: 'PUT',
        params: {
          salesOrg,
          outletId,
          distributorId
        }
      }),
      async onQueryStarted(voucherCartParams, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCart } = await queryFulfilled;
          const { salesOrg, outletId, distributorId, cartId } = voucherCartParams;
          dispatch(
            cartApi.util.updateQueryData(
              'getSelectedCart',
              { salesOrg, outletId, distributorId, cartId },
              () => updatedCart
            )
          );
        } catch {
          dispatch(cartApi.util.invalidateTags(['CART']));
        }
      }
    }),
    removeVoucherFromCart: builder.mutation<Cart, VoucherCartParams>({
      query: ({ salesOrg, outletId, distributorId, cartId, voucherId }) => ({
        url: makeApiUrl(apiEndpoints.CARTS_EID_VOUCHER, { eid: cartId, voucherId }),
        method: 'DELETE',
        params: {
          salesOrg,
          outletId,
          distributorId
        }
      }),
      async onQueryStarted(voucherCartParams, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCart } = await queryFulfilled;
          const { salesOrg, outletId, distributorId, cartId } = voucherCartParams;
          dispatch(
            cartApi.util.updateQueryData(
              'getSelectedCart',
              { salesOrg, outletId, distributorId, cartId },
              () => updatedCart
            )
          );
          dispatch(setCartLoaderFalse());
        } catch {
          dispatch(cartApi.util.invalidateTags(['CART']));
        }
      }
    })
  })
});

export const {
  useGetCartsQuery,
  useGetSelectedCartQuery,
  useAddProductToCartMutation,
  useAddAllProductsToCartMutation,
  useCleanCartProductsMutation,
  useRemoveProductFromCartMutation,
  useAddMultipleProductsToCartMutation,
  useAddVoucherToCartMutation,
  useRemoveVoucherFromCartMutation
} = cartApi;
