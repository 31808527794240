import React from 'react';
import { useTranslation } from 'react-i18next';
import { FONT_SIZE, Text, TEXT_ALIGN, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './noProductsMessage.module.scss';

const NoProductsMessage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['not-found-message']}>
      <Text align={TEXT_ALIGN.CENTER} color={TEXT_COLOR.DARK_GREY} size={FONT_SIZE.SMALL}>
        {t('entries.products.noProductsMatchedYourCriteria', { defaultValue: 'No products matched your criteria' })}
      </Text>
    </div>
  );
};

export { NoProductsMessage };
