import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as Check } from '../../../assets/check.svg';
import { ReactComponent as Destination } from '../../../assets/destination.svg';
import { Outlet } from '../../../global/interfaces/outlet';
import styles from './distributor.module.scss';
import { OutletCard } from './OutletCard';

interface Props {
  outlet: Outlet;
  isSelected?: boolean;
  scrollSelectionListToTop?: () => void;
  onOutletSelect: (outlet: Outlet) => void;
  isDisabled?: boolean;
}

export const OutletListItem: React.FC<Props> = ({
  outlet,
  isSelected = false,
  scrollSelectionListToTop,
  onOutletSelect,
  isDisabled
}) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (isSelected && cardRef?.current) {
      cardRef.current.scrollIntoView({ block: 'start' });
      scrollSelectionListToTop();
    }
  }, []);

  const handleSelect = () => {
    if (!isDisabled) {
      onOutletSelect(outlet);
    }
  };

  return (
    <div
      key={outlet.eid}
      className={styles.cardWrapper}
      onClick={handleSelect}
      data-testid="outlet-option"
      ref={cardRef}
    >
      <div
        className={cn(styles.optionWrapper, {
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled
        })}
      >
        <div className={styles.leftAreaWrapper}>
          <div className={styles.icon}>
            <Destination data-testid="outlet-option-icon" />
          </div>
          <OutletCard outlet={outlet} />
        </div>
        <div className={styles.rightAreaWrapper}>
          {isSelected && (
            <div className={styles.check}>
              <Check data-testid="option-selected-icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
