import React from 'react';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { Outlet } from '../../../global/interfaces/outlet';
import { Loading } from '../Loading';
import styles from './distributor.module.scss';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  uiSelectedOutlet: Outlet;
  isConfirming: boolean;
}

export const SelectionList: React.FC<Props> = ({ children, uiSelectedOutlet, isConfirming }) => {
  const { isFetchingDistributors, isFetchingOutlets } = useDistributors(uiSelectedOutlet);
  const isLoading = isFetchingOutlets || isFetchingDistributors || isConfirming;

  if (isLoading) {
    return (
      <div className={styles.loading} data-testid="loading-list">
        <Loading hideButton />
      </div>
    );
  }

  return (
    <div className={styles.list} data-testid="selection-list">
      {children}
    </div>
  );
};
