import { Button, ButtonStyle, ButtonType } from '@carlsberggroup/malty.atoms.button';
import { Input, InputType } from '@carlsberggroup/malty.atoms.input';
import cn from 'classnames';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CART_SECTION_TYPES, PROMOTION_TYPES } from '../../../../global/constants';
import { ProductCardContext } from '../../../../global/contexts/ProductCardContext';
import { useMenuClick } from '../../../../global/custom-hooks/useMenuClick';
import { useStockData } from '../../../../global/custom-hooks/useStockData';
import { showAlternativesModal } from '../../../AlternativeProducts/slice/alternativeProducts';
import { ProductCartQuantity } from '../../../CartQuantity/components/ProductCartQuantity';
import { RewardCartQuantity } from '../../../CartQuantity/components/RewardCartQuantity';
import styles from '../productcard.module.scss';

interface ProductCardFooterTypeProps {
  promotionType: PROMOTION_TYPES;
  isQuantityReadOnly: boolean;
  dataTestId: string;
}

const ProductCardFooterType = ({ promotionType, isQuantityReadOnly, dataTestId }: ProductCardFooterTypeProps) => {
  const { handleCloseSearchModal } = useMenuClick();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { product, cartSection, searchBarList } = useContext(ProductCardContext);
  const isReward = cartSection === CART_SECTION_TYPES.reward;

  const { shouldRenderAlternativesButton } = useStockData({ product, cartSection });

  const onAlternativeClick = useCallback(() => {
    handleCloseSearchModal();
    dispatch(showAlternativesModal(product.sku));
  }, [product.sku]);

  if (isQuantityReadOnly) {
    return (
      <div className={styles.inputReadOnlyWrapper}>
        <Input
          dataTestId="input-disabled"
          value={product.qualifyingQuantity.toString()}
          onValueChange={() => null}
          disabled
          readOnly
          type={InputType.Number}
        />
      </div>
    );
  }

  if (isReward) {
    return <RewardCartQuantity dataTestId={dataTestId} />;
  }

  if (shouldRenderAlternativesButton && promotionType !== PROMOTION_TYPES.FREE_ITEM) {
    return (
      <div
        className={cn({
          [styles.searchModalWrapper]: searchBarList
        })}
      >
        <Button
          dataTestId={`${dataTestId}-alternative-products-button`}
          text={t('entries.recommendations.alternativeButton', { defaultValue: 'Alternative' })}
          style={ButtonStyle.Secondary}
          type={ButtonType.Submit}
          onClick={onAlternativeClick}
          fullWidth
        />
      </div>
    );
  }

  return <ProductCartQuantity hideAddToCartButton={false} dataTestId={dataTestId} />;
};

export { ProductCardFooterType };
