import * as React from 'react';

type ErrorBoundaryState = {
  hasError: boolean;
};

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  FallbackComponent: React.ElementType;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, FallbackComponent } = this.props;

    if (hasError) {
      return <FallbackComponent />;
    }

    return children;
  }
}
