import { app } from '../constants';

export const formatPrice = (price = 0, currencyCode: string, currencySymbol: string) => {
  const languageCode = localStorage.getItem('i18nextLng') || app.defaultLanguage;

  // Check if backend provides correct language code or fallback to default
  const currencyCodeToUse = currencyCode && currencyCode.length === 3 ? currencyCode : app.defaultCurrencyCode;
  const currencySymbolToUse = currencySymbol || app.defaultCurrencySymbol;

  return new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: currencyCodeToUse,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
    .format(price)
    .replace(currencyCodeToUse, currencySymbolToUse);
};
