import cn from 'classnames';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as IconFavourite } from '../../../../../assets/heart.svg';
import { useDistributors } from '../../../../../global/custom-hooks/useDistributors';
import { useMountedState } from '../../../../../global/custom-hooks/useMountedState';
import { useDeleteFavouriteItemMutation } from '../../../../../services/favourites/favourites';
import { setLastRemovedFavouriteProduct } from '../../../../../store/slices/favourites';
import { Button } from '../../../../../UI-elements/Button';
import styles from './productImageBoxIconFavourites.module.scss';
import { ProductImageBoxIconFavouritesProps } from './ProductImageBoxIconFavourites.types';

const ProductImageBoxIconFavourites: FC<ProductImageBoxIconFavouritesProps> = ({
  isFavourite,
  sku,
  setIsFavouriteModalOpen,
  product
}) => {
  const dispatch = useDispatch();
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const [isDisabled, setIsDisabled] = useState(false);
  const isMounted = useMountedState();
  const { pathname } = useLocation();
  const isFavouritesPage = pathname.indexOf('/favourites/') !== -1;

  // optimistically update the ui
  // if the request fails we'll revert to the previous state
  const [isFavouriteState, setIsFavouriteState] = useState(isFavourite);

  const { id: favListId } = useParams<{ id: string }>();
  const [deleteFavouriteListItem] = useDeleteFavouriteItemMutation();

  useEffect(() => {
    setIsFavouriteState(isFavourite);
  }, [isFavourite]);

  const onClick = async (evt: SyntheticEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!isFavouritesPage) {
      setIsFavouriteModalOpen(true);
    } else {
      setIsDisabled(true);
      setIsFavouriteState(!isFavouriteState);

      try {
        dispatch(setLastRemovedFavouriteProduct(undefined));
        deleteFavouriteListItem({
          outletId: selectedOutlet.eid,
          salesOrg: selectedOutlet.salesOrg,
          distributorId: selectedDistributor.eid,
          favListId,
          sku
        }).then(() => {
          dispatch(setLastRemovedFavouriteProduct({ sku, favListId, favListName: favListId, product }));
        });
      } catch (err) {
        console.warn(`Failed to add ${sku} to favourites`, err);
        if (isMounted()) {
          setIsFavouriteState(isFavourite);
        }
      }

      if (isMounted()) {
        setIsDisabled(false);
      }
    }
  };

  if (!selectedOutlet || !selectedDistributor) {
    return null;
  }

  const dataTestId = `${sku}-favourite-button${isFavouriteState ? '-active' : ''}`;

  return (
    <div
      data-testid="favourite-icon-wrapper"
      className={cn(styles.wrapper, { [styles.favouritesPageEnable]: isFavouritesPage })}
    >
      <Button
        icon={
          <IconFavourite
            className={cn(styles.icon, {
              [styles.active]: isFavourite
            })}
          />
        }
        autoWidth
        type="white"
        action={onClick}
        dataTestId={dataTestId}
        size="tiny"
        className={styles.button}
        disabled={isDisabled}
        ariaLabel="toggle favourite"
      />
    </div>
  );
};

export { ProductImageBoxIconFavourites };
