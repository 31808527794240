import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_ALIGN } from '../../../../../UI-elements/Text';
import styles from './emptyList.module.scss';

export const EmptyList = () => {
  const { Heart } = IconsPaths;
  const { t } = useTranslation();

  return (
    <div className={styles.noFavourites}>
      <div className={styles.iconWrapper}>
        <Heart color={IconColor.Primary} size={IconSize.Large} />
      </div>
      <Text
        align={TEXT_ALIGN.CENTER}
        size={FONT_SIZE.MEDIUM}
        weight={FONT_WEIGHT.BOLD}
        dataTestId="empty-favourites-list"
      >
        {t('entries.favourites.noFavouriteList', {
          defaultValue: 'No favourite lists. Create below'
        })}
      </Text>
    </div>
  );
};
