import { NavHeaderMobileItemButton } from './NavHeaderMobileItemButton';
import { NavHeaderMobileItemRoute } from './NavHeaderMobileItemRoute';
import { NavHeaderMobileRoot } from './NavHeaderMobileRoot';
import { NavHeaderMobileTab } from './NavHeaderMobileTab';

export const NavHeaderMobile = {
  Root: NavHeaderMobileRoot,
  Tab: NavHeaderMobileTab,
  ItemRoute: NavHeaderMobileItemRoute,
  ItemButton: NavHeaderMobileItemButton
};
