import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ConfigContext } from '../../../../global/contexts/ConfigContext';
import { PAGE_ROUTES } from '../../../../global/custom-hooks/useGetCurrentPageInfo';
import { MenuType } from '../../../../global/google-analytics/tracking';
import { Dropdown } from '../../../../UI-elements/DropdownCard';
import styles from '../../header.module.scss';

interface DropdownHeaderProps {
  onMenuClick: (type: MenuType, label: string) => void;
  onLogout: () => void;
  isCustomerDropdownOpen: boolean;
}

const DropdownHeader: React.FC<DropdownHeaderProps> = ({ onMenuClick, isCustomerDropdownOpen, onLogout }) => {
  const { pathname } = useLocation();
  const { featureFlags } = useContext(ConfigContext);
  const { t } = useTranslation();

  if (!isCustomerDropdownOpen) {
    return null;
  }

  return (
    <div data-testid="menu-account-options" className={styles['dropdown-wrapper']}>
      <Dropdown className={styles.headerDropdownItems}>
        <div data-testid="menu-account-profile" className={styles.item}>
          <Link
            to={{ pathname: `/${PAGE_ROUTES.profile.path}`, state: { from: pathname } }}
            onClick={() => onMenuClick(MenuType.Icon, 'Profile')}
          >
            <Text textStyle={TextStyle.MediumSmallDefault}>
              {t('entries.header.account', { defaultValue: 'My Account' })}
            </Text>
          </Link>
        </div>
        {featureFlags?.enableFAQs ? (
          <div data-testid="menu-account-faq" className={styles.item}>
            <Link
              to={{ pathname: `/${PAGE_ROUTES.faqs.path}`, state: { from: pathname } }}
              onClick={() => onMenuClick(MenuType.Icon, 'FAQs')}
            >
              <Text textStyle={TextStyle.MediumSmallDefault}>
                {t('entries.header.faq', { defaultValue: 'FAQ Help' })}
              </Text>
            </Link>
          </div>
        ) : null}
        <div data-testid="menu-account-logout" className={styles.item} onClick={onLogout}>
          <Text textStyle={TextStyle.MediumSmallBold}>{t('entries.header.logout', { defaultValue: 'Log Out' })}</Text>
          <Icon name={IconName.SignOut} color={IconColor.Primary} size={IconSize.Small} />
        </div>
      </Dropdown>
    </div>
  );
};

export { DropdownHeader };
