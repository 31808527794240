import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PAGE_ROUTES } from '../../global/custom-hooks/useGetCurrentPageInfo';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { useGetHeadlessConfigQuery } from '../../services/headless/headlessApi';
import { EnterspeedPages } from '../../services/headless/types';
import { RedirectToErrorPage } from '../ApiErrorHandler/RedirectToErrorPage';
import styles from './footer.module.scss';
import { FooterBrand } from './FooterBrand';
import { FooterLinks } from './FooterLinks';
import { SocialLinks } from './SocialLinks';

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  const shouldSkip = isMobile && pathname === PAGE_ROUTES.checkout.path;

  const {
    i18n: { language }
  } = useTranslation();

  const { data: headlessFooterData, error } = useGetHeadlessConfigQuery(
    { url: EnterspeedPages.CONFIG, languageCode: language },
    {
      skip: shouldSkip
    }
  );

  if (error) {
    return <RedirectToErrorPage />;
  }

  if (!headlessFooterData || headlessFooterData?.meta?.status !== 200) {
    return null;
  }

  if (shouldSkip) {
    return null;
  }

  const { links: footerLinks, socialLinks } = headlessFooterData?.route?.footer;
  const { name: brandName } = headlessFooterData?.route?.brand;

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.wrapper}>
        <FooterBrand brandName={brandName} />
        <FooterLinks footerLinks={footerLinks} />
        <SocialLinks socialLinks={socialLinks} />
      </div>
    </footer>
  );
};

export { Footer };
