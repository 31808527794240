import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGES_DISTRIBUTOR_IS_NOT_VISIBLE } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { DistributorContext } from '../../Main/Main';
import styles from './distributor.module.scss';
import { DistributorModal } from './DistributorModal';
import { DistributorModalInfo } from './DistributorModalInfo';
import { DistributorNavBar } from './DistributorNavBar';
import { checkUserHasSetDistributor, getDefaultStep } from './helpers';

export enum DistributorSteps {
  OUTLET_SELECT = 'OUTLET_SELECT',
  OUTLET_INFO = 'OUTLET_INFO',
  DISTRIBUTOR_SELECT = 'DISTRIBUTOR_SELECT',
  DISTRIBUTOR_INFO = 'DISTRIBUTOR_INFO',
  CONFIRM_TRACKED_CHANGES = 'CONFIRM_TRACKED_CHANGES'
}

export const Distributor = () => {
  const { pathname } = useLocation();
  const {
    selectedOutlet,
    selectedDistributor,
    userHasSingleOutlet,
    userHasSingleOutletAndDistributor,
    hasOutletsHaveActiveDistributors
  } = useDistributors();

  const { featureFlags } = useContext(ConfigContext);

  const hideDistributor = featureFlags?.isDirectMarket;
  const userHasSetDistributor = checkUserHasSetDistributor();
  const [isDistributorModalOpen, setDistributorModalOpen] = useState<boolean>(false);
  const [initialStep, setInitialStep] = useState<DistributorSteps>(getDefaultStep(userHasSingleOutlet));
  const isInfoStep = [DistributorSteps.DISTRIBUTOR_INFO, DistributorSteps.OUTLET_INFO].includes(initialStep);
  const hideDistributorOnPage = PAGES_DISTRIBUTOR_IS_NOT_VISIBLE.includes(pathname);
  const {
    isDistributorModalOpen: modalOpenedFromPage,
    setDistributorModalOpen: triggerDistributorModalOpen
  } = useContext(DistributorContext);

  const shouldModalBeOpened =
    !hasOutletsHaveActiveDistributors || (isDistributorModalOpen && selectedOutlet && selectedDistributor);

  const openDistributorModal = (step: DistributorSteps) => {
    if (hideDistributor && [DistributorSteps.DISTRIBUTOR_INFO, DistributorSteps.DISTRIBUTOR_SELECT].includes(step)) {
      setDistributorModalOpen(false);
      return;
    }
    setInitialStep(step);
    setDistributorModalOpen(true);
  };

  useEffect(() => {
    if (modalOpenedFromPage) {
      openDistributorModal(DistributorSteps.OUTLET_SELECT);
      triggerDistributorModalOpen(false);
    }
  }, [modalOpenedFromPage]);

  useEffect(() => {
    if (!userHasSetDistributor && selectedOutlet && selectedDistributor && !userHasSingleOutletAndDistributor) {
      openDistributorModal(getDefaultStep(userHasSingleOutlet));
    }
  }, [userHasSingleOutletAndDistributor, selectedOutlet, selectedDistributor]);

  if (hideDistributorOnPage) {
    return null;
  }

  const destroyDistributorModal = () => {
    setDistributorModalOpen(false);
  };

  const renderModal = () => {
    if (isInfoStep) {
      return (
        <DistributorModalInfo
          step={initialStep}
          distributor={selectedDistributor}
          outlet={selectedOutlet}
          destroyDistributorModal={destroyDistributorModal}
        />
      );
    }
    return (
      <DistributorModal
        initialOutlet={selectedOutlet}
        initialDistributor={selectedDistributor}
        initialStep={initialStep}
        destroyDistributorModal={destroyDistributorModal}
      />
    );
  };

  return (
    <div data-testid="distributors-modal-wrapper" className={styles.distributorsModalWrapper}>
      <DistributorNavBar openDistributorModal={openDistributorModal} />
      {shouldModalBeOpened && renderModal()}
    </div>
  );
};
