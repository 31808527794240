import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { IconName } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../components/shared/Loading';
import { useFetchCatalog } from '../../../global/custom-hooks/useFetchCatalog';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { useGetDesktopHeaderHeight } from '../../../global/custom-hooks/useGetDesktopHeaderHeight';
import { useLockPageScroll } from '../../../global/custom-hooks/useLockPageScroll';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { FilterTracking } from '../../../global/google-analytics';
import { FilterType } from '../../../global/google-analytics/tracking';
import { Filters } from '../../../global/interfaces/filters';
import { getFilters, getFiltersToggle } from '../../../global/selectors/filters/selectors';
import {
  CatalogFilterActionPayload,
  setCurrentPage,
  setFiltersToggle,
  setFilterValue
} from '../../../store/slices/catalog';
import { Overlay } from '../../../UI-elements/Overlay';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../../UI-elements/Text';
import { FiltersAccordion } from './components';
import { FilteredCount } from './FilteredCount';
import styles from './filters.module.scss';

interface FiltersProps {
  dataTestId?: string;
}

const Filters = ({ dataTestId = '' }: FiltersProps) => {
  const dispatch = useDispatch();
  const { catalogData } = useFetchCatalog();
  const [totalElements, setTotalElements] = useState('0');
  const { t } = useTranslation();
  const gaEventData = useGetBaseGAEventData();
  const filters = useSelector(getFilters);
  const isFiltersOpen = useSelector(getFiltersToggle);
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const isMobile = isXSmall || isSmall || isMedium;
  const setFilterValueFunc = (param: CatalogFilterActionPayload) => {
    dispatch(setFilterValue(param));
  };
  const { topHeaderHeightAsCSS } = useGetDesktopHeaderHeight();
  const { isFetching: isCatalogLoading } = useFetchCatalog();

  const restorePage = () => {
    dispatch(setCurrentPage(0));
  };

  useLockPageScroll(isFiltersOpen);

  const closeFilters = () => {
    dispatch(setFiltersToggle(false));
  };

  const handleOnChange = (
    filterIndex: number,
    subFilterIndex: number,
    checked: boolean,
    filterName: string,
    filterLabel: string,
    filterLabelId: string
  ) => {
    if (isCatalogLoading) {
      return;
    }
    restorePage();
    setFilterValueFunc({ filterIndex, subFilterIndex, value: checked });

    TagManager.dataLayer(
      FilterTracking({
        ...gaEventData,
        filterType: FilterType.SelectBox,
        filterName,
        filterLabel,
        filterLabelId
      })
    );
  };

  useEffect(() => {
    if (!catalogData?.page) {
      return;
    }
    setTotalElements(catalogData?.page.totalElements.toString());
  }, [catalogData?.page]);

  return (
    <>
      <div style={topHeaderHeightAsCSS || {}} className={cn(styles.filters, { [styles.isOpen]: isFiltersOpen })}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            {isMobile ? (
              <div className={styles.header}>
                <Text dataTestId={`${dataTestId}-title`} size={FONT_SIZE.LARGE} weight={FONT_WEIGHT.BOLD}>
                  {t('entries.filters.title', { defaultValue: 'Filters' })}
                </Text>
                <Button style={ButtonStyle.Transparent} icon={IconName.Close} onClick={closeFilters} />
              </div>
            ) : (
              <div className={styles.title}>
                <Text dataTestId={`${dataTestId}-title`} size={FONT_SIZE.LARGE} weight={FONT_WEIGHT.BOLD}>
                  {t('entries.filters.title', { defaultValue: 'Filters' })}
                </Text>
                <FilteredCount
                  totalElements={totalElements}
                  isCatalogLoading={isCatalogLoading}
                  restorePage={restorePage}
                />
              </div>
            )}

            <div className={styles['filters-block']}>
              {!filters ? (
                <Loading small />
              ) : (
                filters.map((filter: Filters, index: number) => (
                  <FiltersAccordion
                    key={filter.code}
                    filter={filter}
                    index={index}
                    dataTestId={dataTestId}
                    handleOnChange={handleOnChange}
                  />
                ))
              )}
            </div>

            <div className={styles['filters-footer']}>
              <Button
                fullWidth
                size={ButtonSize.Large}
                style={ButtonStyle.Primary}
                onClick={() => closeFilters()}
                text={`${t('entries.filters.seeResults', { defaultValue: 'See results' })} (${totalElements})`}
                loading={isCatalogLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <Overlay
        active={isFiltersOpen}
        action={() => {
          closeFilters();
        }}
      />
    </>
  );
};

export { Filters };
