import { useEffect } from 'react';
import globalStyles from '../../styles/global.scss';

function useLockPageScroll(lock: boolean) {
  useEffect(() => {
    if (lock) {
      document.documentElement.classList.add(globalStyles.overflowHidden);
    } else {
      document.documentElement.classList.remove(globalStyles.overflowHidden);
    }

    return () => {
      document.documentElement.classList.remove(globalStyles.overflowHidden);
    };
  }, [lock]);
}

export { useLockPageScroll };
