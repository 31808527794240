import { Reducer } from 'redux';
import { actions } from './constants';

export const initialState: unknown = {};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_RECOMMENDATION_CATALOG_ACTIVE_CHIP: {
      const clonedState = { ...state };

      clonedState[`activeChip${action.recommendationsCatalogId}`] = action.activeChip;

      return clonedState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as recommendationsCatalogReducer };
