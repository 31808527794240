export enum actions {
  SET_CART_TOGGLE = 'SET_CART_TOGGLE',
  SET_ALERT_ALL_PRODUCTS_ADDED_TO_CART = 'SET_ALERT_ALL_PRODUCTS_ADDED_TO_CART',
  SET_CART_LOADER_TRUE = 'SET_CART_LOADER_TRUE',
  SET_CART_LOADER_FALSE = 'SET_CART_LOADER_FALSE',
  SET_CART_ERROR_TRUE = 'SET_CART_ERROR_TRUE',
  SET_CART_ERROR_FALSE = 'SET_CART_ERROR_FALSE'
}

export enum CartNotifications {
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}
