import { configureStore, createImmutableStateInvariantMiddleware, createListenerMiddleware } from '@reduxjs/toolkit';
import { alternativeProductsReducer } from '../components/AlternativeProducts/slice/alternativeProducts';
import { cartReducer } from '../components/Cart/reducers';
import { notificationsReducer } from '../components/Notifications/slice/notifications';
import { modalReducer } from '../components/shared/Modal/reducers';
import { recommendationsCatalogReducer } from '../pages/Home/components/RecommendationsCatalog/reducers';
import { activationApi } from '../services/activation/activation';
import { announcementsApi } from '../services/announcements/announcements';
import { calculationApi } from '../services/calculation/calculation';
import { cartApi } from '../services/cart/cart';
import { customerFeedbackApi } from '../services/customerFeedback/customerFeedback';
import { favouritesApi } from '../services/favourites/favourites';
import { filtersApi } from '../services/filters/filters';
import { headlessApi } from '../services/headless/headlessApi';
import { lotteryApi } from '../services/lottery';
import { loyaltyBalanceApi } from '../services/loyaltyBalance/loyaltyBalance';
import { maintenanceApi } from '../services/maintenance/maintenance';
import { ordersApi } from '../services/orders/orders';
import { outletsApi } from '../services/outlets/outlets';
import { productsApi } from '../services/products/products';
import { profileApi } from '../services/profile/profile';
import { promotionsApi } from '../services/promotions';
import { trackingApi } from '../services/tracking/tracking';
import { userApi } from '../services/user/user';
import { vouchersApi } from '../services/vouchers/vouchers';
import CartSlice from './slices/cartSlice';
import CatalogSliceReducer from './slices/catalog';
import FAQsReducer from './slices/faqs';
import FavouritesPageReducer from './slices/favourites';
import HeaderSliceReducer from './slices/header';
import OrdersPageReducer from './slices/orders';
import PromotionModalSliceReducer from './slices/promotionModalSlice';
import RewardsPageSliceReducer from './slices/rewards';
import userReducer from './slices/user';

export const getStore = (initialState = {}) => {
  const listenerMiddleware = createListenerMiddleware();

  const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    warnAfter: 250
  });

  const store = configureStore({
    preloadedState: initialState,
    reducer: {
      cart: cartReducer,
      catalog: CatalogSliceReducer,
      user: userReducer,
      modal: modalReducer,
      recommendationsCatalog: recommendationsCatalogReducer,
      FAQs: FAQsReducer,
      favourites: FavouritesPageReducer,
      notifications: notificationsReducer,
      alternativeProducts: alternativeProductsReducer,
      cartSlice: CartSlice,
      orders: OrdersPageReducer,
      promotionModal: PromotionModalSliceReducer,
      rewardsPageSlice: RewardsPageSliceReducer,
      [headlessApi.reducerPath]: headlessApi.reducer,
      [customerFeedbackApi.reducerPath]: customerFeedbackApi.reducer,
      [outletsApi.reducerPath]: outletsApi.reducer,
      [cartApi.reducerPath]: cartApi.reducer,
      [vouchersApi.reducerPath]: vouchersApi.reducer,
      [favouritesApi.reducerPath]: favouritesApi.reducer,
      [productsApi.reducerPath]: productsApi.reducer,
      [ordersApi.reducerPath]: ordersApi.reducer,
      [profileApi.reducerPath]: profileApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [lotteryApi.reducerPath]: lotteryApi.reducer,
      [activationApi.reducerPath]: activationApi.reducer,
      [announcementsApi.reducerPath]: announcementsApi.reducer,
      [maintenanceApi.reducerPath]: maintenanceApi.reducer,
      [filtersApi.reducerPath]: filtersApi.reducer,
      [loyaltyBalanceApi.reducerPath]: loyaltyBalanceApi.reducer,
      [trackingApi.reducerPath]: trackingApi.reducer,
      [promotionsApi.reducerPath]: promotionsApi.reducer,
      [calculationApi.reducerPath]: calculationApi.reducer,
      header: HeaderSliceReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: true
      })
        .prepend(listenerMiddleware.middleware)
        .concat(
          immutableInvariantMiddleware,
          headlessApi.middleware,
          customerFeedbackApi.middleware,
          outletsApi.middleware,
          cartApi.middleware,
          vouchersApi.middleware,
          favouritesApi.middleware,
          productsApi.middleware,
          ordersApi.middleware,
          profileApi.middleware,
          userApi.middleware,
          lotteryApi.middleware,
          activationApi.middleware,
          announcementsApi.middleware,
          maintenanceApi.middleware,
          filtersApi.middleware,
          loyaltyBalanceApi.middleware,
          promotionsApi.middleware,
          calculationApi.middleware
        )
  });

  return store;
};

export const store = getStore();

export type RootState = ReturnType<typeof store.getState>;
