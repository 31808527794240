import { Reducer } from 'redux';
import { actions } from './constants';

// TODO REFACTOR THIS TO USE SLICE

enum CartLoadingStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING'
}

interface InitialCartStateProps {
  loadingCart: CartLoadingStatus;
}

export const initialState: unknown & InitialCartStateProps = {
  loadingCart: CartLoadingStatus.IDLE
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CART_TOGGLE: {
      const clonedState = { ...state };
      clonedState.isOpen = action.isCartOpen;

      return clonedState;
    }

    case actions.SET_ALERT_ALL_PRODUCTS_ADDED_TO_CART: {
      const clonedState = { ...state };
      clonedState.isShowAlertAllProductsAddedToCart = action.isShowAlertAllProductsAddedToCart;

      return clonedState;
    }

    case actions.SET_CART_LOADER_TRUE: {
      const clonedState = { ...state };
      clonedState.loadingCart = CartLoadingStatus.LOADING;
      return clonedState;
    }

    case actions.SET_CART_LOADER_FALSE: {
      const clonedState = { ...state };
      clonedState.loadingCart = CartLoadingStatus.IDLE;
      return clonedState;
    }

    case actions.SET_CART_ERROR_TRUE: {
      const clonedState = { ...state };
      clonedState.cartError = true;
      return clonedState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as cartReducer, CartLoadingStatus };
