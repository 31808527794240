import { TooltipProductData } from '../../components/shared/ProductsTooltip/ProductsTooltipItem';
import { Cart } from '../interfaces/cart';
import { IAddToCartNotification } from '../types';
import { mergeCart } from './mergeCart';

export const mergeNotificationsAndCartData = (cart: Cart, notifications: IAddToCartNotification[]) => {
  const cartProductsData = mergeCart(cart).reduce<Record<string, TooltipProductData>>((products, currentProduct) => {
    if (products[currentProduct.sku]) {
      return {
        ...products,
        [currentProduct.sku]: {
          ...products[currentProduct.sku],
          quantity: products[currentProduct.sku].quantity + currentProduct.quantity
        }
      };
    }
    return {
      ...products,
      [currentProduct.sku]: {
        name: currentProduct.name,
        sku: currentProduct.sku,
        images: currentProduct.images,
        quantity: currentProduct.quantity
      }
    };
  }, {});
  return notifications.reduce((result, notification) => {
    if (cartProductsData[notification.sku]) {
      return [...result, cartProductsData[notification.sku]];
    }
    return result;
  }, []);
};
