import { HeaderLink } from './useHeaderNavLinks';
import { useScreenSize } from './useScreenSize';

const useDynamicNavLink = (links: HeaderLink[]): boolean => {
  const { isMedium, isLarge, isXLarge, isXXLarge, isXXXLarge } = useScreenSize();

  // Count the number of visible links
  const visibleLinkCount = links.filter((link) => link.isPathVisible).length;

  let isHeaderDesktopVersion;

  // Determine the media query based on the number of visible links
  switch (true) {
    case visibleLinkCount >= 6:
      isHeaderDesktopVersion = isXLarge || isXXLarge || isXXXLarge;
      break;
    case visibleLinkCount === 5:
      isHeaderDesktopVersion = isLarge || isXLarge || isXXLarge || isXXXLarge;
      break;
    default:
      isHeaderDesktopVersion = isMedium || isLarge || isXLarge || isXXLarge || isXXXLarge;
      break;
  }

  return isHeaderDesktopVersion;
};

export { useDynamicNavLink };
