import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  filterProductQuantityBySku,
  mapQuantitiesToAddAllToCartInput
} from '../../components/AlternativeProducts/utils';
import { useAddMultipleProductsToCartMutation } from '../../services/cart/cart';
import { Product } from '../interfaces/products';
import { SKU } from '../types';
import { CartType, mergeCart } from '../utils/mergeCart';
import { useCart } from './useCart';
import { useDistributors } from './useDistributors';

interface useAddBulkProductsProps {
  productSku?: string;
  onAddedBulk?: () => void;
  cartItemsType?: CartType;
}

const useAddBulkProducts = ({ cartItemsType = CartType.PRODUCT, onAddedBulk }: useAddBulkProductsProps) => {
  const {
    i18n: { language }
  } = useTranslation();
  const { selectedCart } = useCart();
  const { selectedDistributor, selectedOutlet } = useDistributors();
  const [recommendationsQuantities, setRecommendationsQuantities] = useState<Record<SKU, number> | null>(null);
  const [addAllSelectedItemsToCart, { isLoading: isLoadingAddToCart }] = useAddMultipleProductsToCartMutation();
  const cartQuantities = React.useMemo(
    () =>
      mergeCart(selectedCart, cartItemsType).reduce(
        (acc: Record<SKU, number>, curr: Product) => ({ ...acc, [curr.sku]: curr.quantity ?? 0 }),
        {}
      ),
    [selectedCart]
  );

  const onQuantityChange = (sku: string) => (quantity: number) => {
    if (!quantity) {
      const filteredQuantities = filterProductQuantityBySku(sku, recommendationsQuantities);
      setRecommendationsQuantities(filteredQuantities);
      return;
    }
    setRecommendationsQuantities({ ...recommendationsQuantities, [sku]: quantity });
  };

  const onAddBulkClick = async () => {
    if (!recommendationsQuantities) {
      return;
    }
    await addAllSelectedItemsToCart({
      cartId: selectedCart.eid,
      distributorId: selectedDistributor.eid,
      locale: language,
      outletId: selectedOutlet.eid,
      salesOrg: selectedOutlet.salesOrg,
      items: mapQuantitiesToAddAllToCartInput({ recommendationsQuantities, cartQuantities })
    }).finally(() => {
      onAddedBulk?.();
    });
  };

  return {
    recommendationsQuantities,
    cartQuantities,
    onQuantityChange,
    isLoadingAddToCart,
    onAddBulkClick
  };
};

export { useAddBulkProducts };
