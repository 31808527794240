import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { NoProductsMessage } from '../../../components/NoProductsMessage';
import { Loading } from '../../../components/shared/Loading';
import { ProductCard } from '../../../components/shared/ProductCard';
import { CATALOG_TYPES } from '../../../global/constants';
import { useFetchCatalog } from '../../../global/custom-hooks/useFetchCatalog';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { ViewItemListTracking } from '../../../global/google-analytics';
import { ProductWithVariants } from '../../../global/interfaces/products';
import { getActiveChip } from '../../../global/selectors/filters/selectors';
import { getDefaultProducts } from '../../../global/utils/getDefaultProduct';
import styles from './products.module.scss';

interface PropsProps {
  dataTestId?: string;
  renderIconForFavourites: boolean;
  activeTypeOverride?: CATALOG_TYPES;
}

const Products = ({ activeTypeOverride, dataTestId = '', renderIconForFavourites }: PropsProps) => {
  const activeType = useSelector(getActiveChip);
  const { catalogData, isFetching } = useFetchCatalog();
  const gaEventData = useGetBaseGAEventData();

  useEffect(() => {
    if (catalogData && catalogData.page?.totalElements > 0) {
      const allProductVariants = getDefaultProducts(catalogData.products);
      TagManager.dataLayer(ViewItemListTracking({ ...gaEventData, products: allProductVariants }));
    }
  }, [catalogData]);

  if (isFetching) {
    return <Loading fullScreen />;
  }

  if (!isFetching && (!catalogData?.products || !catalogData.products.length)) return null;

  if (catalogData.page?.totalElements === 0) {
    return <NoProductsMessage />;
  }

  return (
    <div data-testid={`${dataTestId}-products-grid`} className={styles.products}>
      {catalogData.products.map((productWithVariants: ProductWithVariants) => {
        const productTestId = `${productWithVariants.sku}-${dataTestId}`;
        return (
          <ProductCard.Root
            dataTestId={`${productWithVariants.sku}-${dataTestId}`}
            productWithVariants={productWithVariants}
            activeType={activeTypeOverride || activeType}
            key={productWithVariants.sku}
            renderIconFavourites={renderIconForFavourites}
          >
            <ProductCard.SKULabel dataTestId={productTestId} />
            <ProductCard.Name dataTestId={productTestId} />
            <ProductCard.Container dataTestId={productTestId}>
              <ProductCard.RewardCurrency dataTestId={productTestId} />
            </ProductCard.Container>
            <ProductCard.StockLabel dataTestId={productTestId} />
            <ProductCard.Variants />
          </ProductCard.Root>
        );
      })}
    </div>
  );
};
export { Products };
