import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { Outlet } from '../../../global/interfaces/outlet';
import { SearchBox } from '../../../UI-elements/SearchBox';
import styles from './distributor.module.scss';
import { NoActiveDistributorsInfoMessage } from './NoActiveDistributorsInfoMessage';
import { OutletListItem } from './OutletListItem';

interface Props {
  selectedOutletId: string;
  scrollSelectionListToTop: () => void;
  onOutletSelect: (outlet: Outlet) => void;
}

export const OutletList: React.FC<Props> = ({ selectedOutletId, scrollSelectionListToTop, onOutletSelect }) => {
  const { t } = useTranslation();
  const { outlets } = useDistributors();
  const [searchValue, setSearchValue] = useState('');
  const SEARCH_VALUE_LENGTH_THRESHOLD = 2;
  const shouldFilter = searchValue.length > SEARCH_VALUE_LENGTH_THRESHOLD;

  if (!outlets) {
    return null;
  }

  const filterOutletsByName = (outletsList: Outlet[], searchName: string): Outlet[] =>
    outletsList?.filter((outlet: Outlet) => outlet.name.toLowerCase().includes(searchName.toLowerCase()));

  const filteredOutlets = shouldFilter ? filterOutletsByName(outlets, searchValue) : outlets;

  const outletsWithActiveDistributors = filteredOutlets.filter((outlet: Outlet) => outlet.activeDistributorCount);
  const outletsWithInactiveDistributors = filteredOutlets.filter((outlet: Outlet) => !outlet.activeDistributorCount);

  const resetSearch = () => {
    setSearchValue('');
  };

  return (
    <>
      <SearchBox
        value={searchValue}
        onChange={setSearchValue}
        placeholder={t('entries.distributor.searchOutletsPlaceholder', { defaultValue: 'Search for the outlet name' })}
        className={styles.searchOutletsContainer}
        onDeleteIconClick={resetSearch}
        dataTestId="search-outlets-box"
      />
      {shouldFilter && (
        <div className={styles.searchResultCount}>
          {t('entries.distributor.searchOutletsResultCount', {
            defaultValue: '{{ count }} results found',
            count: filteredOutlets?.length ?? 0
          })}
        </div>
      )}
      <div className={styles.listWrapper}>
        {outletsWithActiveDistributors?.map((outlet: Outlet) => (
          <OutletListItem
            outlet={outlet}
            isSelected={selectedOutletId === outlet.eid}
            key={outlet.eid}
            onOutletSelect={onOutletSelect}
            scrollSelectionListToTop={scrollSelectionListToTop}
          />
        ))}

        {outletsWithInactiveDistributors.length > 0 && (
          <>
            <NoActiveDistributorsInfoMessage />
            {outletsWithInactiveDistributors.map((outlet: Outlet) => (
              <OutletListItem outlet={outlet} key={outlet.eid} onOutletSelect={onOutletSelect} isDisabled />
            ))}
          </>
        )}
      </div>
    </>
  );
};
