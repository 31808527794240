import { GAEvent, PageType, Platform, SearchFunction, TrackingSearch } from '../tracking';
import { gaClientId, isPWA } from '../utils';

interface SearchTrackingProps {
  userId: string;
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteEnvironment: string;
  siteLanguage: string;
  siteMarket: string;
  searchTerm: string;
  searchFunction: SearchFunction;
  pageType: PageType | string;
}

export const SearchTracking = ({
  userId,
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteEnvironment,
  siteLanguage,
  siteMarket,
  searchTerm,
  searchFunction,
  pageType
}: SearchTrackingProps): TrackingSearch => ({
  dataLayer: {
    event: GAEvent.Search,
    event_name: GAEvent.Search,
    search_term: searchTerm,
    search_function: searchFunction,
    user_id: userId,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    page_type: pageType
  }
});
