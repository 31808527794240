import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { FAVOURITE_PRODUCTS_PER_PAGE } from '../../global/constants';
import { getTodayInIsoFormat } from '../../global/utils/getToday';
import { RootState } from '../../store';
import { baseApiQuery } from '../baseApiQuery';
import { productsApi } from '../products/products';
import { promotionsApi } from '../promotions';
import {
  AddToFavouriteListParams,
  AssociatedFavListParams,
  AssociatedFavListPayload,
  DeleteFavouriteListParams,
  FavouriteListProductsPayload,
  FavouritesPayload,
  FavProductsQueryParams,
  QueryParams
} from './types';

const FAV_LIST_LIMIT = 200;
const productsEndpointNameMaps: { [key: string]: 'getCatalogProducts' | 'getRecommendedProducts' } = {
  getCatalogProducts: 'getCatalogProducts',
  getRecommendedProducts: 'getRecommendedProducts'
};

const promotionDetailEndpointNameMaps: { [key: string]: 'getPromotionDetails' } = {
  getPromotionDetails: 'getPromotionDetails'
};

export const favouritesApi = createApi({
  reducerPath: 'favouritesApi',
  baseQuery: baseApiQuery,
  tagTypes: ['FAVOURITES_LIST', 'FAVOURITE_LIST_PRODUCTS', 'ASSOCIATED_FAVOURITE_LIST'],
  endpoints: (builder) => ({
    getFavouriteList: builder.query<FavouritesPayload, QueryParams>({
      query: (params: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST, { ...params }),
        params: { size: params.size || FAV_LIST_LIMIT }
      }),
      providesTags: ['FAVOURITES_LIST']
    }),

    getFavouriteListProducts: builder.query<FavouriteListProductsPayload, FavProductsQueryParams>({
      query: ({ outletId, salesOrg, favListId, ...params }: FavProductsQueryParams) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST_PRODUCTS, { outletId, salesOrg, favListId }),
        params: { ...params }
      }),
      providesTags: ['FAVOURITE_LIST_PRODUCTS']
    }),

    deleteFavouriteList: builder.mutation<FavouritesPayload, QueryParams>({
      query: (params: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST_PRODUCTS, { ...params }),
        method: 'DELETE'
      }),
      async onQueryStarted({ outletId, salesOrg, favListId }, { dispatch, queryFulfilled }) {
        dispatch(productsApi.util.invalidateTags(['PRODUCTS', 'PRODUCT']));
        dispatch(promotionsApi.util.invalidateTags(['PROMOTION_DETAILS']));

        const patchResult = dispatch(
          favouritesApi.util.updateQueryData('getFavouriteList', { outletId, salesOrg }, (draft) => {
            const content = draft.content.filter((favItem) => favItem.id !== favListId);
            Object.assign(draft, { ...draft, content });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['ASSOCIATED_FAVOURITE_LIST']
    }),

    updateFavouriteListName: builder.mutation<FavouritesPayload, QueryParams>({
      query: (params: QueryParams) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST_PRODUCTS, { ...params }),
        method: 'PUT',
        params: { favListName: params.name }
      }),
      async onQueryStarted({ outletId, salesOrg, favListId, name }, { dispatch, queryFulfilled }) {
        dispatch(productsApi.util.invalidateTags(['PRODUCTS', 'PRODUCT']));
        dispatch(promotionsApi.util.invalidateTags(['PROMOTION_DETAILS']));
        const patchResult = dispatch(
          favouritesApi.util.updateQueryData('getFavouriteList', { outletId, salesOrg }, (draft: FavouritesPayload) => {
            const content = draft.content.map((favItem) => {
              const clonedItem = { ...favItem };
              if (clonedItem.id === favListId) clonedItem.name = name;
              return clonedItem;
            });
            Object.assign(draft, { ...draft, content });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['ASSOCIATED_FAVOURITE_LIST']
    }),

    getAssociatedFavouriteList: builder.query<AssociatedFavListPayload, AssociatedFavListParams>({
      query: (params) => {
        const { salesOrg, outletId, sku } = params;
        return {
          url: makeApiUrl(apiEndpoints.ASSOCIATED_FAVOURITE_LIST, {
            salesOrg,
            outletId,
            sku
          }),
          params: { size: FAV_LIST_LIMIT }
        };
      },
      providesTags: ['ASSOCIATED_FAVOURITE_LIST']
    }),
    createFavouriteList: builder.mutation<FavouritesPayload, AddToFavouriteListParams>({
      query: ({ favListName, salesOrg, outletId }) => ({
        url: makeApiUrl(apiEndpoints.CREATE_FAVOURITES_LIST, {
          salesOrg,
          outletId
        }),
        method: 'POST',
        body: JSON.stringify({
          favListName
        })
      }),
      async onQueryStarted({ outletId, salesOrg }, { dispatch, queryFulfilled }) {
        try {
          dispatch(productsApi.util.invalidateTags(['PRODUCTS', 'PRODUCT']));
          dispatch(promotionsApi.util.invalidateTags(['PROMOTION_DETAILS']));

          const { data } = await queryFulfilled;
          dispatch(
            favouritesApi.util.updateQueryData('getFavouriteList', { outletId, salesOrg }, (draft) => {
              Object.assign(draft, { ...draft, content: [data, ...draft.content] });
            })
          );
        } catch (_error) {
          // do nothing
        }
      }
    }),
    createFavouriteListWithProduct: builder.mutation<AssociatedFavListPayload, AddToFavouriteListParams>({
      query: ({ sku, favListId, favListName, salesOrg, outletId }) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST, {
          salesOrg,
          outletId
        }),
        method: 'POST',
        body: JSON.stringify({
          sku,
          favListName,
          favListId
        })
      }),
      async onQueryStarted({ sku }, { dispatch, queryFulfilled, getState }) {
        const queriesArray = productsApi.util.selectInvalidatedBy(getState() as RootState, [
          { type: 'PRODUCTS' },
          { type: 'PRODUCT' }
        ]);
        const promotionQuery = promotionsApi.util.selectInvalidatedBy(getState() as RootState, [
          { type: 'PROMOTION_DETAILS' }
        ]);

        promotionQuery.map(async ({ endpointName, originalArgs }) => {
          const patchResult = dispatch(
            promotionsApi.util.updateQueryData(
              promotionDetailEndpointNameMaps[endpointName],
              { ...originalArgs },
              (draft) => {
                const products = draft.products.map((product) => {
                  const clonedItem = { ...product };
                  if (clonedItem.sku === sku) clonedItem.isFavourite = true;
                  return clonedItem;
                });
                Object.assign(draft, { ...draft, products });
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        });
        queriesArray?.map(async ({ endpointName, originalArgs }) => {
          if (productsEndpointNameMaps[endpointName]) {
            const patchResult = dispatch(
              productsApi.util.updateQueryData(productsEndpointNameMaps[endpointName], { ...originalArgs }, (draft) => {
                const products = draft.products.map((product) => {
                  const clonedItem = { ...product };
                  if (clonedItem.sku === sku) clonedItem.isFavourite = true;
                  return clonedItem;
                });
                Object.assign(draft, { ...draft, products });
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }

          if (endpointName === 'getProduct') {
            const patchResult = dispatch(
              productsApi.util.updateQueryData('getProduct', { ...originalArgs }, (draft) => {
                const clonedItem = { ...draft };
                if (clonedItem.sku === sku) clonedItem.isFavourite = true;
                Object.assign(draft, clonedItem);
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }
        });
      },
      invalidatesTags: ['FAVOURITES_LIST', 'ASSOCIATED_FAVOURITE_LIST']
    }),

    addToFavouriteList: builder.mutation<AssociatedFavListPayload, AddToFavouriteListParams>({
      query: ({ sku, favListId, favListName, salesOrg, outletId }) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST, {
          salesOrg,
          outletId,
          favListId
        }),
        method: 'POST',
        body: JSON.stringify({
          sku,
          favListName,
          favListId
        })
      }),
      async onQueryStarted({ sku }, { dispatch, queryFulfilled, getState }) {
        const queriesArray = productsApi.util.selectInvalidatedBy(getState() as RootState, [
          { type: 'PRODUCTS' },
          { type: 'PRODUCT' }
        ]);
        const promotionQuery = promotionsApi.util.selectInvalidatedBy(getState() as RootState, [
          { type: 'PROMOTION_DETAILS' }
        ]);

        promotionQuery.map(async ({ endpointName, originalArgs }) => {
          const patchResult = dispatch(
            promotionsApi.util.updateQueryData(
              promotionDetailEndpointNameMaps[endpointName],
              { ...originalArgs },
              (draft) => {
                const products = draft.products.map((product) => {
                  const clonedItem = { ...product };
                  if (clonedItem.sku === sku) clonedItem.isFavourite = true;
                  return clonedItem;
                });
                Object.assign(draft, { ...draft, products });
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        });
        queriesArray?.map(async ({ endpointName, originalArgs }) => {
          if (productsEndpointNameMaps[endpointName]) {
            const patchResult = dispatch(
              productsApi.util.updateQueryData(productsEndpointNameMaps[endpointName], { ...originalArgs }, (draft) => {
                const products = draft.products.map((product) => {
                  const clonedItem = { ...product };
                  if (clonedItem.sku === sku) clonedItem.isFavourite = true;
                  return clonedItem;
                });
                Object.assign(draft, { ...draft, products });
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }

          if (endpointName === 'getProduct') {
            const patchResult = dispatch(
              productsApi.util.updateQueryData('getProduct', { ...originalArgs }, (draft) => {
                const clonedItem = { ...draft };
                if (clonedItem.sku === sku) clonedItem.isFavourite = true;
                Object.assign(draft, clonedItem);
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }
        });
      },
      invalidatesTags: ['ASSOCIATED_FAVOURITE_LIST', 'FAVOURITE_LIST_PRODUCTS']
    }),

    deleteFavouriteItem: builder.mutation<AssociatedFavListPayload, DeleteFavouriteListParams>({
      query: ({ sku, favListId, salesOrg, outletId }) => ({
        url: makeApiUrl(apiEndpoints.FAVOURITES_LIST, {
          salesOrg,
          outletId,
          sku
        }),
        method: 'DELETE',
        body: JSON.stringify({
          sku,
          favListId
        })
      }),
      async onQueryStarted(
        { sku, salesOrg, outletId, favListId, distributorId },
        { dispatch, queryFulfilled, getState }
      ) {
        const queriesArray = productsApi.util.selectInvalidatedBy(getState() as RootState, [
          { type: 'PRODUCTS' },
          { type: 'PRODUCT' }
        ]);
        const promotionQuery = promotionsApi.util.selectInvalidatedBy(getState() as RootState, [
          { type: 'PROMOTION_DETAILS' }
        ]);

        const selector = favouritesApi.endpoints.getAssociatedFavouriteList.select({
          salesOrg,
          outletId,
          sku
        });
        const allFavourites = selector(getState());
        const associatedFavourites =
          allFavourites?.data?.content?.filter((listItem) => listItem.associate === true) ?? [];

        promotionQuery.map(async ({ endpointName, originalArgs }) => {
          const patchResult = dispatch(
            promotionsApi.util.updateQueryData(
              promotionDetailEndpointNameMaps[endpointName],
              { ...originalArgs },
              (draft) => {
                const products = draft.products.map((product) => {
                  const clonedItem = { ...product };
                  if (clonedItem.sku === sku) {
                    if (associatedFavourites.length === 1) {
                      clonedItem.isFavourite = false;
                    }
                  }
                  return clonedItem;
                });
                Object.assign(draft, { ...draft, products });
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        });

        queriesArray?.map(async ({ endpointName, originalArgs }) => {
          if (productsEndpointNameMaps[endpointName]) {
            const patchResult = dispatch(
              productsApi.util.updateQueryData(productsEndpointNameMaps[endpointName], { ...originalArgs }, (draft) => {
                const products = draft.products.map((product) => {
                  const clonedItem = { ...product };
                  if (clonedItem.sku === sku) {
                    if (associatedFavourites.length === 1) {
                      clonedItem.isFavourite = false;
                    }
                  }
                  return clonedItem;
                });
                Object.assign(draft, { ...draft, products });
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }

          if (endpointName === 'getProduct') {
            const patchResult = dispatch(
              productsApi.util.updateQueryData('getProduct', { ...originalArgs }, (draft) => {
                const clonedItem = { ...draft };
                if (clonedItem.sku === sku) {
                  if (associatedFavourites.length === 1) {
                    clonedItem.isFavourite = false;
                  }
                }

                Object.assign(draft, clonedItem);
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }
        });
        const patchResult = dispatch(
          favouritesApi.util.updateQueryData(
            'getFavouriteListProducts',
            {
              outletId,
              salesOrg,
              favListId,
              deliveryDate: getTodayInIsoFormat(),
              distributorId,
              size: FAVOURITE_PRODUCTS_PER_PAGE,
              page: 0
            },
            (draft) => {
              const content = draft.content.filter((favItem) => favItem.sku !== sku);
              Object.assign(draft, { ...draft, content });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['ASSOCIATED_FAVOURITE_LIST']
    })
  })
});

export const {
  useGetFavouriteListQuery,
  useGetFavouriteListProductsQuery,
  useDeleteFavouriteListMutation,
  useUpdateFavouriteListNameMutation,
  useGetAssociatedFavouriteListQuery,
  useCreateFavouriteListMutation,
  useCreateFavouriteListWithProductMutation,
  useAddToFavouriteListMutation,
  useDeleteFavouriteItemMutation
} = favouritesApi;
