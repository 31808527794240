import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { CalculatePriceParams, CalculatePriceResponse } from './types';

export const calculationApi = createApi({
  reducerPath: 'calculationApi',
  baseQuery: baseApiQuery,
  tagTypes: ['CALCULATE_PRICE'],
  endpoints: (builder) => ({
    getCalculatePrice: builder.query<CalculatePriceResponse, CalculatePriceParams>({
      query: (params) => ({
        url: makeApiUrl(apiEndpoints.CALCULATE_PRICE, {
          salesOrg: params.salesOrg,
          outletId: params.outletId,
          distributorId: params.distributorId
        }),
        method: 'POST',
        body: JSON.stringify({
          items: params.items,
          multiplier: params.multiplier
        })
      })
    })
  })
});

export const { useGetCalculatePriceQuery } = calculationApi;
