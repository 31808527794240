import cn from 'classnames';
import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { ReactComponent as IconLoader } from '../../assets/icon-loader.svg';
import styles from './button.module.scss';

type SIZE = 'default' | 'tiny' | 'small' | 'medium' | 'large';

type TYPE =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'grey'
  | 'danger'
  | 'white'
  | 'black'
  | 'white-outline'
  | 'black-outline';

type ICON_POSITION = 'left' | 'right';

interface MainProps {
  action?: (_?: SyntheticEvent) => void;
  autoWidth?: boolean;
  bold?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  disabledAction?: () => void;
  loading?: boolean;
  type?: TYPE;
  size?: SIZE;
  iconPosition?: ICON_POSITION;
  href?: string;
  className?: string;
  preventClick?: boolean;
  ariaLabel?: string;
}

interface PropsTextIcon extends MainProps {
  text: string;
  icon: ReactNode;
}

interface PropsText extends MainProps {
  text: string;
  icon?: ReactNode;
}

interface PropsIcon extends MainProps {
  text?: string;
  icon: ReactNode;
}

export type Props = PropsTextIcon | PropsText | PropsIcon;

const Button: FC<Props> = ({
  type,
  text = null,
  action,
  autoWidth,
  bold,
  dataTestId = '',
  disabled = false,
  disabledAction,
  loading,
  size,
  icon = null,
  iconPosition = 'right',
  href,
  className,
  preventClick = false,
  ariaLabel
}: Props) => {
  const clickHandler = (evt: SyntheticEvent) => {
    if (loading) {
      return;
    }

    if (disabled) {
      if (disabledAction) {
        disabledAction();
      }
      return;
    }

    if (action) {
      action(evt);
    }
  };

  const buttonClass = cn(className, styles.button, styles.noSelect, {
    [styles['auto-width']]: autoWidth,
    [styles.secondary]: type === 'secondary',
    [styles.grey]: type === 'grey',
    [styles.danger]: type === 'danger',
    [styles.white]: type === 'white',
    [styles.black]: type === 'black',
    [styles['black-outline']]: type === 'black-outline',
    [styles['white-outline']]: type === 'white-outline',
    [styles.tiny]: size === 'tiny',
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.bold]: bold,
    [styles.loading]: loading,
    [styles.disabled]: disabled && !loading
  });

  const content = () => (
    <>
      {loading ? (
        <IconLoader data-testid="loading" />
      ) : (
        <>
          {icon && iconPosition === 'left' && icon}
          {text && text}
          {icon && iconPosition === 'right' && icon}
        </>
      )}
    </>
  );

  if (href) {
    return (
      <a href={href} className={cn(buttonClass, styles['auto-width'])} data-testid={dataTestId}>
        {content()}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={buttonClass}
      data-testid={dataTestId}
      onClick={clickHandler}
      disabled={preventClick && disabled}
      aria-label={ariaLabel}
    >
      {content()}
    </button>
  );
};

export { Button };
