import { useContext } from 'react';
import { CART_SECTION_TYPES } from '../constants';
import { ConfigContext } from '../contexts/ConfigContext';
import { Product, StockStatus } from '../interfaces/products';

interface useStockVisibilityHook {
  product: Product;
  cartSection?: CART_SECTION_TYPES;
}

export const useStockVisibility = ({ cartSection, product }: useStockVisibilityHook) => {
  const {
    featureFlags: { showNoStockInfo }
  } = useContext(ConfigContext);

  if (!product?.stock) {
    return false;
  }

  if (cartSection === CART_SECTION_TYPES.returnable) {
    return false;
  }

  if (showNoStockInfo) {
    return true;
  }

  return product.stock.stockStatus !== StockStatus.NO_STOCK_INFO;
};
