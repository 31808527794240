import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout } from '../../auth/authHandler';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { ApiError } from '../../global/interfaces/error';
import { Outlet } from '../../global/interfaces/outlet';
import { getCartError } from '../../global/selectors/cart/selectors';
import { useCreateOrderMutation } from '../../services/outlets/outlets';
import { useGetUserConfigQuery } from '../../services/user/user';
import { userConfigOutletsSelector, userIsLoggedInSelector } from '../../store/slices/user';
import { useErrorBoundary } from '../ErrorBoundary/useErrorBoundary';

const GenericApiErrorContainer: React.FC = () => {
  const { salesOrg } = useContext(ConfigContext);
  const { pathname } = useLocation();
  const { triggerBoundary } = useErrorBoundary();
  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const outlets: Outlet[] = useSelector(userConfigOutletsSelector);
  const { error: userConfigError } = useGetUserConfigQuery(
    {
      salesOrg
    },
    {
      skip: !isLoggedIn || !salesOrg
    }
  );
  const updateCartErrors = useSelector(getCartError);
  const userConfigErrorData = userConfigError as FetchBaseQueryError;

  const [_, { error: orderError }] = useCreateOrderMutation();
  const orderErrorData = ((orderError as FetchBaseQueryError)?.data as ApiError | undefined) ?? null;
  const all = [userConfigErrorData, orderErrorData, ((updateCartErrors as unknown) as ApiError) || undefined];

  useEffect(() => {
    if (outlets && !outlets.length) {
      triggerBoundary();
    }
  }, [outlets, pathname]);

  useEffect(() => {
    all
      .filter((x) => x)
      .forEach((type) => {
        switch (type.status) {
          case 307:
            if (isLoggedIn) {
              logout({ redirectUri: `${window.location.protocol}//${window.location.host}/maintenance` });
            }

            return null;
          default:
            if (pathname === '/checkout') {
              return null;
            }
            triggerBoundary();
            return null;
        }
      });
  }, all);

  return null;
};

export default GenericApiErrorContainer;
