import { createSlice } from '@reduxjs/toolkit';
import { State } from '../reducers';

interface StateInterface {
  drawerMenu: {
    isOpen: boolean;
  };
  searchModal: {
    isOpen: boolean;
  };
}

const initialState: StateInterface = {
  drawerMenu: {
    isOpen: false
  },
  searchModal: {
    isOpen: false
  }
};

export const HeaderSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setDrawerMenuOpen: (state) => {
      state.drawerMenu.isOpen = true;
    },
    setDrawerMenuClose: (state) => {
      state.drawerMenu.isOpen = false;
    },
    setSearchModalOpen: (state) => {
      state.searchModal.isOpen = true;
    },
    setSearchModalClose: (state) => {
      state.searchModal.isOpen = false;
    }
  }
});

export const { setDrawerMenuOpen, setDrawerMenuClose, setSearchModalClose, setSearchModalOpen } = HeaderSlice.actions;
export const drawerMenuIsOpen = (state: State): StateInterface['drawerMenu']['isOpen'] =>
  state?.header?.drawerMenu.isOpen;
export const searchModalIsOpen = (state: State): StateInterface['searchModal']['isOpen'] =>
  state?.header?.searchModal.isOpen;
export default HeaderSlice.reducer;
