import {
  EventAction,
  EventCategory,
  GAEvent,
  PageType,
  PaginationType,
  Platform,
  TrackingAlertMessage,
  TrackingClickInternal,
  TrackingPageView,
  TrackingPagination
} from '../tracking';
import { gaClientId, isPWA } from '../utils';

interface AlertMessageTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  pageType: string;
  eventCategory: EventCategory;
  eventAction: EventAction;
  eventLabel: string;
  messageType?: string;
}

interface PaginationTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  pageType: string;
  paginationType: PaginationType;
  paginationLabel: string;
}

interface PageViewTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  pageType: PageType;
}

interface ClickTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  eventLabel: string;
  eventAction: EventAction;
  eventCategory: EventCategory;
  linkText?: string;
  pageType: PageType;
}

export const AlertMessageTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  pageType,
  eventCategory,
  eventAction,
  eventLabel,
  messageType
}: AlertMessageTrackingProps): TrackingAlertMessage => ({
  dataLayer: {
    event: GAEvent.AlertMessages,
    event_name: GAEvent.AlertMessages,
    user_id: userId,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
    message_type: messageType,
    customer_id: customerId,
    customer_name: customerName,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    page_type: pageType
  }
});

export const PaginationTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  pageType,
  paginationType,
  paginationLabel
}: PaginationTrackingProps): TrackingPagination => ({
  dataLayer: {
    event: GAEvent.Pagination,
    event_name: GAEvent.Pagination,
    user_id: userId,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    customer_id: customerId,
    customer_name: customerName,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    page_type: pageType,
    pagination_type: paginationType,
    pagination_label: paginationLabel
  }
});

export const PageViewTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  pageType
}: PageViewTrackingProps): TrackingPageView => ({
  dataLayer: {
    event: GAEvent.PageView,
    event_name: GAEvent.PageView,
    user_id: userId,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    customer_id: customerId,
    customer_name: customerName,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    page_type: pageType
  }
});

export const ClickInternalTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  eventLabel,
  eventAction,
  eventCategory,
  linkText,
  pageType
}: ClickTrackingProps): TrackingClickInternal => ({
  dataLayer: {
    event: GAEvent.ClickInternal,
    event_name: GAEvent.ClickInternal,
    user_id: userId,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
    link_text: linkText,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId
  }
});
