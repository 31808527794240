import { Headline, HeadlineAlign, HeadlineColor, HeadlineStyle } from '@carlsberggroup/malty.atoms.headline';
import { Text, TextAlign, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Trans, useTranslation } from 'react-i18next';
import { useScreenSize } from '../../../../global/custom-hooks/useScreenSize';
import styles from './circularProgressBar.module.scss';

interface Props {
  total: number;
  completed: number;
  label?: string;
}

export function CircularProgressbar({ total, completed, label }: Props) {
  const { t } = useTranslation();
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  const progressbarStyle = buildStyles({
    pathColor: '#FDBC37', // Laos Primary
    trailColor: '#FFEDA2' // Laos Tertiary
  });
  return (
    <>
      <div className={styles.CircularProgressbar}>
        <CircularProgressbarWithChildren value={(completed / total) * 100} strokeWidth={5} styles={progressbarStyle}>
          <div className={styles.CircularProgressbarContent}>
            <Headline
              as="p"
              headlineStyle={isMobile ? HeadlineStyle.Big : HeadlineStyle.Hero}
              color={HeadlineColor.DigitalBlack}
              align={HeadlineAlign.Center}
            >
              <Trans t={t} i18nKey="entries.prizeLottery.circularProgressBarTitle" values={{ completed, total }}>
                {{ completed }} of {{ total }} <br /> weeks <br />
                achieved
              </Trans>
            </Headline>
            <br />
            <Text
              dataQaId="lottery-circle-progress-bar-label"
              textStyle={isMobile ? TextStyle.SmallBold : TextStyle.MediumBold}
              align={TextAlign.Center}
            >
              {label}
            </Text>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </>
  );
}
