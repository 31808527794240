import { useGetOutletDetailsQuery } from '../../services/outlets/outlets';
import { BasicParamsWithDistributor } from '../../services/outlets/types';
import { OutletDetails } from '../interfaces/outlet';

interface useOutletDetailsHook {
  outletDetails: OutletDetails;
  isFetchingOutletDetails: boolean;
}

export const useOutletDetails = ({
  salesOrg,
  outletId,
  distributorId
}: BasicParamsWithDistributor): useOutletDetailsHook => {
  // fetching outlet details
  const outletDetailsParams = { salesOrg, outletId, distributorId };
  const outletDetailsSkipParams = { skip: !salesOrg || !outletId || !distributorId };
  const {
    data: outletDetails,
    isFetching: isFetchingOutletDetails,
    error: outletDetailsError
  } = useGetOutletDetailsQuery(outletDetailsParams, outletDetailsSkipParams);
  if (outletDetailsError) {
    console.error('outletDetailsError', outletDetailsError);
  }

  return {
    outletDetails,
    isFetchingOutletDetails
  };
};
