import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { DistributorI } from '../../../global/interfaces/distributor';
import { Outlet } from '../../../global/interfaces/outlet';
import { formatDate } from '../../../global/utils/formatDate';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../../UI-elements/Text';
import styles from './distributor.module.scss';
import { DistributorCard } from './DistributorCard';
import { OutletCard } from './OutletCard';

interface Props {
  outlet: Outlet;
  distributor: DistributorI;
  hideDistributorInfo?: boolean;
}

export const SelectionInfo: React.FC<Props> = ({ outlet, distributor, hideDistributorInfo }) => {
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const hasTinyFontSize = isXSmall || isSmall || isMedium;
  const { t } = useTranslation();

  return (
    <div className={styles.selectionInfo} data-testid="desktop-selection-info">
      {outlet && (
        <div className={styles.selectionRow} data-testid="outlet-info-container">
          <Text
            size={hasTinyFontSize ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
            color={TEXT_COLOR.DARK_GREY}
            weight={FONT_WEIGHT.BOLD}
          >
            {t('entries.selection.outlet', { defaultValue: 'Outlet' })}
          </Text>
          <OutletCard outlet={outlet} />
        </div>
      )}
      {!hideDistributorInfo && distributor && (
        <div className={styles.selectionRow} data-testid="distributor-info-container">
          <Text
            size={hasTinyFontSize ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
            color={TEXT_COLOR.DARK_GREY}
            weight={FONT_WEIGHT.BOLD}
          >
            {t('entries.selection.distributor', { defaultValue: 'Distributor' })}
          </Text>
          <DistributorCard distributor={distributor} />
        </div>
      )}
      {!hideDistributorInfo && distributor?.nearestDeliveryDate && (
        <div className={cn([styles.selectionRow, styles.selectionInfoNearestDeliveryDate])}>
          <Text
            size={hasTinyFontSize ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
            color={TEXT_COLOR.DARK_GREY}
            weight={FONT_WEIGHT.BOLD}
            dataTestId="selection-info-delivery-date-text"
          >
            {t('entries.distributor.nearestDeliveryDate', {
              defaultValue: 'Nearest delivery date'
            })}
          </Text>
          <Text
            className={styles.card}
            size={FONT_SIZE.TINY}
            color={TEXT_COLOR.DEFAULT}
            dataTestId="selection-info-delivery-date-value"
          >
            {formatDate(distributor.nearestDeliveryDate)}
          </Text>
        </div>
      )}
    </div>
  );
};
