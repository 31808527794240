import { IconName } from '@carlsberggroup/malty.atoms.icon';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components/shared/ConfirmModal';

interface PrizeLotteryModalProps {
  showCongratulations: CongratulateModalEnum;
}

export enum CongratulateModalEnum {
  MILESTONE = 'MILESTONE',
  EVENT = 'EVENT'
}

const PrizeLotteryModal: FC<PrizeLotteryModalProps> = ({ showCongratulations }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const iconNameModal = showCongratulations === CongratulateModalEnum.MILESTONE ? IconName.Trophy : IconName.Star;

  useEffect(() => {
    if (
      showCongratulations === CongratulateModalEnum.MILESTONE ||
      showCongratulations === CongratulateModalEnum.EVENT
    ) {
      setTimeout(() => {
        setShowModal(true);
      }, 3000);
    }
  }, [showCongratulations]);

  const handleOnConfirmModal = () => {
    setShowModal(false);
  };

  const handleContentMessage = () => {
    if (showCongratulations === CongratulateModalEnum.MILESTONE) {
      return (
        <Trans t={t} i18nKey="entries.prizeLottery.modalWeeklyPoolPrizeText">
          You have finished this week&apos;s objective. <br /> You now have a chance of winning one of the weekly
          prizes. <br />
          If you win, you will be notified next week.
        </Trans>
      );
    }

    if (showCongratulations === CongratulateModalEnum.EVENT) {
      return (
        <Trans t={t} i18nKey="entries.prizeLottery.modalMonthlyPoolPrizeText">
          You have finished all weekly objectives. <br /> You now have a chance of winning one of the monthly prizes.
          <br /> If you win, you will be notified next week.
        </Trans>
      );
    }

    return null;
  };

  return (
    <ConfirmModal
      showModal={showModal}
      iconName={iconNameModal}
      onConfirm={handleOnConfirmModal}
      titleMessage={t('entries.prizeLottery.modalPoolPrizeHeadline', {
        defaultValue: 'Congratulations!'
      })}
      contentMessage={handleContentMessage()}
      confirmButtonText={t('entries.prizeLottery.modalPoolPrizeButton', {
        defaultValue: 'Continue'
      })}
      dataTestId="modal-pool-prize"
      singleButton
    />
  );
};
export { PrizeLotteryModal };
