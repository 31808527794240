import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { FiltersRequestParam, FiltersResponse } from './types';

export const filtersApi = createApi({
  reducerPath: 'filtersApi',
  baseQuery: baseApiQuery,
  tagTypes: ['FILTERS'],
  endpoints: (builder) => ({
    getFilters: builder.query<FiltersResponse, FiltersRequestParam>({
      query: (params) => ({
        url: apiEndpoints.FILTERS,
        params
      }),
      providesTags: ['FILTERS']
    })
  })
});

export const { useGetFiltersQuery } = filtersApi;
