import cn from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StockStatus } from '../../global/interfaces/products';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './stockLabel.module.scss';

interface StockLabelProps {
  status: StockStatus;
  dataTestId?: string;
}

export const StockLabel: FC<StockLabelProps> = ({ dataTestId, status }) => {
  const { t } = useTranslation();
  const isErrorStatus = status === StockStatus.NO_STOCK_INFO || status === StockStatus.OUT_OF_STOCK;
  return (
    <Text
      dataTestId={dataTestId}
      className={styles.stockLabel}
      size={FONT_SIZE.TINY}
      weight={FONT_WEIGHT.BOLD}
      color={isErrorStatus ? TEXT_COLOR.ERROR : undefined}
    >
      {!isErrorStatus && <span className={cn(styles.dot, styles[status])} />}
      {t(`entries.stock.${status}`, { defaultValue: '' })}
    </Text>
  );
};
