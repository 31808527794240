import { debounce } from 'debounce';
import { useCallback, useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import { app, CART_SECTION_TYPES } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { ListContext } from '../../../global/contexts/ListContext';
import { PageContext } from '../../../global/contexts/PageContext';
import { useCart } from '../../../global/custom-hooks/useCart';
import { useCounter } from '../../../global/custom-hooks/useCounter';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { AddToCartTracking, RemoveFromCartTracking } from '../../../global/google-analytics';
import { ItemListName, PageType } from '../../../global/google-analytics/tracking';
import { Cart } from '../../../global/interfaces/cart';
import { Product } from '../../../global/interfaces/products';
import { getCartProduct } from '../../../global/utils/getCartProduct';
import { useAddProductToCartMutation } from '../../../services/cart/cart';
import { setCartLoaderTrue } from '../../Cart/actions';

interface useAddToCartHook {
  product: Product;
  isCart: boolean;
  cartSection: CART_SECTION_TYPES;
  minQuantity?: number;
}

const useAddToCart = ({ product, isCart = false, cartSection, minQuantity = app.minAddToCart }: useAddToCartHook) => {
  const { pageType } = useContext(PageContext);
  const { listName } = useContext(ListContext);
  const isEmpty = cartSection === CART_SECTION_TYPES.returnable;
  const isReward = cartSection === CART_SECTION_TYPES.reward;
  const { currency } = useContext(ConfigContext);
  const { quantity, setQuantity } = useCounter({ initialQuantity: product.quantity || minQuantity });
  const { selectedCart } = useCart();
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const gaEventData = useGetBaseGAEventData();
  const limit = app.cartLimit;
  const cartProduct = getCartProduct(selectedCart, product?.sku, isReward);
  const cartProductCount = cartProduct?.quantity ?? 0;
  const dispatch = useDispatch();
  const [addProductToCart] = useAddProductToCartMutation();

  const addToCart = async (amount: number, isIncreaseCount?: boolean) => {
    if (!quantity || !selectedCart || !selectedOutlet || !selectedDistributor) {
      return;
    }

    let section;

    if (isReward) {
      section = CART_SECTION_TYPES.reward;
    } else if (isEmpty) {
      section = CART_SECTION_TYPES.returnable;
    }
    dispatch(setCartLoaderTrue());

    await addProductToCart({
      salesOrg: selectedOutlet.salesOrg,
      outletId: selectedOutlet.eid,
      distributorId: selectedDistributor.eid,
      cartId: selectedCart.eid,
      sku: product.sku,
      quantity: amount,
      section
    }).then((res) => {
      if (!currency) {
        return;
      }

      const productAddedData = (res as { data: Cart }).data;

      // responsible for tracking add to cart event OUTSIDE of cart component
      if (productAddedData && !isCart) {
        const currentCartProduct = getCartProduct(productAddedData, product?.sku, isReward);

        TagManager.dataLayer(
          AddToCartTracking({
            ...gaEventData,
            products: [currentCartProduct],
            pageType,
            quantity,
            listName
          })
        );

        setQuantity(1);
        return;
      }

      // responsible for tracking add to cart event INSIDE of cart component
      if (isIncreaseCount) {
        TagManager.dataLayer(
          AddToCartTracking({
            ...gaEventData,
            products: [product],
            pageType: PageType.Purchase,
            quantity: amount - product.quantity,
            listName: ItemListName.CartList
          })
        );
      } else {
        TagManager.dataLayer(
          RemoveFromCartTracking({
            ...gaEventData,
            products: [product],
            quantity: product.quantity - amount,
            pageType: PageType.Purchase,
            listName: ItemListName.CartList
          })
        );
      }
    });
  };

  const debouncedAddToCart = useCallback(debounce(addToCart, 500), [
    selectedCart,
    selectedOutlet,
    selectedDistributor,
    product
  ]);

  const handleCountChange = useCallback(
    (value: string) => {
      const counter = parseInt(value, 10);
      setCountHandler(Number.isNaN(counter) ? undefined : counter);
    },
    [product]
  );

  const setCountHandler = (value: number) => {
    let cartQuantity = 0;

    if (cartProduct) {
      cartQuantity = cartProduct.quantity;
    }

    let newCount = value;

    if (isCart ? newCount > limit : newCount + cartQuantity > limit) {
      return;
    }

    if (newCount < 0) {
      newCount = 0;
    }

    if (isCart && newCount === 0) {
      newCount = 1;
    }

    setQuantity(newCount);
  };

  const changeCount = (amount: number, isIncreaseCount: boolean) => {
    if (isCart) {
      debouncedAddToCart(quantity + amount, isIncreaseCount);
    } else {
      setCountHandler(quantity + amount);
    }
  };

  const addToCartMemoised = useCallback(() => {
    addToCart(quantity + cartProductCount);
  }, [quantity, cartProductCount, product]);

  useEffect(() => {
    if (!isCart) {
      return;
    }

    setQuantity(product.quantity);
  }, [product.quantity]);

  return {
    handleCountChange,
    quantity,
    changeCount,
    addToCart: addToCartMemoised,
    debouncedAddToCart
  };
};

export { useAddToCart };
