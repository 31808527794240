import { lazy } from '@loadable/component';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorPage } from './components/ApiErrorHandler';
import GenericApiErrorContainer from './components/ApiErrorHandler/GenericApiErrorContainer';
import { CompliancePack } from './components/CompliancePack';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { Footer } from './components/Footer';
import { GlobalContentContainer } from './components/GlobalContent';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { OfflineMessage } from './components/OfflineMessage';
import { PrivateRoute } from './components/PrivateRoute';
import { Loading } from './components/shared/Loading';
import { ConfigContextProvider } from './global/contexts/ConfigContext';
import { HeaderProvider } from './global/contexts/HeaderContext';
import { PAGE_ROUTES } from './global/custom-hooks/useGetCurrentPageInfo';
import { LoginContainer } from './pages/Login';
import { store } from './store';

const MaintenancePage = lazy(() =>
  import('./pages/Maintenance').then((module) => ({
    default: module.MaintenancePage
  }))
);

const PasswordRecoveryFlow = lazy(() =>
  import('./pages/PasswordRecoveryFlow').then((module) => ({
    default: module.PasswordRecoveryFlow
  }))
);

const ActivationFlow = lazy(() =>
  import('./pages/ActivationFlow').then((module) => ({
    default: module.ActivationFlow
  }))
);

const TextPage = lazy(() =>
  import('./pages/TextPage').then((module) => ({
    default: module.TextPage
  }))
);

const App: React.FC<EmptyObject> = () => (
  <Provider store={store}>
    <ConfigContextProvider>
      <HeaderProvider>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Suspense fallback={<Loading fullScreen />}>
              <Switch>
                <Route path={[`/${PAGE_ROUTES.maintenance.path}`]} exact>
                  <Header showNavBarMenu={false} />
                  <MaintenancePage />
                </Route>
                <Route path={[`/${PAGE_ROUTES.login.path}`]} exact>
                  <LoginContainer />
                </Route>
                <Route path={[`/${PAGE_ROUTES.passwordRecovery.path}`]} exact>
                  <PasswordRecoveryFlow />
                </Route>
                <Route path={[`/${PAGE_ROUTES.activation.path}`]} exact>
                  <ActivationFlow />
                </Route>
                <Route path={[`/${PAGE_ROUTES.pagesSlug.path}`]}>
                  <Header showNavBarMenu={false} />
                  <TextPage />
                  <Footer />
                </Route>
                <PrivateRoute path="/">
                  <Header />
                  <Main />
                  <Footer />
                </PrivateRoute>
                <Route>
                  <div>No Route Found</div>
                </Route>
              </Switch>
            </Suspense>
            <GenericApiErrorContainer />
            <OfflineMessage />
            <GlobalContentContainer />
            <CompliancePack />
          </ErrorBoundary>
        </BrowserRouter>
      </HeaderProvider>
    </ConfigContextProvider>
  </Provider>
);

export { App };
