import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NavHeaderItem } from '../../../../global/custom-hooks/useHeaderNavLinksMobile';
import { MenuType } from '../../../../global/google-analytics/tracking';
import styles from './navHeaderMobile.module.scss';

interface NavHeaderMobileItemRouteProps {
  icon: IconName;
  label: NavHeaderItem['label'];
  routePath: NavHeaderItem['path'];
  isActive: boolean;
  onMenuClick: (type: MenuType, label: string) => void;
}

const NavHeaderMobileItemRoute = ({ icon, label, routePath, isActive, onMenuClick }: NavHeaderMobileItemRouteProps) => {
  const { pathname } = useLocation();
  return (
    <NavLink
      to={{ pathname: routePath, state: { from: pathname } }}
      onClick={() => onMenuClick(MenuType.Icon, label)}
      className={styles.navHeaderMobileItem}
      role="tab-item__link"
    >
      <div className={styles.navHeaderMobileItemIcon}>
        <Icon name={icon} size={IconSize.Medium} color={IconColor.DigitalBlack} />
      </div>
      <div className={styles.navHeaderMobileItemText}>
        <Text dataQaId="mobile-menu-text" textStyle={isActive ? TextStyle.TinyBold : TextStyle.TinyDefault} ellipsis>
          {label}
        </Text>
      </div>
    </NavLink>
  );
};

export { NavHeaderMobileItemRoute };
