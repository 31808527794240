import React, { FC } from 'react';
import { ProductSlider } from '../../../../components/ProductSlider';
import { ProductCard } from '../../../../components/shared/ProductCard';
import { CATALOG_TYPES, HEADLESS_SLIDER_CODES, RECOMMENDATIONS_CODES } from '../../../../global/constants';
import { useClearkIoData } from '../../../../global/custom-hooks/useClerkIoData';
import { useRecommended } from '../../../../global/custom-hooks/useRecommended';
import { HeadlessImageI } from '../../../../global/interfaces/headless';
import { useLogClickMutation } from '../../../../services/tracking/tracking';

type RecommendationCodesUnion = Exclude<
  HEADLESS_SLIDER_CODES,
  HEADLESS_SLIDER_CODES.favourites | HEADLESS_SLIDER_CODES.picos
>;

const typeMapper: Record<RecommendationCodesUnion, RECOMMENDATIONS_CODES> = {
  [HEADLESS_SLIDER_CODES.popular]: RECOMMENDATIONS_CODES.popular,
  [HEADLESS_SLIDER_CODES.new]: RECOMMENDATIONS_CODES.new,
  [HEADLESS_SLIDER_CODES.trending]: RECOMMENDATIONS_CODES.trending,
  [HEADLESS_SLIDER_CODES.recentlyBought]: RECOMMENDATIONS_CODES.recentlyBought,
  [HEADLESS_SLIDER_CODES.promotions]: RECOMMENDATIONS_CODES.promotions
};

interface Props {
  limit?: number;
  title: string;
  headlessSliderCode: RecommendationCodesUnion;
  backgroundImage?: HeadlessImageI;
}

export const RecommendationsProductSlider: FC<Props> = ({ limit = 12, title, headlessSliderCode, backgroundImage }) => {
  const recommendationCode = typeMapper[headlessSliderCode];

  const { data, isRecommendationEngineEnabled, isFetching } = useRecommended({
    limit,
    recommendationsCode: recommendationCode,
    skip: !recommendationCode
  });

  const { clerkIoUrl, clerkIoApiKey } = useClearkIoData();

  const [logClick] = useLogClickMutation();

  const onProductCardClick = (sku: string) => {
    logClick({ sku, clerkIoApiKey, clerkIoUrl });
  };

  if (!data?.products?.length || !isRecommendationEngineEnabled || isFetching) {
    return null;
  }

  return (
    <ProductSlider
      title={title}
      backgroundImage={backgroundImage}
      products={data?.products}
      dataTestId={`${title}-slider-recommendations`}
    >
      {data.products.map((productWithVariants) => {
        const dataTestId = 'slider-recommendations';
        return (
          <ProductCard.Root
            dataTestId={dataTestId}
            productWithVariants={productWithVariants}
            activeType={CATALOG_TYPES.favourites}
            key={productWithVariants.sku}
            onProductCardClick={onProductCardClick}
            renderIconFavourites
          >
            <ProductCard.SKULabel dataTestId={dataTestId} />
            <ProductCard.Name dataTestId={dataTestId} />
            <ProductCard.Container dataTestId={dataTestId}>
              <ProductCard.RewardCurrency dataTestId={dataTestId} />
            </ProductCard.Container>
            <ProductCard.StockLabel dataTestId={dataTestId} />
            <ProductCard.Variants />
          </ProductCard.Root>
        );
      })}
    </ProductSlider>
  );
};
