import { nanoid } from 'nanoid';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from '../../auth/authHandler';
import { saveDistributorModalVisibilityToLocalStorage } from '../../components/shared/Distributor/helpers';
import { Loading } from '../../components/shared/Loading';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { getVisitorId, setVisitorId } from '../../global/google-analytics/utils';
import { useGetUserConfigQuery } from '../../services/user/user';
import { setUserConfig, setUserLoggedIn, userIsLoggedInSelector } from '../../store/slices/user';
import { Login } from './LoginComponent';

const LoginContainer = ({ children }: { children?: React.ReactNode }) => {
  const {
    i18n: { language }
  } = useTranslation();
  const { salesOrg } = useContext(ConfigContext);
  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const dispatch = useDispatch();
  const { data: dataUserConfig } = useGetUserConfigQuery(
    {
      salesOrg
    },
    {
      skip: !isLoggedIn || !salesOrg
    }
  );

  useEffect(() => {
    isAuthenticated().then((isAuthed) => {
      if (!getVisitorId()) {
        setVisitorId(nanoid());
      }
      if (!isAuthed) {
        saveDistributorModalVisibilityToLocalStorage(false);
      }

      if (isLoggedIn !== isAuthed) {
        dispatch(setUserLoggedIn({ isLoggedIn: isAuthed }));
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn || !language) {
      return;
    }

    if (language !== localStorage.getItem('i18nextLng')) {
      localStorage.removeItem('i18nextLng');
    }
  }, [language, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn || !salesOrg || !dataUserConfig) {
      return;
    }

    dispatch(setUserConfig({ config: dataUserConfig }));
  }, [isLoggedIn, salesOrg, dataUserConfig]);

  if (children) return <>{children}</>;

  if (isLoggedIn === undefined) return <Loading fullScreen />;

  return <Login isLoggedIn={isLoggedIn} />;
};

export { LoginContainer };
