import React from 'react';
import { CART_SECTION_TYPES, CHIP_TYPES } from '../constants';
import { Product, ProductWithVariants } from '../interfaces/products';

export type ProductCardContextTypes = {
  product: Product;
  cartSection: CART_SECTION_TYPES;
  productWithVariants?: ProductWithVariants;
  handleChangeProduct?: (product: Product) => void;
  isReward?: boolean;
  activeType?: CHIP_TYPES;
  navigateToProductPage?: () => void;
  searchBarList?: boolean;
  showMaxQuantityAlertOnSearchBar?: boolean;
  setShowMaxQuantityAlertOnSearchBar?: (value: boolean) => void;
};

const INITIAL_STATE: ProductCardContextTypes = {
  cartSection: null,
  product: null,
  productWithVariants: null,
  handleChangeProduct: () => null,
  isReward: false,
  activeType: null,
  navigateToProductPage: () => null,
  searchBarList: false
};

export const ProductCardContext = React.createContext<ProductCardContextTypes>(INITIAL_STATE);
