import { PRODUCTS_PER_SIZE } from '../constants';
import { useScreenSize } from './useScreenSize';

export const useProductsPerSize = (): number => {
  const { isXSmall, isSmall, isMedium, isLarge, isXLarge, isXXLarge } = useScreenSize();
  if (isXSmall || isSmall) {
    return PRODUCTS_PER_SIZE.small;
  }
  if (isMedium) {
    return PRODUCTS_PER_SIZE.medium;
  }
  if (isLarge || isXLarge || isXXLarge) {
    return PRODUCTS_PER_SIZE.large;
  }
  return PRODUCTS_PER_SIZE.xlarge;
};
