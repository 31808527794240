import { Link, LinkColor } from '@carlsberggroup/malty.atoms.link';
import React from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { FooterClickTracking } from '../../global/google-analytics';
import { HeadlessLinkI } from '../../global/interfaces/headless';
import { handleHeadlessLink } from '../../global/utils/handleHeadlessLink';
import { FONT_SIZE, Text, TEXT_COLOR } from '../../UI-elements/Text';
import styles from './footer.module.scss';

interface Props {
  footerLinks: HeadlessLinkI[];
}

export const FooterLinks: React.FC<Props> = ({ footerLinks }) => {
  const { t } = useTranslation();
  const gaEventData = useGetBaseGAEventData();

  const trackFooterLink = (linkText: string, linkUrl?: string) => {
    TagManager.dataLayer(FooterClickTracking({ ...gaEventData, linkText, linkUrl }));
  };

  const manageCookies = () => {
    trackFooterLink(t('entries.footer.manageCookiesLink', { defaultValue: 'Manage Cookies' }));
    window.com_carlsberg_compliance_pack.manageCookies();
  };

  if (footerLinks.length) {
    return (
      <div className={styles.menu}>
        {footerLinks.map((item: HeadlessLinkI) => (
          <div
            key={item.name}
            onClick={() => trackFooterLink(item.name, item.url)}
            data-testid={`footer-link-${item.name.toLocaleLowerCase().replace(/\s/g, '-')}`}
          >
            <Link url={handleHeadlessLink(item.url)} color={LinkColor.White}>
              <Text color={TEXT_COLOR.WHITE} size={FONT_SIZE.TINY}>
                {item.name}
              </Text>
            </Link>
          </div>
        ))}
        <div data-testid="footer-link-manage-cookies">
          <button type="button" className={styles['button-link']} onClick={manageCookies}>
            <Text color={TEXT_COLOR.WHITE} size={FONT_SIZE.TINY}>
              {t('entries.footer.manageCookiesLink', { defaultValue: 'Manage Cookies' })}
            </Text>
          </button>
        </div>
      </div>
    );
  }
  return null;
};
