import { datadogRum } from '@datadog/browser-rum';
import Keycloak, { KeycloakOnLoad } from 'keycloak-js';
import { getBaseUrl } from '../config/getBaseUrl';
import { getSSOConfig } from '../config/getConfig';
import { isAmplifyEnv, isDevelopment } from '../global/utils/environment';

let keycloak: Keycloak;

const getKeycloakConfig = async (isDev: boolean) => {
  const initConfig: {
    onLoad: KeycloakOnLoad;
    promiseType: string;
    checkLoginIframe: boolean;
    enableLogging: boolean;
    silentCheckSsoRedirectUri?: string;
  } = {
    onLoad: 'check-sso',
    promiseType: 'native',
    // we are running sso on a different domain which break under chrome incognito
    checkLoginIframe: !isDev,
    enableLogging: isDev
  };

  if (!isAmplifyEnv) {
    return { ...initConfig, silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html` };
  }

  return initConfig;
};

const initKeycloak = async () => {
  const ssoConfig = await getSSOConfig();
  const keycloakURL =
    isDevelopment || isAmplifyEnv
      ? `${getBaseUrl()}${ssoConfig.auth.relativePath}`
      : `${window.location.protocol}//${window.location.host}${ssoConfig.auth.relativePath}`;

  keycloak = new Keycloak({
    realm: ssoConfig.auth.realm,
    url: keycloakURL,
    clientId: ssoConfig.auth.clientId
  });

  const initConfig = await getKeycloakConfig(isDevelopment);

  return keycloak.init(initConfig);
};

const getKeycloak = () => keycloak;

const login = (locale: string) => {
  const url: string = keycloak.createLoginUrl({
    locale
  });

  window.location.assign(url);
};

const logout = (options?: { redirectUri: string }) => {
  if (!keycloak) return null;

  localStorage.removeItem('showOutletsModal');
  return keycloak.logout(options);
};

const isAuthenticated = async () => {
  if (!keycloak) {
    return initKeycloak().catch((e) => {
      console.warn('Authentication failed', e);
      datadogRum.addError(e);
      logout();

      return false;
    });
  }

  return keycloak?.authenticated ?? false;
};

const getToken = () => {
  if (!keycloak) return null;

  return keycloak.token;
};

const isTokenExpired = () => keycloak.isTokenExpired();

const refreshToken = () => {
  if (!keycloak) return null;

  return keycloak.updateToken(180);
};

export { getKeycloak, getToken, isAuthenticated, isTokenExpired, login, logout, refreshToken };
