import React from 'react';
import { CloudinaryImage } from '../../../../UI-elements/CloudinaryImage';
import styles from './headlessImageListWidget.module.scss';

interface ImageListItemProps {
  logo: string;
  title: string;
  width: number;
  height: number;
  dataQaId: string;
}
const ImageListItem: React.FC<ImageListItemProps> = ({ title, logo, width, height, dataQaId = '' }) => (
  <div className={styles.imageListItem}>
    <CloudinaryImage dataTestId={`${dataQaId}-image-list-item`} src={logo} alt={title} width={width} height={height} />
  </div>
);

export { ImageListItem };
