import { PromotionFields } from '../../../services/promotions/types';
import { Product } from '../../interfaces/products';
import {
  GAEvent,
  ItemListName,
  PageType,
  Platform,
  TrackingClickPromotion,
  TrackingSelectPromotion,
  TrackingViewPromotion,
  TrackingViewPromotionList
} from '../tracking';
import { gaClientId, isPWA, mapProductsItems } from '../utils';

// eslint-disable-next-line camelcase
const mapPromotions = (promotionList: PromotionFields[]): { promotion_id: string; promotion_name: string }[] =>
  promotionList.map((promotion) => ({
    promotion_id: promotion.code,
    promotion_name: promotion.title
  }));

interface ViewPromotionListTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  pageType: PageType;
  promotionList: PromotionFields[];
}

interface PromotionTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products?: Product[];
  pageType: PageType;
  listName: ItemListName;
  promotionId?: string;
  promotionName?: string;
  promotionDescription?: string;
  promotionType?: string;
  promotionViewType?: string;
}

export const ViewPromotionTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  pageType,
  listName,
  promotionName,
  promotionType,
  promotionId
}: PromotionTrackingProps): TrackingViewPromotion => ({
  dataLayer: {
    event: GAEvent.ViewPromotion,
    event_name: GAEvent.ViewPromotion,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      items: mapProductsItems({ products, currency, listName, quantity: 1 }),
      promotion_name: promotionName,
      promotion_id: promotionId,
      promotion_type: promotionType,
      creative_type: 'Popup'
    }
  }
});

export const ClickPromotionTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  pageType,
  listName,
  promotionName,
  promotionType,
  promotionId
}: PromotionTrackingProps): TrackingClickPromotion => ({
  dataLayer: {
    event: GAEvent.ClickPromotion,
    event_name: GAEvent.ClickPromotion,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      items: mapProductsItems({ products, currency, listName, quantity: 1 }),
      promotion_name: promotionName,
      promotion_id: promotionId,
      promotion_type: promotionType,
      creative_type: 'Popup'
    }
  }
});

export const SelectPromotionTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  pageType,
  promotionId,
  promotionName,
  promotionDescription,
  promotionViewType
}: PromotionTrackingProps): TrackingSelectPromotion => ({
  dataLayer: {
    event: GAEvent.ClickPromotion,
    event_name: GAEvent.ClickPromotion,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      items: [
        {
          promotion_id: promotionId,
          promotion_name: promotionName,
          promotion_description: promotionDescription,
          promotion_view_type: promotionViewType
        }
      ]
    }
  }
});

export const ViewPromotionListTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  pageType,
  promotionList
}: ViewPromotionListTrackingProps): TrackingViewPromotionList => ({
  dataLayer: {
    event: GAEvent.ViewPromotionList,
    event_name: GAEvent.ViewPromotionList,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      items: mapPromotions(promotionList)
    }
  }
});
