import cn from 'classnames';
import React, { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { NoProductsMessage } from '../../../../../../components/NoProductsMessage';
import { Loading } from '../../../../../../components/shared/Loading';
import { ProductCard } from '../../../../../../components/shared/ProductCard';
import { RECOMMENDATIONS_CODES } from '../../../../../../global/constants';
import { useClearkIoData } from '../../../../../../global/custom-hooks/useClerkIoData';
import { useGetBaseGAEventData } from '../../../../../../global/custom-hooks/useGetBaseGAEventData';
import { useProductsPerSize } from '../../../../../../global/custom-hooks/useProductsPerSize';
import { ViewItemListTracking } from '../../../../../../global/google-analytics/product';
import { ItemListName } from '../../../../../../global/google-analytics/tracking';
import { ProductWithVariants } from '../../../../../../global/interfaces/products';
import { getDefaultProducts } from '../../../../../../global/utils/getDefaultProduct';
import { useLogClickMutation } from '../../../../../../services/tracking/tracking';
import { FONT_SIZE, Text, TEXT_ALIGN, TEXT_COLOR } from '../../../../../../UI-elements/Text';
import styles from './recommendationsProducts.module.scss';

interface Props {
  products: ProductWithVariants[];
  isProductsLoading: boolean;
  activeChip: RECOMMENDATIONS_CODES;
  error: unknown;
}

const RecommendationProducts: FC<Props> = ({ products, isProductsLoading, activeChip, error }) => {
  const { t } = useTranslation();
  const productsPerRow = useProductsPerSize();
  const gaEventData = useGetBaseGAEventData();

  const { clerkIoUrl, clerkIoApiKey } = useClearkIoData();

  const [logClick] = useLogClickMutation();

  const onProductCardClick = (sku: string) => {
    logClick({ sku, clerkIoApiKey, clerkIoUrl });
  };

  useEffect(() => {
    if (products && products.length > 0) {
      TagManager.dataLayer(
        ViewItemListTracking({
          ...gaEventData,
          products: getDefaultProducts(products),
          listName: ItemListName.RecommendationsList
        })
      );
    }
  }, [products]);

  if (isProductsLoading) {
    return <Loading small hideText />;
  }

  if (error) {
    return (
      <div className={styles.error}>
        <Text align={TEXT_ALIGN.CENTER} color={TEXT_COLOR.DARK_GREY} size={FONT_SIZE.SMALL}>
          {t('entries.error.somethingWentWrong', {
            defaultValue: 'Hum… Something went wrong, please refresh the page!'
          })}
        </Text>
      </div>
    );
  }

  if (!products) {
    return null;
  }

  return (
    <>
      {products.length ? (
        <div className={cn(styles.products, styles[`visible-products-${productsPerRow}`])}>
          {products.map((productWithVariants: ProductWithVariants) => {
            const productTestId = 'recommendation-catalog';
            return (
              <ProductCard.Root
                dataTestId={productTestId}
                productWithVariants={productWithVariants}
                activeType={activeChip}
                key={productWithVariants.sku}
                renderIconFavourites
                onProductCardClick={onProductCardClick}
              >
                <ProductCard.SKULabel dataTestId={productTestId} />
                <ProductCard.Name dataTestId={productTestId} />
                <ProductCard.Container dataTestId={productTestId}>
                  <ProductCard.RewardCurrency dataTestId={productTestId} />
                </ProductCard.Container>
                <ProductCard.StockLabel dataTestId={productTestId} />
                <ProductCard.Variants />
              </ProductCard.Root>
            );
          })}
        </div>
      ) : (
        <NoProductsMessage />
      )}
    </>
  );
};

export { RecommendationProducts };
