import styles from './carousel.module.scss';

export const defaultTheme = {
  carouselContainer: styles.carouselContainer,
  itemsPlaceholder: styles['items-placeholder'],
  itemsWrapper: styles.itemsWrapper,
  item: styles.items__item,
  slide: styles.items__slide,
  arrow: styles.arrow,
  arrowLeft: styles['arrow--left'],
  arrowRight: styles['arrow--right'],
  dots: styles.dots,
  dot: styles.dot,
  dotActive: styles['dot--active'],
  alignItemsLeft: styles.alignItemsLeft
};
