import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CATALOG_TYPES } from '../../global/constants';
import { FilterValue, FilterWithValues } from '../../global/interfaces/filters';
import { SortMode } from '../../global/interfaces/sortMode';
import { FiltersResponse } from '../../services/filters/types';

export interface CatalogFilterActionPayload {
  filterIndex: number;
  subFilterIndex: number;
  value: boolean;
}

export interface CatalogState {
  filters: FiltersResponse | null;
  activeChip: CATALOG_TYPES;
  isFiltersOpen: boolean;
  currentPage: number;
  currentSortMode: SortMode | null;
}

const initialState: CatalogState = {
  filters: null,
  activeChip: CATALOG_TYPES.default,
  isFiltersOpen: false,
  currentPage: 0,
  currentSortMode: null
};

export const CatalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    initializeFilter: (state, action: PayloadAction<FiltersResponse>) => ({ ...state, filters: action.payload }),
    setFilterValue: (state, action: PayloadAction<CatalogFilterActionPayload>) => {
      const { filterIndex, subFilterIndex, value } = action.payload;
      const filter = state?.filters?._embedded?.filters[filterIndex];

      (filter as FilterWithValues).values[subFilterIndex].selected = value;
    },
    resetFilterValue: (state) => {
      const newState = state;
      newState.filters = {
        ...state.filters,
        _embedded: {
          ...state.filters._embedded,
          filters: state.filters?._embedded?.filters?.map((filter) => ({
            ...filter,
            values: filter.values?.map((item: FilterValue) => ({ ...item, selected: false }))
          }))
        }
      };
    },
    setCatalogActiveChip: (state, action: PayloadAction<CATALOG_TYPES>) => ({ ...state, activeChip: action.payload }),
    setFiltersToggle: (state, action: PayloadAction<boolean>) => ({ ...state, isFiltersOpen: action.payload }),
    setCurrentPage: (state, action: PayloadAction<number>) => ({ ...state, currentPage: action.payload }),
    setSortMode: (state, action: PayloadAction<SortMode>) => ({ ...state, currentSortMode: action.payload })
  }
});

export const {
  initializeFilter,
  resetFilterValue,
  setFilterValue,
  setCatalogActiveChip,
  setFiltersToggle,
  setCurrentPage,
  setSortMode
} = CatalogSlice.actions;

export default CatalogSlice.reducer;
