import { matchPath, useLocation } from 'react-router-dom';
import { PageType } from '../google-analytics/tracking';

/*
 * This is a custom hook that returns the current  page type by checking the path against the route pattern .
 * */
export const PAGE_ROUTES = {
  home: { name: 'Home', path: 'home', pageType: PageType.Highlight },
  productDetails: {
    name: 'productDetails',
    path: 'products/:cartSection/:baseSku/:variantSku',
    pageType: PageType.Details
  },
  catalog: { name: 'Catalog', path: 'catalog', pageType: PageType.List },
  favourites: { name: 'favourites', path: 'favourites/:id?', pageType: PageType.List },
  discover: { name: 'discover', path: 'discover', pageType: PageType.List },
  discoverArticle: { name: 'discoverArticle', path: 'discover/:id/', pageType: PageType.Details },
  prizeLottery: { name: 'prizeLottery', path: 'prize-lottery', pageType: PageType.Details },
  profile: { name: 'profile', path: 'profile', pageType: PageType.Private },
  faqs: { name: 'faqs', path: 'faqs', pageType: PageType.List },
  orders: { name: 'orders', path: 'orders', pageType: PageType.List },
  orderDetails: { name: 'orderDetails', path: 'orders/:id/', pageType: PageType.Details },
  rewards: { name: 'rewards', path: 'rewards', pageType: PageType.List },
  checkout: { name: 'checkout', path: 'checkout', pageType: PageType.Purchase },
  promotions: { name: 'promotions', path: 'promotions', pageType: PageType.List },
  promotionDetails: { name: 'promotionDetails', path: 'promotions/:id/', pageType: PageType.Details },
  maintenance: { name: 'maintenance', path: 'maintenance', pageType: PageType.Details },
  pagesSlug: { name: 'pagesSlug', path: 'pages/:slug/', pageType: PageType.Details },
  cookiePolicy: { name: 'cookiePolicy', path: 'pages/cookie-policy', pageType: PageType.Details },
  privacyPolicy: { name: 'privacyPolicy', path: 'pages/privacy-policy', pageType: PageType.Details },
  termsOfUse: { name: 'termsOfUse', path: 'pages/terms-of-use', pageType: PageType.Details },
  activation: { name: 'activation', path: 'activation', pageType: PageType.Private },
  passwordRecovery: { name: 'passwordRecovery', path: 'password-recovery', pageType: PageType.Private },
  login: { name: 'login', path: 'login', pageType: PageType.Private }
};

interface PageInfoProps {
  name: string;
  path: string;
  pageType: PageType;
}

export const useGetCurrentPageInfo = (): PageInfoProps => {
  const { pathname } = useLocation();
  return (Object.values(PAGE_ROUTES).find((page) => matchPath(pathname, { path: `/${page.path}`, exact: true })) ||
    {}) as PageInfoProps;
};
