import React from 'react';
import { useTranslation } from 'react-i18next';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../../../../UI-elements/Text';
import styles from './modalHeader.module.scss';

export const ModalHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.modalHeaderWrapper}>
      <Text dataTestId="favourites-pop-up-title" size={FONT_SIZE.LARGE} weight={FONT_WEIGHT.BLACK}>
        {t('entries.favourites.myFavourites', { defaultValue: 'My Favourites' })}
      </Text>
    </div>
  );
};
