import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { login } from '../../auth/authHandler';
import { Loading } from '../../components/shared/Loading';
import { ConfigContext } from '../../global/contexts/ConfigContext';

interface LoginProps {
  isLoggedIn: boolean;
}

const Login = ({ isLoggedIn = false }: LoginProps) => {
  const { featureFlags, locales } = useContext(ConfigContext);

  useEffect(() => {
    if (!isLoggedIn && locales?.default.length) {
      login(localStorage.getItem('i18nextLng') || locales.default);
    }
  }, [isLoggedIn, locales]);

  if (!featureFlags || !isLoggedIn) return <Loading fullScreen />;

  return <Redirect to="/home" />;
};

export { Login };
