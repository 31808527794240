import cn from 'classnames';
import React, { ReactNode } from 'react';
import { ReactComponent as AlertIcon } from '../../assets/alert.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../Text';
import styles from './info.module.scss';

export enum INFO_TYPES {
  INFO = 'info',
  WARNING = 'warning'
}

export enum INFO_SIZES {
  SMALL = 'small',
  LARGE = 'large'
}

interface Props {
  action?: () => void;
  actionText?: string;
  children: ReactNode;
  className?: string;
  size?: INFO_SIZES;
  type?: INFO_TYPES;
  dataTestId?: string;
}

const Info: React.FC<Props> = ({ action, actionText, children, className, size, type, dataTestId }: Props) => {
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  let icon = <InfoIcon />;
  if (type === INFO_TYPES.WARNING) {
    icon = <AlertIcon />;
  }

  let textSize = FONT_SIZE.TINY;
  if (size === INFO_SIZES.LARGE && !isMobile) {
    textSize = FONT_SIZE.SMALL;
  }

  return (
    <div className={cn([styles.info, styles[type], styles[size], className])}>
      {icon}
      <Text size={textSize} dataTestId={dataTestId}>
        {children}
      </Text>
      {actionText && (
        <button type="button" className={cn([styles.button])} onClick={action}>
          <Text size={textSize} weight={FONT_WEIGHT.BOLD} underline>
            {actionText}
          </Text>
        </button>
      )}
    </div>
  );
};

export { Info };
