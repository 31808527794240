import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import cn from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { Filters } from '../../../global/interfaces/filters';
import { getFilters } from '../../../global/selectors/filters/selectors';
import { resetFilterValue } from '../../../store/slices/catalog';
import { FONT_SIZE, Text, TEXT_COLOR } from '../../../UI-elements/Text/index';
import styles from './filters.module.scss';

interface Props {
  totalElements: string;
  className?: string;
  restorePage: () => void;
  isCatalogLoading: boolean;
}

const FilteredCount: FC<Props> = ({ totalElements, className = '', isCatalogLoading, restorePage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters: Filters[] = useSelector(getFilters);
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const hasSelectedFilters: boolean = useMemo(
    () => filters?.some((item) => item.values?.some(({ selected }) => !!selected)),
    [filters]
  );

  if (!selectedOutlet || !selectedDistributor) {
    return null;
  }

  const clearFilters = () => {
    restorePage();
    dispatch(resetFilterValue());
  };

  return (
    <div className={cn(styles['items-count-wrapper'], className)}>
      <Text color={TEXT_COLOR.DARK_GREY} size={FONT_SIZE.SMALL} dataTestId="total-items">
        {`${totalElements} ${t('entries.catalog.items', { defaultValue: 'Items' })}`}
      </Text>
      <div className={cn(styles['clear-all-filters'])}>
        <Button
          text={t('entries.filters.clearFiltersButton', { defaultValue: 'Clear filters' })}
          onClick={clearFilters}
          style={ButtonStyle.Secondary}
          size={ButtonSize.Small}
          disabled={isCatalogLoading || !hasSelectedFilters}
          dataTestId="clear-filters"
        />
      </div>
    </div>
  );
};

export { FilteredCount };
