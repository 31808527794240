import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { User } from '../../global/interfaces/user';
import { userConfigUserSelector, userIsLoggedInSelector } from '../../store/slices/user';

const GlobalContentContainer: React.FC = () => {
  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const user: User = useSelector(userConfigUserSelector);
  const { selectedOutlet, selectedDistributor } = useDistributors();

  useEffect(() => {
    if (isLoggedIn && user && selectedDistributor && selectedOutlet) {
      datadogRum.setUser({
        id: user.eid,
        name: `${user.firstName} ${user.lastName}`,
        outlet: selectedOutlet.eid,
        distributor: selectedDistributor.eid
      });
    } else {
      datadogRum.clearUser();
    }
  }, [isLoggedIn, selectedOutlet, selectedDistributor]);

  return null;
};

export default GlobalContentContainer;
