import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../global/interfaces/products';
import { ProductImageBox } from '../ProductImageBox';
import { ProductImageBoxLength, ProductImageBoxSize } from '../ProductImageBox/ProductImageBox.types';
import styles from './productsTooltip.module.scss';

export type TooltipProductData = {
  name: string;
  sku: string;
  quantity: number;
  images: Image[];
};

type ProductsTooltipItemProps = {
  dataTestId?: string;
  productsTooltip: TooltipProductData;
};

const ProductsTooltipItem = ({
  productsTooltip: { name, images, sku, quantity },
  dataTestId = 'tooltip-product'
}: ProductsTooltipItemProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} data-testid={`${dataTestId}-${sku}`}>
      <ProductImageBox
        dataTestId={`tooltip-product-image-box-${sku}`}
        images={images}
        boxLength={ProductImageBoxLength.XXSMALL}
        boxSize={ProductImageBoxSize.XXSMALL}
      />
      <div className={styles.content}>
        <Text textStyle={TextStyle.SmallBold} align={TextAlign.Left} color={TextColor.White}>
          {name}
        </Text>
        <Text textStyle={TextStyle.SmallDefault} align={TextAlign.Left} color={TextColor.Support40}>
          {t('entries.cart.quantityInCart', { defaultValue: '{{quantity}} in cart', quantity })}
        </Text>
      </div>
    </div>
  );
};

export { ProductsTooltipItem };
