import { Button, ButtonSize, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconPromo } from '../../assets/icon-promo.svg';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { ClickPromotionTracking, ViewPromotionTracking } from '../../global/google-analytics';
import { ItemListName } from '../../global/google-analytics/tracking';
import { Cart } from '../../global/interfaces/cart';
import { State } from '../../store/reducers';
import { cleanUp } from '../../store/slices/promotionModalSlice';
import { FONT_SIZE, Text, TEXT_ALIGN } from '../../UI-elements/Text';
import { Modal } from '../shared/Modal/ModalComponent';
import { ProductImageBox } from '../shared/ProductImageBox/ProductImageBox';
import { ProductImageBoxLength, ProductImageBoxSize } from '../shared/ProductImageBox/ProductImageBox.types';
import styles from './promotionalmodal.module.scss';

interface Props {
  cart: Cart;
}

const PromotionalModalComponent = ({ cart }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gaEventData = useGetBaseGAEventData();

  const [activeModal, setActiveModal] = useState(false);
  const [alreadyShowList, setAlreadyShowList] = useState<string[]>([]);

  const { appliedPromotionData, appliedPromotions } = useSelector(
    (state: State) => state.promotionModal.promotionObject
  );

  useEffect(() => {
    if (!activeModal) return;
    TagManager.dataLayer(
      ViewPromotionTracking({
        ...gaEventData,
        listName: ItemListName.PromotionsList,
        products: cart.freeItems,
        promotionName: appliedPromotionData.appliedMessage,
        promotionId: appliedPromotions[0]?.code,
        promotionType: appliedPromotions[0]?.type
      })
    );
  }, [activeModal]);

  useEffect(() => {
    if (cart && appliedPromotionData?.appliedMessage) {
      setActiveModal(true);
      if (appliedPromotionData?.targetSku) {
        setAlreadyShowList([...alreadyShowList, appliedPromotionData?.targetSku]);
      }
    }
  }, [appliedPromotionData, cart]);

  const closeModalHandler = () => {
    TagManager.dataLayer(
      ClickPromotionTracking({
        ...gaEventData,
        listName: ItemListName.PromotionsList,
        products: cart.freeItems,
        promotionName: appliedPromotionData.appliedMessage,
        promotionId: appliedPromotions[0]?.code,
        promotionType: appliedPromotions[0]?.type
      })
    );
    dispatch(cleanUp());
    setActiveModal(false);
  };

  if (!cart) {
    return null;
  }

  return (
    <div>
      {cart && appliedPromotionData ? (
        <Modal
          dataTestId="promotion-modal-container"
          className={styles['shadow-wrapper']}
          active={activeModal}
          closeModal={closeModalHandler}
          showCloseButton
        >
          <div className={styles.title}>
            <IconPromo />
            {t('entries.promotions.title', { defaultValue: 'Congratulations, you got a promotion!' })}
          </div>

          <ProductImageBox
            boxLength={ProductImageBoxLength.MEDIUM}
            images={appliedPromotionData?.images}
            boxSize={ProductImageBoxSize.MEDIUM}
            hasTransparentBackground
          />

          <Text dataTestId="promotion-modal-text" align={TEXT_ALIGN.CENTER} size={FONT_SIZE.SMALL}>
            {appliedPromotionData?.appliedMessage}
          </Text>

          <div className={styles.footer}>
            <Button
              dataTestId="promotion-modal-ok-button"
              style={ButtonStyle.Primary}
              size={ButtonSize.Large}
              onClick={closeModalHandler}
              text={t('entries.promotions.ok', { defaultValue: 'ok' })}
              fullWidth
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default PromotionalModalComponent;
