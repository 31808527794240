import React from 'react';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { DistributorI } from '../../../global/interfaces/distributor';
import { DeliveryDate } from '../../../UI-elements/DeliveryDate';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../../UI-elements/Text';
import styles from './distributor.module.scss';

interface Props {
  distributor: DistributorI;
  dataTestId?: string;
}

export const DistributorCard: React.FC<Props> = ({ distributor, dataTestId }) => {
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const hasTinyFontSize = isXSmall || isSmall || isMedium;

  if (!distributor) {
    return null;
  }

  return (
    <div className={styles.card}>
      <Text
        className={styles.cardName}
        size={hasTinyFontSize ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
        color={TEXT_COLOR.DEFAULT}
        weight={FONT_WEIGHT.BOLD}
        dataTestId={dataTestId}
      >
        {distributor.name}
      </Text>

      <div className={styles.distributorDetails}>
        <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARKER_GREY}>
          {distributor.phone}
        </Text>
        <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARKER_GREY}>
          {distributor.email}
        </Text>
        <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARKER_GREY}>
          {distributor.address}
        </Text>
        {distributor.nearestDeliveryDate && <DeliveryDate nearestDeliveryDate={distributor.nearestDeliveryDate} />}
      </div>
    </div>
  );
};
