import { nanoid } from 'nanoid';
import React, { CSSProperties } from 'react';
import { TextAsJson } from '../../services/headless/types';

export const umbracoParser = (umbracoJSON: TextAsJson[] = []): JSX.Element[] => {
  try {
    return parseElementTypes(umbracoJSON);
  } catch (_e) {
    return [];
  }
};

const parseElementTypes = (umbracoNode: TextAsJson[]): JSX.Element[] =>
  umbracoNode.map((el: TextAsJson, idx: number) => {
    if (el.Type === 'element') {
      return createElement(el, idx);
    }

    if (el.Type === 'text') {
      return getTextContent(el.Content as string, idx);
    }

    return null;
  });

const getTextContent = (content: string, idx: number) => {
  if (content === '\n') {
    return null;
  }

  if (content === ' ') {
    return <span>&nbsp;</span>;
  }

  return <React.Fragment key={idx}>{content}</React.Fragment>;
};

const convertCSSToJS = (css: string | undefined): CSSProperties => {
  const cssArray = css.split(';');
  const cssObject = cssArray.reduce((acc: Record<string, string>, cssProp) => {
    let [key, value] = cssProp.split(':');
    if (key && value) {
      key = key.trim().replace(/-([a-z])/g, (char) => char[1].toUpperCase());
      value = value.trim();
      acc[key] = value;
    }
    return acc;
  }, {});

  return cssObject;
};

const createElement = (element: TextAsJson, idx: number) => {
  const HtmlTag = element.Element as keyof JSX.IntrinsicElements;
  if (HtmlTag === 'br') {
    return <br />;
  }

  const { rowspan, ...props }: Record<string, string | number> = { key: idx, ...element.Attributes };
  const style = props.style ? convertCSSToJS(props.style as string) : {};

  // replace rowspan with rowSpan to support JSX
  if (rowspan !== undefined) {
    props.rowSpan = rowspan;
  }

  delete props.class;
  delete props.style;

  return (
    <HtmlTag {...props} style={style} className={element.Attributes?.class as string}>
      {parseElementTypes(element.Content as TextAsJson[])}
    </HtmlTag>
  );
};

export const renderHTML = (html: string): React.ReactNode[] => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;

  const reactElements: React.ReactNode[] = Array.from(wrapper.childNodes).map((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const tag = element.tagName.toLowerCase();
      const props: { [key: string]: string } = {};
      if (tag === 'br') {
        return <br key={nanoid()} />;
      }
      return React.createElement(tag, { key: nanoid(), ...props }, renderHTML(element.innerHTML));
    }
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }
    return null;
  });

  return reactElements;
};
