import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface AlternativeProductsState {
  productSku: string | null;
  isModalVisible: boolean;
}

const initialState: AlternativeProductsState = {
  productSku: null,
  isModalVisible: false
};

export const AlternativeProducts = createSlice({
  name: 'AlternativeProducts',
  initialState,
  reducers: {
    showAlternativesModal: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.productSku = payload;
        state.isModalVisible = true;
      }
    },
    closeAlternativesModal: (state) => {
      state.productSku = null;
      state.isModalVisible = false;
    }
  }
});

export const alternativeProductsSelector = (state: RootState) => state.alternativeProducts;
export const alternativesModalVisibleSelector = (state: RootState) => state.alternativeProducts.isModalVisible;
export const alternativeProductsSkuSelector = (state: RootState) => state.alternativeProducts.productSku;

export const { showAlternativesModal, closeAlternativesModal } = AlternativeProducts.actions;
export const { reducer: alternativeProductsReducer } = AlternativeProducts;
