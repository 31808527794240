import { IconName } from '@carlsberggroup/malty.atoms.icon';
import { Pill, PillColor, PillSize } from '@carlsberggroup/malty.atoms.pill';
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CART_SECTION_TYPES } from '../../../../../global/constants';
import { ConfigContext } from '../../../../../global/contexts/ConfigContext';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import { useScreenSize } from '../../../../../global/custom-hooks/useScreenSize';
import {
  getDiscountText,
  isAmountDiscount,
  isOtherPromotion,
  isPercentage
} from '../../../../../global/utils/promotionsUtils';
import styles from './productImageBoxPillPromotions.module.scss';

const ProductImageBoxPillPromotions: FC = () => {
  const { currency } = useContext(ConfigContext);
  const { product, cartSection } = useContext(ProductCardContext);
  const { t } = useTranslation();
  const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();

  const isMobile = isXSmall || isSmall || isMedium || isLarge;
  const isReturnable = cartSection === CART_SECTION_TYPES.returnable;

  const hasDiscountPrice = !!product?.price?.discount;

  const discountPromotion = useMemo(
    () => product.availablePromotionTypes?.find((promotion) => isPercentage(promotion) || isAmountDiscount(promotion)),
    [product.availablePromotionTypes]
  );

  const otherPromotion = useMemo(
    () => product.availablePromotionTypes?.find((promotion) => isOtherPromotion(promotion)),
    [product.availablePromotionTypes]
  );

  const showDiscountBadge = discountPromotion && hasDiscountPrice;

  return (
    <div className={styles.badges}>
      {isReturnable && (
        <Pill
          size={PillSize.Small}
          color={PillColor.alertStrong}
          icon={IconName.Refresh}
          text={!isMobile ? t('entries.products.returnable', { defaultValue: 'Empty' }) : null}
          dataTestId="pill-returnable-product"
        />
      )}

      {product.isNew && (
        <Pill
          size={PillSize.Small}
          color={PillColor.alertStrong}
          icon={null}
          text={t('entries.products.new', { defaultValue: 'New' })}
          dataTestId="pill-new-product"
        />
      )}

      {showDiscountBadge && (
        <Pill
          size={PillSize.Small}
          color={PillColor.alertStrong}
          icon={null}
          text={getDiscountText(discountPromotion, currency)}
          dataTestId="pill-discount-promotion"
        />
      )}

      {otherPromotion && (
        <Pill
          size={PillSize.Small}
          color={PillColor.alertStrong}
          icon={IconName.Coupon}
          text={!isMobile ? t(`entries.promotions.promotion`, { defaultValue: 'Promo' }) : null}
          dataTestId="pill-default-promotion"
        />
      )}
    </div>
  );
};

export { ProductImageBoxPillPromotions };
