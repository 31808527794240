import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface RewardsPageSliceProps {
  currentPage: number;
}

const initialState: RewardsPageSliceProps = {
  currentPage: 0
};

export const RewardsPageSlice = createSlice({
  name: 'rewardsPageSlice',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    }
  }
});

export const { setCurrentPage } = RewardsPageSlice.actions;
export const currentPage = (state: RootState) => state?.rewardsPageSlice?.currentPage as number;

export default RewardsPageSlice.reducer;
