type Theme = { [key: string]: string };

export function mergeTheme<T extends Theme>(defaultTheme: T, extendingTheme: Partial<T> = {}): T {
  return Object.keys(defaultTheme).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `${defaultTheme[key]} ${extendingTheme[key] ?? ''}`
    }),
    defaultTheme
  );
}
