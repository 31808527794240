import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiQuery } from '../baseApiQuery';
import {
  ActivateUserPayload,
  ActivateUserQueryParams,
  CreatePasswordQueryParams,
  ResetPasswordPayload,
  ResetPasswordQueryParams,
  StoreConsentQueryParams,
  ValidateCodeQueryParams
} from './types';

export const activationApi = createApi({
  reducerPath: 'activationApi',
  baseQuery: baseApiQuery,
  endpoints: (builder) => ({
    activateUser: builder.mutation<ActivateUserPayload, ActivateUserQueryParams>({
      query: ({ endpoint, body }: ActivateUserQueryParams) => ({
        url: endpoint,
        method: 'POST',
        body: JSON.stringify(body)
      }),
      extraOptions: {
        noAuth: true
      }
    }),
    storeConsent: builder.mutation<void, StoreConsentQueryParams>({
      query: ({ endpoint, body }: StoreConsentQueryParams) => ({
        url: endpoint,
        method: 'POST',
        body: JSON.stringify(body)
      }),
      extraOptions: {
        noAuth: true
      }
    }),
    validateCode: builder.query<void, ValidateCodeQueryParams>({
      query: ({ endpoint, scc }: ValidateCodeQueryParams) => ({
        method: 'GET',
        url: endpoint,
        params: { scc }
      }),
      extraOptions: {
        noAuth: true
      }
    }),
    createPassword: builder.mutation<void, CreatePasswordQueryParams>({
      query: ({ endpoint, scc, body }: CreatePasswordQueryParams) => ({
        url: endpoint,
        params: { scc },
        method: 'POST',
        body: JSON.stringify(body)
      }),
      extraOptions: {
        noAuth: true
      }
    }),
    resetPassword: builder.mutation<ResetPasswordPayload, ResetPasswordQueryParams>({
      query: ({ endpoint, body }: ResetPasswordQueryParams) => ({
        url: endpoint,
        method: 'POST',
        body: JSON.stringify(body)
      }),
      extraOptions: {
        noAuth: true
      }
    })
  })
});

export const {
  useActivateUserMutation,
  useStoreConsentMutation,
  useLazyValidateCodeQuery,
  useCreatePasswordMutation,
  useResetPasswordMutation
} = activationApi;
