import { Icon, IconColor, IconName, IconSize } from '@carlsberggroup/malty.atoms.icon';
import { ProgressBar } from '@carlsberggroup/malty.atoms.progress-bar';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './customizedProgressBar.module.scss';

interface CustomizedProgressBarProps {
  current: number;
  total: number;
  label?: string;
  progressLabel?: string;
  progressUnderTitle?: boolean;
}

const CustomizedProgressBar: FC<CustomizedProgressBarProps> = ({
  current,
  total,
  label,
  progressLabel,
  progressUnderTitle
}) => {
  const { t } = useTranslation();
  const progress = (current / total) * 100;

  return (
    <div className={styles.progressBarWrapper} style={{ gap: `${progressUnderTitle && '8px'}` }}>
      <div className={styles.titleAndProgressContainer} style={{ gap: `${progressUnderTitle ? '2px' : '12px'}` }}>
        <Text textStyle={TextStyle.SmallBold} ellipsis dataQaId="lottery-progress-bar-label">
          {label ||
            t('entries.prizeLottery.progressBarLabel', { defaultValue: 'Place an order to enter the prize pool' })}
        </Text>
        <Text textStyle={TextStyle.SmallBold}>{progressLabel || `${current}/${total}`}</Text>
        {progressUnderTitle && <Text textStyle={TextStyle.SmallDefault}>{progressLabel || `${current}/${total}`}</Text>}
        <div className={styles.progress}>
          <ProgressBar progress={progress} />
          {!progressUnderTitle && <Icon name={IconName.Trophy} color={IconColor.Primary} size={IconSize.MediumSmall} />}
        </div>
      </div>
    </div>
  );
};

export { CustomizedProgressBar };
