import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserConfig } from '../../global/interfaces/user';
import { State } from '../reducers';

interface StateInterface {
  isLoggedIn?: boolean;
  config?: UserConfig;
}

const initialState: StateInterface = {};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserLoggedIn: (state, action: PayloadAction<StateInterface>) => {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    setUserConfig: (state, action: PayloadAction<StateInterface>) => {
      state.config = action.payload.config;
    }
  }
});

export const { setUserLoggedIn, setUserConfig } = UserSlice.actions;
export const userIsLoggedInSelector = (state: State): StateInterface['isLoggedIn'] => state?.user?.isLoggedIn;
export const userConfigSelector = (state: State): StateInterface['config'] => state?.user?.config;
export const userConfigOutletsSelector = (state: State): StateInterface['config']['outlets'] =>
  state?.user?.config?.outlets;
export const userConfigUserSelector = (state: State): StateInterface['config']['user'] => state?.user?.config?.user;
export default UserSlice.reducer;
