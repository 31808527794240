import { rest } from 'msw';
import getApiConfigMock from '../../mockData/config/api';
import getCSPlusConfigMock from '../../mockData/config/csplus';
import getSSOConfigMock from '../../mockData/config/sso';
import headlessConfig from '../../mockData/headless/content.json';
import { ENVIRONMENTS, MARKETS } from '../global/constants';

export const handlers = [
  rest.get('/__cx__conf__/global/api.json', (_, res, ctx) =>
    res(ctx.json(getApiConfigMock(process.env.MARKET, process.env.ENV)))
  ),
  rest.get('/configuration/handler', (_, res, ctx) =>
    res(ctx.json(getCSPlusConfigMock(<MARKETS>process.env.MARKET, <ENVIRONMENTS>process.env.ENV)))
  ),
  rest.get('/__cx__conf__/global/sso.json', (_, res, ctx) =>
    res(ctx.json(getSSOConfigMock(process.env.MARKET, process.env.ENV)))
  ),
  rest.get('https://delivery.enterspeed.com/v1/**', (req, res, ctx) => {
    const url = req.url.searchParams.get('url');
    if (url) {
      const newUrl = new URL(url);
      if (newUrl.pathname === '/en-gb/') {
        return res(ctx.json(headlessConfig));
      }
    }
    return undefined;
  })
];
