import { Total } from '../../interfaces/cart';
import { Product } from '../../interfaces/products';
import {
  AddToCartType,
  GAEvent,
  PageType,
  Platform,
  TrackingAddToCart,
  TrackingRemoveFromCart,
  TrackingViewCart
} from '../tracking';
import { gaClientId, getProductsValue, isPWA, mapProductsItems } from '../utils';

interface AddToCartTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteEnvironment: string;
  siteLanguage: string;
  siteMarket: string;
  pageType: PageType;
  userId: string;
  currency: string;
  products: Product[];
  quantity?: number;
  listName: string;
  addToCartType?: AddToCartType;
  value?: number;
}

interface RemoveFromCartTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  pageType: PageType;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products: Product[];
  listName: string;
  quantity?: number;
}

interface ViewCartTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products: Product[];
  pageType: PageType;
  listName: string;
  cartTotal: Total;
}

export const AddToCartTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteEnvironment,
  siteLanguage,
  siteMarket,
  pageType,
  userId,
  currency,
  products,
  quantity,
  listName,
  value,
  addToCartType = AddToCartType.Single
}: AddToCartTrackingProps): TrackingAddToCart => ({
  dataLayer: {
    event: GAEvent.AddToCart,
    event_name: GAEvent.AddToCart,
    page_type: pageType,
    add_to_cart_type: addToCartType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      currency,
      value: value || getProductsValue(products, quantity),
      items: mapProductsItems({ products, currency, quantity, listName })
    }
  }
});

export const RemoveFromCartTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  pageType,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  listName,
  quantity
}: RemoveFromCartTrackingProps): TrackingRemoveFromCart => ({
  dataLayer: {
    event: GAEvent.RemoveFromCart,
    event_name: GAEvent.RemoveFromCart,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      currency,
      value: getProductsValue(products),
      items: mapProductsItems({ products, currency, listName, quantity })
    }
  }
});

export const ViewCartTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  pageType,
  listName,
  cartTotal
}: ViewCartTrackingProps): TrackingViewCart => ({
  dataLayer: {
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_market: siteMarket,
    site_language: siteLanguage,
    site_environment: siteEnvironment,
    user_id: userId,
    page_type: pageType,
    event: GAEvent.ViewCart,
    event_name: GAEvent.ViewCart,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    ecommerce: {
      items: mapProductsItems({
        products,
        currency,
        listName
      }),
      value: getProductsValue(products),
      currency,
      total_discount: cartTotal?.totalDiscount || 0,
      total_points: cartTotal?.totalCollectablePoints || 0,
      total_voucher_value: cartTotal?.totalVouchersValue || 0,
      payment_value: cartTotal?.value || 0
    }
  }
});
