import { Button, ButtonIconPosition, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { IconName } from '@carlsberggroup/malty.atoms.icon';
import cn from 'classnames';
import keen from 'keen-slider/keen-slider.scss';
import { useKeenSlider } from 'keen-slider/react';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useMountedState } from '../../global/custom-hooks/useMountedState';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { Banner } from '../../global/interfaces/headless';
import { CloudinaryImage } from '../../UI-elements/CloudinaryImage';
import { ConditionalLink } from '../shared/ConditionalLink';
import styles from './banner.module.scss';

interface Props {
  banners: Banner[];
  autoplay: boolean;
  interval: number;
}

const BannerComponent: React.FC<Props> = ({ banners, autoplay = false, interval = 5000 }) => {
  const timerId = useRef(null);
  const hasSeveralSlides = banners?.length > 1;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [pause, setPause] = useState(false);
  const isMounted = useMountedState();
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: hasSeveralSlides,
    rubberband: hasSeveralSlides,
    slideChanged(sliderInstance) {
      if (isMounted) setCurrentSlide(sliderInstance.details().relativeSlide);
    },
    created: (sliderInstance) => {
      if (sliderRef.current) {
        timerId.current = setTimeout(() => {
          sliderInstance.resize();
        }, 500);
      }
    },
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    }
  });

  useEffect(() => () => clearTimeout(timerId.current));

  useEffect(() => {
    if (sliderRef?.current) {
      const pauseSlider = () => setPause(true);
      const play = () => setPause(false);
      const currentSliderRef = sliderRef.current;
      currentSliderRef.addEventListener('mouseover', pauseSlider);
      currentSliderRef.addEventListener('mouseout', play);
      return () => {
        currentSliderRef.removeEventListener('mouseover', pauseSlider);
        currentSliderRef.removeEventListener('mouseout', play);
      };
    }
    return () => null;
  }, [sliderRef]);

  useEffect(() => {
    if (autoplay) {
      const checkedInterval = interval < 5 ? 5 : interval;
      const timer = setInterval(() => {
        if (!pause && slider) {
          slider.next();
        }
      }, checkedInterval * 1000);

      return () => {
        clearInterval(timer);
      };
    }
    return undefined;
  }, [pause, slider, interval, autoplay]);

  if (!banners || !banners.length) {
    return (
      <div className={styles.banners}>
        <div className={styles['banners-placeholder']} />
      </div>
    );
  }

  return (
    <div className={styles.banners}>
      <div data-testid="banner" ref={sliderRef} className={cn(['keen-slider', keen['keen-slider']])}>
        {banners.map((banner) => {
          const image = isMobile ? banner.mobile : banner.desktop;
          return (
            image && (
              <div key={banner.id} className={cn(['keen-slider__slide', keen['keen-slider__slide']])}>
                <ConditionalLink to={banner.link} dataTestId="banner-image-link">
                  <div className={styles.banners__slide}>
                    <CloudinaryImage alt="image-headless-title" src={image?.url} />
                  </div>
                </ConditionalLink>
              </div>
            )
          );
        })}

        {slider && hasSeveralSlides && (
          <>
            <div className={cn(styles.arrow, styles['arrow--left'])}>
              <Button
                icon={IconName.ChevronLeft}
                iconPos={ButtonIconPosition.Left}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  slider.prev();
                }}
                style={ButtonStyle.Transparent}
                aria-label="previous-slide"
              />
            </div>
            <div className={cn(styles.arrow, styles['arrow--right'])}>
              <Button
                icon={IconName.ChevronRight}
                iconPos={ButtonIconPosition.Right}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  slider.next();
                }}
                style={ButtonStyle.Transparent}
                aria-label="next-slide"
              />
            </div>
          </>
        )}
      </div>

      {slider && hasSeveralSlides && (
        <div className={styles.dots}>
          {[...Array(slider.details().size).keys()].map((idx) => (
            <div
              key={`dot-button-${idx}`}
              className={cn(styles.dot, { [styles['dot--active']]: currentSlide === idx })}
            >
              <Button
                style={ButtonStyle.Transparent}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                aria-label={`dot-button-${idx}`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { BannerComponent };
