import { IconName } from '@carlsberggroup/malty.atoms.icon';
import React, { useContext } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { MenuTracking } from '../../../global/google-analytics';
import { GAEvent, MenuType } from '../../../global/google-analytics/tracking';
import { CutOffInfo } from './CutOffInfo';
import { DistributorSteps } from './Distributor';
import styles from './distributor.module.scss';
import { DistributorNavBarButton } from './DistributorNavBarButton';

interface Props {
  openDistributorModal: (step: DistributorSteps) => void;
}

export const DistributorNavBar: React.FC<Props> = ({ openDistributorModal }) => {
  const {
    selectedOutlet,
    selectedDistributor,
    isFetchingOutlets,
    isFetchingDistributors,
    outletHasSingleDistributor,
    refetchDistributors,
    userHasSingleOutlet
  } = useDistributors();

  const gaEventData = useGetBaseGAEventData();
  const trackingMenuClicks = (label: string) => {
    TagManager.dataLayer(
      MenuTracking({
        ...gaEventData,
        event: GAEvent.MenuSecClick,
        menuType: MenuType.Text,
        menuLabel: label
      })
    );
  };

  const {
    featureFlags: { hideCutoffTime, isDirectMarket }
  } = useContext(ConfigContext);
  const hideDistributor = isDirectMarket;

  const { t } = useTranslation();

  const getOutletName = () => {
    if (selectedOutlet) {
      return selectedOutlet.name;
    }
    return t('entries.distributors.navBarOutletButton', { defaultValue: 'Outlet' });
  };

  const getDistributorOutletName = () => {
    if (selectedDistributor) {
      return selectedDistributor.name;
    }
    return t('entries.distributors.navBarDistributorButton', { defaultValue: 'Distributors' });
  };

  if (!selectedOutlet || !selectedDistributor || isFetchingOutlets || isFetchingDistributors) {
    return null;
  }

  const handleClickOutletButton = () => {
    trackingMenuClicks(getOutletName());
    if (userHasSingleOutlet) {
      openDistributorModal(DistributorSteps.OUTLET_INFO);
    } else {
      openDistributorModal(DistributorSteps.OUTLET_SELECT);
    }
  };

  const handleClickDistributorButton = () => {
    trackingMenuClicks(getDistributorOutletName());

    if (outletHasSingleDistributor) {
      openDistributorModal(DistributorSteps.DISTRIBUTOR_INFO);
    } else {
      openDistributorModal(DistributorSteps.DISTRIBUTOR_SELECT);
    }
  };

  return (
    <nav className={styles.distributorsNavBar}>
      <div className={styles.distributorsContainer}>
        <DistributorNavBarButton
          content={getOutletName()}
          icon={IconName.Outlet}
          clickHandler={handleClickOutletButton}
          dataTestIdButton="open-modal-button-outlet"
          dataTestIdButtonText="open-modal-button-outlet-text"
        />
        {!hideDistributor && (
          <DistributorNavBarButton
            content={getDistributorOutletName()}
            icon={IconName.Truck}
            clickHandler={handleClickDistributorButton}
            dataTestIdButton="open-modal-button-distributor"
            dataTestIdButtonText="open-modal-button-distributor-text"
          />
        )}
      </div>
      {!hideCutoffTime && selectedDistributor.nearestCutOffDateTime && (
        <CutOffInfo
          cutOffDate={selectedDistributor.nearestCutOffDateTime}
          timeExpiredFn={refetchDistributors}
          className={styles.cutOffMessageWrapper}
          hoursLeftToShow={8}
        />
      )}
    </nav>
  );
};
