import React from 'react';
import { BannerComponent } from '../../../components/Banner/BannerComponent';
import { EnterspeedWidgetBanner } from '../../../services/headless/types';

export const HeadlessBannerWidget: React.FC<EnterspeedWidgetBanner> = ({ widgets, bannerAutoplay, bannerInterval }) => {
  if (!widgets?.length) {
    return null;
  }

  return <BannerComponent banners={widgets} autoplay={bannerAutoplay} interval={bannerInterval} />;
};
