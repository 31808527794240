import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { baseApiQuery } from '../baseApiQuery';
import { AnnouncementsParameter, AnnouncementsResponse, QueryParams } from './types';

export const announcementsApi = createApi({
  reducerPath: 'announcementsApi',
  baseQuery: baseApiQuery,
  tagTypes: ['ANNOUNCEMENTS'],
  endpoints: (builder) => ({
    getAnnouncements: builder.query<AnnouncementsResponse, AnnouncementsParameter>({
      query: ({ ...params }: AnnouncementsParameter) => ({
        url: makeApiUrl(apiEndpoints.ANNOUNCEMENTS, {}),
        params
      }),
      providesTags: ['ANNOUNCEMENTS']
    }),
    dismissAnnouncement: builder.mutation<unknown, QueryParams>({
      query: ({ announcementId, salesOrg, outletId }) => ({
        url: makeApiUrl(apiEndpoints.ANNOUNCEMENTS_DISMISS, {
          salesOrg,
          outletId,
          announcementId
        }),
        params: {
          salesOrg,
          outletId,
          announcementId
        },
        method: 'POST'
      })
    })
  })
});

export const { useGetAnnouncementsQuery, useDismissAnnouncementMutation } = announcementsApi;
