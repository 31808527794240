import { useContext, useMemo } from 'react';
import { getToken } from '../../auth/authHandler';
import {
  getSelectedDistributor,
  getSelectedOutlet,
  useGetDistributorsQuery,
  useGetOutletsQuery
} from '../../services/outlets/outlets';
import { ConfigContext } from '../contexts/ConfigContext';
import { DistributorI } from '../interfaces/distributor';
import { Outlet } from '../interfaces/outlet';

interface useDistributorsHook {
  outlets: Outlet[];
  distributors: DistributorI[];
  selectedOutlet: Outlet | undefined;
  selectedDistributor: DistributorI;
  userHasSingleOutlet: boolean | undefined;
  userHasManyOutlets: boolean | undefined;
  userHasSingleOutletAndDistributor: boolean | undefined;
  outletHasSingleDistributor: boolean | undefined;
  outletHasManyDistributors: boolean | undefined;
  isFetchingOutlets: boolean;
  isFetchingDistributors: boolean;
  isLoadingOutletsDistributors: boolean;
  isSingleInactiveDistributor: boolean;
  hasOutletsHaveActiveDistributors: boolean;
  distributorsError: unknown;
  refetchDistributors: () => void;
}

export const useDistributors = (outlet?: Outlet): useDistributorsHook => {
  const token = getToken();

  const { salesOrg, featureFlags } = useContext(ConfigContext);
  const getOutletsParams = { salesOrg };
  const getOutletsSkipParams = { skip: !salesOrg || !token };
  const {
    data: userConfigData,
    isFetching: isFetchingOutlets,
    isLoading: isLoadingOutlets,
    error: getOutletsError
  } = useGetOutletsQuery(getOutletsParams, getOutletsSkipParams);
  if (getOutletsError) {
    console.error(getOutletsError);
  }

  const outlets = userConfigData?.outlets;
  const userHasManyOutlets = outlets ? outlets?.length > 1 : undefined;
  const userHasSingleOutlet = useMemo(() => outlets?.length === 1, [outlets]);
  const selectedOutlet = getSelectedOutlet(outlets);
  const hasOutletsHaveActiveDistributors = !!outlets?.find((outletItem) => outletItem.activeDistributorCount > 0);

  const outletId = outlet ? outlet.eid : selectedOutlet?.eid;
  const distributorQueryParams = { salesOrg, outletId };
  const distributorSkipParams = { skip: !salesOrg || !outletId || !hasOutletsHaveActiveDistributors || !token };
  const {
    data: distributors,
    refetch: refetchDistributors,
    isFetching: isFetchingDistributors,
    isLoading: isLoadingDistributors,
    error: getDistributorsError
  } = useGetDistributorsQuery(distributorQueryParams, distributorSkipParams);
  if (getDistributorsError) {
    console.error(getDistributorsError);
  }

  const selectedDistributor = getSelectedDistributor(distributors);
  const outletHasSingleDistributor = distributors ? distributors.length === 1 : undefined;
  const outletHasManyDistributors = distributors ? distributors.length > 1 : undefined;
  const userHasSingleOutletAndDistributor = distributors
    ? userHasSingleOutlet && outletHasSingleDistributor
    : undefined;

  const isSingleInactiveDistributor =
    !featureFlags?.isDirectMarket && outlets?.length === 1 && outlets[0]?.activeDistributorCount === 0;

  const isLoadingOutletsDistributors = isLoadingDistributors || isLoadingOutlets;

  return {
    outlets,
    distributors,
    selectedOutlet,
    selectedDistributor,
    userHasSingleOutlet,
    userHasSingleOutletAndDistributor,
    userHasManyOutlets,
    outletHasSingleDistributor,
    outletHasManyDistributors,
    isFetchingOutlets,
    isFetchingDistributors,
    isLoadingOutletsDistributors,
    isSingleInactiveDistributor,
    hasOutletsHaveActiveDistributors,
    distributorsError: getDistributorsError,
    refetchDistributors
  };
};
