import { useRef, useState } from 'react';
import { useScreenSize } from './useScreenSize';

const useCartSummaryToggleAccordion = () => {
  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const cartProductsRef = useRef<HTMLDivElement>();
  const cartSummaryToggleRef = useRef<HTMLDivElement>();

  const onProductsScroll = () => {
    const { clientHeight: totalDetailsClientHeight } = cartSummaryToggleRef.current || {};
    const { scrollTop, offsetHeight, scrollHeight } = cartProductsRef.current || {};
    const summaryOpenedThreshold = Math.floor(offsetHeight * 0.2);
    const isScrollLost = scrollHeight - totalDetailsClientHeight - summaryOpenedThreshold < offsetHeight;
    const isScrollOnTopOfSummary = scrollTop < summaryOpenedThreshold + totalDetailsClientHeight;

    if (isMobile && (isScrollLost || isScrollOnTopOfSummary)) {
      setIsSummaryVisible(false);
    }
  };

  return {
    isSummaryVisible,
    setIsSummaryVisible,
    cartProductsRef,
    cartSummaryToggleRef,
    onProductsScroll
  };
};

export { useCartSummaryToggleAccordion };
