import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useCart } from '../../../../global/custom-hooks/useCart';
import styles from './cartCountBadge.module.scss';

const CartCountBadge: React.FC = () => {
  const { totalProducts } = useCart();

  if (!totalProducts) {
    return null;
  }

  return (
    <div className={styles.badge}>
      <Text
        dataQaId="total-products-badge"
        textStyle={TextStyle.MicroBold}
        align={TextAlign.Center}
        color={TextColor.White}
      >
        {totalProducts > 99 ? '99+' : totalProducts.toString()}
      </Text>
    </div>
  );
};
export { CartCountBadge };
