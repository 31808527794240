export const setAriaLabelOnVisibleSlides = (
  firstSlideIndex: number,
  slidesPerView: number,
  slides: HTMLCollection
): void => {
  Array.from(slides).forEach((slide, index) => {
    if (index < firstSlideIndex + slidesPerView && index >= firstSlideIndex) {
      slide.setAttribute('aria-label', 'visible');
    } else {
      slide.removeAttribute('aria-label');
    }
    return slide;
  });
};
