import cn from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ALIGNED } from '../../../global/constants';
import { ConfigContext } from '../../../global/contexts/ConfigContext';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { useGetHeadlessDataByPageHandleQuery } from '../../../services/headless/headlessApi';
import { EnterspeedHandles } from '../../../services/headless/types';
import { CloudinaryImage } from '../../../UI-elements/CloudinaryImage';
import { FONT_SIZE, FONT_WEIGHT, Text, TEXT_COLOR } from '../../../UI-elements/Text';
import styles from './rewardCurrency.module.scss';

export enum SIZE {
  BIG = 'big',
  SMALL = 'small',
  LARGE = 'large',
  TINY = 'tiny'
}

export enum OPERATION {
  SUM = '+',
  SUBTRACTION = '-',
  NONE = ''
}

export interface Props {
  value: number;
  size?: SIZE;
  dataTestId?: string;
  operation?: OPERATION;
  aligned?: ALIGNED;
  className?: string;
}

const RewardCurrency: React.FC<Props> = ({
  value = 0,
  size = SIZE.SMALL,
  operation = OPERATION.NONE,
  aligned = ALIGNED.RIGHT,
  dataTestId,
  className
}) => {
  const {
    featureFlags: { showLoyalty }
  } = useContext(ConfigContext);

  const {
    i18n: { language }
  } = useTranslation();

  const { isXSmall, isSmall } = useScreenSize();
  const isMobile = isXSmall || isSmall;
  const { selectedOutlet } = useDistributors();

  const { data: loyaltyHeadlessData } = useGetHeadlessDataByPageHandleQuery(
    {
      handle: EnterspeedHandles.LOYALTY_PAGE,
      languageCode: language,
      salesOrg: selectedOutlet?.salesOrg,
      contentDelivery: true
    },
    { skip: !language || !showLoyalty }
  );

  const { loyaltyIcon } = loyaltyHeadlessData?.views?.csLoyalty ?? {};

  const fontSizeRewardCurrencyMapper = {
    [SIZE.TINY]: FONT_SIZE.TINY,
    [SIZE.BIG]: FONT_SIZE.MEDIUM_LARGE,
    [SIZE.LARGE]: FONT_SIZE.LARGE,
    [SIZE.SMALL]: isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL
  };

  const fontSize = fontSizeRewardCurrencyMapper[size];

  const renderRewardValue = (rewardValue: number): number | string => {
    if (rewardValue === 0) {
      return rewardValue;
    }
    return `${operation}${rewardValue}`;
  };

  if (!showLoyalty) {
    return null;
  }

  return (
    <div
      className={cn(styles.points, className, styles[size], {
        [styles.left]: aligned === ALIGNED.LEFT
      })}
    >
      <Text weight={FONT_WEIGHT.BOLD} color={TEXT_COLOR.DEFAULT} size={fontSize} dataTestId={dataTestId}>
        {renderRewardValue(value)}
      </Text>

      {loyaltyIcon && (
        <CloudinaryImage
          dataTestId={`reward-icon-${dataTestId}`}
          src={loyaltyIcon}
          alt="loyalty-icon"
          width={20}
          height={20}
        />
      )}
    </div>
  );
};

export { RewardCurrency };
