import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export type INotification = {
  id: string;
};

export interface NotificationsState {
  list: INotification[];
}

const initialState: NotificationsState = {
  list: []
};

export const NotificationsSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    setNotification: <T extends INotification>(state: NotificationsState, { payload }: PayloadAction<T>) => {
      const existingNotification = state.list.find((_notification) => _notification.id === payload.id);
      if (existingNotification) {
        Object.assign(existingNotification, payload);
      } else {
        state.list.push(payload);
      }
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter((notification) => notification.id !== action.payload);
    }
  }
});

export const notificationsSelector = (state: RootState) => state.notifications.list;
export const notificationByIdSelector = (id: string) => (state: RootState) =>
  state.notifications.list.find((notification) => notification.id === id);

export const { setNotification, removeNotification } = NotificationsSlice.actions;
export const { reducer: notificationsReducer } = NotificationsSlice;
