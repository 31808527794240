import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADDING_PRODUCTS_NOTIFICATION } from '../../../global/constants';
import { INotification } from '../../Notifications/slice/notifications';
import styles from './productsTooltip.module.scss';

interface ProductsTooltipFetchingProps {
  addingProductsNotification: INotification | null;
}
const ProductsTooltipFetching = ({ addingProductsNotification }: ProductsTooltipFetchingProps) => {
  const { t } = useTranslation();

  if (addingProductsNotification?.id !== ADDING_PRODUCTS_NOTIFICATION) {
    return null;
  }

  return (
    <div className={styles.tooltipTextWrapper} data-testid="adding-products-tooltip">
      <Text textStyle={TextStyle.SmallBold} align={TextAlign.Left} color={TextColor.White}>
        {t('entries.cart.addingProducts', { defaultValue: 'Adding products' })}
      </Text>
    </div>
  );
};
export { ProductsTooltipFetching };
