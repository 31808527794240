import { ProductsTooltipFetching } from './ProductsTooltipFetching';
import { ProductsTooltipLimited } from './ProductsTooltipLimited';
import { ProductsTooltipList } from './ProductsTooltipList';
import { ProductsTooltipRoot } from './ProductsTooltipRoot';

export const ProductsTooltip = {
  Root: ProductsTooltipRoot,
  List: ProductsTooltipList,
  Limited: ProductsTooltipLimited,
  Fetching: ProductsTooltipFetching
};
