import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import React from 'react';
import { useFloatTooltipContext } from '../../../global/contexts/FloatTooltipContext';

const FloatTooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ style, ...props }, propRef) => {
    const context = useFloatTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) {
      return null;
    }

    return (
      <FloatingPortal>
        <div
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...style
          }}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  }
);

export { FloatTooltipContent };
