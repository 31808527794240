import React from 'react';
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as YouTube } from '../../assets/youtube.svg';
import { HeadlessSocialLink } from '../../global/interfaces/headless';
import styles from './footer.module.scss';

interface Props {
  socialLinks: HeadlessSocialLink[];
}

export const SocialLinks: React.FC<Props> = ({ socialLinks }) => {
  if (socialLinks.length) {
    return (
      <div className={styles.social}>
        {socialLinks.map((social: HeadlessSocialLink) => {
          const type = social.icon.toLowerCase();

          return (
            <div data-testid={`social-link-${type}`} className={styles[type]} key={type}>
              <a href={social.link} target="_blank" rel="noopener noreferrer" aria-label={type} role="link">
                {type === 'facebook' && <Facebook />}
                {type === 'instagram' && <Instagram />}
                {type === 'youtube' && <YouTube />}
              </a>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
