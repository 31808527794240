import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import React, { useRef, useState } from 'react';
import styles from './video.module.scss';

interface Props {
  url: string;
  thumbnail: string;
  dataQaId: string;
  extension: string;
}

const Video: React.FC<Props> = ({ url, thumbnail, extension, dataQaId = '' }) => {
  const { MediaPlay } = IconsPaths;
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef(null);
  const toggleVideoPlay = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setPlaying(true);
    } else {
      videoRef.current.pause();
      setPlaying(false);
    }
  };
  return (
    <div className={styles.videoWrapper} data-testid={`${dataQaId}-video-item`}>
      <video ref={videoRef} preload="none" controls poster={thumbnail}>
        <source src={url} type={`video/${extension}`} />
      </video>
      {!isPlaying && (
        <div className={styles.controlsContainer} onClick={toggleVideoPlay}>
          <div className={styles.controls}>
            <div className={styles.controlsIcon}>
              <MediaPlay color={IconColor.Primary} size={IconSize.Large} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export { Video };
