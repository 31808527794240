import { Total } from '../../interfaces/cart';
import { Product } from '../../interfaces/products';
import {
  GAEvent,
  PageType,
  Platform,
  TrackingBeginCheckout,
  TrackingPurchase,
  TrackingPurchaseEvaluation
} from '../tracking';
import { gaClientId, getProductsValue, isPWA, mapProductsItems } from '../utils';

interface PurchaseEvaluationTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  pageType: PageType;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  evaluationName: string;
  evaluationGrade: number;
  evaluationComments: string;
}

interface CheckoutTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  pageType: PageType;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products: Product[];
  listName: string;
  coupon?: string;
  cartTotal: Total;
}

interface PurchaseTrackingProps {
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  pageType: PageType;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  currency: string;
  products: Product[];
  listName: string;
  transactionId: string;
  tax: number;
  coupon?: string;
  cartTotal: Total;
}

export const PurchaseEvaluationTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  pageType,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  evaluationName,
  evaluationGrade,
  evaluationComments
}: PurchaseEvaluationTrackingProps): TrackingPurchaseEvaluation => ({
  dataLayer: {
    event: GAEvent.Evaluation,
    event_name: GAEvent.Evaluation,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    evaluation_name: evaluationName,
    evaluation_grade: evaluationGrade,
    evaluation_comments: evaluationComments
  }
});

export const BeginCheckoutTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  pageType,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  listName,
  coupon,
  cartTotal
}: CheckoutTrackingProps): TrackingBeginCheckout => ({
  dataLayer: {
    event: GAEvent.Checkout,
    event_name: GAEvent.Checkout,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      currency,
      value: getProductsValue(products),
      items: mapProductsItems({ products, currency, listName }),
      coupon,
      total_discount: cartTotal?.totalDiscount || 0,
      total_points: cartTotal?.totalCollectablePoints || 0,
      total_voucher_value: cartTotal?.totalVouchersValue || 0,
      payment_value: cartTotal?.value || 0
    }
  }
});

export const PurchaseTracking = ({
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  pageType,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  currency,
  products,
  listName,
  transactionId,
  tax,
  coupon,
  cartTotal
}: PurchaseTrackingProps): TrackingPurchase => ({
  dataLayer: {
    event: GAEvent.Purchase,
    event_name: GAEvent.Purchase,
    page_type: pageType,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    client_id: gaClientId,
    user_id: userId,
    ecommerce: {
      currency,
      value: getProductsValue(products),
      items: mapProductsItems({ products, currency, listName }),
      transaction_id: transactionId,
      tax,
      coupon,
      total_discount: cartTotal?.totalDiscount || 0,
      total_points: cartTotal?.totalCollectablePoints || 0,
      total_voucher_value: cartTotal?.totalVouchersValue || 0,
      payment_value: cartTotal?.value || 0
    }
  }
});
