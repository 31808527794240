import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnterspeedWidgetImageList } from '../../../../services/headless/types';
import styles from './headlessImageListWidget.module.scss';
import { ImageListItem } from './ImageListItem';

const HeadlessImageListWidget: React.FC<EnterspeedWidgetImageList> = ({ items }) => {
  const { t } = useTranslation();

  if (!items.length) {
    return null;
  }

  return (
    <div className={styles.contentWrapper}>
      <Text textStyle={TextStyle.SmallBold} as="h1">
        {t('entries.discoverDetails.otherBrands', {
          defaultValue: 'Other brands'
        })}
      </Text>

      <div className={styles.imageList}>
        {items.map((item) => (
          <ImageListItem
            key={item.id}
            dataQaId={item.id}
            logo={item?.url}
            title={item.title}
            width={item?.properties?.width}
            height={item?.properties?.height}
          />
        ))}
      </div>
    </div>
  );
};

export { HeadlessImageListWidget };
