import { Button, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { IconName } from '@carlsberggroup/malty.atoms.icon';
import { Select, SelectOptionsType, SelectType } from '@carlsberggroup/malty.atoms.select';
import { SelectPosition } from '@carlsberggroup/malty.atoms.select/dist/Select.types';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CATALOG_TYPES } from '../../../global/constants';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useFetchCatalog } from '../../../global/custom-hooks/useFetchCatalog';
import { useGetBaseGAEventData } from '../../../global/custom-hooks/useGetBaseGAEventData';
import { useGetDesktopHeaderHeight } from '../../../global/custom-hooks/useGetDesktopHeaderHeight';
import { useScreenSize } from '../../../global/custom-hooks/useScreenSize';
import { FilterTracking } from '../../../global/google-analytics';
import { FilterType } from '../../../global/google-analytics/tracking';
import { getActiveChip, getFilters, getFiltersToggle } from '../../../global/selectors/filters/selectors';
import { getTodayInIsoFormat } from '../../../global/utils/getToday';
import { useNumberOfProducts } from '../../../global/utils/numberOfProducts';
import { useGetFiltersQuery } from '../../../services/filters/filters';
import { FiltersRequestParam } from '../../../services/filters/types';
import { useGetCatalogProductsQuery } from '../../../services/products/products';
import { CatalogQueryParams } from '../../../services/products/types';
import {
  initializeFilter,
  setCatalogActiveChip,
  setCurrentPage,
  setFiltersToggle,
  setSortMode
} from '../../../store/slices/catalog';
import { Chips } from '../Chips';
import styles from './listSort.module.scss';

const ListSort = () => {
  const isFiltersOpen = useSelector(getFiltersToggle);
  const [isFiltersSelected, setIsFiltersSelected] = useState(false);
  const gaEventData = useGetBaseGAEventData();
  const dispatch = useDispatch();
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const { topHeaderHeightAsCSS } = useGetDesktopHeaderHeight();
  const { t } = useTranslation();
  const isTabletLandscape = isXSmall || isSmall || isMedium;
  const { selectedOutlet, selectedDistributor } = useDistributors();
  const numberOfProducts = useNumberOfProducts();
  const filters = useSelector(getFilters);
  const activeChip = useSelector(getActiveChip);
  const { isFetching: isCatalogLoading, catalogData } = useFetchCatalog();
  const picosParams: CatalogQueryParams = {
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    deliveryDate: getTodayInIsoFormat(),
    size: numberOfProducts,
    withFilters: false,
    withSortMode: false,
    type: CATALOG_TYPES.picos,
    distributorId: selectedDistributor?.eid
  };
  const filtersParams: FiltersRequestParam = {
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    type: activeChip,
    distributorId: selectedDistributor?.eid
  };

  const { data: picosData, isLoading } = useGetCatalogProductsQuery(picosParams, {
    skip: !selectedOutlet || !selectedDistributor
  });

  const { data: filtersApiData } = useGetFiltersQuery(filtersParams, {
    skip: !activeChip || !selectedOutlet || !selectedDistributor
  });

  const getChipsWithProducts = useCallback(() => {
    if (!isLoading) {
      const hasPicosProducts = picosData?.products.length > 0;
      return Object.values(CATALOG_TYPES).filter(
        (chip) => chip !== CATALOG_TYPES.picos || (chip === CATALOG_TYPES.picos && hasPicosProducts)
      );
    }
    return [];
  }, [picosData, isLoading]);

  const toggleFilters = () => {
    dispatch(setFiltersToggle(!isFiltersOpen));
  };

  const chipSelection = (type: CATALOG_TYPES, label: string) => {
    dispatch(setCatalogActiveChip(type));
    restorePage();

    TagManager.dataLayer(
      FilterTracking({
        ...gaEventData,
        filterType: FilterType.Tab,
        filterName: 'categories',
        filterLabel: label,
        filterLabelId: type
      })
    );
  };

  const selectSortMode = (selectedOptions: SelectOptionsType[]) => {
    dispatch(setSortMode(catalogData?.sortModes?.find((sortItem) => sortItem.code === selectedOptions[0].value)));
    restorePage();

    TagManager.dataLayer(
      FilterTracking({
        ...gaEventData,
        filterType: FilterType.Sort,
        filterName: 'listSort',
        filterLabel: selectedOptions[0].name,
        filterLabelId: selectedOptions[0].value.toString()
      })
    );
  };

  const restorePage = () => {
    dispatch(setCurrentPage(0));
  };

  useEffect(() => {
    if (!filters || !filters.length) {
      return;
    }

    const isSelected = filters.some((item) => {
      if ('values' in item) {
        return item.values?.some(({ selected }) => selected);
      }
      return false;
    });

    setIsFiltersSelected(!!isSelected);
  }, [filters]);

  useEffect(() => {
    dispatch(initializeFilter(filtersApiData));
  }, [filtersApiData]);

  return (
    <div style={topHeaderHeightAsCSS} className={styles['filters-wrapper']}>
      <div className={styles['filters-content']}>
        <div className={styles['filters-chips']}>
          {isTabletLandscape && (
            <div className={cn(styles['filters-button'], { [styles['filters-button--active']]: isFiltersSelected })}>
              <Button style={ButtonStyle.Secondary} icon={IconName.Filters} onClick={toggleFilters} />
            </div>
          )}
          <Chips chips={getChipsWithProducts()} onTabChange={chipSelection} isCatalogLoading={isCatalogLoading} />
        </div>
        <div className={styles['sort-row']}>
          <div data-testid="sort-button">
            {catalogData?.sortModes ? (
              <Select
                label={t(`entries.catalog.sort`, { defaultValue: 'Sort by' })}
                dataTestId="catalog-sorting-value"
                options={catalogData?.sortModes.map((sortItem) => {
                  const { code } = sortItem;
                  return { name: t(`entries.sortMode.${code}`, { defaultValue: code }), value: code };
                })}
                onValueChange={selectSortMode}
                type={SelectType.Inline}
                clearAllOption={false}
                alignPosition={SelectPosition.Right}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ListSort };
