import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useCurrentLanguage } from '../../global/custom-hooks/useCurrentLanguage';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { useLogout } from '../../global/custom-hooks/useLogout';
import { LoginTracking } from '../../global/google-analytics';
import { LoginMethod } from '../../global/google-analytics/tracking';
import { UserConfig } from '../../global/interfaces/user';
import { useGetHeadlessConfigQuery } from '../../services/headless/headlessApi';
import { EnterspeedPages } from '../../services/headless/types';
import { userConfigSelector } from '../../store/slices/user';
import { checkUserHasSetDistributor } from '../shared/Distributor/helpers';
import { Loading } from '../shared/Loading';
import { SideDrawerHeader } from './components/DrawerHeader';
import { HeaderComponent } from './HeaderComponent';

interface HeaderContainerProps {
  showNavBarMenu?: boolean;
}

const HeaderContainer = ({ showNavBarMenu }: HeaderContainerProps) => {
  const currentLanguage = useCurrentLanguage();
  const [trackingCall, setTrackingCall] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { isSingleInactiveDistributor, outlets, selectedOutlet } = useDistributors();
  const userConfig: UserConfig = useSelector(userConfigSelector);

  const userHasSetDistributor = checkUserHasSetDistributor();
  const { handleLogout } = useLogout();

  const gaEventData = useGetBaseGAEventData();

  useEffect(() => {
    if (!userConfig || trackingCall) {
      return;
    }

    const { user } = userConfig;

    if (user && selectedOutlet) {
      TagManager.dataLayer(
        LoginTracking({
          ...gaEventData,
          loginMethod: userHasSetDistributor ? LoginMethod.Automatic : LoginMethod.Manual
        })
      );
    }
    setTrackingCall(true);
  }, [userConfig, selectedOutlet, currentLanguage, userHasSetDistributor]);

  const { data: headerHeadlessData } = useGetHeadlessConfigQuery(
    { url: EnterspeedPages.CONFIG, languageCode: currentLanguage?.isoCode },
    {
      skip: !currentLanguage?.isoCode
    }
  );

  // Don't rerender logo
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    if (!headerHeadlessData) {
      return;
    }

    if (!logo && headerHeadlessData?.route?.brand?.logo) {
      setLogo(headerHeadlessData?.route?.brand?.logo);
    }
  }, [headerHeadlessData]);

  if (outlets && !outlets?.length) {
    return null;
  }

  if (isSingleInactiveDistributor) {
    return null;
  }

  const logoutHandler = () => {
    setIsLoggingOut(true);
    handleLogout({ redirectUri: `${window.location.protocol}//${window.location.host}/` });
  };

  return (
    <>
      {isLoggingOut && <Loading fullScreen hideButton fixed />}
      <HeaderComponent logo={logo} onLogout={logoutHandler} showNavBarMenu={showNavBarMenu} />
      <SideDrawerHeader logout={logoutHandler} logo={logo} />
    </>
  );
};
export { HeaderContainer };
