import { GAEvent, MenuType, PageType, Platform, TrackingMenu } from '../tracking';
import { gaClientId, isPWA } from '../utils';

interface MenuTrackingProps {
  event: GAEvent;
  customerId: string;
  customerName: string;
  customerChannel: string;
  localTimestamp: string;
  pageType: PageType | string;
  siteMarket: string;
  siteLanguage: string;
  siteEnvironment: string;
  userId: string;
  menuType: MenuType;
  menuLabel: string;
}

export const MenuTracking = ({
  event,
  customerId,
  customerName,
  customerChannel,
  localTimestamp,
  pageType,
  siteMarket,
  siteLanguage,
  siteEnvironment,
  userId,
  menuType,
  menuLabel
}: MenuTrackingProps): TrackingMenu => ({
  dataLayer: {
    event,
    event_name: event,
    user_id: userId,
    customer_id: customerId,
    customer_name: customerName,
    customer_channel: customerChannel,
    local_timestamp: localTimestamp,
    site_environment: siteEnvironment,
    site_language: siteLanguage,
    site_market: siteMarket,
    client_id: gaClientId,
    platform: isPWA() ? Platform.Webapp : Platform.Web,
    page_type: pageType,
    menu_type: menuType,
    menu_label: menuLabel
  }
});
