import { useAPIConfig } from './useConfig';
import { useDistributors } from './useDistributors';
import { useOutletDetails } from './useOutletDetails';

export const useClearkIoData = () => {
  const { selectedOutlet, selectedDistributor } = useDistributors();

  const { outletDetails } = useOutletDetails({
    salesOrg: selectedOutlet?.salesOrg,
    outletId: selectedOutlet?.eid,
    distributorId: selectedDistributor?.eid
  });

  const { clerkIoUrl } = useAPIConfig();

  return {
    clerkIoApiKey: outletDetails?.recommendationEngine?.key,
    clerkIoUrl
  };
};
