import { CSSProperties, useEffect, useState } from 'react';
import { useHeaderContext } from '../contexts/HeaderContext';
import { useScreenSize } from './useScreenSize';

export const useGetDesktopHeaderHeight = () => {
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const isTabletLandscape = isXSmall || isSmall || isMedium;
  const [topHeaderHeightAsCSS, setTopStickyHeight] = useState<
    CSSProperties & {
      '--top-sticky-height': string;
    }
  >({
    '--top-sticky-height': '0px'
  });
  const headerContext = useHeaderContext();

  useEffect(() => {
    if (!isTabletLandscape) {
      setTopStickyHeight({
        '--top-sticky-height': `${headerContext?.currentDesktopHeaderHeight}px`
      } as CSSProperties & { '--top-sticky-height': string });
    }
  }, [headerContext?.currentDesktopHeaderHeight]);

  return {
    topHeaderHeightAsCSS
  };
};
