import React from 'react';
import { CART_SECTION_TYPES } from '../../../../../global/constants';
import { ProductCardContext } from '../../../../../global/contexts/ProductCardContext';
import { getCartSection } from '../../../../../global/utils/getCartSection';
import { Variants } from '../../../../Variants';

const VariantComponent = () => {
  const { productWithVariants, handleChangeProduct, activeType } = React.useContext(ProductCardContext);
  const cartSection = getCartSection(activeType);
  const isReward = cartSection === CART_SECTION_TYPES.reward;
  const isProductWithMoreThanOneVariants = productWithVariants.variants.length > 1;
  if (!isReward && isProductWithMoreThanOneVariants) {
    return <Variants productWithVariants={productWithVariants} setSelectedVariantProduct={handleChangeProduct} />;
  }
  return null;
};

export { VariantComponent };
