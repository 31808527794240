import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface OrdersPageState {
  activePeriod: number;
  currentPage: number;
}

const initialState: OrdersPageState = {
  activePeriod: 7,
  currentPage: 0
};

export const OrdersPageSlice = createSlice({
  name: 'ordersPage',
  initialState,
  reducers: {
    setActivePeriod: (state, action: PayloadAction<{ activePeriod: number }>) => {
      state.activePeriod = action.payload.activePeriod;
    },
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      state.currentPage = action.payload.currentPage;
    }
  }
});

export const { setActivePeriod, setCurrentPage } = OrdersPageSlice.actions;
export const activePeriodSelector = (state: RootState): OrdersPageState['activePeriod'] => state.orders.activePeriod;
export const currentPage = (state: RootState): OrdersPageState['currentPage'] => state.orders.currentPage;
export default OrdersPageSlice.reducer;
