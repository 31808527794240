import React from 'react';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { CART_SECTION_TYPES, CATALOG_TYPES } from '../../global/constants';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { useMenuClick } from '../../global/custom-hooks/useMenuClick';
import { ProductClickTracking } from '../../global/google-analytics';
import { ProductWithVariants } from '../../global/interfaces/products';
import { getDefaultProduct } from '../../global/utils/getDefaultProduct';
import { ProductCard } from '../shared/ProductCard';
import styles from './search.module.scss';

interface SearchResultProps {
  product: ProductWithVariants;
}

const SearchResult = ({ product }: SearchResultProps) => {
  const history = useHistory();
  const gaEventData = useGetBaseGAEventData();
  const { handleCloseSearchModal } = useMenuClick();
  const defaultProduct = getDefaultProduct(product);

  const closeSearch = () => {
    handleCloseSearchModal();
    TagManager.dataLayer(
      ProductClickTracking({
        ...gaEventData,
        products: [getDefaultProduct(product)]
      })
    );
  };

  const cartSection = CART_SECTION_TYPES.product;

  const productTestId = `search-results-${product.sku}`;

  const handleClickProduct = () => {
    closeSearch();
    history.push(
      defaultProduct.baseSku === defaultProduct.sku
        ? `/products/${cartSection}/${defaultProduct.baseSku}/${defaultProduct.baseSku}`
        : `/products/${cartSection}/${defaultProduct.baseSku}/${defaultProduct.sku}`
    );
  };

  return (
    <React.Fragment key={product.sku}>
      <ProductCard.Root
        dataTestId={productTestId}
        productWithVariants={product}
        key={product.sku}
        renderIconFavourites={false}
        activeType={CATALOG_TYPES.default}
        searchBarList
        onProductCardClick={handleClickProduct}
      >
        <ProductCard.SKULabel dataTestId={productTestId} />
        <ProductCard.Name dataTestId={productTestId} />
        <ProductCard.Container dataTestId={productTestId}>
          <ProductCard.RewardCurrency dataTestId={productTestId} />
        </ProductCard.Container>
        <ProductCard.Variants />
      </ProductCard.Root>
      <div className={styles.divider} />
    </React.Fragment>
  );
};

export { SearchResult };
