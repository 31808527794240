import React from 'react';
import { useFloatTooltip } from '../custom-hooks/useFloatTooltip';

type ContextType = ReturnType<typeof useFloatTooltip> | null;

export const FloatTooltipContext = React.createContext<ContextType>(null);

export const useFloatTooltipContext = () => {
  const context = React.useContext(FloatTooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <FloatTooltip.Root />');
  }

  return context;
};
