import { IconColor, IconSize, IconsPaths } from '@carlsberggroup/malty.atoms.icon';
import { Input, InputType } from '@carlsberggroup/malty.atoms.input';
import { Text, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React, { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { FAVOURITE_TITLE_MAX_LENGTH } from '../../../../../global/constants';
import { ListContext } from '../../../../../global/contexts/ListContext';
import { PageContext } from '../../../../../global/contexts/PageContext';
import { useGetBaseGAEventData } from '../../../../../global/custom-hooks/useGetBaseGAEventData';
import { AddToFavouritesTracking } from '../../../../../global/google-analytics';
import { Product } from '../../../../../global/interfaces/products';
import { useCreateFavouriteListWithProductMutation } from '../../../../../services/favourites/favourites';
import { AssociatedFavListPayload, FavouriteListContent } from '../../../../../services/favourites/types';
import { RedirectToErrorPage } from '../../../../ApiErrorHandler/RedirectToErrorPage';
import { CreateButton } from '../CreateButton/CreateButton';
import styles from './modalFooter.module.scss';

interface ModalFooterProps {
  favouriteList: AssociatedFavListPayload;
  salesOrg: string;
  product: Product;
  outletId: string;
  setIsCreateClicked: (isCreateClicked: boolean) => void;
}

export const ModalFooter = ({ favouriteList, salesOrg, product, outletId, setIsCreateClicked }: ModalFooterProps) => {
  const { Plus } = IconsPaths;
  const { pageType } = useContext(PageContext);
  const { listName } = useContext(ListContext);
  const { t } = useTranslation();
  const sku = product.baseSku;
  const gaEventData = useGetBaseGAEventData();
  const validationErrorOptions = {
    emptyName: t('entries.favourites.listNameEmptyError', { defaultValue: 'Please enter a list name' }),
    nameExists: t('entries.favourites.nameExistsError', { defaultValue: 'List name already exists' })
  };

  const [isValidListName, setValidListName] = useState(true);
  const [validationError, setValidationError] = useState('');
  const [favListName, setFavListName] = useState('');
  const [isShowCreateFavouriteInput, setShowCreateFavouriteInput] = useState(false);

  const [
    createFavouriteListWithProduct,
    { error: createFavouriteListError, isLoading: isAddFavouriteLoading }
  ] = useCreateFavouriteListWithProductMutation();

  if (createFavouriteListError) {
    return <RedirectToErrorPage />;
  }

  const handleCreateFavouriteList = () => {
    setIsCreateClicked(true);
    const isValid = validateListName(favListName);
    if (isValid) {
      createFavouriteListWithProduct({
        sku,
        favListName,
        salesOrg,
        outletId
      }).then(() => {
        TagManager.dataLayer(
          AddToFavouritesTracking({
            ...gaEventData,
            product,
            pageType,
            listName
          })
        );

        setFavListName('');
      });
    }
  };

  const onChange = (value: string) => {
    setFavListName(value);
    validateIfNameExists(value);
  };

  const validateIfNameExists = (name: string) => {
    if (favouriteList?.content?.length > 0) {
      if (
        favouriteList.content.some(
          (item: FavouriteListContent) => item.name.toLowerCase().trim() === name.toLowerCase().trim()
        )
      ) {
        setValidationError(validationErrorOptions.nameExists);
        setValidListName(false);
      } else {
        setValidationError('');
        setValidListName(true);
      }
    } else {
      setValidationError('');
      setValidListName(true);
    }
  };

  const validateListName = (name: string): boolean => {
    const isValid = name.length > 0;
    if (!isValid) {
      setValidationError(validationErrorOptions.emptyName);
      setValidListName(false);
      return false;
    }
    setValidationError('');
    setValidListName(true);
    return true;
  };

  const handleShowInput = () => {
    setShowCreateFavouriteInput(true);
  };

  return (
    <>
      <div className={styles.modalContentShadow} />
      <div className={styles.modalFooterWrapper}>
        {(!isShowCreateFavouriteInput && (
          <div className={styles.addFavouriteToggle} onClick={handleShowInput}>
            <Plus color={IconColor.Primary} size={IconSize.Medium} />
            <div className={styles.createText}>
              <Text textStyle={TextStyle.MediumSmallDefault}>
                {t('entries.favourites.createNewFavouriteList', { defaultValue: 'Create new favourite list' })}
              </Text>
            </div>
          </div>
        )) || (
          <>
            <div className={styles.inputWrapper}>
              <Input
                dataTestId="input-create-favourite-list"
                value={favListName}
                onValueChange={onChange}
                placeholder={t('entries.favourites.createListPlaceholder', { defaultValue: 'Create new list' })}
                required
                error={!isValidListName && validationError}
                type={InputType.Text}
                maxLength={FAVOURITE_TITLE_MAX_LENGTH}
              />
            </div>
            <CreateButton
              isValid={isValidListName}
              onClick={handleCreateFavouriteList}
              isLoading={isAddFavouriteLoading}
            />
          </>
        )}
      </div>
    </>
  );
};
