import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cart } from '../../global/interfaces/cart';

interface StateInterface {
  promotionObject?: {
    appliedPromotionData: Cart['appliedPromotionData'];
    appliedPromotions?: Cart['appliedPromotions'];
  };
}

const initialState: StateInterface = {
  promotionObject: null
};

export const PromotionModalSlice = createSlice({
  name: 'promotionModalSlice',
  initialState,
  reducers: {
    setPromotionData: (state, action: PayloadAction<StateInterface['promotionObject']>) => {
      state.promotionObject = action.payload;
    },
    cleanUp: () => initialState
  }
});

export const { setPromotionData, cleanUp } = PromotionModalSlice.actions;
export default PromotionModalSlice.reducer;
