import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { baseMaintenanceApiQuery } from '../baseMaintenanceApiQuery';
import { AppMaintenanceInfoResponse } from './types';

export const maintenanceApi = createApi({
  reducerPath: 'maintenanceApi',
  baseQuery: baseMaintenanceApiQuery,
  tagTypes: ['MAINTENANCE'],
  endpoints: (builder) => ({
    getAppInfo: builder.query<AppMaintenanceInfoResponse, void>({
      query: () => ({
        url: makeApiUrl(apiEndpoints.APP_MAINTENANCE_INFO, {})
      }),
      providesTags: ['MAINTENANCE']
    })
  })
});

export const { useGetAppInfoQuery } = maintenanceApi;
