import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDown } from '../../../../assets/chevron-down.svg';
import { useScreenSize } from '../../../../global/custom-hooks/useScreenSize';
import { Filters } from '../../../../global/interfaces/filters';
import { FONT_SIZE, FONT_WEIGHT, Text } from '../../../../UI-elements/Text';
import styles from '../filters.module.scss';
import { FiltersDropDownContent } from './FiltersDropDownContent';

export interface FiltersAccordionProps {
  filter: Filters;
  index: number;
  handleOnChange: (
    filterIndex: number,
    subFilterIndex: number,
    checked: boolean,
    filterName: string,
    filterLabel: string,
    filterLabelId: string
  ) => void;
  dataTestId?: string;
}

const FiltersAccordion = ({ filter, index, handleOnChange, dataTestId }: FiltersAccordionProps) => {
  const { t } = useTranslation();
  const { isXSmall, isSmall, isMedium } = useScreenSize();
  const isMobile = isXSmall || isSmall || isMedium;

  return (
    <details data-testid={`filter-${filter.code}`} className={cn(styles['details-container'])} open>
      <summary>
        <div className={styles['filters-title']}>
          <Text
            dataTestId={`${dataTestId}-label-${filter.code}`}
            className={styles['filters-label']}
            size={isMobile ? FONT_SIZE.TINY : FONT_SIZE.SMALL}
            weight={FONT_WEIGHT.BOLD}
          >
            {t(`entries.filterCode.${filter.code}`, { defaultValue: filter.code })}
          </Text>
          <ChevronDown />
        </div>
      </summary>
      <div data-testid={`dropdown-wrapper-${filter.code}`} className={cn(styles['dropdown-content-wrapper'])}>
        <FiltersDropDownContent filter={filter} index={index} handleOnChange={handleOnChange} dataTestId={dataTestId} />
      </div>
    </details>
  );
};

export { FiltersAccordion };
