import { useContext } from 'react';
import { CART_SECTION_TYPES } from '../constants';
import { ConfigContext } from '../contexts/ConfigContext';
import { Product, StockStatus } from '../interfaces/products';

type Props = {
  product: Product;
  cartSection?: CART_SECTION_TYPES;
};

export const useStockData = ({ cartSection, product }: Props) => {
  const { featureFlags } = useContext(ConfigContext);

  const isReward = cartSection === CART_SECTION_TYPES.reward;
  const isFree = cartSection === CART_SECTION_TYPES.free;
  const isReturnable = cartSection === CART_SECTION_TYPES.returnable;
  const isProductWithPrice = product?.price;
  const isProductOutOfStock = product.stock?.stockStatus === StockStatus.OUT_OF_STOCK;
  const isProductNoStockInformation = product.stock?.stockStatus === StockStatus.NO_STOCK_INFO;
  const showAlternativeProducts = featureFlags?.showAlternativeProducts;
  const canBuyNoStockInfo = featureFlags?.orderingNoStockInformation;
  const canBuyOutOfStock = featureFlags?.orderingOutOfStock;
  const isNotAvailableToBuyDueStock =
    (isProductOutOfStock && !canBuyOutOfStock) || (isProductNoStockInformation && !canBuyNoStockInfo);

  const shouldRenderAlternativesButton =
    isProductWithPrice &&
    isNotAvailableToBuyDueStock &&
    showAlternativeProducts &&
    !isReward &&
    !isFree &&
    !isReturnable;

  return {
    isProductOutOfStock,
    isProductNoStockInformation,
    showAlternativeProducts,
    canBuyNoStockInfo,
    canBuyOutOfStock,
    isNotAvailableToBuyDueStock,
    shouldRenderAlternativesButton
  };
};
