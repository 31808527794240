import { GUTTER_PER_SIZE } from '../constants';
import { isTestEnv } from '../utils/environment';
import { useScreenSize } from './useScreenSize';

export const useGuttersPerSize = () => {
  const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
  if (isTestEnv) {
    // otherwise it will return NaN and break tests
    return 0;
  }
  if (isXSmall) {
    return GUTTER_PER_SIZE.xsmall;
  }
  if (isSmall) {
    return GUTTER_PER_SIZE.small;
  }
  if (isMedium || isLarge) {
    return GUTTER_PER_SIZE.medium;
  }
  return GUTTER_PER_SIZE.large;
};
