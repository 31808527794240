import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, makeApiUrl } from '../../api/constants/endpoints';
import { LotteryMilestonesProps } from '../../pages/PrizeLottery/PrizeLottery';
import { baseApiQuery } from '../baseApiQuery';
import { LotteryQueryParams } from './types';

export const lotteryApi = createApi({
  reducerPath: 'lotteryApi',
  baseQuery: baseApiQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    fetchMilestones: builder.query<LotteryMilestonesProps, LotteryQueryParams>({
      query: (params: LotteryQueryParams) => ({
        url: makeApiUrl(apiEndpoints.MILESTONES_LIST, {
          outletId: params.outletId,
          salesOrg: params.salesOrg
        })
      })
    })
  })
});

export const { useFetchMilestonesQuery } = lotteryApi;
