import { PROMOTION_TYPES } from '../constants';
import { Currency } from '../interfaces/currencies';
import { PromotionTypes } from '../interfaces/products';
import { formatPrice } from './formatPrice';

/**
 *
 * The next three fuctions are used to recognise the three cases that we have
 * in the following document:
 * https://carlsberggbs.atlassian.net/wiki/spaces/CSA/pages/1159168263/Bundle+and+Order+Level+Promotion+Scenarios
 *
 * IsPercentage is for the promotions that the type is "Percentage" and they have product.price.discount.
 * isAmountDiscount is for the promotions that the type is "Amount" and they have product.price.discount.
 *
 * These two cases have to show the label "Discount" and the percentage or the product.price.discount.
 * In case that a product has percentage and Amount discounts, we can only show one of them,
 * so it'll look for percentage discount and then, for Amount Discount.
 *
 * isOtherPromotion is for all the rest of the cases. In this case, it's only necessary to show the "Promotion" label.
 * Only one promotion badge can be shown.
 *
 */

export const isPercentage = (promotion: PromotionTypes): boolean =>
  promotion.type === PROMOTION_TYPES.PERCENTAGE || promotion.type === PROMOTION_TYPES.PERCENTAGE_OLD;

export const isAmountDiscount = (promotion: PromotionTypes): boolean =>
  promotion.type === PROMOTION_TYPES.AMOUNT || promotion.type === PROMOTION_TYPES.AMOUNT_OLD;

export const isLoyaltyReward = (promotion: PromotionTypes): boolean =>
  promotion.type === PROMOTION_TYPES.POINTS || promotion.type === PROMOTION_TYPES.POINTS_OLD;

export const isOtherPromotion = (promotion: PromotionTypes): boolean =>
  !isPercentage(promotion) && !isAmountDiscount(promotion) && !isLoyaltyReward(promotion);

export const getDiscountText = (promotion: PromotionTypes, currency?: Currency) => {
  if (isPercentage(promotion)) {
    return `-${promotion.value}%`;
  }

  if (isAmountDiscount(promotion) && currency) {
    return `-${formatPrice(promotion.value, currency.code, currency.symbol)}`;
  }

  return null;
};

export const getPromotionsDetails = (promotions: PromotionTypes[]) =>
  promotions?.reduce(
    (acc, promotion) => {
      if (isPercentage(promotion)) {
        acc.isPercentageDiscount = true;
        acc.percentageDiscountValue = promotion.value;
      } else if (isAmountDiscount(promotion)) {
        acc.isAmountDiscount = true;
        acc.amountDiscountValue = promotion.value;
      } else if (isLoyaltyReward(promotion)) {
        acc.isPointsRewards = true;
        acc.pointsRewardsValue = promotion.value;
      } else if (isOtherPromotion(promotion)) {
        acc.isOtherPromotion = true;
      }
      return acc;
    },
    {
      isPercentageDiscount: false,
      percentageDiscountValue: 0,
      isAmountDiscount: false,
      amountDiscountValue: 0,
      isPointsRewards: false,
      pointsRewardsValue: 0,
      isOtherPromotion: false
    }
  );
