import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProductCardContext } from '../../../global/contexts/ProductCardContext';
import { useCart } from '../../../global/custom-hooks/useCart';
import { useDistributors } from '../../../global/custom-hooks/useDistributors';
import { useGetLoyaltyBalanceQuery } from '../../../services/loyaltyBalance/loyaltyBalance';
import { FONT_SIZE, Text } from '../../../UI-elements/Text';
import { AddToCart } from '../../shared/AddToCart';
import { AddToCartProvider, withRewardMaxQuantity } from '../../shared/AddToCart/AddToCartContext';
import styles from '../cartquantity.module.scss';

interface Props {
  dataTestId?: string;
  primary?: boolean;
}

const EnhancedAddToCartProvider = withRewardMaxQuantity(AddToCartProvider);

const RewardCartQuantity: React.FC<Props> = ({ dataTestId = '', primary }) => {
  const { t } = useTranslation();
  const { product, cartSection } = useContext(ProductCardContext);
  const { selectedCart } = useCart();
  const { selectedOutlet } = useDistributors();
  const { data: balance } = useGetLoyaltyBalanceQuery(
    {
      salesOrg: selectedOutlet?.salesOrg,
      outletId: selectedOutlet?.eid
    },
    {
      skip: !selectedOutlet
    }
  );

  if (!selectedCart || !balance || !product?.price) {
    return null;
  }

  const currentBalanceAfterCartItemsCost =
    (balance?.availablePoints ?? 0) - (selectedCart?.total?.totalRedeemablePoints ?? 0);
  const neededExtraPointsForProduct = product.price.base - currentBalanceAfterCartItemsCost;

  const showPointsNeeded: boolean = neededExtraPointsForProduct > 0 || !balance;

  return (
    <>
      <EnhancedAddToCartProvider cartSection={cartSection} isCart={false} product={product}>
        <AddToCart dataTestId={dataTestId} primary={primary} />
      </EnhancedAddToCartProvider>
      {showPointsNeeded && (
        <Text size={FONT_SIZE.MICRO} className={styles.wrapper} dataTestId={`${dataTestId}-cart-max-quantity`}>
          <Trans t={t} i18nKey="entries.rewards.pointsNeeded" values={{ quantity: neededExtraPointsForProduct }}>
            <strong>{{ quantity: neededExtraPointsForProduct }} JingJai(s) needed </strong>
          </Trans>
        </Text>
      )}
    </>
  );
};

export { RewardCartQuantity };
