import loadable from '@loadable/component';
import cn from 'classnames';
import React, { createContext, Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigContext } from '../../global/contexts/ConfigContext';
import { useDistributors } from '../../global/custom-hooks/useDistributors';
import { PAGE_ROUTES } from '../../global/custom-hooks/useGetCurrentPageInfo';
import { Catalog } from '../../pages/Catalog';
import { Home } from '../../pages/Home';
import globalStyles from '../../styles/global.scss';
import { AlternativesModal } from '../AlternativeProducts';
import { RedirectToErrorPage } from '../ApiErrorHandler/RedirectToErrorPage';
import { Cart } from '../Cart';
import { InsertCartLoading } from '../InsertCartLoading';
import { PromotionalModal } from '../PromotionalModal';
import { SearchModal } from '../Search';
import { AddToHomeScreen } from '../shared/AddToHomeScreen';
import { Distributor } from '../shared/Distributor';
import { Loading } from '../shared/Loading';

const RewardPoints = loadable(() =>
  import('../../pages/RewardPoints').then((module) => ({
    default: module.Rewards
  }))
);

const Checkout = loadable(() =>
  import('../../pages/Checkout').then((module) => ({
    default: module.Checkout
  }))
);

const ProductPage = loadable(() =>
  import('../../pages/ProductPage').then((module) => ({
    default: module.ProductPage
  }))
);

const Profile = loadable(() =>
  import('../../pages/Profile').then((module) => ({
    default: module.Profile
  }))
);

const OrdersDetails = loadable(() =>
  import('../../pages/OrderDetails').then((module) => ({
    default: module.OrdersDetails
  }))
);

const FAQs = loadable(() =>
  import('../../pages/FAQs').then((module) => ({
    default: module.FAQs
  }))
);

const PrizeLottery = loadable(() =>
  import('../../pages/PrizeLottery').then((module) => ({
    default: module.PrizeLottery
  }))
);

const Promotions = loadable(() =>
  import('../../pages/Promotions').then((module) => ({
    default: module.Promotions
  }))
);

const Orders = loadable(() =>
  import('../../pages/Orders').then((module) => ({
    default: module.Orders
  }))
);
const Favourites = loadable(() =>
  import('../../pages/Favourites').then((module) => ({
    default: module.Favourites
  }))
);
const Discover = loadable(() =>
  import('../../pages/Discover').then((module) => ({
    default: module.Discover
  }))
);
const DiscoverDetails = loadable(() =>
  import('../../pages/DiscoverDetails').then((module) => ({
    default: module.DiscoverDetails
  }))
);
const PromotionDetails = loadable(() =>
  import('../../pages/PromotionDetails').then((module) => ({
    default: module.PromotionDetails
  }))
);

interface DistributorContextModel {
  isDistributorModalOpen: boolean;
  setDistributorModalOpen: (isOpen: boolean) => void;
}

export const DistributorContext = createContext<DistributorContextModel>({
  isDistributorModalOpen: false,
  setDistributorModalOpen: (_) => null
});

const Main = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { featureFlags } = useContext(ConfigContext);
  const { isSingleInactiveDistributor } = useDistributors();
  const iOSAddToHomeScreen = useMemo(() => <AddToHomeScreen />, []);
  const [isDistributorModalOpen, setDistributorModalOpen] = useState<boolean>(false);
  const distributorContextValue = useMemo(() => ({ isDistributorModalOpen, setDistributorModalOpen }), [
    isDistributorModalOpen
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isSingleInactiveDistributor) {
    return <RedirectToErrorPage />;
  }

  if (!featureFlags) return null;

  return (
    <DistributorContext.Provider value={distributorContextValue}>
      <Distributor />
      <main className={cn(globalStyles.mainContent, globalStyles.container)}>
        <InsertCartLoading />

        <Suspense fallback={<Loading fullScreen />}>
          <Switch>
            <Route path={`${path}${PAGE_ROUTES.home.path}`}>
              <Home />
              {iOSAddToHomeScreen}
            </Route>

            <Route path={[`${path}${PAGE_ROUTES.productDetails.path}`]}>
              <ProductPage />
              {iOSAddToHomeScreen}
            </Route>

            <Route path={`${path}${PAGE_ROUTES.catalog.path}`}>
              <Catalog />
              {iOSAddToHomeScreen}
            </Route>

            {featureFlags?.showFavourites ? (
              <Route path={`${path}${PAGE_ROUTES.favourites.path}`}>
                <Favourites />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            {featureFlags?.showDiscover ? (
              <Route exact path={`${path}${PAGE_ROUTES.discover.path}`}>
                <Discover />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            {featureFlags?.showDiscover ? (
              <Route path={`${path}${PAGE_ROUTES.discoverArticle.path}`}>
                <DiscoverDetails />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            {featureFlags?.showPromotionPage ? (
              <Route path={`${path}${PAGE_ROUTES.promotions.path}`} exact>
                <Promotions />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            {featureFlags?.showPromotionPage ? (
              <Route path={`${path}${PAGE_ROUTES.promotionDetails.path}`}>
                <PromotionDetails />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            {featureFlags?.showPrizeLottery ? (
              <Route path={`${path}${PAGE_ROUTES.prizeLottery.path}`}>
                <PrizeLottery />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            <Route path={`${path}${PAGE_ROUTES.profile.path}`}>
              <Profile />
            </Route>

            {featureFlags.enableFAQs ? (
              <Route path={`${path}${PAGE_ROUTES.faqs.path}`}>
                <FAQs />
              </Route>
            ) : null}

            <Route path={`${path}${PAGE_ROUTES.orderDetails.path}`}>
              <OrdersDetails />
              {iOSAddToHomeScreen}
            </Route>

            <Route path={`${path}${PAGE_ROUTES.orders.path}`}>
              <Orders />
              {iOSAddToHomeScreen}
            </Route>

            <Route path={`${path}${PAGE_ROUTES.checkout.path}`}>
              <Checkout />
              {iOSAddToHomeScreen}
            </Route>

            {featureFlags.enableRewards ? (
              <Route path={`${path}${PAGE_ROUTES.rewards.path}`}>
                <RewardPoints />
                {iOSAddToHomeScreen}
              </Route>
            ) : null}

            <Redirect from={path} to={`${path}${PAGE_ROUTES.home.path}`} />
          </Switch>
        </Suspense>

        <Cart />
        <AlternativesModal />
        <PromotionalModal />
        <SearchModal />
      </main>
    </DistributorContext.Provider>
  );
};

export { Main };
