import { Text, TextAlign, TextStyle } from '@carlsberggroup/malty.atoms.text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CongratulationGift } from '../../../../assets/CongratulationGift.svg';
import styles from '../../PrizeLottery.module.scss';
import { CircularProgressbar } from '../CircularProgressBar';

type Props = {
  total: number;
  completed: number;
  progressLabel?: string;
};

export const CircularProgressWrapper: React.FC<Props> = ({ total, completed, progressLabel }) => {
  const { t } = useTranslation();

  return (
    <>
      {completed === total ? (
        <>
          <CongratulationGift data-testid="huge-gift-icon" />
          <Text textStyle={TextStyle.SmallDefault} className={styles['bolder-text']}>
            {`${t('entries.prizeLottery.congratulationMessage', {
              defaultValue: 'Congratulations!!'
            })}`}
          </Text>
          <Text textStyle={TextStyle.SmallDefault} align={TextAlign.Center}>
            {`${t('entries.prizeLottery.congratulationMessageDetails', {
              defaultValue: 'You are now in a lottery pool prize, and will be contacted next week with the prize'
            })}`}
          </Text>
        </>
      ) : (
        <CircularProgressbar total={total} completed={completed} label={progressLabel} />
      )}
    </>
  );
};
