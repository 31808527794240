import cn from 'classnames';
import keen from 'keen-slider/keen-slider.scss';
import { useKeenSlider } from 'keen-slider/react';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { CAROUSEL_SLIDES_PER_VIEW } from '../../global/constants';
import { useGuttersPerSize } from '../../global/custom-hooks/useGuttersPerSize';
import { useMountedState } from '../../global/custom-hooks/useMountedState';
import { useScreenSize } from '../../global/custom-hooks/useScreenSize';
import { mergeTheme } from '../../global/utils/mergeTheme';
import { setAriaLabelOnVisibleSlides } from '../../global/utils/setAriaLabelOnVisibleSlides';
import { defaultTheme } from './CarouselDefaultTheme';
import { CarouselProps } from './CarouselProps';

export type CarouselTheme = Partial<typeof defaultTheme>;

const Carousel: FC<CarouselProps> = ({
  children,
  autoplay = false,
  interval = 5000,
  trackingItems = () => null,
  slidesPerView = CAROUSEL_SLIDES_PER_VIEW,
  moveSlideDuration = 1000,
  dataTestId = 'carousel',
  theme
}) => {
  const mergedTheme = useMemo(() => mergeTheme<typeof defaultTheme>(defaultTheme, theme), [defaultTheme, theme]);
  const resizeTimerId = useRef(null);
  const moveTimerId = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMounted = useMountedState();

  const totalSlides = children.length;
  const shouldLoop = totalSlides > slidesPerView;
  const { isXSmall, isSmall } = useScreenSize();
  const spacing = useGuttersPerSize();

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    controls: isXSmall || isSmall,
    initial: 0,
    slidesPerView,
    spacing,
    loop: shouldLoop,
    dragEnd: () => {
      if (isMounted() && sliderRef.current) {
        clearInterval(moveTimerId.current);
      }
    },
    slideChanged: (sliderInstance) => {
      const firstVisibleSlideIndex = sliderInstance.details().relativeSlide;
      if (isMounted() && sliderRef.current) {
        setCurrentSlide(firstVisibleSlideIndex);
        setAriaLabelOnVisibleSlides(firstVisibleSlideIndex, slidesPerView, sliderRef.current.children);
      }
    },
    created: (sliderInstance) => {
      if (sliderRef.current) {
        resizeTimerId.current = setTimeout(() => {
          sliderInstance.resize();
        }, 500);
      }
    }
  });

  useEffect(() => () => clearTimeout(resizeTimerId.current), []);

  useEffect(() => {
    if (autoplay && isMounted()) {
      moveTimerId.current = setInterval(() => {
        if (slider) {
          sliderRightClick(false);
        }
      }, interval);
    }
    return () => {
      clearInterval(moveTimerId.current);
    };
  }, [slider]);

  useEffect(() => {
    // Center slides when sliders are deleted and are less than slides per view
    if (slider) {
      slider.destroy();
      if (totalSlides > slidesPerView) {
        slider.refresh();
      }
      const { relativeSlide } = slider.details();
      setCurrentSlide(relativeSlide);
      slider.moveToSlide(0, moveSlideDuration);
    }
  }, [totalSlides]);

  const sliderLeftClick = () => {
    const { relativeSlide, absoluteSlide } = slider.details();

    const isFirstSlide = relativeSlide === 0;
    const isZeroOrNegativeTargetSlide = relativeSlide - slidesPerView <= 0;
    let slidesPerScroll = slidesPerView;

    if (!isFirstSlide && isZeroOrNegativeTargetSlide) {
      slidesPerScroll = relativeSlide;
    }

    slider.moveToSlide(absoluteSlide - Math.abs(slidesPerScroll), moveSlideDuration);

    clearInterval(moveTimerId.current);
  };

  const sliderRightClick = (shouldStopAnimation = true) => {
    if (shouldStopAnimation) {
      clearInterval(moveTimerId.current);
    }
    const { relativeSlide, absoluteSlide } = slider.details();
    const targetSlide = relativeSlide + slidesPerView;
    let slidesPerScroll = slidesPerView;

    if (targetSlide + slidesPerView > totalSlides && targetSlide !== totalSlides) {
      if (relativeSlide === totalSlides - 1) {
        slidesPerScroll = 1;
      } else {
        slidesPerScroll = totalSlides - targetSlide;
      }
    }

    slider.moveToSlide(absoluteSlide + slidesPerScroll, moveSlideDuration);
  };

  const handleLeftClick = () => {
    sliderLeftClick();
  };

  const handleRightClick = () => {
    sliderRightClick();
  };

  const dotIndices = [...new Array(Math.ceil(totalSlides / slidesPerView)).keys()];
  const currentDotActive = Math.ceil(currentSlide / slidesPerView);
  useEffect(() => {
    if (currentDotActive >= 0 && currentDotActive < dotIndices.length) {
      trackingItems(currentDotActive);
    }
  }, [currentDotActive]);

  const isCurrentDotActive = (dotIndex: number) => currentDotActive === dotIndex;

  const handleDotClick = (idx: number) => {
    const { absoluteSlide } = slider.details();

    const absTotalSlides = totalSlides + Math.floor(absoluteSlide / totalSlides) * totalSlides;

    let targetSlide = absTotalSlides - (totalSlides - slidesPerView * idx);

    if (targetSlide + slidesPerView > absTotalSlides) {
      targetSlide = absTotalSlides - slidesPerView;
    }

    if (absoluteSlide !== targetSlide) {
      slider.moveToSlide(targetSlide, moveSlideDuration);
      clearInterval(moveTimerId.current);
    }
  };

  if (!totalSlides) {
    return (
      <div className={mergedTheme.carouselContainer}>
        <div className={mergedTheme.itemsPlaceholder} />
      </div>
    );
  }

  if (totalSlides < slidesPerView) {
    return (
      <div className={cn(mergedTheme.carouselContainer, mergedTheme.alignItemsLeft)} style={{ gap: spacing }}>
        {children}
      </div>
    );
  }

  return (
    <div className={mergedTheme.carouselContainer} data-testid={dataTestId}>
      <div ref={sliderRef} className={cn(mergedTheme.itemsWrapper, ['keen-slider'], keen['keen-slider'])}>
        {children.map((child, index) => (
          <div
            key={(index as unknown) as string}
            className={cn(mergedTheme.item, ['keen-slider__slide', keen['keen-slider__slide']])}
            data-testid={`carousel-slide-${index}`}
          >
            <div className={mergedTheme.slide}>{child}</div>
          </div>
        ))}

        {slider && shouldLoop && (
          <>
            <button
              aria-label="arrow-left"
              type="button"
              onClick={handleLeftClick}
              className={cn(mergedTheme.arrow, mergedTheme.arrowLeft)}
              data-testid={`${dataTestId}-button-left`}
            >
              <ArrowLeft />
            </button>
            <button
              aria-label="arrow-right"
              type="button"
              onClick={handleRightClick}
              className={cn(mergedTheme.arrow, mergedTheme.arrowRight)}
              data-testid={`${dataTestId}-button-right`}
            >
              <ArrowRight />
            </button>
          </>
        )}
      </div>
      {slider && shouldLoop && (
        <div className={mergedTheme.dots}>
          {dotIndices.map((dotIndex: number) => (
            <button
              aria-label="carousel-dot"
              type="button"
              key={dotIndex}
              onClick={() => handleDotClick(dotIndex)}
              className={cn(mergedTheme.dot, {
                [mergedTheme.dotActive]: isCurrentDotActive(dotIndex)
              })}
              data-testid="carousel-dot"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { Carousel };
