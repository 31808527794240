import { isAmplifyEnv, isDevelopment } from '../global/utils/environment';

const marketCodeMapping: { [key: string]: string } = {
  la: 'laos',
  my: 'malaysia',
  ru: 'russia',
  lt: 'lithuania',
  lv: 'latvia',
  ee: 'estonia'
};

const getCountryName = () => {
  const market = process.env.MARKET;
  return marketCodeMapping[market] ?? marketCodeMapping.la;
};

export const getBaseUrl = () => {
  try {
    if (isDevelopment || isAmplifyEnv) {
      return `https://csplus-${getCountryName()}.${process.env.ENV || 'dev'}.cx-apps.io`;
    }

    return window.location.origin;
  } catch (err) {
    return null;
  }
};
